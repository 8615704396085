<template>
  <v-col
    v-for="button in activeSettings"
    :key="button.id"
    class="pa-0 ma-0"
  >
    <v-btn-toggle
      v-model="grade"
      density="comfortable"
      class="hidden-sm-and-down grade-buttons-sm-and-up rounded-0 w-100"
    >
      <v-btn
        size="sm"
        density="compact"
        :readonly="readonly"
        :active="button.id === grade"
        :value="button.id"
        style="width:100%"
        :class="getCssBackground(button.id === grade, button)"
      >
        {{ button.text }}
      </v-btn>
      <v-tooltip
        v-if="button.description.length > 0"
        location="top"
        activator="parent"
      >
        <span>{{ button.description }}</span>
      </v-tooltip>
    </v-btn-toggle>
    <v-alert
      v-model="error"
      variant="tonal"
      color="error"
      icon="warning"
      closable
    >
      {{ errorMessage }}
    </v-alert>
  </v-col>
</template>

<script>
import {putTeacherEffort, putStudentEffort} from '@/utils/effort-tracking-api'
import {UserType} from "@/utils/settings";
import {globalStore} from "@/main";
import {saveEffortsData} from "@/api/teachersApi";

export default {
  name: 'GradingButtons',
  props: {
    value: {
      type: Array,
      required: true
    },
    settings: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      required: true
    },
    active: {
      type: Number,
      required: true
    },
    classId: {
      type: Number,
      required: true
    }
  },
  emits:['grade-changed','save-failed'],
  data: function () {
    return {
      grade: Number(this.value[0].grade),
      error: false,
      errorMessage: 'Warning – Connection failed - changes may not have been stored',
      gradeButtonText1: '1 - Unsatisfactory',
      gradeButtonText2: '2 - Fair',
      gradeButtonText3: '3 - Good',
      gradeButtonText4: '4 - Very good',
      gradeButtonText5: '5 - Outstanding',
      buttonDescription1: '',
      buttonDescription2: '',
      buttonDescription3: '',
      buttonDescription4: '',
      buttonDescription5: '',
      activeSettings: []
    }
  },
  computed: {
    globalStore() {
      return globalStore
    }
  },
  watch: {
    grade: {
      handler() {
        console.log('On grade watch handler...');
        if (this.grade === 0) {
          return
        }
        let user = this.$store.state.usertype;
        const route = this.$route.name;
        if (user === UserType.Admin || user === UserType.MiddleManager && route === 'TeacherEfforts') {
          user = UserType.Teacher;
        }

        if (user === UserType.Admin && route === 'StudentEfforts') {
          user = UserType.Student;
        }


        this.value[0].grade = this.grade != null ? this.grade : 0;
        this.updateGrade(user, this.value[0]);
      },
    }
  },

  mounted() {
    const element = document.getElementsByClassName("effort-grades");
    element[0].style.display = 'inline';
    this.update();
  },

  methods: {
    getCssBackground(isActive, button) {

      if (this.readonly && !isActive) {
        return `readOnly `
      }
      if (this.readonly && isActive) {
        return `bg-green opacity-100 elevation-22  `
      }
      if (isActive) {
        return `bg-green opacity-100`
      }
      if (!isActive) {
        return `bg-green-lighten-${button.classId} opacity-70`
      }
    },
    updateGrade(usertype, data) {
      this.error = false;
      if (this.$route.name === 'StudentEfforts') {
        putStudentEffort(data)
        .then(
            () => {
              this.$emit('grade-changed', data, this.classId);
            }
        ).catch(() => {
          this.error = true;
          this.$emit('save-failed');
        });
      }
      else {
        if (usertype !== UserType.Student) {
          data.reportingPeriodId = globalStore.selectedPeriod;
          saveEffortsData(data)
          .then(
              () => {
                this.$emit('grade-changed', data, this.classId);
              }
          ).catch(
              () => {
                this.error = true;
                this.$emit('save-failed');
              }
          );
        }
      }
    },
    update() {
      const buttons = [];
      const descriptions = [];
      this.settings.forEach((element) => {
        let text = '';
        if (element.key.indexOf('text') !== -1) {
          text = element.value;
          buttons.push(text);
        }

        let descrip = '';
        if (element.key.indexOf('description') !== -1) {
          descrip = element.value;
          descriptions.push(descrip);
        }
      });

      let classId = 5;
      this.activeSettings = [];

      for (let i = 1; i < 6; i++) {
        const setting = {
          id: i,
          text: buttons[i - 1],
          description: descriptions[i - 1],
          classId: classId
        };
        classId--;

        this.activeSettings.push(setting);
      }
    }
  }
}
</script>

<style scoped>
.grade-buttons-sm-and-up,
.grade-buttons-sm-and-down {
  width: 100%;
}

.grade-buttons-sm-and-up-btn {
  width: 20%;
}

.grade-buttons-sm-and-up btn span {
  width: 200px;
}

.grade-buttons-sm-and-down .btn {
  width: 20%;
}

.grade-text {
  font-size: 12px;
}

.btn-grade {
  width: 20% !important;
}

.btn-grade .v-btn {
  width: 100% !important;
}


</style>
