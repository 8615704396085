import {
    BASE_URL,
    deleteData,
    postData,
    getDataFromUrl,
    addQueryToUrl,
} from '@/utils/effort-tracking-api'

import {Routes} from '@/utils/settings'

export function addMiddleManager(teacherId) {
    const url = `${BASE_URL}${Routes.TeachersRoute}/middle-manager/${teacherId}`;
    return postData(url);
}

export function removeMiddleManager(teacherId) {
    const url = `${BASE_URL}${Routes.TeachersRoute}/middle-manager/${teacherId}`;
    return deleteData(url);
}

export function getMiddleManager(middleManagerId){
    let url = `${BASE_URL}${Routes.TeachersRoute}/teacher/${middleManagerId}`;
    return getDataFromUrl(url);
}

export function getMiddleManagerClassesByPeriod( periodId, serverFilters) {
    let url = `${BASE_URL}${Routes.ClassesRoute}/middle-manager/period/${periodId}`;
    url = addQueryToUrl(url, serverFilters);
    return getDataFromUrl(url);
}

export function getClassesByPeriodAndMiddleManager( periodId ,middleManagerId, serverFilters) {
    let url = `${BASE_URL}${Routes.ClassesRoute}/period/${periodId}/middle-manager/${middleManagerId}`;
    url = addQueryToUrl(url, serverFilters);
    return getDataFromUrl(url);
}

export function getMiddleManagerClasses(serverFilters) {
    let url = `${BASE_URL}${Routes.ClassesRoute}/middle-manager`;
    url = addQueryToUrl(url, serverFilters);
    return getDataFromUrl(url);
}

export function getClassesByAssociation(middleManagerId, serverFilters) {
    let url = `${BASE_URL}${Routes.ClassesRoute}/associations/middle-manager/${middleManagerId}`;
    url = addQueryToUrl(url, serverFilters);
    return getDataFromUrl(url);
}

export function getClassesByAssociationAndPeriod(periodId, middleManagerId, serverFilters) {
    let url = `${BASE_URL}${Routes.ClassesRoute}/associations/middle-manager/${middleManagerId}/period/${periodId}`;
    url = addQueryToUrl(url, serverFilters);
    return getDataFromUrl(url);
}

export function addAssociations(middleManagerId , periodId, classIds, addAll){
    const url = `${BASE_URL}${Routes.ClassesRoute}/associations`;

    const data = {
        middleManagerId : Number(middleManagerId),
        periodId : Number(periodId),
        classIds : classIds.length > 0 ? classIds : [],
        addAll: addAll,
    }
    return postData(url, JSON.stringify(data));
}

export function removeAllAssociations(middleManagerId) {
    const url = `${BASE_URL}${Routes.ClassesRoute}/middle-manager/${middleManagerId}`
    return deleteData(url);
}

export function removeAssociation(middleManagerId, classId) {
    const url = `${BASE_URL}${Routes.ClassesRoute}/middle-manager/${middleManagerId}/class/${classId}`;
    return deleteData(url);
}

export function getManagedTeacherByPeriod(periodId, middleManagerId, serverFilter){
    let url = `${BASE_URL}${Routes.TeachersRoute}/manager/${middleManagerId}/teachers/${periodId}`;
    return postData(url, serverFilter);
}
