import {Enumify} from "enumify";
export class Routes extends Enumify {
    static TeachersRoute = 'v2/teachers';
    static StudentsRoute = 'v2/students';
    static ClassesRoute = 'v2/classes';
    static SettingsRoute = 'v2/settings';
    static AccountsRoute = 'v2/accounts';
    static PeriodsRoute = 'v2/periods';
    static StatusRoutes = 'v2/status';
    static EffortsRoutes = 'v2/efforts';
    static AuthRoute = 'v2/auth'
    static DataRoute = 'v2/data'
    static PublicRoute = 'v2/public'
    static ChartRoute = 'v2/chart'
}

export class Emit extends Enumify {
    static ActivesChanged = 'actives-changed';
    static PeriodAdded = 'period-added';
    static SelectedDeleted = 'selected-deleted';
    static ShowPeriodNotification = 'show-period-notification';
    static PeriodSelected = 'period-selected';
}

export class Setting extends Enumify {
    static MiddleManager = 'MiddleManager';
    static TeachersDefaultPassword = 'TeachersDefaultPassword';
    static StudentsDefaultPassword = 'StudentsDefaultPassword';
    static ShowTeacherNameInEffortGrades = 'ShowTeacherNameInEffortGrades';
    static AdditionalLogo ='AdditionalLogo';
    static WelcomeText = 'WelcomeText';
    static WidgetId = 'WidgetId'
    static BackgroundImageUrl = 'BackgroundImageUrl';
    static DiligenceStudentDescription = 'DiligenceStudentDescription';
    static DiligenceTeacherDescription = 'DiligenceTeacherDescription';
    static DiligenceCustomName = 'DiligenceCustomName';
    static EngagementStudentDescription = 'EngagementStudentDescription';
    static EngagementTeacherDescription = 'EngagementTeacherDescription';
    static EngagementCustomName = 'EngagementCustomName';
    static BehaviourStudentDescription = 'BehaviourStudentDescription';
    static BehaviourTeacherDescription = 'BehaviourTeacherDescription';
    static BehaviourCustomNam = 'BehaviourCustomNam';
    static MiddleManagerDeleteClass = 'MiddleManagerDeleteClass';
    static MiddleManagerHideClass = 'MiddleManagerHideClass';
    static MiddleManagerInspectStudents = 'MiddleManagerInspectStudents';
    static MiddleManagerManageTeachers = 'MiddleManagerManageTeachers';
}


export class Defaults extends Enumify {
    static TeachersDefaultPassword = 'bZ7a!B9jq';
    static StudentsDefaultPassword = 'effort23';
    static TimeOutMs = 2000;
    static MaxRetries = 3;
    static AlertError = 'error';
    static AlertSuccess = 'success'
    static ShowPasswordIcon = 'mdi-eye';
    static HidePasswordIcon = 'mdi-eye-off';
    static ShowPassword = 'text';
    static HidePassword = 'password'

}

export class UserType extends Enumify {
    static Teacher = 'teacher';
    static Student = 'student';
    static MiddleManager = 'middlemanager';
    static Admin = 'admin';
    static SuperAdmin = 'superadmin';

}
export class LocalStorageKey extends Enumify {
    static MiddleManagerid = 'middlemanagerid';
}
