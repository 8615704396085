import {
    BASE_URL,
    getDataFromUrl,
    addQueryToUrl, deleteData,
} from '@/utils/effort-tracking-api';

import {Routes} from '@/utils/settings'

export function getStudent(studentId){
    const url = `${BASE_URL}${Routes.StudentsRoute}/student/${studentId}`;
    return getDataFromUrl(url);
}
export function countStudentsInPeriod(periodId){
    let url = `${BASE_URL}${Routes.StudentsRoute}/period/${periodId}/count`;
    return getDataFromUrl(url);
}

export function getStudentEfforts(studentId, periodId) {
    const url = `${BASE_URL}${Routes.StudentsRoute}/student/${studentId}/period/${periodId}`
    return getDataFromUrl(url)
}

export function getStudentsInClasses(periodId, classId, serverFilters) {
    let url = `${BASE_URL}${Routes.StudentsRoute}/class/${classId}/period/${periodId}`;
    url = addQueryToUrl(url, serverFilters);
    return getDataFromUrl(url);
}

export function getStudentsCohortByPeriod(periodId, serverFilters) {
    let url = `${BASE_URL}${Routes.StudentsRoute}/period/${periodId}/cohort`;
    url = addQueryToUrl(url, serverFilters);
    return getDataFromUrl(url);
}

export function removeStudentFromClass(periodId, studentId){
    const url = `${BASE_URL}${Routes.StudentsRoute}/student/${studentId}/classes/period/${periodId}`;
    return deleteData(url);
}

export function getCompletedAndVisiblePeriods(studentId) {
    const url = `${BASE_URL}${Routes.StudentsRoute}/student/${studentId}/periods/complete`;

    return getDataFromUrl(url);
}
