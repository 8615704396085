<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>
        Data Import
      </v-toolbar-title>
      <v-spacer />
      <counters-component ref="counters" />
    </v-toolbar>
    <v-tabs
      v-model="active"
      variant="text"
      hide-slider="hide-slider"
      class="tab-bar"
    >
      <v-tab
        value="tab-1"
        class="fix-tab-bg"
      >
        Efforts
      </v-tab>

      <v-tab
        value="tab-2"
        class="fix-tab-bg"
      >
        Academic
      </v-tab>
    </v-tabs>
    <v-window v-model="active">
      <!-- upload efforts-->
      <v-window-item value="tab-1">
        <v-card>
          <v-expansion-panels
            v-model="panel"
            variant="accordion"
          >
            <v-expansion-panel
              value="uploadbar"
            >
              <v-expansion-panel-title :class="panel === 'uploadbar' ? 'bg-grey-lighten-3':'' ">
                Upload Data
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-alert

                  v-model="alertErrorPeriod"
                  variant="tonal"
                  type="error"
                  closable
                >
                  {{ alertMessagePeriod }}
                </v-alert>

                <v-file-input
                  v-model="chosenFile"
                  style="margin-top: 20px;"
                  label="Efforts file"
                  accept="text/csv"

                  variant="plain"
                  @change="filesChange"
                >
                  <template #prepend-inner>
                    <v-btn base-color="grey-lighten-3">
                      Choose File
                    </v-btn>
                  </template>
                </v-file-input>

                <div class="d-flex justify-end">
                  <v-btn
                    class="text-blue-darken-5"
                    variant="text"
                    density="compact"
                    href="Example.csv"
                    download
                  >
                    <span class="text-blue-grey-darken-4  text-decoration-underline text-capitalize">Download Example CSV</span>
                  </v-btn>
                </div>
                <h2>Options</h2>

                <v-radio-group v-model="selectedOption">
                  <v-radio
                    v-for="n in options"
                    :key="n.id"
                    :label="n.text"
                    :value="n.id"
                  />
                </v-radio-group>
                <v-btn
                  color="success"
                  :disabled="disabledImport"
                  @click.stop="loadDialog()"
                >
                  Import
                </v-btn>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel
              value="uploadProgress"
            >
              <v-expansion-panel-title
                :class="showUploadProgress || panel === 'uploadResults' ? 'bg-grey-lighten-3':'' "
              >
                Data Upload Progress
              </v-expansion-panel-title>

              <v-expansion-panel-text class="pt-4 px-4">
                <v-progress-linear
                  class="mb-4"
                  color="success"
                  :indeterminate="loading"
                  :active="show"
                />

                <i>Please be patient, it can take a while...</i>

                <div
                  class="vue3-easy-data-table__main fixed-header hoverable mt-6"
                  v-html="progressReport"
                />
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel
              value="uploadResults"
            >
              <v-expansion-panel-title
                :class="panel === 'uploadResults' || showUploadResults ? 'bg-grey-lighten-3':'' "
              >
                Data Import Results
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <p><strong>{{ teachersProcessed }}</strong> Teachers processed.</p> <br>

                <p><strong>{{ classesProcessed }}</strong> Classes processed.</p> <br>

                <p><strong>{{ studentsProcessed }}</strong> Students processed.</p> <br>

                <p><strong>{{ processedAcademicScores }}</strong> Academic Scores processed.</p> <br>

                <p><strong>{{ errors }}</strong> Errors found</p> <br>

                <ul style="margin-left:40px">
                  <li
                    v-for="err in errorItems"
                    :key="err.Id"
                    style="color:red"
                  >
                    {{ err.value }}
                  </li>
                </ul>

                <br>
                <br>
                <p v-if="emailSent">
                  <strong>An email has been sent to admin users with these results</strong>
                </p> <br>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-window-item>
      <!-- upload academic-->
      <v-window-item value="tab-2">
        <v-card>
          <v-expansion-panels
            v-model="academicPanel"
            variant="accordion"
          >
            <v-expansion-panel
              value="uploadbarAcademic"
            >
              <v-expansion-panel-title :class="academicPanel === 'uploadbarAcademic' ? 'bg-grey-lighten-3':'' ">
                Upload Academic Data
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-file-input
                  v-model="fileAcademic"
                  style="margin-top: 20px;"
                  label="Academic Data File"
                  accept="text/csv"
                  prepend-icon=""
                  variant="plain"
                  @change="filesAcademicChange"
                >
                  <template #prepend-inner>
                    <v-btn base-color="grey-lighten-3">
                      Choose File
                    </v-btn>
                  </template>
                </v-file-input>

                <div class="d-flex justify-end">
                  <v-btn
                    variant="text"
                    href="Example-Academic.csv"
                    download
                  >
                    <span class="text-blue-grey-darken-4  text-decoration-underline text-capitalize">Download Example CSV</span>
                  </v-btn>
                </div>


                <h2>Options</h2>

                <v-radio-group v-model="selectedOptionAcademic">
                  <v-radio
                    v-for="n in optionsAcademic"
                    :key="n.id"
                    :label="n.text"
                    :value="n.id"
                  />
                </v-radio-group>


                <v-btn
                  color="success"
                  :disabled="disabledImportAcademic"
                  @click.stop="loadDialogAcademic()"
                >
                  Import
                </v-btn>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel
              value="uploadProgressAcademic"
            >
              <v-expansion-panel-title
                :class="showUploadProgressAcademic || academicPanel === 'uploadProgressAcademic' ? 'bg-grey-lighten-3':'' "
              >
                Upload Academic Data
              </v-expansion-panel-title>
              <v-expansion-panel-text class="mt-4">
                <v-progress-linear
                  color="green"
                  :indeterminate="loadingAcademic"
                  :active="showAcademic"
                />

                <i>Please be patient, it can take a while...</i>

                <div
                  class="vue3-easy-data-table__main fixed-header hoverable mt-4"
                  v-html="progressReportAcademic"
                />
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel
              value="uploadResultsAcademic"
            >
              <v-expansion-panel-title
                :class="showUploadResultsAcademic || academicPanel === 'uploadResultsAcademic' ? 'bg-grey-lighten-3':'' "
              >
                Academic Data Import Results
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <p><strong>{{ processedAcademicScores }}</strong> Academic Scores processed.</p> <br>

                <p><strong>{{ importedAcademicScores }}</strong> Academic Scores imported.</p> <br>

                <div v-if="alertsAcademic > 0">
                  <p><strong>{{ alertsAcademic }}</strong> Alerts found</p> <br>

                  <ul style="margin-left:40px">
                    <li
                      v-for="alr in alertItemsAcademic"
                      :key="alr.Id"
                      style="color:blue"
                    >
                      {{ alr.value }}
                    </li>
                  </ul>
                </div>

                <div v-if="errorsAcademic > 0">
                  <p><strong>{{ errorsAcademic }}</strong> Errors found</p> <br>

                  <ul style="margin-left:40px">
                    <li
                      v-for="err in errorItemsAcademic"
                      :key="err.Id"
                      style="color:red"
                    >
                      {{ err.value }}
                    </li>
                  </ul>
                </div>

                <div v-if="emailSent">
                  <br>
                  <br>
                  <p><strong>An email has been sent to admin users with these results</strong></p> <br>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-window-item>
    </v-window>

    <!-- import effort dialog -->
    <v-dialog
      v-model="dialog"
      max-width="35%"
    >
      <v-card>
        <v-card-title>
          Confirmation
        </v-card-title>
        <v-card-text>
          This action will import the contents of <b>{{ selectedFileName }}</b>
          into period <b>{{ periodName }}</b>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            @click.stop="dialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            variant="elevated"
            @click.stop="importCsv()"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- import academic data-->
    <v-dialog
      v-model="dialogAcademic"
      max-width="35%"
    >
      <v-card>
        <v-card-title>
          Confirmation
        </v-card-title>
        <v-card-text>
          This action will import the contents of <b> {{ selectedAcademicFileName }} </b>
          into period <b>{{ periodName }}</b>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            @click.stop="dialogAcademic = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            variant="elevated"
            @click.stop="importAcademicCsv()"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="replaceDataDialog"
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          Replace data
        </v-card-title>
        <v-card-text>
          <strong>
            You appear to be uploading some data from assessment tasks which already exist
            - do you wish to replace these entries with the new data ?
            <br><br>
          </strong>
          <v-data-table
            v-model="selected"
            :headers="replaceHeaders"
            :items="replaceItems"
            select-all
            item-key="Id"
            class="elevation-1"
            :rows-per-page-items="[-1]"
          >
            <template #headers="props">
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                >
                  {{ header.text }}
                </th>
                <th>
                  <v-checkbox
                    :input-value="props.all"
                    :indeterminate="props.indeterminate"
                    primary
                    hide-details
                    @click="toggleAll"
                  />
                </th>
              </tr>
            </template>
            <template #items="props">
              <tr
                :active="props.selected"
                @click="props.selected = !props.selected"
              >
                <td>{{ props.item.Task }}</td>
                <td class="text-xs-right">
                  {{ props.item.DueDate }}
                </td>
                <td class="text-xs-right">
                  {{ props.item.Student }}
                </td>
                <td class="text-xs-right">
                  {{ props.item.OldGrade }}
                </td>
                <td class="text-xs-right">
                  {{ props.item.NewGrade }}
                </td>
                <td>
                  <v-checkbox
                    :input-value="props.selected"
                    primary
                    hide-details
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-progress-linear
          :indeterminate="loadingUpdate"
          :active="updateprogress"
        />
        <v-alert
          v-model="alertSuccess"
          variant="tonal"
          type="success"
          closable
        >
          {{ alertMessage }}
        </v-alert>
        <v-alert
          v-model="alertError"
          variant="tonal"
          type="error"
          closable
        >
          {{ alertMessage }}
        </v-alert>
        <v-card-actions v-model="updateTasksActions">
          <v-btn
            color="primary"
            flat
            @click.stop="replaceDataDialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click.stop="updateStudentTasks()"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>

import {
  postCsvFile,
  initialize,
  postAcademicCsvFile,
  GetAcademicImportProgress,
  updateStudentTasks,
  getImportProgress
} from '@/utils/effort-tracking-api';
import CountersComponent from './components/CountersComponent';
import {globalStore} from '@/main';
import {getPeriods} from '@/api/periodApi';
import {sendImportResults} from "@/api/dataApi";

export default {
  components: {
    CountersComponent
  },
  data() {
    return {
      chosenFile: null,
      AcademicFile: null,
      uploadFieldName: '',
      uploadFieldNameAcademic: '',
      fileName: '',
      file: [],
      fileAcademic: [],
      options: [],
      optionsAcademic: [],
      selectedOption: null,
      selectedOptionAcademic: null,

      showUploadProgress: false,
      showUploadProgressAcademic: false,
      showUploadResults: false,
      showUploadResultsAcademic: false,
      show: true,
      showAcademic: false,
      loading: false,
      loadingAcademic: false,
      teachersProcessed: 0,
      studentsProcessed: 0,
      classesProcessed: 0,
      adminStudentsProcessed: 0,
      processedAcademicScores: 0,
      importedAcademicScores: 0,
      errors: 0,
      errorsAcademic: 0,
      alertsAcademic: 0,
      errorItems: [],
      errorItemsAcademic: [],
      alertItemsAcademic: [],
      defaultSelected: true,
      timer: '',
      timerExport: '',
      progressReport: '',
      progressReportAcademic: '',
      progressRetries: 0,
      maxProgressRetries: 3,
      emailSent: false,
      active: null,
      dialog: false,
      dialogAcademic: false,
      periodName: '',
      selectedFileName: '',
      selectedAcademicFileName: '',
      disabledImport: true,
      disabledImportAcademic: true,
      replaceDataDialog: false,
      studentTasksToPrompt: [],
      updatedStudentTasks: [],
      selected: [],
      replaceItems: [],
      replaceHeaders: [
        {text: 'Task', align: 'left', value: 'task', sortable: false},
        {text: 'Due Date', value: 'dueDate', sortable: false},
        {text: 'Student', value: 'student', sortable: false},
        {text: 'Old Grade', value: 'oldGrade', sortable: false},
        {text: 'New Grade', value: 'newGrade', sortable: false}
      ],
      pagination: {},
      updateprogress: false,
      loadingUpdate: false,
      alertSuccess: false,
      alertError: false,
      alertErrorPeriod: false,
      alertMessage: '',
      alertMessagePeriod: '',
      updateTasksActions: true,
      panel: [],
      academicPanel: []
    };
  },
  computed: {
    globalStore() {
      return globalStore;
    }
  },
  mounted() {
    this.uploadbar = false;

    this.showUploadProgress = false;
    this.showUploadProgress = false;

    this.uploadbarAcademic = false;
    this.showUploadProgressAcademic = false;
    this.showUploadResultsAcademic = false;

    this.options.push(
      {id: 1, text: 'Merge with existing data (this will insert new data and update existing records)'},
      {id: 2, text: 'Replace existing data (this will wipe out all existing information, including Effort Grades)'}
    );
    this.optionsAcademic.push(
      {id: 1, text: 'Merge with existing data (this will insert new data and update existing records)'}
    );

    this.selectedOption = 1;
    this.selectedOptionAcademic = 1;
    initialize()
      .then(() => {
        getPeriods()
          .then(periods => {
            if(periods.length === 0) {
              this.disabledImport = true;
              this.periodName = '';
            }
            else {
               let p =  periods.filter(p=> p.periodId === Number(globalStore.selectedPeriod));
                if (p.length > 0){
                  this.periodName = p[0].periodName;
                }
            }
          })
          .catch(ex => console.log(ex));
      })
      .catch(ex => console.log(ex));
  },
  beforeUnmount() {
    clearInterval(this.timer);
    clearInterval(this.timerExport);
  },
  methods: {
    toggleAll() {
      if(this.selected.length) {
        this.selected = [];
      }
      else {
        this.selected = this.replaceItems.slice();
      }
    },
    loadDialog() {
      this.dialog = true;
      this.selectedFileName = this.chosenFile.name;
    },
    loadDialogAcademic() {
      this.dialogAcademic = true;
      this.selectedAcademicFileName = this.fileAcademic[0].name;
    },
    filesChange() {
      const periods = this.$store.state.periods;
      if(periods === undefined || periods.length === 0) {
        this.disabledImport = true;
        this.chosenFile = null;
        this.alertErrorPeriod = true;
        this.alertMessagePeriod = 'Period must be selected to import data';

        setTimeout(() => {
          this.status = 401;
          console.log(this.alertErrorPeriod);
        }, 3000);
      }
      else {
        const periodId = localStorage.getItem('period-selected');
        const period = this.$store.state.periods.filter(p => p.periodId === Number(periodId));
        if(period.length !== 0) {
          if(!period[0].complete) {
            this.disabledImport = false;
            this.alertErrorPeriod = false;
            this.alertMessagePeriod = '';
          }
          else {
            this.disabledImport = true;
            this.chosenFile = null;
            this.alertErrorPeriod = true;
            this.alertMessagePeriod
              = 'This reporting period has been completed. If you wish to update new data, please un-complete the reporting period first (you will then need to complete it again after updating the data).';

            setTimeout(() => {
              this.status = 401;
              console.log(this.alertErrorPeriod);
            }, 3000);
          }
        }
        else {
          this.disabledImport = true;
          this.chosenFile = null;
          this.alertErrorPeriod = true;
          this.alertMessagePeriod = 'An existing and Valid Period must be selected to import data.';

          setTimeout(() => {
            this.status = 401;
            console.log(this.alertErrorPeriod);
          }, 3000);
        }
      }
    },
    filesAcademicChange() {
      this.disabledImportAcademic = false;
    },

    importAcademicCsv() {
      this.disabledImportAcademic = true;
      this.dialogAcademic = false;
      this.uploadbarAcademic = false;

      this.academicPanel = ['uploadProgressAcademic'];

      this.showUploadProgressAcademic = true;
      this.showAcademic = true;
      this.loadingAcademic = true;
      this.showUploadResultsAcademic = false;

      this.progressReport = '';
      this.progressReportAcademic = '';

      this.errors = 0;
      this.errorsAcademic = 0;
      this.alertsAcademic = 0;
      this.errorItems.splice(0);
      this.errorItemsAcademic.splice(0);
      this.alertItemsAcademic.splice(0);

      this.teachersProcessed = 0;
      this.studentsProcessed = 0;
      this.classesProcessed = 0;
      this.processedAcademicScores = 0;
      this.importedAcademicScores = 0;

      const option = this.selectedOptionAcademic;
      postAcademicCsvFile(this.fileAcademic, option)
        .then(result => {
          this.loadingAcademic = false;
          this.progressRetries = 0;
          if(result.status === 200) {
            this.loadingAcademic = true;
            this.timer = setInterval(this.progressUpdateAcademic, 2000);
          }

          this.disabledImportAcademic = false;
        })
        .catch(ex => {
          console.log(ex);
          const message = ex.message;
          this.progress = 100;
          this.loadingAcademic = false;
          this.errorsAcademic = 1;
          this.alertsAcademic = 1;
          this.errorItemsAcademic.push({'id': '0', 'value': message});
          this.alertItemsAcademic.push({'id': '0', 'value': message});
          this.uploadResultsAcademic = true;
        });
    },
    importCsv() {
      this.showUploadProgress = true;
      this.disabledImport = true;
      this.dialog = false;
      this.panel = ['uploadProgress'];
      this.show = true;
      this.loading = true;
      this.showUploadResults = false;

      this.progressReport = '';
      this.progressReportAcademic = '';

      this.errors = 0;
      this.errorsAcademic = 0;
      this.alertsAcademic = 0;
      this.errorItems.splice(0);
      this.errorItemsAcademic.splice(0);
      this.alertItemsAcademic.splice(0);

      this.teachersProcessed = 0;
      this.studentsProcessed = 0;
      this.classesProcessed = 0;
      this.processedAcademicScores = 0;
      this.importedAcademicScores = 0;

      const option = this.selectedOption;
      postCsvFile(this.chosenFile, option)
        .then(result => {
          this.progress = 100;
          this.loading = false;
          this.progressRetries = 0;
          if(result.status === 200) {
            this.loading = true;
            this.timer = setInterval(this.progressUpdate, 2000);
          }
          this.disabledImport = false;
        })
        .catch(ex => {
          console.log(ex);
          let message = ex.message;
          if(ex.response) {
            message = ex.response.data === undefined
                      ? ex
                      : ex.response.data;
          }
          this.progress = 100;
          this.loading = false;
          this.errors = 1;
          this.errorItems.push({'id': '0', 'value': message});
          this.showUploadProgress = false;
          this.showUploadProgress = true;
        });
    },

    progressUpdateAcademic() {
      GetAcademicImportProgress()
        .then(progress => {
          console.log('Getting status...');
          console.log(progress);
          console.log(this.progressRetries);

          this.progressReportAcademic = progress.report;
          if(!progress.isRunning) {
            this.progressRetries++;
            if(this.progressRetries > this.maxProgressRetries) {
              clearInterval(this.timer);
              console.log('progress', progress);
              this.showResultsAcademic(progress.status, progress.report);
            }
          }
        })
        .catch(ex => {
          clearInterval(this.timer);
          let message = ex.message;
          console.log(ex);
          if(ex.response) {
            message = ex.response.data === undefined
                      ? ex
                      : ex.response.data;
          }

          this.loadingAcademic = false;
          this.errorsAcademic = 1;
          this.alertsAcademic = 1;
          this.errorItemsAcademic.push({'id': '0', 'value': message});
          this.alertItemsAcademic.push({'id': '0', 'value': message});
          this.uploadProgressAcademic = false;
          this.uploadResultsAcademic = true;
        });
    },
    progressUpdate() {
      getImportProgress()
        .then(progress => {
          this.progressReport = progress.report;

          if(!progress.isRunning) {
            this.progressRetries++;
            if(this.progressRetries > this.maxProgressRetries) {
              clearInterval(this.timer);
              this.showResults(progress.status, progress.report);
            }
          }
        })
        .catch(ex => {
          clearInterval(this.timer);
          let message = ex.message;
          console.log(ex);
          if(ex.response) {
            message = ex.response.data === undefined
                      ? ex
                      : ex.response.data;
          }

          this.progress = 100;
          this.loading = false;
          this.errors = 1;
          this.errorItems.push({'id': '0', 'value': message});
          this.showUploadProgress = false;
          this.showUploadResults = true;
        });
    },
    showResultsAcademic(results, report) {

      this.uploadProgressAcademic = false;
      //this.uploadResultsAcademic = true;
      this.academicPanel = ['uploadResultsAcademic'];
      this.loadingAcademic = false;
      this.processedAcademicScores = results.processedAcademicScores;
      this.importedAcademicScores = results.importedAcademicScores;

      this.errorsAcademic = results.ErrorsFound;
      this.alertsAcademic = results.AlertsFound;

      results.errorsAcademic.forEach(element => {
        this.errorItemsAcademic.push({'id': '0', 'value': element});
      });

      results.alertsAcademic.forEach(element => {
        this.alertItemsAcademic.push({'id': '0', 'value': element});
      });

      // Send report to admin users
      sendImportResults( {message: report})
        .then(response => {
          console.log(response);
          if(response.success) {
            this.emailSent = true;
          }
        })
        .catch(ex => console.log(ex));
    },
    showResults(results, report) {
      this.showUploadProgress = false;
      this.showUploadResults = true;
      this.panel = ['uploadResults'];
      this.loading = false;
      this.teachersProcessed = results.processedTeachers;
      this.studentsProcessed = results.processedStudents;
      this.classesProcessed = results.processedClasses;
      this.processedAcademicScores = results.processedAcademicScores;
      this.errors = results.ErrorsFound;

      results.errors.forEach(element => {
        this.errorItems.push({'id': '0', 'value': element});
      });

      //Update counters
      this.$refs.counters.updateCounters();

      // Send report to admin users
      sendImportResults({ message: report })
        .then(response => {
          console.log(response);
          if(response.success) {
            this.emailSent = true;
          }
        })
        .catch(ex => console.log(ex));
    },

    updateStudentTasks() {
      console.log('Updating student tasks...');
      this.loadingUpdate = true;
      console.log(this.selected);
      updateStudentTasks(this.selected)
        .then(result => {
          this.alertSuccess = false;
          this.alertError = false;

          console.log(result);
          if(result.status === 200) {
            this.alertSuccess = true;
            this.alertMessage = 'Changes saved successfully';
            this.updateTasksActions = false;

            setTimeout(() => {
              this.studentTasksToPrompt = [];
              this.replaceItems = [];
              this.studentTasksToPrompt = [];
              this.replaceDataDialog = false;
              this.selected = [];
            }, 5000);
          }
          else {
            this.alertError = true;
            this.alertMessage = 'Something went wrong. Please try again';
            this.selected = [];
          }


        })
        .catch(ex => console.log(ex));
    }
  }
};
</script>
<style>
.input-file {
  opacity: 1 !important;
  position: relative;
  height: auto;
}
</style>
