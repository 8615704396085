<template>
  <v-container class="pa-0 ma-0 w-100 mw-100">
    <v-card>
      <v-toolbar class="elevation-1">
        <v-toolbar-title>Reporting Periods</v-toolbar-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-inner-icon="mdi-magnify"
          placeholder="Search"
          variant="solo"
          density="compact"
          style="margin-top: 20px; margin-right: 10px;"
          clearable
        />
        <v-btn
          variant="elevated"
          @click="addPeriod.showDialog = true"
        >
          Add Period
        </v-btn>
      </v-toolbar>
    </v-card>
    <k-grid
      ref="grid"
      :size="'small'"
      class="gridFullHeight"
      :data-items="gridData.items"
      :sort="sort"
      :sortable="true"
      :skip="serverFilters.skip"
      :take="serverFilters.take"
      :total="gridData.totalItems"
      :pageable="pageable"
      :resizable="true"
      :page-size="globalStore.defaultRowsPerPage"
      :columns="gridData.columns"
      :loader="loader"
      @cellclick="cellClick"
      @sortchange="sortChangeHandler"
      @pagechange="pageChangeHandler"
    >
      <template #loader>
        <div class="k-loader-container k-loader-container-md k-loader-top">
          <div class="k-loader-container-overlay k-overlay-dark" />
          <div class="k-loader-container-inner">
            <k-loader
              :size="'large'"
              :type="'pulsing'"
              :theme-color="'success'"
            />
          </div>
        </div>
      </template>
      <template #text="{props}">
        <td class="px-4">
          <span
            v-if="!props.dataItem.periodNameEdit"
            @click="props.dataItem.periodNameEdit = !props.dataItem.periodNameEdit"
          >{{ props.dataItem.periodName }} </span>
          <k-input
            v-if="props.dataItem.periodNameEdit"
            v-model="props.dataItem.periodName"
            :size="'small'"
            :fill-mode="'flat'"
            :variant="globalStore.textFieldVariant"
            @focusout="props.dataItem.periodNameEdit = false"
            @change="update(props.dataItem);props.dataItem.periodNameEdit = false"
          />
        </td>
      </template>
      <template #startDate="{props}">
        <td class="px-4">
          <span
            v-if="!props.dataItem.startDateEdit"
            @click="props.dataItem.startDateEdit = !props.dataItem.startDateEdit"
          >{{ moment(props.dataItem.startDate).format('DD/MM/YYYY') }} </span>
          <k-date-picker
            v-if="props.dataItem.startDateEdit"
            v-model="props.dataItem.startDate"
            :value="new Date(props.dataItem.startDate)"
            :format="'dd/MM/yyyy'"
            :size="'small'"
            :show="props.dataItem.startDateEdit"
            :disabled="props.dataItem.complete"
            :fill-mode="'flat'"
            @click="props.dataItem.startDateEdit = !props.dataItem.startDateEdit"
            @change="update(props.dataItem);props.dataItem.startDateEdit=false;"
          />
        </td>
      </template>
      <template #endDate="{props}">
        <td
          class="px-4"
        >
          <span
            v-if="!props.dataItem.endDateEdit"
            @click="props.dataItem.endDateEdit = !props.dataItem.endDateEdit"
          >
            {{ moment(props.dataItem.endDate).format('DD/MM/YYYY') }}
          </span>
          <k-date-picker
            v-if="props.dataItem.endDateEdit"
            v-model="props.dataItem.endDate"
            :disabled="props.dataItem.complete"
            :value="new Date(props.dataItem.endDate)"
            :format="'dd/MM/yyyy'"
            :min="new Date(props.dataItem.startDate)"
            :size="'small'"
            :fill-mode="'flat'"
            :show="props.dataItem.endDateEdit"
            @click="props.dataItem.endDateEdit = !props.dataItem.endDateEdit"
            @change="update(props.dataItem);props.dataItem.endDateEdit=false"
          />
        </td>
      </template>
      <template #reportingDate="{props}">
        <td class="px-4">
          <k-date-picker
            v-if="props.dataItem.reportingDate > new Date('01-01-2000')"
            v-model="props.dataItem.reportingDate"
            :value="new Date(props.dataItem.reportingDate)"
            :format="'dd/MM/yyyy'"
            :disabled="!props.dataItem.complete"
            :min="new Date(props.dataItem.end)"
            :size="'small'"
            :fill-mode="'flat'"
          />
        </td>
      </template>
      <template #active="{props}">
        <td class="px-4">
          <v-switch
            v-model="props.dataItem.active"
            hide-details
            :disabled="props.dataItem.complete"
            color="success"
            @change="setActive(props.dataItem)"
          />
        </td>
      </template>
      <template #complete="{props}">
        <td class="px-4">
          <v-switch
            v-model="props.dataItem.complete"
            hide-details
            color="success"
            @change="setReportingDate(props.dataItem)"
          />
        </td>
      </template>
      <template #visible="{props}">
        <td class="px-4">
          <v-switch
            v-model="props.dataItem.visible"
            hide-details
            color="success"
            @change="setVisible(props.dataItem)"
          />
        </td>
      </template>
      <template #myTemplate="{props}">
        <td class="px-4">
          <v-btn
            icon="true"
            density="comfortable"
            variant="outlined"
            color="red"
            @click.stop="showDialogDelete(props.dataItem)"
          >
            <v-tooltip activator="parent">
              Delete Reporting Period
            </v-tooltip>
            <v-icon
              small
              icon="mdi-delete"
            />
          </v-btn>
        </td>
      </template>
    </k-grid>

    <!-- Dialog to Add a new reporting period -->
    <v-dialog
      v-model="addPeriod.showDialog"
      max-width="50%"
      persistent
    >
      <v-card ref="formNewPeriod">
        <v-card-title>
          Add Reporting Period
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="addPeriod.newPeriodModel.periodName"
                  label="Name"
                  :error-messages="addPeriod.nameErrorMessage"
                  :variant="globalStore.textFieldVariant"
                  :rules="[v => !!v || 'This field is required']"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <p class="dpTitle">
                  <b>Start Date</b>
                </p>
                <k-calendar
                  v-model="addPeriod.newPeriodModel.startDate"
                />
                <v-alert
                  v-model="addPeriod.noStartDateNotification"
                  style="width: 90%"
                  type="error"
                  density="compact"
                  variant="tonal"
                  closable
                >
                  Please select a start date
                </v-alert>
              </v-col>

              <v-col cols="6">
                <p class="dpTitle">
                  <b>End Date</b>
                </p>
                <k-calendar
                  v-model="addPeriod.newPeriodModel.endDate"
                  :min="new Date(addPeriod.newPeriodModel.startDate)"
                />
                <v-alert
                  v-model="addPeriod.noEndDateNotification"
                  type="error"
                  style="width: 90%"
                  density="compact"
                  variant="tonal"
                  closable
                >
                  Please select an end date
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            @click.stop="resetAddPeriodForm()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            variant="elevated"
            @click="add()"
          >
            Add period
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog to delete a reporting period -->
    <v-dialog
      v-model="removePeriod.showDialog"
      max-width="35%"
    >
      <v-card>
        <v-card-title>
          Confirmation
        </v-card-title>
        <v-card-text>
          This action will <span class="dialogWarningColor">REMOVE</span> all the info related to the period &nbsp; <b>{{
            removePeriod.periodName
          }}</b>
        </v-card-text>
        <v-progress-linear
          :indeterminate="removePeriod.showProgress"
          :active="removePeriod.showProgress"
        />
        <v-alert
          v-model="removePeriod.isSuccess"
          variant="tonal"
          type="success"
          closable
        >
          {{ removePeriod.successMessage }}
        </v-alert>
        <v-alert
          v-model="removePeriod.isError"
          variant="tonal"
          type="error"
          closable
        >
          {{ removePeriod.errorMessage }}
        </v-alert>
        <v-card-actions>
          <v-btn
            color="success"
            variant="text"
            @click.stop="removePeriod.showDialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            variant="elevated"
            @click.stop="emptyPeriod()"
          >
            Remove period
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- This dialog is made to select a reporting date when a period is marked as complete -->
    <v-dialog
      v-model="reportingDate.showDialog"
      max-width="25%"
      persistent
      lazy
      full-width
    >
      <v-card>
        <v-card-title>Set Reporting Date</v-card-title>
        <v-card-text class="d-flex justify-center">
          <k-calendar
            v-model="reportingDate.date"
            :min="selectedPeriod.endDate"
          />
          <v-alert
            v-model="reportingDate.isError"
            style="width: 90%"
            text="reportingDate.errorMessage"
            type="error"
            density="compact"
            variant="tonal"
            closable
          >
            Please select a start date
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            variant="text"
            color="error"
            @click="closeSetReportingDateDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            variant="elevated"
            color="success"
            @click="setComplete(reportingDate.date)"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- This dialog shows the progress of dashboard calculations when a reporting period is marked as complete or incomplete -->
    <v-dialog
      v-model="complete.progress.showDialog"
      max-width="35%"
      persistent
    >
      <v-card>
        <v-card-title>
          Updating Student Dashboard
        </v-card-title>
        <v-card-text align-center>
          <br>
          <br>
          <br>
          <v-progress-linear
            centered
            :indeterminate="complete.progress.calculatingData"
            color="green"
          />
        </v-card-text>
        <v-card-text v-if="complete.showReport">
          <v-container>
            <v-row>
              <v-col><b>Status:</b></v-col>
              <v-col>{{ complete.report.status?.status ?? ' ' }}</v-col>
            </v-row>
            <v-row>
              <v-col><b>Running:</b></v-col>
              <v-col>{{ complete.report.status?.isRunning ?? ' ' }}</v-col>
            </v-row>
            <v-row>
              <v-col><b>Complete:</b></v-col>
              <v-col>{{ complete.report.status?.isDone ?? ' ' }}</v-col>
            </v-row>
            <v-row>
              <v-col><b>Start Time:</b></v-col>
              <v-col>
                {{
                  complete.startDate
                }}
              </v-col>
            </v-row>
            <v-row>
              <v-col><b>End Time</b></v-col>
              <v-col> {{ complete.endDate }}</v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-alert
            v-model="complete.isError"
            type="error"
            density="compact"
            variant="tonal"
            closable
          >
            Error marking period as complete
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click="finishCalculations()"
          >
            Finish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="notification.showDialog"
      max-width="35%"
    >
      <v-card>
        <v-card-title>
          Notify users about new data available for viewing
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="notification.subject"
            label="Subject"
            required
          />
        </v-card-text>
        <v-card-text>
          <v-textarea
            v-model="notification.message"
            height="5em"
            name="input-7-1"
            label="Message"
            required
          />
        </v-card-text>

        <v-alert
          v-model="notification.isSuccess"
          variant="tonal"
          type="success"
          closable
        >
          {{ notification.successMessage }}
        </v-alert>

        <v-alert
          v-model="notification.isError"
          variant="tonal"
          type="error"
          closable
        >
          {{ notification.errorMessage }}
        </v-alert>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            @click.stop="notification.showDialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            variant="elevated"
            @click="sendNotifications()"
          >
            Send notifications
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {initialize} from '@/utils/effort-tracking-api';
import {store} from '@/store';
import {
  getPeriods,
  addPeriod,
  updatePeriod,
  deletePeriod,
  setPeriodComplete,
  setPeriodActive,
  setPeriodVisible,
  sendPeriodNotifications,
  getProgress,
  searchPeriods
} from '@/api/periodApi';

import moment from 'moment';
import {globalStore} from '@/main';
import {debounce} from 'lodash';
import {Grid as KGrid} from '@progress/kendo-vue-grid';
import {Loader as KLoader} from '@progress/kendo-vue-indicators';
import {DatePicker as KDatePicker} from '@progress/kendo-vue-dateinputs';
import {Input as KInput} from '@progress/kendo-vue-inputs';
import {Calendar as KCalendar} from '@progress/kendo-vue-dateinputs';
import {Defaults, Emit} from '@/utils/settings';

export default {
  name: 'ReportingPeriods',
  components: {
    KGrid,
    KInput,
    KDatePicker,
    KLoader,
    KCalendar
  },
  emits: [Emit.ActivesChanged, Emit.PeriodAdded, Emit.SelectedDeleted],
  data() {
    return {
      editItem: undefined,
      changes: false,
      updatedData: [],
      editID: null,
      editField: undefined,
      show: false,
      search: '',
      // Does not work as a child property.
      sort: [{field: 'active', dir: 'desc'}],
      loader: false,
      serverFilters: {
        sortBy: 'active',
        filter: '',
        take: globalStore.defaultRowsPerPage,
        skip: 0,
        descending: true
      },
      pagerValues: {
        pagerType: globalStore.pagerTypes['numeric'],
        pagerButtons: globalStore.pagerButtons,
        pagerInfo: globalStore.pagerInfo,
        pagerSizes: globalStore.pagerSizes,
        pagePrevNext: globalStore.pagerSizes,
        pageSize: globalStore.defaultRowsPerPage,
        pageSizeValue: globalStore.defaultRowsPerPage,
      },
      gridData: {
        columns: [
          {field: 'periodName', title: 'Name', cell: 'text', width: '200px'},
          {field: 'startDate', title: 'Start Date', cell: 'startDate', width: '150px'},
          {field: 'endDate', title: 'End Date', cell: 'endDate', width: '150px'},
          {field: 'reportingDate', title: 'Reporting Date', cell: 'reportingDate', width: '200px'},
          {field: 'active', title: 'Active', cell: 'active', width: '100px'},
          {field: 'complete', title: 'Complete', cell: 'complete', width: '100px'},
          {field: 'visible', title: 'Visible', cell: 'visible', width: '100px'},
          {field: '', cell: 'myTemplate', sortable: false, width: '100px'},
        ],
        items: [],
        totalItems: 0,
      },
      reportingDate: {
        date: '',
        showDialog: false,
        isError: false,
        errorMessage: 'Please select a reporting date',
      },
      actives: [],
      addPeriod: {
        showDialog: false,
        noStartDateNotification: false,
        noEndDateNotification: false,
        nameErrorMessage: '',
        newPeriodModel: {
          periodName: '',
          startDate: '',
          endDate: '',
          active: true,
        },
      },
      removePeriod: {
        periodName: '',
        periodId: 0,
        showDialog: false,
        showProgress: false,
        loading: false,
        isSuccess: false,
        isError: false,
        successMessage: 'Reporting period deleted successfully',
        errorMessage: 'Something went wrong, please try again.',
      },
      notification: {
        subject: 'Effort Tracking Data Available',
        message: '',
        isSuccess: false,
        isError: false,
        showDialog: false,
        periodId: 0,
        successMessage: 'Notifications sent successfully.',
        errorMessage: 'Something went wrong, please try again.',
      },
      complete: {
        progress: {
          retries: 0,
          maxRetries: 0,
          showDialog: false,
          startDate: '',
          endDate:'',
          calculatingData: false,
        },
        isError: false,
        report: {},
        showReport: false,
        showDialog: false,
        periodComplete: false,
      },
      selectedPeriod: null,
      defaultPeriod: 0,
      timer: '',
    };
  },
  computed: {
    globalStore() {
      return globalStore;
    },
    moment() {
      return moment;
    },
    pageable() {
      return {
        buttonCount: this.pagerValues.pagerButtons,
        info: this.pagerValues.pagerInfo,
        type: this.pagerValues.pagerType,
        pageSizes: this.pagerValues.pagerSizes
                   ? globalStore.allowedRowsPerPage
                   : undefined,
        previousNext: this.pagerValues.pagePrevNext,
        pageSizeValue: this.pagerValues.pageSizeValue,
      };
    },
  },
  watch: {
    search: {
      handler() {
        this.debouncedGetDataFromApi();
      }
    },
    actives: function() {
      initialize()
        .then(() => {
          getPeriods()
            .then(periods => {
              store.state.periods = [];

              periods.forEach(period => {
                const p = {
                  periodName: period.periodName,
                  periodId: period.periodId,
                  startDate: new Date(period.startDate),
                  endDate: new Date(period.endDate),
                  active: period.active,
                  complete: period.complete
                };
                store.state.periods.push(p);
              });
              this.$emit(Emit.ActivesChanged);
            })
            .catch(error => {
              console.log('initialize', error);
            });
        });
    },
    periodName() {
      this.errorMessages = '';
    }
  },
  created() {
    this.debouncedGetDataFromApi = debounce(() => {
      this.getDataFromApi();
    }, 500);
  },
  mounted() {
    this.loader = true;
    this.debouncedGetDataFromApi();
    initialize()
      .then(
        () => {
          getPeriods()
            .then(
              periods => {
                localStorage.setItem('active-periods', '');
                store.state.periods = [];
                periods.forEach(
                  period => {
                    const p = {
                      periodName: period.periodName,
                      periodId: period.periodId,
                      startDate: new Date(period.startDate),
                      endDate: new Date(period.endDate),
                      active: period.active,
                      complete: period.complete
                    };

                    store.state.periods.push(p);

                    if(period.active) {
                      this.actives.push(period.periodId);
                    }
                  });

                localStorage.setItem('active-periods', this.actives);
              })
            .catch(error => {
              console.debug('initialize getPeriods', error);
            });
        })
      .catch(error => {
        console.debug('initialize error', error);
        this.loading = false;
      });
    this.loader = false;
  },
  beforeUnmount() {
    //clearInterval(this.timer);
  },
  methods: {
    cellClick: function (e) {
      console.log('cellClick', e);
      console.log('dataItem', e.dataItem.inEdit);
      if (e.dataItem.inEdit === undefined){
        e.dataItem.inEdit = true;
      }
      else {
        e.dataItem.inEdit = !e.dataItem.inEdit;
      }
    },
    exitEdit: function (dataItem, exitEdit) {
      console.log('exitEdit', exitEdit);
      console.log('dataItem', dataItem);
      if (!exitEdit && dataItem.inEdit) {
        return;
      }
      this.gridData.items.forEach((d) => {
        if (d.inEdit) {
          d.inEdit = undefined;
        }
      });
      this.editField = undefined;
    },
    sortChangeHandler: function(e) {
      if(e.sort.length === 0) {
        this.serverFilters.descending = false;
        this.debouncedGetDataFromApi();
        this.sort = e.sort;
        return;
      }
      this.serverFilters.descending = e.sort[0].dir !== 'asc';
      this.serverFilters.sortBy = e.sort[0].field;
      this.debouncedGetDataFromApi();
      this.sort = e.sort;
    },
    pageChangeHandler: function(event) {
      this.pagerValues.pageSize = event.page.take;
      this.pagerValues.pageSizeValue = event.page.take;
      this.serverFilters.skip = event.page.skip;
      this.serverFilters.take = event.page.take;
      this.debouncedGetDataFromApi();
    },
    getDataFromApi() {
      this.loader = true;
      this.serverFilters.filter = this.search;
      searchPeriods(this.serverFilters)
        .then(data => {
          this.actives = [];
          this.gridData.totalItems = data.data.count;
          this.gridData.items = data.data.items;
          this.gridData.items.forEach(d=> {
            d.startDate = new Date(d.startDate);
            d.endDate = new Date(d.endDate);
            d.reportingDate = new Date(d.reportingDate)
          })
          this.$store.state.periods = this.gridData.items;
          this.$emit(Emit.ActivesChanged);
        })
        .catch(error => {
          console.debug('getDataFromApi', error);
        });
      this.loader = false;

    },
    resetAddPeriodForm() {
      this.addPeriod.noStartDateNotification = false;
      this.addPeriod.noEndDateNotification = false;
      this.addPeriod.newPeriodModel.startDate = '';
      this.addPeriod.newPeriodModel.endDate = '';
      this.addPeriod.newPeriodModel.periodName = '';
      this.addPeriod.nameErrorMessage = '';
      this.addPeriod.showDialog = false;
    },
    add() {
      this.addPeriod.noStartDateNotification = this.addPeriod.newPeriodModel.startDate === '';
      this.addPeriod.noEndDateNotification = this.addPeriod.newPeriodModel.endDate === '';
      this.addPeriod.nameErrorMessage = this.addPeriod.newPeriodModel.periodName.length === 0
                                        ? 'Name is required'
                                        : '';
      if(this.addPeriod.noEndDateNotification
        || this.addPeriod.noStartDateNotification
        || this.addPeriod.nameErrorMessage.length > 0) {
        return;
      }
      addPeriod(this.addPeriod.newPeriodModel)
        .then(() => {
          this.resetAddPeriodForm();
          this.debouncedGetDataFromApi();
          this.$emit(Emit.PeriodAdded);
          this.$emit(Emit.ActivesChanged);
        });
    },
    update(reportingPeriod) {
      updatePeriod({
                     periodId: reportingPeriod.periodId,
                     periodName: reportingPeriod.periodName,
                     startDate: moment(new Date(reportingPeriod.startDate))
                       .format('YYYY-MM-DD'),
                     endDate: moment(new Date(reportingPeriod.endDate))
                       .format('YYYY-MM-DD'),
                     reportingDate: moment(new Date(reportingPeriod.reportingDate))
                       .format('YYYY-MM-DD'),
                     active: reportingPeriod.active
                   })
        .then(
          () => {
            this.getDataFromApi();
          })
        .catch(ex => console.log(ex));
    },
    showDialogDelete(period) {
      this.removePeriod.periodName = period.periodName;
      this.removePeriod.showProgress = false;
      this.removePeriod.isSuccess = false;
      this.removePeriod.periodId = period.periodId;
      this.removePeriod.showDialog = true;
    },
    emptyPeriod() {
      this.removePeriod.showProgress = true;
      deletePeriod(this.removePeriod.periodId)
        .then(() => {
          this.removePeriod.showProgress = false;
          this.removePeriod.isSuccess = true;
          setTimeout(() => {
            this.removePeriod.showDialog = false;
            const lsPeriod = localStorage.getItem('period-selected');
            if(this.removePeriod.periodId === lsPeriod) {
              this.$emit(Emit.SelectedDeleted);
            }
          }, Defaults.TimeOutMs);
          this.getDataFromApi();
          this.$emit(Emit.ActivesChanged);
        })
        .catch(() => {
          this.alertError = true;
        });
    },
    setVisible(period) {
      setPeriodVisible({
                         periodId: period.periodId,
                         visible: period.visible})
        .then(() => {
          this.getDataFromApi();
        })
        .catch(ex => {
          console.log(ex);
        });
    },
    setActive(period) {
      setPeriodActive({
                        periodId: period.periodId,
                        active: period.active})
        .then(() => {
          let activePeriods = localStorage.getItem('active-periods')
                                          .split(',');
          const index = activePeriods.indexOf(period.periodId.toString());

          if(index === -1) {
            activePeriods.push(period.periodId.toString());
          }

          if(index !== -1) {
            activePeriods.splice(index, 1);
          }

          localStorage.setItem('active-periods', activePeriods);
          this.getDataFromApi();
          this.$emit(Emit.ActivesChanged);
        })
        .catch(ex => console.log(ex));
    },
    setReportingDate(period) {
      this.notification.periodId = period.periodId;
      if(period.complete) {
        this.selectedPeriod = period;
        this.reportingDate.showDialog = true;
      }
      else {
        this.setIncomplete(period);
      }
    },
    setComplete(reportingDate) {
      console.log('reportingDate', reportingDate)
      if(reportingDate === '') {
        this.reportingDate.isError = true;
        return;
      }
      this.reportingDate.showDialog = false;
      this.complete.progress.retries = 0;
      this.complete.progress.maxRetries = Defaults.MaxRetries;
      this.complete.report = {};
      this.complete.showDialog = false;
      this.complete.periodComplete = true;

      // Start Dashboard calculations
      this.complete.progress.showDialog = true;
      this.selectedPeriod.complete = true;
      this.selectedPeriod.reportingDate = reportingDate;
      setPeriodComplete(this.selectedPeriod)
        .then(
          response => {
            if (response.status !== 200){
              this.complete.progress.showDialog = false;
            }
            this.timer = setInterval(this.dashboardProgress, 200);
          })
        .catch(() => {
           this.complete.isError =true;

        });
    },
    setIncomplete(period) {
      this.complete.report = {};
      this.complete.progress.retries = 0;
      this.complete.progress.maxRetries = Defaults.MaxRetries;
      this.complete.progress.showDialog = true;
      this.complete.progress.calculatingData = true;
      this.complete.periodComplete = false;
      period.complete = false;
      setPeriodComplete(period)
        .then(() => {
          this.timer = setInterval(this.dashboardProgress, Defaults.TimeOutMs);
        })
        .catch(ex => console.log(ex));
    },
    closeSetReportingDateDialog() {
      this.reportingDate.showDialog = false;
      this.reportingDate.isError = false;

      this.getDataFromApi();
    },
    dashboardProgress() {
      getProgress()
        .then(progress => {
          this.complete.progress.calculatingData = true;
          this.complete.report = progress;
          if (progress.isRunning === false) {
            clearInterval(this.timer);
            this.complete.progress.calculatingData = false
            this.complete.progress.showDialog = true;
            if(this.complete.periodComplete) {
              this.complete.progress.showDialog = false;
              this.notification.periodId = this.selectedPeriod.periodId;
              this.notification.message
                = `New data is available for viewing for the period ${this.selectedPeriod.periodName}. \n`
                + `You can login to the app to see your summaries.`;
              this.notification.isError = false;
              this.notification.isSuccess = false;
              this.notification.showDialog = true;
              this.getDataFromApi();
              this.$emit(Emit.ActivesChanged);
            }
            else {
              this.complete.progress.showDialog = false;
              this.getDataFromApi();
              this.$emit(Emit.ActivesChanged);
            }
          }
        })
        .catch(() => {
          clearInterval(this.timer);
        });
    },
    finishCalculations() {
      this.complete.report = {};
      this.complete.progress.showDialog = false;
      this.getDataFromApi();
      this.$emit(Emit.ActivesChanged);
    },
    sendNotifications() {
      sendPeriodNotifications(this.notification.periodId, {
        subject: this.notification.subject,
        message: this.notification.message
      })
        .then(response => {
          if(response.success) {
            this.notification.isSuccess = true;
          }
          else {
            this.notification.isError = true;
          }
          setTimeout(() => {
            this.notification.showDialog = false;
          }, 6000);
        })
        .catch(ex => console.log(ex));
    },
  }
};
</script>

<style>
.default {
  background: rgba(206, 220, 208, 0.5);
}

.dpTitle {
  margin-bottom: 10px;
}

.dp__outer_menu_wrap {
  width: 100%
}

.new-period-data-container {
  text-align: center;
}

.new-period-data-container > .xs6 > .v-picker {
  width: 90%;
}

.new-period-data-container > .xs6 > .v-picker > .v-picker__body {
  width: 90% !important;
}
</style>
