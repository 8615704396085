import 'babel-polyfill'
import {createApp} from 'vue'
import App from './App.vue'
import {store} from './store';
import router from './router'
import 'vuetify/dist/vuetify.js'
import Vuex from 'vuex';
import * as Sentry from "@sentry/vue";
import once from 'once';
const appVersion = require('../package.json').version



// Vuetify 3
import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader


// Custom CSS
import './assets/css/main.css'

const vuetify = createVuetify( {
    components,
    directives,
    icons: {
        defaultSet: 'mdi', // This is already the default value - only for display purposes
    },
    theme: {
        defaultTheme: 'effortTrackingTheme',
        themes: {
            effortTrackingTheme: {
                dark: false,
                variables: {}, // this property is required to avoid Vuetify crash
                colors: {
                    greenish: '#03DAC5',  //green: '#00ff00' // cannot use primary color names here, so use a custom color name (such as 'greenish')
                    // Workaround: Custom colors seem to erase default colors, so we need to include the default colors (of `light` or `dark` theme)
                    accent: '#8c9eff',
                    primary: '#4CAF50',
                    'primary-darken-1': '#3700B3',
                    secondary: '#8BC34A',
                    'secondary-darken-1': '#03DAC5',
                    error: '#b71c1c',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FB8C00'
                },
            }
        }
    }
})

// Font Awesome-related initialization
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faFacebook, faTwitter, faInstagram, faLinkedin, fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'

// Add the specific imported icons
library.add(fas, fab, faFacebook, faTwitter, faInstagram, faLinkedin)


export const globalStore = {
    selectedPeriod: 0,
    selectedPeriodName: '',
    selectedPeriodData: '',
    periodWarning: 'The selected period is not active or it has been completed. All the data is in read only mode.',
    periods: store.state.periods,
    pagerButtons:5,
    pagerInfo: true,
    pagerSizes: true,
    pagePrevNext: true,
    pagerTypes: [
        {
            label: 'Numeric',
            value: 'numeric',
        },
        {
            label: 'Input',
            value: 'input',
        },
    ],
    middleManageRole:'middlemanager',
    defaultRowsPerPage: 100,
    allowedRowsPerPage: [15, 50, 100, 500],
    textFieldVariant: 'underlined',
    alertVariant: '"tonal"',
    passwordRules: [
        v => !!v || 'Password is required',
        v => !!v && v.length > 6 || 'Password must be greater than 6 characters',
        v => v && /\d/.test(v) || 'At least one digit',
        v => v && /[A-Z]{1}/.test(v) || 'At least one capital latter',
        v => v && /[^A-Za-z0-9]/.test(v) || 'At least one special character'
    ],
    emailRules: [
        v => !!v || 'E-mail is required',
        v => !!v && /^\w+([.-]?'?\w+)*@\w+([.-]\w+)+$/.test(v) || 'E-mail must be valid']
    ,
    generatePasswordSettings: {
        maxLength: 64,
        maxDigits: 10,
        maxSymbols: 10,
        length: 12,
        digits: 4,
        symbols: 2,
        ambiguous: true,
    },
}

const build = `EffortTrackingVue3@${appVersion}`
const app = createApp(App)

Sentry.init({
    app,
    dsn: "https://4c431e26c46cb5e7918fc8ed5052d0bb@o4507508018053120.ingest.us.sentry.io/4507657047703552",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    release: build,
    environment: process.env.NODE_ENV,
    
});
app.use(router)
app.use(store)
app.use(vuetify)
app.use(Vuex)
app.use(once)
app.component('FontAwesomeIcon', FontAwesomeIcon)

app.mount('#app')
