import {
  BASE_URL,
  postData,
} from '@/utils/effort-tracking-api'

import {Routes} from '@/utils/settings'

export function userLogin(model){
  const url =`${BASE_URL}${Routes.AuthRoute}/login`;
  return postData(url, model)
}
