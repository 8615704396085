<template>
  <v-list
    :model="selectedStudentId"
    :value="selectedStudentId"
  >
    <v-list-subheader>Students</v-list-subheader>
    <v-list-item
      v-for="studentItem in students"
      :key="studentItem.id"
      :class="{ 'border-thin':true,'complete': studentItem.incompleteGrades === 0, 'incomplete': studentItem.incompleteGrades !== 0 }"
      :model="selectedStudentId === studentItem.id"
      @click="studentSelected(studentItem)"
    >
      {{ studentItem.lastName }}, {{ studentItem.firstName }}
    </v-list-item>
  </v-list>
</template>

<script>
import {getStudentsInClass} from '@/api/classesApi';
import {globalStore} from '@/main';
import {Loader as KLoader} from '@progress/kendo-vue-indicators';
export default {
  name: 'StudentList',
  components: {
    KLoader,
  },
  props: {
    classId: {
      type: Number,
      required: true
    },
    teacherId: {
      type: Number,
      required: true
    }
  },
  emits: ['abc'],
  data() {
    return {
      students: [],
      selectedStudentId: '0',
      incompleteGrades: -1,
      studentGraded: []
    };
  },
  watch: {
    classId: function() {
      console.log('On watch classID inside classes students list...');

      // Load students list
      this.update();
    },
    incompleteGrades: {
      handler() {
      }
    }
  },
  mounted() {
    console.log('on mounted class students list...');
    this.update();
  },
  methods: {
    update() {
      if(this.classId !== 0) {
        getStudentsInClass(globalStore.selectedPeriod, this.classId, this.teacherId)
          .then(students => {
            this.$store.state.classStudents = students;
            this.students = students;
          });
      }
    },
    refresh(data) {
      let student = this.$store.state.classStudents.filter(st => st.id === data.studentId)[0];
      const grades = student.grades;
      const incomplete = grades.some(sg => sg.grade === 0);

      this.$store.state.classStudents.filter(st => st.id === data.studentId)[0] = student;
      student.incompleteGrades = (incomplete)
                                 ? 1
                                 : 0;
      this.incompleteGrades = student.incompleteGrades;
      this.studentGraded = student;
    },
    reset() {
      this.students = [];
    },
    studentSelected(studentItem) {
      this.selectedStudentId = studentItem.id;
      let selector = `.v-window-item--active [data-id="${studentItem.id}"]`;
      document.querySelector(selector).scrollIntoView(true)
      //this.$emit('abc', studentItem.studentId, toRaw(studentItem.studentId));

    }
  },
};
</script>

<style scoped>
.complete {
  background: #DDF2BB;
  color: black;
  height: 2em !important;
  overflow: hidden;
}


.incomplete {
  background: #F39797;
  height: 2em !important;
  overflow: hidden;
}

</style>
