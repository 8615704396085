<template>
  <v-btn
    elevation="1"
    :loading="loading"
    @click="loader='loading4'; downloadData()"
  >
    Export Summary Data
  </v-btn>
</template>
<script>
import {downloadFile} from '@/utils/effort-tracking-api';
import {getFilePath, getStudentDashboardFile} from '@/api/dataApi';

export default {
  name: 'DashboardDataDownloader',
  props: {
    studentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loader: null,
      loading: false
    };
  },
  methods: {
    downloadData() {
      this.loading = true;
      const l = this.loader;
      this[l] = !this[l];
      getStudentDashboardFile(this.studentId)
        .then(fileName => {
          console.log(fileName);
          const fileUrl = getFilePath(fileName);
          downloadFile(fileUrl, fileName);
          this[l] = false;
          this.loader = null;
        })
        .catch(ex => console.log(ex));
      this.loading = false;
    }
  }
};
</script>
