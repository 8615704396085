<template>
  <v-container class="pa-0 ma-0 w-100 mw-100">
    <v-alert
      v-model="alertSuccess"
      variant="tonal"
      type="success"
      closable
    >
      {{ resultMessage }}
    </v-alert>
    <v-alert
      v-model="alertError"
      variant="tonal"
      type="error"
      closable
    >
      {{ resultMessage }}
    </v-alert>
    <v-card>
      <v-toolbar class="elevation1">
        <v-toolbar-title>
          {{ selectedTeacherClass }} - Teachers
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          :variant="readonly ? 'flat' : 'elevated'"
          :readonly="readonly"
          @click="addTeacher()"
        >
          Add Teacher to Class
        </v-btn>
      </v-toolbar>
    </v-card>

    <k-grid
      ref="grid"
      class="teacherClassesGrid gridFullHeight"
      :data-items="gridData.items"
      :sort="sort"
      :sortable="true"
      :skip="serverFilters.skip"
      :take="serverFilters.take"
      :total="gridData.totalItems"
      :pageable="pageable"
      :page-size="globalStore.defaultRowsPerPage"
      :columns="gridData.columns"
      :loader="loader"
      @sortchange="sortChangeHandler"
      @pagechange="pageChangeHandler"
    >
      <template #loader>
        <div class="k-loader-container k-loader-container-md k-loader-top">
          <div class="k-loader-container-overlay k-overlay-dark" />
          <div class="k-loader-container-inner">
            <k-loader
              :size="'large'"
              :type="'pulsing'"
              :theme-color="'success'"
            />
          </div>
        </div>
      </template>
      <template #myTemplate="{props}">
        <td class="d-flex justify-center ">
          <v-btn
            icon="true"
            density="comfortable"
            class="ma-2 pa-0"
            variant="outlined"
            color="red"
            :disabled="readonly"
            @click.stop="loadDialog(props.dataItem)"
          >
            <v-tooltip activator="parent">
              Remove Teacher from Class
            </v-tooltip>
            <v-icon
              small
              icon="mdi-delete"
            />
          </v-btn>

          <v-btn
            v-if="!props.dataItem.primaryTeacher"
            icon="true"
            class="ma-2 pa-0"
            density="comfortable"
            variant="outlined"
            color="grey"
            :disabled="readonly"
            @click.stop="changePrimaryTeacherOfClass(props.dataItem.teacherId)"
          >
            <v-tooltip activator="parent">
              Make Primary Teacher
            </v-tooltip>
            <v-icon
              size="small"
              icon="mdi-clipboard-check"
            />
          </v-btn>
          <v-btn
            v-if="props.dataItem.primaryTeacher"
            icon="true"
            class="ma-2 pa-0"
            density="comfortable"
            variant="outlined"
            color="green"
            :disabled="readonly"
          >
            <v-tooltip activator="parent">
              Is Primary Teacher
            </v-tooltip>
            <v-icon
              size="small"
              icon="mdi-clipboard-check"
            />
          </v-btn>
        </td>
      </template>
    </k-grid>
    <!-- remove dialog -->
    <v-dialog
      v-model="dialog"
      max-width="40%"
    >
      <v-card>
        <v-card-title>
          Confirmation
        </v-card-title>
        <v-card-text>
          This action will <span class="dialogWarningColor">REMOVE</span> the teacher
          <b>{{ teacherName }}</b>
          from the class
          <b>{{ selectedTeacherClass }}</b>
          on period <b>{{ periodName }}</b>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            variant="text"
            @click.stop="unloadDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            variant="elevated"
            @click.stop="deleteTC()"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add teacher -->
    <v-dialog
      v-model="dialogAddTeacher"
      max-width="40%"
    >
      <v-card>
        <v-card-title>
          Add Teacher to class
        </v-card-title>
        <v-card-text>
          <v-form ref="addTeacherForm">
            <v-autocomplete
              v-model="selectedTeacher"
              item-title="name"
              item-value="teacherId"
              :items="teacherNames"
              label="Select Teacher"
              variant="underlined"
              :rules="[v => !!v || 'Teacher required']"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            @click.stop="unloadDialogAdd()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            variant="elevated"
            @click.stop="saveTeacherToClass()"
          >
            Add Teacher
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {initialize} from '@/utils/effort-tracking-api';
import {
  addTeacherToClassData,
  searchTeachersData,
  getTeachersInClassByPeriod,
  removeTeacherFromClass,
  changePrimaryTeacherData
} from '@/api/teachersApi';
import {getPeriod} from '@/api/periodApi';
import {globalStore} from '@/main';
import {debounce} from 'lodash';
import {Grid as KGrid} from '@progress/kendo-vue-grid';
import {Loader as KLoader} from '@progress/kendo-vue-indicators';


export default {
  components: {
    KLoader,
    KGrid,
  },
  data() {
    return {
      search: '',
      // Does not work as a child property.
      sort: [{dir:'asc', field:'firstName'}],
      loader: false,
      serverFilters: {
        sortBy: 'firstName',
        filter: '',
        take: globalStore.defaultRowsPerPage,
        skip: 0,
        descending: true
      },
      pagerValues: {
        pagerType: globalStore.pagerTypes['numeric'],
        pagerButtons: globalStore.pagerButtons,
        pagerInfo: globalStore.pagerInfo,
        pagerSizes: globalStore.pagerSizes,
        pagePrevNext: globalStore.pagerSizes,
        pageSize: globalStore.defaultRowsPerPage,
        pageSizeValue: globalStore.defaultRowsPerPage,
      },
      gridData: {
        columns: [
          {field: 'code', title: 'Class Code'},
          {field: 'name', title: 'Class Name'},
          {field: 'firstName', title: 'First Name'},
          {field: 'lastName', title: 'Last Name'},
          {field: '', cell: 'myTemplate', sortable: false},
        ],
        items: [],
        totalItems: 0,
      },

      dialog: false,
      dialogAddTeacher: false,
      alertSuccess: false,
      alertError: false,
      selectedTeacherClass: '',
      delReportingPeriodClassId: 0,
      delTeacherId: 0,
      teacherName: '',
      resultMessage: '',
      periodName: '',
      classCode: '',
      readonly: false,
      teachersForClass: [],
      selectedTeacherClassId: 0,
      allTeachers: [],
      teacherNames: [],
      selectedTeacher: '',
      primaryTeacher: 0,
    };
  },
  computed: {
    globalStore() {
      return globalStore;
    },
    pageable() {
      return {
        buttonCount: this.pagerValues.pagerButtons,
        info: this.pagerValues.pagerInfo,
        type: this.pagerValues.pagerType,
        pageSizes: this.pagerValues.pagerSizes
                   ? globalStore.allowedRowsPerPage
                   : undefined,
        previousNext: this.pagerValues.pagePrevNext,
        pageSizeValue: this.pagerValues.pageSizeValue,
      };
    },
  },
  watch: {
    search: {
      handler() {
        this.debouncedGetDataFromApi();
      }
    },
  },
  created() {
    this.debouncedGetDataFromApi = debounce(() => {
      this.getDataFromApi();
    }, 500);
  },

  mounted() {
    console.debug('On teacher classes mounted...');

    this.selectedTeacherClass = this.$route.params.className;
    this.classCode = this.$route.params.classCode;

    this.debouncedGetDataFromApi();
    this.setPeriodName();
    this.disableActions();
  },
  methods: {
    sortChangeHandler: function(e) {
      if(e.sort.length === 0) {
        this.serverFilters.descending = false;
        this.debouncedGetDataFromApi();
        this.sort = e.sort;
        return;
      }
      this.serverFilters.descending = e.sort[0].dir !== 'asc';
      this.serverFilters.sortBy = e.sort[0].field;
      this.debouncedGetDataFromApi();
      this.sort = e.sort;
    },
    pageChangeHandler: function(event) {
      console.log('event', event);
      this.pagerValues.pageSize = event.page.take;
      this.pagerValues.pageSizeValue = event.page.take;
      this.serverFilters.skip = event.page.skip;
      this.serverFilters.take = event.page.take;
      this.debouncedGetDataFromApi();
    },
    getDataFromApi() {
      console.log('Getting data from API (TEACHER CLASSES)');
      this.loader = true;
      const classId = this.$route.params.classId;
      this.serverFilters.filter = this.search;
      getTeachersInClassByPeriod(globalStore.selectedPeriod, classId, this.serverFilters)
        .then(
          data => {
            this.gridData.items = data.items;
            this.gridData.totalItems = data.count;
            this.loader = false;
            localStorage.setItem('ClassesGrid', JSON.stringify(this.gridData));
          })
        .catch(() => {
          this.loading = false;
        });
    },
    addTeacher() {
      this.loading = true;
      this.serverFilters.filter = this.search;
      searchTeachersData(this.serverFilters)
        .then(request => {
          const data = request.data;
          this.allTeachers = data.items;
          data.items.forEach(element => {
            this.teacherNames.push(element);
          });
          this.loading = false;
          this.dialogAddTeacher = true;
        });
    },
    async saveTeacherToClass() {
      const {valid} = await this.$refs.addTeacherForm.validate();
      if(!valid) {
        return;
      }
      console.log('selectedTeacher', this.selectedTeacher);
      const teacherId = this.selectedTeacher;
      const classId = this.$route.params.classId;

      addTeacherToClassData({
                          classId: classId,
                          teacherId: teacherId,
                          periodId: globalStore.selectedPeriod
                        })
        .then(() => {
          this.debouncedGetDataFromApi();
        });

      this.dialogAddTeacher = false;
    },
    changePrimaryTeacherOfClass(primaryTeacherId) {
      const classId = this.$route.params.classId;

      const model = {
        classId: classId,
        teacherId: primaryTeacherId,
        periodId: globalStore.selectedPeriod};
      changePrimaryTeacherData(model)
        .then(() => {
          this.gridData.items.forEach(item => {
            item.primaryTeacher = item.teacherId === primaryTeacherId;
          });
          const primaryIndexIndex = this.gridData.items.findIndex(item => item.primaryTeacher);
          this.gridData.items.push(...this.gridData.items.splice(0, primaryIndexIndex));
        })
        .catch(ex => {
          console.log(ex);
        });
    },
    loadDialog(item) {
      this.alertSuccess = false;
      this.alertError = false;
      this.teacherName = item.firstName + ' ' + item.lastName;
      this.delTeacherId = item.teacherId;
      this.dialog = true;
    },
    setPeriodName() {
      this.periodName = globalStore.selectedPeriodName;
    },
    unloadDialog() {
      this.dialog = false;
      this.selectedTeacherClassId = 0;
      this.selectedTeacherClass = '';
    },
    unloadDialogAdd() {
      this.dialogAddTeacher = false;
    },
    deleteTC() {
      this.dialog = false;
      const classId = this.$route.params.classId;
      console.log('classId ', typeof(classId));
      console.log('delTeacherId ', typeof (this.delTeacherId));
      removeTeacherFromClass( globalStore.selectedPeriod, Number(classId),  this.delTeacherId )
        .then(() => {
          this.debouncedGetDataFromApi();
        })
        .catch(ex => {
          console.log(ex);
        });
    },
    disableActions() {
      initialize()
        .then(() => {
          getPeriod(globalStore.selectedPeriod)
            .then(result => {
              this.readonly = !result.active || result.complete;
            })
            .catch(ex => console.log(ex));

          return false;
        });
    }
  }
};
</script>
