import axios from 'axios';
import {store} from '@/store';

let BASE_URL = '';

export {
    BASE_URL,
    addQueryToUrl,
    addQuery,
    postData,
    putData,
    postMultiPartFormData,
    getDataFromUrl,
    deleteData,
    initialize,
    getCsvFile,
    downloadFile,
    
    getTeacherEfforts,
    getStudentEfforts,
    putStudentEffort,
    postCsvFile,
    getImportProgress,
    
    getAllDataAsCsv,
    getExportProgress,
    createFileWithStudentEfforts,
    createFileWithTeacherEfforts,
    postAcademicCsvFile,
    GetAcademicImportProgress,
    getMyEffortsStudent,
    updateStudentTasks,
    createStudentsDashboardFile,
    createFileWithGradeSummary,
    getHelpFile,
    getDashboardDataCount,
    getClassesForStudentDashboardByPeriodId,
    getValuesForGaugesByPeriodId,
    getTeacherGradedByPeriodIdFromApi,
    getStudentGradedByPeriodIdFromApi,
    getDashboardDifferencesByPeriodId,
    getEffortScoreByPeriodIdFromApi,
    getChartDataByPeriodIdFromApi,
    getStudentDashboardTotalsByPeriodId,
    getSsoDataFromUrl,
};


axios.interceptors.response.use((response) => { // intercept the global error
    return response;
}, function (error) {
    //console.log("axios.interceptors", error)
    if (error.code === 'ERR_NETWORK'){
        return Promise.reject("API Connection not found");
    }
    let originalRequest = error.config;
    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) { // if the error is 401 and has not already been retried
        originalRequest._retry = true;
        console.log('401 Unauthorized');
        store.commit('removeUserData');
        window.location.href = '/#/users/login';
        return;
    }
    // Do something with response error
    return Promise.reject(error);
});

function initialize() {
    return axios.get('/config.json.txt')
    .then(response => {
        store.state.baseUrl = BASE_URL;
        BASE_URL = response.data.baseUrl;
    });
}

function handleException(ex) {
    throw ex;
}

function deleteData(url) {
    return axios.delete(url, getConfig())
    .then(response => response)
    .catch(ex => handleException(ex));
}

function postData(url, data) {
    return axios.post(url, data, {
        headers: {
            Authorization: 'Bearer ' + store.state.token,
            'Content-Type': 'application/json'
        }
    })
    .then(result => result)
    .catch(ex => handleException(ex));
}

function postMultiPartFormData(url, data) {
    return axios.post(url, data, {
        headers: {
            Authorization: 'Bearer ' + store.state.token,
            'Content-Type': 'multipart/form-data'
        }
    })
    .then(result => result);
}

function putData(url, data) {
    console.log('PUT - url: ', url);
    return axios.put(url, data, {
        headers: {
            Authorization: 'Bearer ' + store.state.token,
            'Content-Type': 'application/json'
        }
    }).then(result => result);
}

function getAuthHeader() {
    return {'Authorization': 'Bearer ' + store.state.token};
}

function getConfig() {
    return {
        headers: {
            'Authorization': 'Bearer ' + store.state.token,
            'Content-Type': 'application/json',
            accept: 'application/json'
        }
    };
}

function getFilterPrefix(url) {
    return url.includes('?')
        ? '&'
        : '?';
}

function addQuery(add, url, queryName, queryValue) {
    if (!add) {
        return url;
    }
    const prefix = getFilterPrefix(url);
    return url += `${prefix}${queryName}=${queryValue}`;
}

function addQueryToUrl(url, serverFilters) {
    if (serverFilters === undefined || serverFilters === null) {
        return url;
    }
    
    if (serverFilters.skip >= 0) {
        const skipPrefix = getFilterPrefix(url);
        url += `${skipPrefix}skip=${serverFilters.skip}`;
    }
    
    if (serverFilters.take) {
        const takePrefix = getFilterPrefix(url);
        url += `${takePrefix}take=${serverFilters.take}`;
    }
    
    if (serverFilters.filter.length > 0) {
        const filterPrefix = getFilterPrefix(url);
        url += `${filterPrefix}filter=${serverFilters.filter.trim()}`;
    }
    
    if (serverFilters.sortBy.length > 0) {
        const sortPrefix = getFilterPrefix(url);
        url += `${sortPrefix}sortBy=${serverFilters.sortBy}`;
    }
    
    if (serverFilters.descending) {
        const orderPrefix = getFilterPrefix(url);
        url += `${orderPrefix}descending=${serverFilters.descending}`;
    }
    
    return url;
}

async function downloadFile(url, fileName) {
    axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        headers: getAuthHeader()
    })
    .then(response => {
        
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    });
}

// end v2
function getSsoDataFromUrl(url) {
    return axios.get(url)
    .then(response => response.data)
    .catch(ex => handleException(ex));
}

function getDataFromUrl(url) {
    return axios.get(url, {headers: getAuthHeader()})
    .then(response => {
        if (response) {
            return response.data;
        }
        return false;
    })
    .catch(
        ex =>
            handleException(ex)
    );
}

function getTeacherEfforts(teacherId, classId) {
    const periodId = localStorage.getItem('period-selected');
    const url = `${BASE_URL}teacher-efforts/period/${periodId}/teacher/${teacherId}/class/${classId}/group-effort`;
    return getDataFromUrl(url);
}

function getStudentEfforts(studentId, periodId) {
    const url = `${BASE_URL}studentefforts/period/${periodId}/student/${studentId}/group-effort/group-class`;
    return getDataFromUrl(url);
}

function putStudentEffort(studentEffort) {
    const periodId = localStorage.getItem('period-selected');
    const url = `${BASE_URL}studentefforts/update/${periodId}`;
    const newData = JSON.stringify(studentEffort);
    
    return axios
    .put(url, newData, getConfig())
    .then(response => response.data)
    .catch(ex => handleException(ex));
}

function postCsvFile(file, option) {
    
    const periodId = localStorage.getItem('period-selected');
    const url = `${BASE_URL}datamanagement/upload/option/${option}/period/${periodId}`;
    
    const formData = new FormData();
    formData.append('csv', file);
    
    return axios
    .post(url, formData, {headers: {'Accept': 'application/json', 'Authorization': 'Bearer ' + store.state.token}})
    .then(response => response);
    
}

function postAcademicCsvFile(file, option) {
    const periodId = localStorage.getItem('period-selected');
    const url = `${BASE_URL}datamanagement/upload/academic/period/${periodId}`;
    
    const formData = new FormData();
    formData.append('csv', file[0]);
    
    return axios
    .post(url, formData, {headers: {'Accepr': 'application/json', 'Authorization': 'Bearer ' + store.state.token}})
    .then(response => response)
    .catch(ex => handleException(ex));
}

function getAllDataAsCsv(periodId) {
    const url = `${BASE_URL}datamanagement/getalldata/` + periodId;
    
    return getDataFromUrl(url);
}

function getImportProgress() {
    const url = `${BASE_URL}datamanagement/status`;
    return getDataFromUrl(url);
}

function GetAcademicImportProgress() {
    const url = `${BASE_URL}datamanagement/academicstatus`;
    return getDataFromUrl(url);
}

function getExportProgress() {
    const url = `${BASE_URL}datamanagement/exportstatus`;
    return getDataFromUrl(url);
}

function getCsvFile(fileName) {
    return `${BASE_URL}datamanagement/getfile/${fileName}`;
}

function createFileWithStudentEfforts(studentId, periodId) {
    const url = `${BASE_URL}datamanagement/createfilestudent/${studentId}/${periodId}`;
    return getDataFromUrl(url);
}

function createFileWithTeacherEfforts(teacherId, classId, periodId) {
    const url = `${BASE_URL}datamanagement/createfileteacher/${teacherId}/${classId}/${periodId}`;
    return getDataFromUrl(url);
}

function createFileWithGradeSummary(userId, periodId, usertype) {
    const url = `${BASE_URL}datamanagement/createfilegradesummary/${userId}/${periodId}/${usertype}`;
    return getDataFromUrl(url);
}

function getMyEffortsStudent(studentId, pagination, filter) {
    const periodId = localStorage.getItem('period-selected');
    let url = `${BASE_URL}studentefforts/period/${periodId}/student/${studentId}/all?sortBy=${pagination.sortBy}`;
    
    if (filter) {
        const _filter = filter.trim();
        url += `&filter=${_filter}`;
    }
    
    if (pagination.descending) {
        url += `&descending=${pagination.descending}`;
    }
    
    return getDataFromUrl(url);
}

function updateStudentTasks(tasks) {
    // StudentTasks
    const url = `${BASE_URL}studenttasks`;
    const data = JSON.stringify(tasks);
    
    return axios.post(url, data, getConfig())
    .then(response => response)
    .catch(ex => handleException(ex));
}


function getValuesForGaugesByPeriodId(studentId, dashboardPeriodId) {
    const url = `${BASE_URL}studentdashboard/student/${studentId}/gauges/period/${dashboardPeriodId}`;
    return getDataFromUrl(url);
}

function getClassesForStudentDashboardByPeriodId(studentId, dashboardPeriodId) {
    const url = `${BASE_URL}studentdashboard/getclassesbyperiod/${studentId}/${dashboardPeriodId}`;
    return getDataFromUrl(url);
}

function getTeacherGradedByPeriodIdFromApi(studentId, dashboardPeriodId) {
    const url = `${BASE_URL}studentdashboard/getteachergradedbyperiod/${studentId}/${dashboardPeriodId}`;
    return getDataFromUrl(url);
}

function getStudentGradedByPeriodIdFromApi(studentId, dashboardPeriodId) {
    const url = `${BASE_URL}studentdashboard/getstudentgradedbyperiod/${studentId}/${dashboardPeriodId}`;
    return getDataFromUrl(url);
}

function getChartDataByPeriodIdFromApi(studentId, dashboardPeriodId) {
    const url = `${BASE_URL}studentdashboard/getchartdatabyperiod/${studentId}/${dashboardPeriodId}`;
    return getDataFromUrl(url);
}

function getEffortScoreByPeriodIdFromApi(studentId, dashboardPeriodId) {
    const url = `${BASE_URL}studentdashboard/geteffortscorebyperiod/${studentId}/${dashboardPeriodId}`;
    return getDataFromUrl(url);
}

function getDashboardDifferencesByPeriodId(studentId, dashboardPeriodId) {
    const url = `${BASE_URL}studentdashboard/differences/student/${studentId}/period/${dashboardPeriodId}`;
    return getDataFromUrl(url);
}

function getStudentDashboardTotalsByPeriodId(studentId, dashboardPeriodId) {
    const url = `${BASE_URL}studentdashboard/totalsbyperiod/${studentId}/${dashboardPeriodId}`;
    return getDataFromUrl(url);
}

function createStudentsDashboardFile(periodId) {
    const url = `${BASE_URL}datamanagement/getstudentsdashboarddata/${periodId}`;
    return getDataFromUrl(url);
}

function getDashboardDataCount(periodId) {
    const url = `${BASE_URL}datamanagement/getdashboarddatacount/${periodId}`;
    return getDataFromUrl(url);
}

async function getHelpFile(usertype) {
    
    if (usertype === 'middlemanager') {
        usertype = 'teacher';
    }
    
    const url = `${BASE_URL}datamanagement/gethelpfile/${usertype}`;
    
    axios({url: url, method: 'GET', responseType: 'blob', headers: getAuthHeader()})
    .then(result => {
        const file = new Blob([result.data], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank');
        link.view = 'EffortTracking-help.pdf';
        link.click();
    })
    .catch(error => console.log(error));
}
