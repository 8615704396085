<template>
  <v-container class="pa-0 ma-0 mw-100 w-100">
    <v-alert
      v-model="alertSuccess"
      variant="tonal"
      type="success"
      closable
    >
      {{ resultMessage }}
    </v-alert>
    <v-alert
      v-model="alertError"
      variant="tonal"
      type="error"
      closable
    >
      {{ resultMessage }}
    </v-alert>
    <v-card>
      <v-toolbar class="elevation-1">
        <v-toolbar-title>
          Classes
        </v-toolbar-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-inner-icon="mdi-magnify"
          label="Search"
          class="search"
          density="compact"
          variant="solo"
        />
      </v-toolbar>
      <k-grid
        ref="grid"
        class="gridFullHeight"
        :data-items="gridData.items"
        :skip="serverFilters.skip"
        :sort="sort"
        :take="serverFilters.take"
        :sortable="true"
        :total="gridData.totalItems"
        :pageable="pageable"
        :page-size="pagerValues.pageSize"
        :columns="gridData.columns"
        :loader="loader"
        @sortchange="sortChangeHandler"
        @pagechange="pageChangeHandler"
      >
        <template #loader>
          <div class="k-loader-container k-loader-container-md k-loader-top">
            <div class="k-loader-container-overlay k-overlay-dark" />
            <div class="k-loader-container-inner">
              <k-loader
                :size="'large'"
                :type="'pulsing'"
                :theme-color="'success'"
              />
            </div>
          </div>
        </template>
        <template #myTemplate="{props}">
          <td class="d-flex justify-center">
            <v-btn
              color="red"
              icon="true"
              class="ma-2 pa-0"
              variant="outlined"
              density="comfortable"
              :disabled="readonly"
              :value="props.dataItem.id"
              @click.stop="showRemoveClassFromPeriodDialog(props.dataItem.id, props.dataItem.name)"
            >
              <v-tooltip activator="parent">
                Delete class
              </v-tooltip>
              <v-icon
                small
                icon="mdi-delete"
              />
            </v-btn>
            <v-btn
              v-if="buttonSettings.hideClass && props.dataItem.online"
              class="ma-2 pa-0"
              density="comfortable"
              color="green"
              variant="outlined"
              icon="true"
              :disabled="readonly"
              :value="props.dataItem.id"
              @click.stop="makeOnline(props.dataItem, false)"
            >
              <v-tooltip activator="parent">
                Hide class
              </v-tooltip>
              <v-icon
                small
                icon="mdi-eye"
              />
            </v-btn>
            <v-btn
              v-if="buttonSettings.hideClass && !props.dataItem.online"
              ref="icon"
              class="ma-2 pa-0"
              color="grey"
              variant="outlined"
              density="comfortable"
              icon="true"
              :value="props.dataItem.id"
              @click.stop="makeOnline(props.dataItem, true)"
            >
              <v-tooltip activator="parent">
                Show class
              </v-tooltip>
              <v-icon icon="mdi-eye" />
            </v-btn>
            <v-btn
              v-if="buttonSettings.inspectStudents"
              variant="outlined"
              density="comfortable"
              icon="true"
              class="ma-2 pa-0"
              color="grey"
              :to="{
                name: 'StudentClasses',
                params: {
                  classId: props.dataItem.id,
                  className: props.dataItem.name,
                  classCode: props.dataItem.code }}"
            >
              <v-tooltip activator="parent">
                Inspect students
              </v-tooltip>
              <v-icon icon="mdi-account-supervisor" />
            </v-btn>
            <v-btn
              v-if="buttonSettings.manageTeachers"
              variant="outlined"
              icon="true"
              density="comfortable"
              class="ma-2 pa-0"
              color="orange"
              :to="{
                name: 'TeacherClasses',
                params: {
                  classId: props.dataItem.id,
                  className: props.dataItem.name,
                  classCode: props.dataItem.code }}"
            >
              <v-tooltip activator="parent">
                manage teachers
              </v-tooltip>
              <v-icon icon="mdi-account-supervisor" />
            </v-btn>
          </td>
        </template>
      </k-grid>
    </v-card>
    <v-dialog
      v-model="removeClassFromPeriodDialog"
      max-width="35%"
    >
      <v-card>
        <v-card-title>
          Confirmation
        </v-card-title>
        <v-card-text>
          This action will <span class="dialogWarningColor">remove </span>all the info related to the class
          <b> {{ selectedClass }} </b>
          from the period <b>{{ periodName }}</b>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            variant="text"
            @click.stop="hideRemoveClassFromPeriodDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            variant="elevated"
            @click.stop="deleteClass()"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import {initialize} from '@/utils/effort-tracking-api'
import {getClassesByPeriodAndMiddleManager} from '@/api/middleManagerApi'
import {getSettingsForMiddleManagers} from '@/api/settingsApi'
import {removeClassFromPeriod, updateClass} from '@/api/classesApi'
import {globalStore} from '@/main'
import {debounce} from "lodash";
import {Grid as KGrid} from '@progress/kendo-vue-grid';
import {Loader as KLoader} from '@progress/kendo-vue-indicators';

export default {
  components: {
    KLoader,
    KGrid
  },
  data() {
    return {
      removeClassFromPeriodDialog: false,

      periodName: '',
      selectedClass: '',
      selectedClassId: 0,

      alertSuccess: false,
      alertError: false,
      resultMessage: '',

      readonly: false,

      middleManagerSettings: [],
      buttonSettings: {
        deleteClass: false,
        hideClass: false,
        inspectStudents: false,
        manageTeachers: false,
      },

      // Does not work as serverFilters.filter due to the watch.
      search: '',
      // Does not work as a child property.
      sort: [{field: 'name', dir: 'asc'}],
      loader: false,
      serverFilters: {
        sortBy: 'name',
        filter: '',
        take: globalStore.defaultRowsPerPage,
        skip: 0,
        descending: false
      },
      pagerValues: {
        pagerType: globalStore.pagerTypes['numeric'],
        pagerButtons: globalStore.pagerButtons,
        pagerInfo: globalStore.pagerInfo,
        pagerSizes: globalStore.pagerSizes,
        pagePrevNext: globalStore.pagerSizes,
        pageSize: globalStore.defaultRowsPerPage,
        pageSizeValue: globalStore.defaultRowsPerPage,
      },
      gridData: {
        columns: [
          {title: 'Code', field: 'classCode'},
          {title: 'Course', field: 'courseName'},
          {title: 'Name', field: 'className'},
          {title: 'Teacher', field: 'teacherName'},
          {title: 'Actions', field: '', cell: 'myTemplate', sortable: false},
        ],
        items: [],
        totalItems: 0,
      },
    }
  },
  computed: {
    globalStore() {
      return globalStore
    },
    pageable() {
      return {
        buttonCount: this.pagerValues.pagerButtons,
        info: this.pagerValues.pagerInfo,
        type: this.pagerValues.pagerType,
        pageSizes: this.pagerValues.pagerSizes ? globalStore.allowedRowsPerPage : undefined,
        previousNext: this.pagerValues.pagePrevNext,
        pageSizeValue: this.pagerValues.pageSizeValue,
      };
    },

  },
  watch: {
    search: {
      handler() {
        this.debouncedGetDataFromApi()
      },
    },
  },
  created() {
    this.debouncedGetDataFromApi = debounce(() => {
      this.getDataFromApi();
    }, 500);
  },
  mounted() {
    console.log('on ClassesMiddleManager.vue page mounted');
    this.debouncedGetDataFromApi();
    this.setPeriodName();
    this.disableActions();
  },
  methods: {
    getDataFromApi() {
      this.loader = true;
      const session = JSON.parse(localStorage.getItem('ef-session'));
      this.serverFilters.filter = this.search;
      initialize()
      .then( () => {
        getClassesByPeriodAndMiddleManager( globalStore.selectedPeriod, session.userdata.id, this.serverFilters )
        .then( classes => {
          this.gridData.items = classes.items;
          this.gridData.totalItems = classes.count;
          this.loader = false;
        } );
        getSettingsForMiddleManagers()
        .then(
            settings => {
              console.log( 'Middle Manager Settings: ' );
              console.log( settings );
              this.middleManagerSettings = settings;

              if ( settings.length > 0 ) {
                const _deleteClass = settings.filter( st => st.key === 'middle-manager-delete-class' );
                if ( _deleteClass.length > 0 )
                  this.buttonSettings.deleteClass = _deleteClass[0].value === 'true';

                const _hideClass = settings.filter( st => st.key === 'middle-manager-hide-class' );
                if ( _hideClass.length > 0 )
                  this.buttonSettings.hideClass = _hideClass[0].value === 'true';

                const _inspectStudents = settings.filter( st => st.key === 'middle-manager-inspect-students' );
                if ( _inspectStudents.length > 0 )
                  this.buttonSettings.inspectStudents = _inspectStudents[0].value === 'true';

                const _manageTeachers = settings.filter( st => st.key === 'middle-manager-manage-teachers' );
                if ( _manageTeachers.length > 0 )
                  this.buttonSettings.manageTeachers = _manageTeachers[0].value === 'true';
              }
            } );
      } );
    },
    sortChangeHandler: function (e) {
      if (e.sort.length === 0) {
        this.serverFilters.descending = false;

        this.getDataFromApi()
        this.sort = e.sort;
        return;
      }
      this.serverFilters.descending = e.sort[0].dir !== 'asc';
      this.serverFilters.sortBy = e.sort[0].field
      this.getDataFromApi()
      this.sort = e.sort;
    },
    pageChangeHandler: function (event) {
      console.log('pageChangeHandler: ', event);
      this.pagerValues.pageSize = event.page.take;
      this.pagerValues.pageSizeValue = event.page.take;
      this.serverFilters.skip = event.page.skip;
      this.serverFilters.take = event.page.take;
      this.getDataFromApi()
    },
    hideRemoveClassFromPeriodDialog() {
      this.removeClassFromPeriodDialog = false;
      this.selectedClassId = 0;
      this.selectedClass = '';
    },
    showRemoveClassFromPeriodDialog(classId, className) {
      this.alertSuccess = false;
      this.alertError = false;
      this.removeClassFromPeriodDialog = true;
      this.selectedClass = className;
      this.selectedClassId = classId;
    },

    setPeriodName() {
      const periodId = localStorage.getItem('period-selected');
      const period = this.$store.state.periods.filter(p => p.id === Number(periodId));
      this.periodName = ((period.length > 0) ? period[0].text : '');
    },
    deleteClass() {
      removeClassFromPeriod( this.selectedClassId, globalStore.selectedPeriod ).then(
          result => {
            this.removeClassFromPeriodDialog = false;
            this.selectedClass = '';
            this.selectedClassId = 0;
            this.resultMessage = result.data.message;
            if ( result.data.success ) {
              this.alertSuccess = true;
            }
            else {
              this.alertError = true;
            }
            this.getDataFromApi();
          } );
    },
    disableActions() {
      const periodId = localStorage.getItem('period-selected');
      const p = this.$store.state.periods.filter(p => Number(p.id) === periodId);
      this.readonly = ((p.length > 0) ? !p[0].active : false);
    },
    makeOnline(_class, online) {
      const periodId = localStorage.getItem('period-selected');
      updateClass(_class.id, periodId, online)
          .then(() => {
            this.debouncedGetDataFromApi();
          })
          .catch(ex => console.log(ex));
    }
  }
}
</script>
