import {
    BASE_URL,
    postData,
    putData,
} from '@/utils/effort-tracking-api'

import {Routes} from '@/utils/settings'

export function sendPasswordResetEmail(model){
    const url =`${BASE_URL}${Routes.AccountsRoute}/user/password/forgot`;
    return postData(url, model);
}

export function resetPassword(model){
    const url =`${BASE_URL}${Routes.AccountsRoute}/reset`;
    return putData(url, model)
}

export function resetForgottenPassword(model){
    const url =`${BASE_URL}${Routes.AccountsRoute}/user/password/forgot`;
    return putData(url, model)
}

export function resetUserPassword(model){
    const url =`${BASE_URL}${Routes.AccountsRoute}/user/password/reset`;
    return putData(url, model)
}

export function unlockUser(model){
    const url =`${BASE_URL}${Routes.AccountsRoute}/user/lockout`;
    return putData(url, model)
}

export function lockUser(model){
    const url =`${BASE_URL}${Routes.AccountsRoute}/user/lockout`;
    return putData(url, model)
}

export function createUser(model){
    const url =`${BASE_URL}${Routes.AccountsRoute}/user`;
    return postData(url, model)
}

export function resetAllPasswordsForUserType(model){
    const url =`${BASE_URL}${Routes.AccountsRoute}/reset/all`;
    return putData(url, model)
}

export function getUsersForPeriod(periodId, serverFilters){
    let url =`${BASE_URL}${Routes.AccountsRoute}/users/${periodId}`;
    return postData(url, serverFilters);
}

export function updateUser(model){
    const url =`${BASE_URL}${Routes.AccountsRoute}/user`;
    return putData(url, model);
}
