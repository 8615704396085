
<template>
  <router-view />    
</template>

<script>
  export default {
    name: 'App'
  }
</script>

<style>
@import '@progress/kendo-theme-material/dist/all.css';
</style>
