<template>
  <v-btn
    elevation="1"
    :loading="loading"
    @click="loader = 'loading'; downloadGradeSummary();"
  >
    Download Summary
  </v-btn>
</template>

<script>
import {createFileWithGradeSummary, getCsvFile, downloadFile} from '@/utils/effort-tracking-api'

export default {
  name: 'GradeSummaryDownloader',
  props: {
    usertype: {
      type: String,
      required: true
    },
    userId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loader: null,
      loading: false
    }
  },
  methods: {
    downloadGradeSummary() {
      const l = this.loader
      this[l] = !this[l]
      const periodId = localStorage.getItem('period-selected');

      createFileWithGradeSummary( this.userId, periodId, this.usertype ).then( fileName => {
        console.log( fileName );
        const fileUrl = getCsvFile( fileName );
        downloadFile( fileUrl, fileName );
        this[l] = false;
        this.loader = null;
      } ).catch( ex => console.log( ex ) );
    }
  }
}
</script>
