import {
  BASE_URL,
  postData,
  getDataFromUrl,
} from '@/utils/effort-tracking-api';

import {Routes} from '@/utils/settings'

export function updateEffortTabs(model){
  const url = `${BASE_URL}${Routes.EffortsRoutes}`;
  return postData(url, model);
}

export function getEffortTabs(){
  const url = `${BASE_URL}${Routes.EffortsRoutes}`;
  return getDataFromUrl(url);
}
