
export { Gauge, loadLiquidFillGauge, liquidFillGaugeDefaultSettings  }
export { ScatterChart }
import * as d3 from './d3.min'

function Gauge(placeholderName, configuration)
{
    this.placeholderName = placeholderName;

    var self = this;
    this.configure = function(configuration) {
        this.config = configuration;
        this.config.size = this.config.size * 0.9;
        this.config.radius = this.config.size * 0.97 / 2;
        this.config.cx = this.config.size / 2;
        this.config.cy = this.config.size / 2;

        this.config.min = undefined != configuration.min ? configuration.min : 0;
        this.config.max = undefined != configuration.max ? configuration.max : 100;
        this.config.range = this.config.max - this.config.min;

        this.config.majorTicks = configuration.majorTicks || 5;
        this.config.minorTicks = configuration.minorTicks || 2;

        this.config.greenColor = configuration.greenColor || "#109618";
        this.config.yellowColor = configuration.yellowColor || "#FF9900";
        this.config.redColor = configuration.redColor || "#DC3912";

        this.config.transitionDuration = configuration.transitionDuration || 500;
    }

    this.render = function ()
    {
        this.body = d3.select("#" + this.placeholderName)
        .append("svg:svg")
        .attr("class", "gauge")
        .attr("width", this.config.size)
        .attr("height", this.config.size);

        this.body.append("svg:circle")
        .attr("cx", this.config.cx)
        .attr("cy", this.config.cy)
        .attr("r", this.config.radius)
        .style("fill", "#ccc")
        .style("stroke", "#000")
        .style("stroke-width", "0.5px");

        this.body.append("svg:circle")
        .attr("cx", this.config.cx)
        .attr("cy", this.config.cy)
        .attr("r", 0.9 * this.config.radius)
        .style("fill", "#fff")
        .style("stroke", "#e0e0e0")
        .style("stroke-width", "2px");

        for (var index in this.config.greenZones)
        {
            this.drawBand(this.config.greenZones[index].from, this.config.greenZones[index].to, self.config.greenColor);
        }

        for (var index in this.config.yellowZones)
        {
            this.drawBand(this.config.yellowZones[index].from, this.config.yellowZones[index].to, self.config.yellowColor);
        }

        for (var index in this.config.redZones)
        {
            this.drawBand(this.config.redZones[index].from, this.config.redZones[index].to, self.config.redColor);
        }

        if (undefined != this.config.label)
        {
            var fontSize = Math.round(this.config.size / 9);
            this.body.append("svg:text")
            .attr("x", this.config.cx)
            .attr("y", this.config.cy / 2 + fontSize / 2)
            .attr("dy", fontSize / 2)
            .attr("text-anchor", "middle")
            .text(this.config.label)
            .style("font-size", fontSize + "px")
            .style("fill", "#333")
            .style("stroke-width", "0px");
        }

        var fontSize = Math.round(this.config.size / 16);
        var majorDelta = this.config.range / (this.config.majorTicks - 1);
        for (var major = this.config.min; major <= this.config.max; major += majorDelta)
        {
            var minorDelta = majorDelta / this.config.minorTicks;
            for (var minor = major + minorDelta; minor < Math.min(major + majorDelta, this.config.max); minor += minorDelta)
            {
                var point1 = this.valueToPoint(minor, 0.75);
                var point2 = this.valueToPoint(minor, 0.85);

                this.body.append("svg:line")
                .attr("x1", point1.x)
                .attr("y2", point1.y)
                .attr("x2", point2.x)
                .attr("y2", point2.y)
                .style("stroke", "#666")
                .style("stroke-width", "1px");
            }

            var point1 = this.valueToPoint(major, 0.7);
            var point2 = this.valueToPoint(major, 0.85);

            this.body.append("svg:line")
            .attr("x1", point1.x)
            .attr("y1", point1.y)
            .attr("x2", point2.x)
            .attr("y2", point2.y)
            .style("stroke", "#333")
            .style("stroke-width", "2px");

            if (major == this.config.min || major == this.config.max)
            {
                var point = this.valueToPoint(major, 0.63);

                this.body.append("svg:text")
                .attr("x", point.x)
                .attr("y", point.y)
                .attr("dy", fontSize / 3)
                .attr("text-anchor", major == this.config.min ? "start" : "end")
                .text(major)
                .style("font-size", fontSize + "px")
                .style("fill", "#333")
                .style("stroke-width", "0px");
            }
        }

        var pointerContainer = this.body.append("svg:g").attr("class", "pointerContainer");
        var midValue = (this.config.min + this.config.max) / 2;
        var pointerPath = this.buildPointerPath(midValue);
        var pointerLine = d3.line()
        .x(function(d) { return d.x })
        .y(function(d) { return d.y })
        .curve(d3.curveBasis);

        pointerContainer.selectAll("path")
        .data([pointerPath])
        .enter()
        .append("svg:path")
        .attr("d", pointerLine)
        .style("fill", "#dc3912")
        .style("stroke", "#c63310")
        .style("fill-opacity", 0.7)

        pointerContainer.append("svg:circle")
        .attr("cx", this.config.cx)
        .attr("cy", this.config.cy)
        .attr("r", 0.12 * this.config.radius)
        .style("fill", "#4684EE")
        .style("stroke", "#666")
        .style("opacity", 1);

        var fontSize = Math.round(this.config.size / 10);
        pointerContainer.selectAll("text")
        .data([midValue])
        .enter()
        .append("svg:text")
        .attr("x", this.config.cx)
        .attr("y", this.config.size - this.config.cy / 4 - fontSize)
        .attr("dy", fontSize / 2)
        .attr("text-anchor", "middle")
        .style("font-size", fontSize + "px")
        .style("fill", "#000")
        .style("stroke-width", "0px");

        this.redraw(this.config.min, 0);
    }

    this.buildPointerPath = function(value) 
    {
        var delta = this.config.range / 13;

        var head = valueToPoint(value, 0.85);
        var head1 = valueToPoint(value - delta, 0.12);
        var head2 = valueToPoint(value + delta, 0.12);

        var tailValue = value - (this.config.range * (1/(270/360)) / 2);
        var tail = valueToPoint(tailValue, 0.28);
        var tail1 = valueToPoint(tailValue - delta, 0.12);
        var tail2 = valueToPoint(tailValue + delta, 0.12);

        return [head, head1, tail2, tail, tail1, head2, head];

        function valueToPoint(value, factor) {
            var point = self.valueToPoint(value, factor);
            point.x -= self.config.cx;
            point.y -= self.config.cy;
            return point;
        }
    }

    this.drawBand = function(start, end, color)
    {
        if (0 >= end - start) return;

        this.body.append("svg:path")
        .style("fill", color)
        .attr("d", d3.arc()
            .startAngle(this.valueToRadians(start))
            .endAngle(this.valueToRadians(end))
            .innerRadius(0.65 * this.config.radius)
            .outerRadius(0.85 * this.config.radius))
        .attr("transform", function() { return "translate(" + self.config.cx + ", " + self.config.cy + ") rotate(270)" });
    }

    this.redraw = function(value, transitionDuration)
    {
        var pointerContainer = this.body.select(".pointerContainer");
        pointerContainer.selectAll("text").text(Math.round(value));

        var pointer = pointerContainer.selectAll("path");
        pointer.transition()
        .duration(undefined != transitionDuration ? transitionDuration : this.config.transitionDuration)
        .attrTween("transform", function() {
            var pointerValue = value;
            if (value > self.config.max) pointerValue = self.config.max + 0.02*self.config.range;
            else if (value < self.config.min) pointerValue = self.config.min - 0.02*self.config.range;
            var targetRotation = (self.valueToDegrees(pointerValue) - 90);
            var currentRotation = self._currentRotation || targetRotation;
            self._currentRotation = targetRotation;

            return function(step)
            {
                var rotation = currentRotation + (targetRotation-currentRotation)*step;
                return "translate(" + self.config.cx + ", " + self.config.cy + ") rotate(" + rotation + ")";
            }
        });
    }

    this.valueToDegrees = function(value)
    {
        return value / this.config.range * 270 - (this.config.min / this.config.range * 270 + 45);
    }

    this.valueToRadians = function(value){
        return this.valueToDegrees(value) * Math.PI / 180;
    }

    this.valueToPoint = function(value, factor)
    {
        return { x: this.config.cx - this.config.radius * factor * Math.cos(this.valueToRadians(value)),
            y: this.config.cy - this.config.radius * factor * Math.sin(this.valueToRadians(value)) };
    }

    // Initialization
    this.configure(configuration);
}



function liquidFillGaugeDefaultSettings(){
    return {
        minValue: 0, // The gauge minimum value.
        maxValue: 100, // The gauge maximum value.
        circleThickness: 0.05, // The outer circle thickness as a percentage of it's radius.
        circleFillGap: 0.05, // The size of the gap between the outer circle and wave circle as a percentage of the outer circles radius.
        circleColor: "rgba(0 , 132, 46, 0.7)", // The color of the outer circle.
        waveHeight: 0.05, // The wave height as a percentage of the radius of the wave circle.
        waveCount: 1, // The number of full waves per width of the wave circle.
        waveRiseTime: 1000, // The amount of time in milliseconds for the wave to rise from 0 to it's final height.
        waveAnimateTime: 10000, // The amount of time in milliseconds for a full wave to enter the wave circle.
        waveRise: true, // Control if the wave should rise from 0 to it's full height, or start at it's full height.
        waveHeightScaling: true, // Controls wave size scaling at low and high fill percentages. When true, wave height reaches it's maximum at 50% fill, and minimum at 0% and 100% fill. This helps to prevent the wave from making the wave circle from appear totally full or empty when near it's minimum or maximum fill.
        waveAnimate: true, // Controls if the wave scrolls or is static.
        //waveColor: "#00842B", // The color of the fill wave.
        waveColor: "rgba(0, 132, 46, 0.7)",
        waveOffset: 0, // The amount to initially offset the wave. 0 = no offset. 1 = offset of one full wave.
        textVertPosition: .5, // The height at which to display the percentage text withing the wave circle. 0 = bottom, 1 = top.
        textSize: 1, // The relative height of the text to display in the wave circle. 1 = 50%
        valueCountUp: false, // If true, the displayed value counts up from 0 to it's final value upon loading. If false, the final value is displayed.
        displayPercent: false, // If true, a % symbol is displayed after the value.
        textColor: "rgba(0 , 132, 46, 0.7)", // The color of the value text when the wave does not overlap it.
        waveTextColor: "white", // The color of the value text when the wave overlaps it.
    };
}

function loadLiquidFillGauge(elementId, value, config) {
    if(config == null) config = liquidFillGaugeDefaultSettings();

    var gauge = d3.select("#" + elementId);
    var radius = Math.min(parseInt(gauge.style("width")), parseInt(gauge.style("height")))/2;
    var locationX = parseInt(gauge.style("width"))/2 - radius;
    var locationY = parseInt(gauge.style("height"))/2 - radius;
    var fillPercent = Math.max(config.minValue, Math.min(config.maxValue, value))/config.maxValue;

    var waveHeightScale;
    if(config.waveHeightScaling){
        waveHeightScale = d3.scaleLinear()
        .range([0,config.waveHeight,0])
        .domain([0,50,100]);
    } else {
        waveHeightScale = d3.scaleLinear()
        .range([config.waveHeight,config.waveHeight])
        .domain([0,100]);
    }

    var textPixels = (config.textSize*radius/2);
    var textFinalValue = parseFloat(value).toFixed(2);
    var textStartValue = config.valueCountUp?config.minValue:textFinalValue;
    var percentText = config.displayPercent?"%":"";
    var circleThickness = config.circleThickness * radius;
    var circleFillGap = config.circleFillGap * radius;
    var fillCircleMargin = circleThickness + circleFillGap;
    var fillCircleRadius = radius - fillCircleMargin;
    var waveHeight = fillCircleRadius*waveHeightScale(fillPercent*100);

    var waveLength = fillCircleRadius*2/config.waveCount;
    var waveClipCount = 1+config.waveCount;
    var waveClipWidth = waveLength*waveClipCount;

    // Rounding functions so that the correct number of decimal places is always displayed as the value counts up.
    var textRounder = function(value){ return Math.round(value); };
    if(parseFloat(textFinalValue) != parseFloat(textRounder(textFinalValue))){
        textRounder = function(value){ return parseFloat(value).toFixed(1); };
    }
    if(parseFloat(textFinalValue) != parseFloat(textRounder(textFinalValue))){
        textRounder = function(value){ return parseFloat(value).toFixed(2); };
    }

    // Data for building the clip wave area.
    var data = [];
    for(var i = 0; i <= 40*waveClipCount; i++){
        data.push({x: i/(40*waveClipCount), y: (i/(40))});
    }

    // Scales for drawing the outer circle.
    var gaugeCircleX = d3.scaleLinear().range([0,2*Math.PI]).domain([0,1]);
    var gaugeCircleY = d3.scaleLinear().range([0,radius]).domain([0,radius]);

    // Scales for controlling the size of the clipping path.
    var waveScaleX = d3.scaleLinear().range([0,waveClipWidth]).domain([0,1]);
    var waveScaleY = d3.scaleLinear().range([0,waveHeight]).domain([0,1]);

    // Scales for controlling the position of the clipping path.
    var waveRiseScale = d3.scaleLinear()
    // The clipping area size is the height of the fill circle + the wave height, so we position the clip wave
    // such that the it will overlap the fill circle at all when at 0%, and will totally cover the fill
    // circle at 100%.
    .range([(fillCircleMargin+fillCircleRadius*2+waveHeight),(fillCircleMargin-waveHeight)])
    .domain([0,1]);
    var waveAnimateScale = d3.scaleLinear()
    .range([0, waveClipWidth-fillCircleRadius*2]) // Push the clip area one full wave then snap back.
    .domain([0,1]);

    // Scale for controlling the position of the text within the gauge.
    var textRiseScaleY = d3.scaleLinear()
    .range([fillCircleMargin+fillCircleRadius*2,(fillCircleMargin+textPixels*0.7)])
    .domain([0,1]);

    // Center the gauge within the parent SVG.
    var gaugeGroup = gauge.append("g")
    .attr('transform','translate('+locationX+','+locationY+')');

    // Draw the outer circle.
    var gaugeCircleArc = d3.arc()
    .startAngle(gaugeCircleX(0))
    .endAngle(gaugeCircleX(1))
    .outerRadius(gaugeCircleY(radius))
    .innerRadius(gaugeCircleY(radius-circleThickness));
    gaugeGroup.append("path")
    .attr("d", gaugeCircleArc)
    .style("fill", config.circleColor)
    .attr('transform','translate('+radius+','+radius+')');

    if(value <= 69) {
        config.textColor = 'rgba(0 , 132, 46, 0.7)';
        config.waveTextColor = 'rgba(0 , 132, 46, 0.7)';
    } else {
        config.textColor = 'white';
    }

    // Text where the wave does not overlap.
    var text1 = gaugeGroup.append("text")
    .text(textRounder(textStartValue) + percentText)
    .attr("class", "liquidFillGaugeText")
    .attr("text-anchor", "middle")
    .attr("font-size", textPixels + "px")
    .style("fill", config.textColor)
    .attr('transform','translate('+radius+','+textRiseScaleY(config.textVertPosition)+')');

    // The clipping wave area.
    var clipArea = d3.area()
    .x(function(d) { return waveScaleX(d.x); } )
    .y0(function(d) { return waveScaleY(Math.sin(Math.PI*2*config.waveOffset*-1 + Math.PI*2*(1-config.waveCount) + d.y*2*Math.PI));} )
    .y1(function(d) { return (fillCircleRadius*2 + waveHeight); } );
    var waveGroup = gaugeGroup.append("defs")
    .append("clipPath")
    .attr("id", "clipWave" + elementId);
    var wave = waveGroup.append("path")
    .datum(data)
    .attr("d", clipArea)
    .attr("T", 0);

    // The inner circle with the clipping wave attached.
    switch(value) {
        case 21:
            config.waveColor = '#FDFEFD';
            break;
        case 22:
            config.waveColor = '#FBFEFB';
            break;
        case 23:
            config.waveColor = '#F9FDF9';
            break;
        case 24:
            config.waveColor = '#F8FDF8';
            break;
        case 25:
            config.waveColor = '#F6FDF6';
            break;
        case 26:
            config.waveColor = '#F5FCF5';
            break;
        case 27:
            config.waveColor = '#F3FCF3';
            break;
        case 28:
            config.waveColor = '#F2FCF2';
            break;
        case 29:
            config.waveColor = '#F1FBF1';
            break;
        case 30:
            config.waveColor = '#F0FBF0';
            break;
        case 31:
            config.waveColor = '#F0FBF0';
            break;
        case 32:
            config.waveColor = '#EFFAEF';
            break;
        case 33:
            config.waveColor = '#EFFAEF';
            break;
        case 34:
            config.waveColor = '#EEFAEE';
            break;
        case 35:
            config.waveColor = '#EEFAEE';
            break;
        case 36:
            config.waveColor = '#EEFAEE';
            break;
        case 37:
            config.waveColor = '#EDFAED';
            break;
        case 38:
            config.waveColor = '#EDF9ED';
            break;
        case 39:
            config.waveColor = '#ECF9EC';
            break;
        case 40:
            config.waveColor = '#ECF9EC';
            break;
        case 41:
            config.waveColor = '#EBF9EB';
            break;
        case 42:
            config.waveColor = '#EBF9EB';
            break;
        case 43:
            config.waveColor = '#EAF8EA';
            break;
        case 44:
            config.waveColor ='#E9F8E9';
            break;
        case 45:
            config.waveColor = '#E8F7E8';
            break;
        case 46:
            config.waveColor = '#E6F7E6';
            break;
        case 47:
            config.waveColor = '#E4F6E4';
            break;
        case 48:
            config.waveColor = '#E2F6E2';
            break;
        case 49:
            config.waveColor = '#E0F5E0';
            break;
        case 50:
            config.waveColor = '#DEF4DE';
            break;
        case 51:
            config.waveColor = '#DBF4DB';
            break;
        case 52:
            config.waveColor = '#D8F3D8';
            break;
        case 53:
            config.waveColor = '#D4F1D4';
            break;
        case 54:
            config.waveColor = '#D0F0D0';
            break;
        case 55:
            config.waveColor = '#CCEFCC' ;
            break;
        case 56:
            config.waveColor = '#C8EDC8';
            break;
        case 57:
            config.waveColor = '#C3ECC3';
            break;
        case 58:
            config.waveColor = '#BEEABE';
            break;
        case 59:
            config.waveColor = '#B9E8B9';
            break;
        case 60:
            config.waveColor = '#B3E6B3';
            break;
        case 61:
            config.waveColor = '#ADE4AD';
            break;
        case 62:
            config.waveColor = '#A7E1A7';
            break;
        case 63:
            config.waveColor = '#A1DFA1';
            break;
        case 64:
            config.waveColor = '#9ADC9A';
            break;
        case 65:
            config.waveColor = '#94D994';
            break;
        case 66:
            config.waveColor = '#8DD68D';
            break;
        case 67:
            config.waveColor = '#86D386';
            break;
        case 68:
            config.waveColor = '#7FCF7F';
            break;
        case 69:
            config.waveColor = '#78CC78';
            break;
        case 70:
            config.waveColor = '#71C871';
            break;
        case 71:
            config.waveColor = '#6AC46A';
            break;
        case 72:
            config.waveColor = '#63C063';
            break;
        case 73:
            config.waveColor = '#5CBB5C';
            break;
        case 74:
            config.waveColor = '#56B756';
            break;
        case 75:
            config.waveColor = '#4FB24F';
            break;
        case 76:
            config.waveColor = '#49AD49';
            break;
        case 77:
            config.waveColor = '#43A843';
            break;
        case 78:
            config.waveColor = '#3DA33D';
            break;
        case 79:
            config.waveColor = '#389E38';
            break;
        case 80:
            config.waveColor = '#339933'
            break;
        case 81:
            config.waveColor = '#2E942E';
            break;
        case 82:
            config.waveColor = '#2A8E2A';
            break;
        case 83:
            config.waveColor = '#268926';
            break;
        case 84:
            config.waveColor = '#238323';
            break;
        case 85:
            config.waveColor = '#207E20';
            break;
        case 86:
            config.waveColor = '#1D781D';
            break;
        case 87:
            config.waveColor = '#1B721B';
            break;
        case 88:
            config.waveColor = '#196D19';
            break;
        case 89:
            config.waveColor = '#186718';
            break;
        case 90:
            config.waveColor = '#176217';
            break;
        case 91:
            config.waveColor = '#165D16';
            break;
        case 92:
            config.waveColor = '#165816';
            break;
        case 93:
            config.waveColor = '#155315';
            break;
        case 94:
            config.waveColor = '#154E15';
            break;
        case 95:
            config.waveColor = '#154A15';
            break;
        case 96:
            config.waveColor = '#164616';
            break;
        case 97:
            config.waveColor = '#154215';
            break;
        case 98:
            config.waveColor = '#153F15';
            break;
        case 99:
            config.waveColor = '#143C14';
            break;
        case 100:
            config.waveColor = '#133913';
            break;
    }

    var fillCircleGroup = gaugeGroup.append("g")
    .attr("clip-path", "url(#clipWave" + elementId + ")");

    
    fillCircleGroup.append("circle")
    .attr('id', "innerEffortCircle" )
    .attr("cx", radius)
    .attr("cy", radius)
    .attr("r", fillCircleRadius)
    .style("fill", config.waveColor);

    // Text where the wave does overlap.
    var text2 = fillCircleGroup.append("text")
    .text(textRounder(textStartValue) + percentText)
    .attr("class", "liquidFillGaugeText")
    .attr("text-anchor", "middle")
    .attr("font-size", textPixels + "px")
    .style("fill", config.waveTextColor)
    .attr('transform','translate('+radius+','+textRiseScaleY(config.textVertPosition)+')');

    // Make the value count up.
    if(config.valueCountUp){
        var textTween = function(){
            var i = d3.interpolate(this.textContent, textFinalValue);
            return function(t) { this.textContent = textRounder(i(t)) + percentText; }
        };
        text1.transition()
        .duration(config.waveRiseTime)
        .tween("text", textTween);
        text2.transition()
        .duration(config.waveRiseTime)
        .tween("text", textTween);
    }

    // Make the wave rise. wave and waveGroup are separate so that horizontal and vertical movement can be controlled independently.
    var waveGroupXPosition = fillCircleMargin+fillCircleRadius*2-waveClipWidth;
    if(config.waveRise){
        waveGroup.attr('transform','translate('+waveGroupXPosition+','+waveRiseScale(0)+')')
        .transition()
        .duration(config.waveRiseTime)
        .attr('transform','translate('+waveGroupXPosition+','+waveRiseScale(fillPercent)+')')
    .on("start", function(){ wave.attr('transform','translate(1,0)'); }); // This transform is necessary to get the clip wave positioned correctly when waveRise=true and waveAnimate=false. The wave will not position correctly without this, but it's not clear why this is actually necessary.
    } else {
        waveGroup.attr('transform','translate('+waveGroupXPosition+','+waveRiseScale(fillPercent)+')');
    }

    if(config.waveAnimate) animateWave();

    function animateWave() {
        wave.attr('transform','translate('+waveAnimateScale(wave.attr('T'))+',0)');
        wave.transition()
        .duration(config.waveAnimateTime * (1-wave.attr('T')))
        // .ease('linear')
        .attr('transform','translate('+waveAnimateScale(1)+',0)')
        .attr('T', 1)
        .on('end', function(){
            wave.attr('T', 0);
            animateWave(config.waveAnimateTime);
        });
    }

    function GaugeUpdater() {
        this.update = function(value, control) {
            var newWaveColor = 'rgba(0 , 132, 46, 0.7)';
            var textColor = 'white';
            var waveTextColor = 'white'

            var newFinalValue = parseFloat(value).toFixed(2);

            var textRounderUpdater = function(value){ return Math.round(value); };
            if(parseFloat(newFinalValue) != parseFloat(textRounderUpdater(newFinalValue))){
                textRounderUpdater = function(value){ return parseFloat(value).toFixed(1); };
            }
            if(parseFloat(newFinalValue) != parseFloat(textRounderUpdater(newFinalValue))){
                textRounderUpdater = function(value){ return parseFloat(value).toFixed(2); };
            }

            var textTween = function(){
                var i = d3.interpolate(control.textContent, parseFloat(value).toFixed(2));
                return function(t) { 
                    control.textContent = textRounderUpdater(i(t)) + percentText;
                }
            };
     

            var fillPercent = Math.max(config.minValue, Math.min(config.maxValue, value))/config.maxValue;
            var waveHeight = fillCircleRadius*waveHeightScale(fillPercent*100);
            var waveRiseScale = d3.scaleLinear()
            // The clipping area size is the height of the fill circle + the wave height, so we position the clip wave
            // such that the it will overlap the fill circle at all when at 0%, and will totally cover the fill
            // circle at 100%.
            .range([(fillCircleMargin+fillCircleRadius*2+waveHeight),(fillCircleMargin-waveHeight)])
            .domain([0,1]);

            var newHeight = waveRiseScale(fillPercent);
            var waveScaleX = d3.scaleLinear().range([0,waveClipWidth]).domain([0,1]);
            var waveScaleY = d3.scaleLinear().range([0,waveHeight]).domain([0,1]);
            var newClipArea;
            if(config.waveHeightScaling){
                newClipArea = d3.area()
                .x(function(d) { return waveScaleX(d.x); } )
                .y0(function(d) { return waveScaleY(Math.sin(Math.PI*2*config.waveOffset*-1 + Math.PI*2*(1-config.waveCount) + d.y*2*Math.PI));} )
                .y1(function(d) { return (fillCircleRadius*2 + waveHeight); } );
            } else {
                newClipArea = clipArea;
            }

            if(value <= 69) {
                textColor = 'rgba(0 , 132, 46, 0.7)';
            } else {
                textColor = 'white';
            }

            if(value <= 20)
                newWaveColor = '#FFFFFF';
                
            switch(value) {
                case 21:
                    newWaveColor = '#FDFEFD';
                    break;
                case 22:
                    newWaveColor = '#FBFEFB';
                    break;
                case 23:
                    newWaveColor = '#F9FDF9';
                    break;
                case 24:
                    newWaveColor = '#F8FDF8';
                    break;
                case 25:
                    newWaveColor = '#F6FDF6';
                    break;
                case 26:
                    newWaveColor = '#F5FCF5';
                    break;
                case 27:
                    newWaveColor = '#F3FCF3';
                    break;
                case 28:
                    newWaveColor = '#F2FCF2';
                    break;
                case 29:
                    newWaveColor = '#F1FBF1';
                    break;
                case 30:
                    newWaveColor = '#F0FBF0';
                    break;
                case 31:
                    newWaveColor = '#F0FBF0';
                    break;
                case 32:
                    newWaveColor = '#EFFAEF';
                    break;
                case 33:
                    newWaveColor = '#EFFAEF';
                    break;
                case 34:
                    newWaveColor = '#EEFAEE';
                    break;
                case 35:
                    newWaveColor = '#EEFAEE';
                    break;
                case 36:
                    newWaveColor = '#EEFAEE';
                    break;
                case 37:
                    newWaveColor = '#EDFAED';
                    break;
                case 38:
                    newWaveColor = '#EDF9ED';
                    break;
                case 39:
                    newWaveColor = '#ECF9EC';
                    break;
                case 40:
                    newWaveColor = '#ECF9EC';
                    break;
                case 41:
                    newWaveColor = '#EBF9EB';
                    break;
                case 42:
                    newWaveColor = '#EBF9EB';
                    break;
                case 43:
                    newWaveColor = '#EAF8EA';
                    break;
                case 44:
                    newWaveColor ='#E9F8E9';
                    break;
                case 45:
                    newWaveColor = '#E8F7E8';
                    break;
                case 46:
                    newWaveColor = '#E6F7E6';
                    break;
                case 47:
                    newWaveColor = '#E4F6E4';
                    break;
                case 48:
                    newWaveColor = '#E2F6E2';
                    break;
                case 49:
                    newWaveColor = '#E0F5E0';
                    break;
                case 50:
                    newWaveColor = '#DEF4DE';
                    break;
                case 51:
                    newWaveColor = '#DBF4DB';
                    break;
                case 52:
                    newWaveColor = '#D8F3D8';
                    break;
                case 53:
                    newWaveColor = '#D4F1D4';
                    break;
                case 54:
                    newWaveColor = '#D0F0D0';
                    break;
                case 55:
                    newWaveColor = '#CCEFCC' ;
                    break;
                case 56:
                    newWaveColor = '#C8EDC8';
                    break;
                case 57:
                    newWaveColor = '#C3ECC3';
                    break;
                case 58:
                    newWaveColor = '#BEEABE';
                    break;
                case 59:
                    newWaveColor = '#B9E8B9';
                    break;
                case 60:
                    newWaveColor = '#B3E6B3';
                    break;
                case 61:
                    newWaveColor = '#ADE4AD';
                    break;
                case 62:
                    newWaveColor = '#A7E1A7';
                    break;
                case 63:
                    newWaveColor = '#A1DFA1';
                    break;
                case 64:
                    newWaveColor = '#9ADC9A';
                    break;
                case 65:
                    newWaveColor = '#94D994';
                    break;
                case 66:
                    newWaveColor = '#8DD68D';
                    break;
                case 67:
                    newWaveColor = '#86D386';
                    break;
                case 68:
                    newWaveColor = '#7FCF7F';
                    break;
                case 69:
                    newWaveColor = '#78CC78';
                    break;
                case 70:
                    newWaveColor = '#71C871';
                    break;
                case 71:
                    newWaveColor = '#6AC46A';
                    break;
                case 72:
                    newWaveColor = '#63C063';
                    break;
                case 73:
                    newWaveColor = '#5CBB5C';
                    break;
                case 74:
                    newWaveColor = '#56B756';
                    break;
                case 75:
                    newWaveColor = '#4FB24F';
                    break;
                case 76:
                    newWaveColor = '#49AD49';
                    break;
                case 77:
                    newWaveColor = '#43A843';
                    break;
                case 78:
                    newWaveColor = '#3DA33D';
                    break;
                case 79:
                    newWaveColor = '#389E38';
                    break;
                case 80:
                    newWaveColor = '#339933'
                    break;
                case 81:
                    newWaveColor = '#2E942E';
                    break;
                case 82:
                    newWaveColor = '#2A8E2A';
                    break;
                case 83:
                    newWaveColor = '#268926';
                    break;
                case 84:
                    newWaveColor = '#238323';
                    break;
                case 85:
                    newWaveColor = '#207E20';
                    break;
                case 86:
                    newWaveColor = '#1D781D';
                    break;
                case 87:
                    newWaveColor = '#1B721B';
                    break;
                case 88:
                    newWaveColor = '#196D19';
                    break;
                case 89:
                    newWaveColor = '#186718';
                    break;
                case 90:
                    newWaveColor = '#176217';
                    break;
                case 91:
                    newWaveColor = '#165D16';
                    break;
                case 92:
                    newWaveColor = '#165816';
                    break;
                case 93:
                    newWaveColor = '#155315';
                    break;
                case 94:
                    newWaveColor = '#154E15';
                    break;
                case 95:
                    newWaveColor = '#154A15';
                    break;
                case 96:
                    newWaveColor = '#164616';
                    break;
                case 97:
                    newWaveColor = '#154215';
                    break;
                case 98:
                    newWaveColor = '#153F15';
                    break;
                case 99:
                    newWaveColor = '#143C14';
                    break;
                case 100:
                    newWaveColor = '#133913';
                    break;
            }
        

            var circle = d3.select("#innerEffortCircle");
            circle.transition().style("fill", newWaveColor);

            

            text1.text(textRounder(newFinalValue))
            text1.transition()
            .duration(config.waveRiseTime)
            .tween("text", textTween);

            text2.text(textRounder(newFinalValue))
            text2.transition()
            .duration(config.waveRiseTime)
            .tween("text", textTween);
            
            var effortScoreText = d3.selectAll(".liquidFillGaugeText");
            effortScoreText.transition().style("fill", textColor);

            var newWavePosition = config.waveAnimate?waveAnimateScale(1):0;
            wave.transition()
            .duration(0)
            .transition()
            .duration(config.waveAnimate?(config.waveAnimateTime * (1-wave.attr('T'))):(config.waveRiseTime))
            // .ease('linear')
            .attr('d', newClipArea)
            .attr('transform','translate('+newWavePosition+',0)')
            .attr('T','1')
            .on("end", function(){
                if(config.waveAnimate){
                    wave.attr('transform','translate('+waveAnimateScale(0)+',0)');
                    animateWave(config.waveAnimateTime);
                }
            });
            waveGroup.transition()
                .duration(config.waveRiseTime)
                .attr('transform','translate('+waveGroupXPosition+','+newHeight+')');

            
        }
    }

    return new GaugeUpdater();
}

function ScatterChart(effortScore, academicScore) {

    var data = [[academicScore, effortScore]];

    var margin = { top: 20, right: 20, bottom: 30, left: 40},
    width = 960 - margin.left - margin.right,
    height = 500 - margin.top - margin.bottom;

    var x = d3.scaleLinear().range([0, width]);
    var y = d3.scaleLinear().range([height, 0]);

    var color = d3.scaleOrdinal(d3.schemeCategory10);
    var xAxis = d3.axisBottom(x);
    var yAxis = d3.axisLeft(y);

    var _chart = document.getElementById("effortsChart");
    if (null !== _chart)
        _chart.remove();

    var chart = d3.select("#chartContainer").append("svg")
    .attr("id", "effortsChart")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top +")");

    // chart.append('g');

    x.domain([0, 100]).range([0, width]);
    y.domain([100, 0]).range([0, width]);


    // draw x Axis
    chart.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0, "+ height +")")
    .call(xAxis)
    .append("text")
    .attr("class", "label")
    .attr("x", width)
    .attr("y", -6)
    .style("text-anchor", "end")
    .text("Effort Score");

    // draw y Axis
    chart.append("g")
    .attr("class", "y axis")
    .call(yAxis)
    .append("text")
    .attr("class", "label")
    .attr("transform", "rotate(-90)")
    .attr("y", 6)
    .attr("dy", ".71em")
    .style("text-anchor", "end")
    .text("Academic Percentile")

    chart.selectAll(".dot")
    .data(data)
    .enter().append("circle")
    .attr("class", "dot")
    .attr("r", 6)
    .attr("cx", d => x(d[1]))
    .attr("cy", d => y(d[0]))



    // draw Scatter dots
    // .append("g")
    // .selectAll("scatter-dots")
    // .data(data)
    // .enter()
    // .append("circle")
    // .attr("cx", function(d, i) { return x(d[0]); })
    // .attr("cy", function(d) { return y(d[1]); })
    // .attr("r", 1);



    // var g = svg.append('g');

    // g.selectAll('scatter-dots')
    //     .data(data)
    //     .enter()
    //     .append('circle')
    //     .attr('cx', (d, i) => x(d[0]))
    //     .attr('cy', d => y(d[1]))
    //     .attr('r', 8);

    // //     svg.selectAll(".dot")
    // //         .data(data)
    // //         .enter().append("circle")
    // //         .attr("class", "dot")
    // //         .attr("r", 3.5)
    // //         .attr("cx", d => x(d.sepalWidth))
    // //         .attr("cy", d => y(d.sepalWidth))
    // //         .style("fill", d => color(d.species));

    // //     var legend = svg.selectAll(".legend")
    // //         .data(color.domain())
    // //         .enter().append("g")
    // //         .attr("class", "legend")
    // //         .attr("transform", function(d, i) { return "translate(0,"+ i*20 + ")"});


    // //     legend.append("rect")
    // //         .attr("x", width - 18)
    // //         .attr("width", 18)
    // //         .attr("height", 18)
    // //         .style("fill", color);

    // //     legend.append("text")
    // //         .attr("x", width - 24)
    // //         .attr("y", 9)
    // //         .attr("dy", ".35em")
    // //         .style("text-anchor", "end")
    // //         .text(d => d);
    // });
}