<template>
  <v-list>
    <div>
      <v-btn
        v-for="item in socialItems()"
        :key="item.icon"
        icon="true"
        density="comfortable"
        fab
        variant="text"
        style="margin-right: 0.6em; margin-left: 0.5em;"
        @click="openLink(item.link)"
      >
        <font-awesome-icon
          dark
          :icon="{ prefix: 'fab', iconName: item.icon }"
          class="social-icon"
        />
      </v-btn>
    </div>
  </v-list>
</template>

<script>
import {getSocialLinks} from '@/utils/menubuilder'

export default {
    name: 'SocialComponent',
    data () {
        return {
            items: []
        }
    },
    methods: {
        socialItems() {
          return getSocialLinks();
        },
        openLink(link) {
            window.open(link, '_blank');
        }
    }
}
</script>
<style scoped>
    .social-icon {
        height: 1.35em;
        width: 1.35em;
    }
</style>
