<template>
  <v-container class="pa-0 ma-0 w-100 mw-100">
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Middle Managers</v-toolbar-title>
        <v-spacer />
        <v-btn
          variant="elevated"
          @click="addMiddleManager()"
        >
          Add Middle Manager
        </v-btn>
      </v-toolbar>
    </v-card>

    <k-grid
      ref="grid"
      class="middleManagerGrid gridFullHeight"
      :data-items="gridData.items"
      :sort="sort"
      :sortable="true"
      :skip="serverFilters.skip"
      :take="serverFilters.take"
      :total="gridData.totalItems"
      :pageable="pageable"
      :page-size="globalStore.defaultRowsPerPage"
      :columns="gridData.columns"
      :loader="loader"
      @sortchange="sortChangeHandler"
      @pagechange="pageChangeHandler"
    >
      <template #loader>
        <div class="k-loader-container k-loader-container-md k-loader-top">
          <div class="k-loader-container-overlay k-overlay-dark" />
          <div class="k-loader-container-inner">
            <k-loader
              :size="'large'"
              :type="'pulsing'"
              :theme-color="'success'"
            />
          </div>
        </div>
      </template>

      <template #myTemplate="{props}">
        <td class="d-flex justify-center">
          <v-btn
            icon="true"
            density="comfortable"
            color="red"
            variant="outlined"
            class="ma-2 pa-0"
            @click.stop="openRemoveTeacherDialog(props.dataItem)"
          >
            <v-tooltip activator="parent">
              Revoke access
            </v-tooltip>

            <v-icon
              small
              icon="mdi-delete"
            />
          </v-btn>

          <v-btn
            icon="true"
            variant="outlined"
            density="comfortable"
            color="grey"
            class="ma-2 pa-0"
            :to="{
              name: 'MiddleManagerClassSelector',
              params: {
                managerId: props.dataItem.id
              }}"
          >
            <v-tooltip activator="parent">
              Manage Classes
            </v-tooltip>
            <v-icon
              small
              icon="mdi-cog"
            />
          </v-btn>
        </td>
      </template>
    </k-grid>

    <v-dialog
      v-model="addMiddleManagerDialog"
      max-width="40%"
    >
      <v-card :loading="loading">
        <v-card-title>
          Add Middle Manager
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="selectedTeacher"
            :items="allTeachers"
            label="Select Teacher"
            hide-no-data
            item-title="name"
            item-value="id"
            :rules="[v => !!v || 'Teacher required']"
            hide-details
            clearable
            variant="underlined"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            @click.stop="addMiddleManagerDialog = false;"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            variant="elevated"
            @click.stop="saveMiddleManager()"
          >
            Add Middle Manager
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="removeTeacherDialog"
      max-width="40%"
    >
      <v-card>
        <v-card-title>
          Confirmation
        </v-card-title>
        <v-card-text>
          This action will <span class="dialogWarningColor">
            remove</span> the Middle Manager Access Level for teacher
          <b> {{ teacherName }} </b>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            variant="outlined"
            @click.stop="closeRemoveTeacherDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            variant="elevated"
            @click.stop="removeMiddleManager()"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {initialize} from '@/utils/effort-tracking-api';
import {addMiddleManager,removeMiddleManager} from '@/api/middleManagerApi';
import {getTeacherByRole} from '@/api/teachersApi';
import {Grid as KGrid} from '@progress/kendo-vue-grid';
import {Loader as KLoader} from '@progress/kendo-vue-indicators';
import {globalStore} from '@/main';
import {debounce} from 'lodash';

export default {
  components: {
    KLoader,
    KGrid,
  },
  data: () => ({
    loading: false,

    delMiddleManagerId: 0,
    teacherName: '',

    removeTeacherDialog: false,
    addMiddleManagerDialog: false,

    autocompleteSearch: null,
    autoCompleteItems: [],
    allTeachers: [],
    teacherNames: [],
    selectedTeacher: null,

    search: '',
    // Does not work as a child property.
    sort: [{field: 'lastName', dir: 'asc'}],
    loader: false,
    serverFilters: {
      sortBy: 'lastName',
      filter: '',
      take: globalStore.defaultRowsPerPage,
      skip: 0,
      descending: false
    },
    pagerValues: {
      pagerType: globalStore.pagerTypes['numeric'],
      pagerButtons: globalStore.pagerButtons,
      pagerInfo: globalStore.pagerInfo,
      pagerSizes: globalStore.pagerSizes,
      pagePrevNext: globalStore.pagerSizes,
      pageSize: globalStore.defaultRowsPerPage,
      pageSizeValue: globalStore.defaultRowsPerPage,
    },
    gridData: {
      columns: [

        {field: 'firstName', title: 'First Name'},
        {field: 'lastName', title: 'Last Name'},
        {field: 'email', title: 'Email'},
        // { field: 'teacherId', title: 'teacherId'  },
        {field: '', cell: 'myTemplate', sortable: false},
      ],
      items: [],
      totalItems: 0,
    },
  }),
  computed: {
    globalStore() {
      return globalStore;
    },
    pageable() {
      return {
        buttonCount: this.pagerValues.pagerButtons,
        info: this.pagerValues.pagerInfo,
        type: this.pagerValues.pagerType,
        pageSizes: this.pagerValues.pagerSizes
                   ? globalStore.allowedRowsPerPage
                   : undefined,
        previousNext: this.pagerValues.pagePrevNext,
        pageSizeValue: this.pagerValues.pageSizeValue,
      };
    },
  },
  watch: {
    search: {
      handler() {
        this.debouncedGetDataFromApi();
      },
    },
    autocompleteSearch(val) {
      val && val !== this.selectedTeacher && this.querySelections(val);
    }
  },
  created() {
    this.debouncedGetDataFromApi = debounce(() => {
      this.getDataFromApi();
    }, 500);
  },
  mounted() {
    this.debouncedGetDataFromApi();
  },
  methods: {
    sortChangeHandler: function(e) {
      if(e.sort.length === 0) {
        this.serverFilters.descending = false;
        this.debouncedGetDataFromApi();
        this.sort = e.sort;
        return;
      }
      this.serverFilters.descending = e.sort[0].dir !== 'asc';
      this.serverFilters.sortBy = e.sort[0].field;
      this.debouncedGetDataFromApi();
      this.sort = e.sort;
    },
    pageChangeHandler: function(event) {
      console.log('event', event);
      this.pagerValues.pageSize = event.page.take;
      this.pagerValues.pageSizeValue = event.page.take;
      this.serverFilters.skip = event.page.skip;
      this.serverFilters.take = event.page.take;
      this.debouncedGetDataFromApi();
    },
    getDataFromApi() {
      this.loader = true;
      initialize()
        .then(() => {
          this.serverFilters.filter = this.search;
          getTeacherByRole(globalStore.middleManageRole, false, this.serverFilters)
            .then(
              data => {
                this.gridData.items = data.items;
                this.gridData.totalItems = data.count;
                this.loader = false;
              });
        });
    },
    openRemoveTeacherDialog(item) {
      this.teacherName = item.title + ' ' + item.firstName + ' ' + item.lastName;
      this.delMiddleManagerId = item.id;
      this.removeTeacherDialog = true;
    },
    closeRemoveTeacherDialog() {
      this.removeTeacherDialog = false;
      this.delMiddleManagerId = 0;
      this.selectedTeacher = null;
    },
    addMiddleManager() {
      this.loading = true;
      initialize()
        .then(() => {
          getTeacherByRole(globalStore.middleManageRole, true, undefined)
            .then(
              data => {
                this.allTeachers = data.items;
                data.items.forEach(
                  element => {
                    this.teacherNames.push(element.name);
                  });

                this.addMiddleManagerDialog = true;
              });
        });
      this.loading = false;
    },
    saveMiddleManager() {
      console.log('selectedTeacher', this.selectedTeacher);
      const teacherId = this.allTeachers.filter(tt => tt.id === this.selectedTeacher)[0].id;

      console.log('Adding middle manager with teacher Id: ');
      console.log(teacherId);

      this.loader = true;
      addMiddleManager(teacherId)
        .then(() => {
          this.debouncedGetDataFromApi();
        });
      this.loader = false;
      this.addMiddleManagerDialog = false;
    },
    removeMiddleManager() {
      this.loader = true;
      this.removeTeacherDialog = false;
      removeMiddleManager(this.delMiddleManagerId)
        .then(() => {
          this.debouncedGetDataFromApi();
        })
        .catch(ex => {
          console.log(ex);
        });
      this.loader = false;
    },
    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.autoCompleteItems = this.teacherNames.filter(e => {
          return (e || '').toLowerCase()
                          .indexOf((v || '').toLowerCase()) > -1;
        });
        this.loading = false;
      }, 500);
    }
  },
};
</script>

<style>
.middleManagerGrid .k-grid-header col:nth-of-type(1),
.middleManagerGrid .k-grid-table col:nth-of-type(1){
  width: 25%;
}

.middleManagerGrid .k-grid-header col:nth-of-type(2),
.middleManagerGrid .k-grid-table col:nth-of-type(2) {
  width: 25%;
}

.middleManagerGrid .k-grid-header col:nth-of-type(3),
.middleManagerGrid .k-grid-table col:nth-of-type(3){
  width: 20%;
}


.middleManagerGrid .k-grid-header col:nth-of-type(4),
.middleManagerGrid .k-grid-table col:nth-of-type(4){
  width: 15%;
}




</style>
