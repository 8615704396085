<template>
  <v-select
    v-model="selectedPeriod"
    :items="items"
    item-title="periodName"
    item-value="periodId"
    label="Reporting Period"
    variant="outlined"
    density="compact"
    prepend-icon="mdi-calendar-range"
    style="margin-top: 20px;"
  />
</template>
<script>
import {initialize} from '@/utils/effort-tracking-api';
import {globalStore} from '@/main';
import {isProxy, toRaw} from 'vue';
import {getOrderedPeriods} from '@/api/periodApi';
import {UserType} from '@/utils/settings';

export default {
  name: 'PeriodSelector',

  data() {
    return {
      selectedPeriod: 0,
      items: [],
      isTeacher: false,
      isStudent: false,
      placeholder: '',
    };
  },
  watch: {
    selectedPeriod: function(val) {
      if(val != null) {
        const oldGlobalStore = globalStore.selectedPeriod;
        if(oldGlobalStore === val) {
          return;
        }
        if (typeof(val) === 'number') {
          globalStore.selectedPeriod = val;
          localStorage.setItem('period-selected', val);
        }
        else {
          globalStore.selectedPeriod = val.periodId;
          localStorage.setItem('period-selected', val.periodId);
        }

        this.$emit('period-selected', val);
      }
    }
  },
  beforeMount() {
    const defaultPeriod = localStorage.getItem('period-selected');
    const periods = JSON.parse(localStorage.getItem('periods'));
    if(periods !== null && periods.length > 0) {
      let selectedPeriod = periods.find(p => p.periodId === Number(defaultPeriod));
      this.selectDefaultPeriod(selectedPeriod);
    }
    else {
      this.selectDefaultPeriod(defaultPeriod);
    }
    this.isStudent = (this.$store.state.usertype === UserType.Student);
    this.isTeacher = (this.$store.state.usertype === UserType.Teacher || this.$store.state.usertype === UserType.MiddleManager);
    this.update();
  },
  expose: [
    'update',
    'selectDefaultPeriod'
  ],
  methods: {
    selectDefaultPeriod(periodId) {
      if (!(periodId === null || periodId === 0 || periodId === undefined)) {
        if (typeof (periodId) === 'number') {
          globalStore.selectedPeriod = periodId;
          localStorage.setItem('period-selected', periodId);
        }
        else {
          globalStore.selectedPeriod = periodId.periodId;
          localStorage.setItem('period-selected', periodId.periodId);
        }
        this.selectedPeriod = periodId;
      }
    },
    update() {
      initialize()
        .then(() => {
          getOrderedPeriods(this.$store.state.usertype, this.$store.state.userdata.id)
            .then(reportingPeriods => {
              if (reportingPeriods.length > 0) {
              this.$store.state.periods = [];
              reportingPeriods.forEach(period => {
                let startDate = new Date(period.startDate);
                let endDate = new Date(period.endDate);
                endDate.setDate(endDate.getDate() + 1);

                let p = {
                  periodName: period.periodName,
                  periodId: period.periodId,
                  startDate: startDate,
                  endDate: endDate,
                  active: period.active,
                  visible: period.visible,
                  complete: period.complete
                };
                if(this.$store.state.usertype !== UserType.Admin) {
                  if(p.visible) {
                    this.$store.state.periods.push(p);
                  }
                  this.items = reportingPeriods.filter(rpp => rpp.visible);
                }
                else {
                  this.$store.state.periods.push(p);
                  this.items = reportingPeriods;
                }
              });
              if(this.selectedPeriod === "undefined" ||
                  this.selectedPeriod === 0 ||
                  this.selectedPeriod === null) {
                globalStore.selectedPeriod = reportingPeriods[0].periodId;
                localStorage.setItem('period-selected', reportingPeriods[0].periodId);
                if(isProxy(this.items)) { //this If() block is not really necessary
                  let rps = toRaw(this.items);
                  this.selectDefaultPeriod(rps[0].periodId);
                }
              }}
            })
            .catch(ex => {
              console.log(ex);
            });
        })
        .catch(ex => {
          console.log(ex);
        });
    },
  }
}
</script>
