<template>
  <v-btn
    dark
    flat
    large
  >
    <v-icon
      icon="mdi-account-circle"
    />
    <span> {{ user }}</span>
    <v-menu
      offset-y
      activator="parent"
    >
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="goToAction(item.link)"
        >
          <v-list-item>{{ item.title }}</v-list-item>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn>
</template>

<script>
  export default {
    data() {
        return {
            name: 'account-controls',
            items: [
                { title: 'My Account', link: "Account"},
                { title: 'Logout' , link: "Logout"}
            ],
            user: '',
        }
    },
    mounted() {
        this.user = this.getUserData();
    },
    methods: {
        goToAction(action){
        console.log(action);
            if(action === 'Logout') {
                this.$store.commit('removeUserData');
                window.location.href = '#/users/login';
            }

            if(action === 'Account') {
                window.location.href = '#/account';
            }
        },

        getUserData() {
            if(this.$store.state.userdata != null)
            {
              const title =
                  typeof this.$store.state.userdata.title != "undefined" ? this.$store.state.userdata.title : '';

              let user = '';
              if(this.$store.state.usertype === 'student')
                {
                    user = this.$store.state.userdata.lastName + ', ' + this.$store.state.userdata.firstName;
                } else {
                    user = title + ' '
                    + this.$store.state.userdata.firstName + ' '
                    + this.$store.state.userdata.lastName;
                }
                return user;
            }
            return '';            
        },

      //   logOutSSO() {
      //     userManager.signoutRedirect()
      //     .then(() => {
      //       window.location.href = 'http://localhost:8080/#/users/login';
      //     })
      //     .catch((error) => {
      //       console.error('Error with logout:', error);
      //       window.location.href = 'http://localhost:8080/#/users/login';
      //     });
      // },
    },
  }
</script>
