export { getMenuItems, getExternalLinks, getSocialLinks, getHelpLinks }

function getSocialLinks() {
    const socialLinks = [];
    socialLinks.push(
        { icon: "facebook", link: 'https://www.facebook.com/EffortTracking' },
        { icon: 'twitter', link: 'https://twitter.com/EffortTracking' },
        { icon: 'instagram', link: 'https://www.instagram.com/efforttracking' },
        { icon: 'linkedin', link: 'https://www.linkedin.com/company/effort-tracking' }
    );

    return socialLinks;
}

function getExternalLinks() {
    const externalLinks = [];
    externalLinks.push(
        { icon: 'mdi-information', title: 'Privacy Policy', link: 'https://www.efforttracking.com/index.php/privacy' },
        { icon: 'mdi-information', title: 'Terms and Conditions', link: 'https://www.efforttracking.com/index.php/terms-and-conditions' },
        { icon: 'mdi-information', title: 'Website', link: 'https://www.efforttracking.com' }
    );

    return externalLinks;
}

function getHelpLinks() {
    const helpLinks = [];
    helpLinks.push(
        { icon: 'mdi-help-circle', title: 'Help', link: 'user-manual' },
    );

    return helpLinks;
}

function getMenuItems(usertype, userId, periodComplete, showDashboard) {
    const menuItems = [];
    switch (usertype) {
        case 'student':
            menuItems.push({ icon: 'how_to_reg', title: 'Effort Grading', link: `/student/${userId}/student-efforts` });
            if (periodComplete)
                menuItems.push({ icon: 'how_to_reg', title: 'Grade Summary', link: `/student/${userId}/myefforts` });
            if (showDashboard)
                menuItems.push({ icon: 'dashboard', title: 'Student Dashboard', link: `/student/${userId}/dashboard` });
            break;
        case 'teacher':
            menuItems.push(
                { icon: 'how_to_reg', title: 'Effort Grading', link: '/teacher/' + userId + '/teacher-efforts' },
                { icon: 'assignment_ind', title: 'Students', link: '/students' },
                { icon: 'bubble_chart', title: 'Bubble Chart', link: '/motionchart' }
            );

            if (periodComplete)
                menuItems.push({ icon: 'how_to_reg', title: 'Grade Summary', link: '/teacher/' + userId + '/students-grades' });
            break;
        case 'middlemanager':
            menuItems.push(
                { icon: 'account_circle', title: 'Teachers', link: '/middle-manager-teachers' },
                { icon: 'people', title: 'Classes', link: '/classes-middle-manager' },
                { icon: 'how_to_reg', title: 'Effort Grading', link: '/teacher/' + userId + '/teacher-efforts' },
                { icon: 'assignment_ind', title: 'Students', link: '/students' },
                { icon: 'bubble_chart', title: 'Bubble Chart', link: '/motionchart' }
            );

            if (periodComplete)
                menuItems.push({ icon: 'how_to_reg', title: 'Grade Summary', link: '/teacher/' + userId + '/students-grades' });
            break;
        case 'admin':
            menuItems.push(
                { icon: 'mdi-account-circle', title: 'Middle Managers', link: '/middle-managers' },
                { icon: 'mdi-account-circle', title: 'Teachers', link: '/teachers' },
                { icon: 'mdi-clipboard-account', title: 'Students', link: '/students' },
                { icon: 'mdi-account-multiple', title: 'Classes', link: '/classes' },
                { icon: 'mdi-account-check', title: 'Status', link: '/status' },
                { icon: 'mdi-chart-bubble', title: 'Bubble Chart', link: '/motionchart' },
                { isDivider: true },
                { icon: 'mdi-clock-outline', title: 'Reporting Periods', link: '/reporting-periods' },
                { isDivider: true },
                { icon: 'mdi-package-up', title: 'Data Import', link: '/datamanagement/import' },
                { icon: 'mdi-package-down', title: 'Data Export', link: '/datamanagement/export' },
                { icon: 'mdi-cog', title: 'Settings', link: '/settings' },
                { icon: 'mdi-account-circle', title: 'User Management', link: '/datamanagement/usermanagement' },
                { isDivider: true }
            );
            break;
    }

    return menuItems;
}
