<template>
  <v-container class="pa-0 ma-0 mw-100">
    <v-alert
      v-model="disableEffortGrades"
      type="error"
      density="compact"
      variant="tonal"
      closable
    >
      Student Effort Grades are only viewable in completed periods.
    </v-alert>
    <v-card>
      <v-alert
        v-model="alertSuccess"
        variant="tonal"
        type="success"
        closable
      >
        {{ resultMessage }}
      </v-alert>

      <v-alert
        v-model="alertError"
        variant="tonal"
        type="error"
        closable
      >
        {{ resultMessage }}
      </v-alert>

      <v-toolbar class="elevation-1">
        <v-toolbar-title>
          Students
        </v-toolbar-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-inner-icon="mdi-magnify"
          placeholder="Search"
          variant="solo"
          density="compact"
          style="margin-top: 20px; margin-right: 10px;"
        />
      </v-toolbar>
    </v-card>

    <k-grid
      ref="grid"
      class="studentGrid gridFullHeight"
      :data-items="gridData.items"
      :sort="sort"
      :sortable="true"
      :skip="serverFilters.skip"
      :take="serverFilters.take"
      :total="gridData.totalItems"
      :pageable="pageable"
      :page-size="globalStore.defaultRowsPerPage"
      :columns="gridData.columns"
      :loader="loader"
      @sortchange="sortChangeHandler"
      @pagechange="pageChangeHandler"
    >
      <template #loader>
        <div class="k-loader-container k-loader-container-md k-loader-top">
          <div class="k-loader-container-overlay k-overlay-dark" />
          <div class="k-loader-container-inner">
            <k-loader
              :size="'large'"
              :type="'pulsing'"
              :theme-color="'success'"
            />
          </div>
        </div>
      </template>
      <template #myTemplate="{props}">
        <td class="d-flex justify-center">
          <!-- Effort grades -->

          <v-btn
            variant="outlined"
            icon="true"
            class="ma-2 pa-0 hidden-md-and-up"
            density="comfortable"
            :disabled="disableEffortGrades"

            color="success"
            :to="{
              name: 'StudentEfforts',
              params: {
                studentId: props.dataItem.studentId
              }}"
          >
            <v-tooltip activator="parent">
              Effort grades
            </v-tooltip>
            <v-icon
              small
              icon="mdi-clipboard-check"
            />
          </v-btn>

          <v-btn
            variant="text"
            class="mr-4 hidden-sm-and-down"
            size="sm"
            density="compact"
            :disabled="disableEffortGrades"
            color="success"
            :to="{
              name: 'StudentEfforts',
              params: {
                studentId: props.dataItem.studentId
              }}"
          >
            <v-icon
              class="mr-4"
              icon="mdi-clipboard-check"
            />
            <span>
              Effort grades
            </span>
          </v-btn>

          <!--   Grade Summary -->
          <v-btn
            icon="true"
            density="comfortable"
            class="ma-2 pa-0"
            variant="outlined"
            color="success"
            :to="{
              name: 'StudentsGradeSummary',
              params: {
                studentId: props.dataItem.studentId
              }}"
          >
            <v-tooltip activator="parent">
              Grade Summary
            </v-tooltip>
            <v-icon
              size="small"
              icon="mdi-clipboard-text"
            />
          </v-btn>

          <!-- Dashboard -->
          <v-btn
            icon="true"
            density="comfortable"
            class="ma-2 pa-0"
            variant="outlined"
            color="success"

            :to="{
              name: 'StudentDashboard',
              params: {
                studentId: props.dataItem.studentId}}"
          >
            <v-tooltip activator="parent">
              Dashboard
            </v-tooltip>
            <v-icon
              small
              icon="mdi-view-dashboard"
            />
          </v-btn>

          <!-- Bubble Chart -->
          <v-btn
            icon="true"
            density="comfortable"
            class="ma-2 pa-0"
            variant="outlined"
            color="success"
            :to="{
              name: 'StudentMotionChart',
              params: {
                studentId: props.dataItem.studentId,
                student: props.dataItem }}"
          >
            <v-tooltip activator="parent">
              Bubble Chart
            </v-tooltip>
            <v-icon
              size="small"
              icon="mdi-chart-bubble"
            />
          </v-btn>

          <!-- reset password -->
          <v-btn
            v-show="!isTeacher"
            icon="true"
            density="comfortable"
            class="ma-2 pa-0"
            variant="outlined"
            color="red"
            @click.stop="loadPasswordResetDialog(props.dataItem.email)"
          >
            <v-tooltip activator="parent">
              Reset Password
            </v-tooltip>
            <v-icon
              small
              icon="mdi-lastpass"
            />
          </v-btn>
        </td>
      </template>
    </k-grid>

    <!-- resetPassword -->
    <v-dialog
      v-model="showResetPasswordDialog"
      max-width="50%"
    >
      <v-card>
        <v-card-title>
          Confirmation
        </v-card-title>
        <v-card-text>
          This action will <span class="dialogWarningColor"><b> RESET </b></span> the Student
          password. <br>
          User:
          <b> {{ selectedStudentEmail }} </b>
        </v-card-text>
        <v-card-text>
          <i>The new password will be the default school password for students. If you want to set a specific password,
            please type it here:</i>
        </v-card-text>
        <v-card-text>
          <v-container class="pa-0">
            <v-row no-gutters>
              <v-text-field
                v-model="txtNewPassword"
                :append-inner-icon="studentsDefaultPasswordMask ? 'mdi-eye-off' : 'mdi-eye'"
                :type="studentsDefaultPasswordMask ? 'password' : 'text'"
                label="New password"
                hint="At least 6 characters"
                :rules="globalStore.passwordRules"
                :variant="globalStore.textFieldVariant"
                @click:append-inner="studentsDefaultPasswordMask = !studentsDefaultPasswordMask"
              />
              <v-btn
                icon="true"
                class="align-self-center"
                density="compact"
                variant="plain"
                @click="generatePassword()"
              >
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  Suggest secure password
                </v-tooltip>
                <v-icon
                  small
                  icon="mdi-lastpass"
                  class="align-self-center ml-4"
                />
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            variant="text"
            @click.stop="closePasswordResetDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            variant="elevated"
            @click.stop="resetStudentPassword"
          >
            Reset Password
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>

import {initialize} from '@/utils/effort-tracking-api';
import {resetPassword} from '@/api/accountsApi';
import {getPeriods} from '@/api/periodApi';
import {getSettingByKey} from '@/api/settingsApi';
import {getStudentsCohortByPeriod} from '@/api/studentApi';
import {globalStore} from '@/main';
import {debounce} from 'lodash';
import {generateRandomPassword} from '@/utils/passwordService';
import {Loader as KLoader} from '@progress/kendo-vue-indicators';
import {Grid as KGrid} from '@progress/kendo-vue-grid';
import {UserType, Setting, Defaults} from '@/utils/settings';

export default {
  components: {
    KLoader,
    KGrid,
  },
  data() {
    return {
      isTeacher: false,
      disableEffortGrades: false,
      alertSuccess: false,
      alertError: false,

      showResetPasswordDialog: false,
      selectedStudentEmail: '',

      resultMessage: '',

      defaultPassword: '',
      txtNewPassword: '',
      studentsDefaultPasswordMask: true,

      search: '',
      // Does not work as a child property.
      sort: [{field: 'lastName', dir: 'asc'}],
      loader: false,
      serverFilters: {
        sortBy: 'lastName',
        filter: '',
        take: globalStore.defaultRowsPerPage,
        skip: 0,
        descending: false
      },
      pagerValues: {
        pagerType: globalStore.pagerTypes['numeric'],
        pagerButtons: globalStore.pagerButtons,
        pagerInfo: globalStore.pagerInfo,
        pagerSizes: globalStore.pagerSizes,
        pagePrevNext: globalStore.pagerSizes,
        pageSize: globalStore.defaultRowsPerPage,
        pageSizeValue: globalStore.defaultRowsPerPage,
      },
      gridData: {
        columns: [
          {field: 'lastName', title: 'Last Name'},
          {field: 'firstName', title: 'First Name'},
          {field: 'email', title: 'Email'},
          {title: 'Cohort', field: 'cohort'},
          {field: '', cell: 'myTemplate', sortable: false},
        ],
        items: [],
        totalItems: 0,
      },
    };
  },
  computed: {
    globalStore() {
      return globalStore;
    },
    pageable() {
      return {
        buttonCount: this.pagerValues.pagerButtons,
        info: this.pagerValues.pagerInfo,
        type: this.pagerValues.pagerType,
        pageSizes: this.pagerValues.pagerSizes
                   ? globalStore.allowedRowsPerPage
                   : undefined,
        previousNext: this.pagerValues.pagePrevNext,
        pageSizeValue: this.pagerValues.pageSizeValue,
      };
    },
  },
  watch: {
    search: {
      handler() {
        this.debouncedGetDataFromApi();
      },
    },
  },
  created() {
    this.debouncedGetDataFromApi = debounce(() => {
      this.getDataFromApi();
    }, 500);
  },
  mounted() {
    console.log('On students mounted');

    this.isTeacher = this.$store.state.usertype === UserType.Teacher
      || this.$store.state.usertype === UserType.MiddleManager;
    const periods = JSON.parse(localStorage.getItem('periods'));
    const period = periods.find(p=> p.periodId === globalStore.selectedPeriod);
    if (this.isTeacher && !period.complete ) {
      this.disableEffortGrades = true;
    }
    initialize()
      .then(() => {
        getSettingByKey(Setting.StudentsDefaultPassword)
          .then(setting => {
            this.txtNewPassword = (setting.value !== undefined)
                                  ? setting.value
                                  : Defaults.StudentsDefaultPassword;
            this.defaultPassword = this.txtNewPassword;
          })
          .catch(ex => console.log(ex));

        getPeriods()
          .then(periods => {
            this.$store.state.periods = periods;
          })
          .catch(ex => console.log(ex));
      })
      .catch(ex => console.log(ex));
    this.debouncedGetDataFromApi();
  },
  methods: {
    sortChangeHandler: function(e) {
      if(e.sort.length === 0) {
        this.serverFilters.descending = false;
        this.debouncedGetDataFromApi();
        this.sort = e.sort;
        return;
      }
      this.serverFilters.descending = e.sort[0].dir !== 'asc';
      this.serverFilters.sortBy = e.sort[0].field;
      this.debouncedGetDataFromApi();
      this.sort = e.sort;
    },
    pageChangeHandler: function(event) {
      console.log('event', event);
      this.pagerValues.pageSize = event.page.take;
      this.pagerValues.pageSizeValue = event.page.take;
      this.serverFilters.skip = event.page.skip;
      this.serverFilters.take = event.page.take;
      this.debouncedGetDataFromApi();
    },
    getDataFromApi() {
      this.loader = true;
      this.serverFilters.filter = this.search;
      getStudentsCohortByPeriod(globalStore.selectedPeriod, this.serverFilters)
        .then(data => {
          this.gridData.items = data.items;
          this.gridData.totalItems = data.count;
          this.loader = false;
        });
    },
    closePasswordResetDialog() {
      this.showResetPasswordDialog = false;
      this.selectedStudentEmail = '';
    },
    loadPasswordResetDialog(studentEmail) {
      this.alertSuccess = false;
      this.alertError = false;
      this.showResetPasswordDialog = true;
      this.selectedStudentEmail = studentEmail;
    },
    resetStudentPassword() {
      if(this.txtNewPassword !== '') {
        resetPassword(
          {
            email: this.selectedStudentEmail,
            newPassword: this.txtNewPassword
          })
          .then(
            () => {
              this.showResetPasswordDialog = false;
              this.resultMessage = `Password reset for ${this.selectedStudentEmail}`;
              this.selectedStudentEmail = '';
              this.alertSuccess = true;
              this.txtNewPassword = this.defaultPassword;
              this.studentsDefaultPasswordMask = true;
            })
        .catch(() => {
          this.resultMessage = `Unable to reset password for ${this.selectedStudentEmail}`;
          this.alertError = true;
          this.txtNewPassword = this.defaultPassword;
          this.studentsDefaultPasswordMask = true;
        });
      }
      else {
        this.resultMessage = `Unable to reset password for ${this.selectedStudentEmail}`;
        this.alertError = true;
        this.txtNewPassword = this.defaultPassword;
        this.studentsDefaultPasswordMask = true;
      }
    },
    generatePassword() {
      this.txtNewPassword = generateRandomPassword();
      this.studentsDefaultPasswordMask = false;
    }
  }
};
</script>

<style>
.studentGrid .k-grid-header col:nth-of-type(1) {
  width: 10%;
}

.studentGrid .k-grid-table col:nth-of-type(1) {
  width: 10%;
}

.studentGrid .k-grid-header col:nth-of-type(2) {
  width: 10%;
}

.studentGrid .k-grid-table col:nth-of-type(2) {
  width: 10%;
}

.studentGrid .k-grid-header col:nth-of-type(3) {
  width: 20%;
}

.studentGrid .k-grid-table col:nth-of-type(3) {
  width: 20%;
}

.studentGrid .k-grid-header col:nth-of-type(4) {
  width: 15%;
}

.studentGrid .k-grid-table col:nth-of-type(4) {
  width: 15%;
}

.studentGrid .k-grid-header col:nth-of-type(5) {
  width: 40%;
}

.studentGrid .k-grid-table col:nth-of-type(5) {
  width: 40%;
}
</style>
