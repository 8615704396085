import {createRouter, createWebHashHistory} from 'vue-router';

import PasswordRecovery from '../components/PasswordRecovery';
import HomeComponent from '../components/HomeComponent';
import LoginComponent from '../components/LoginComponent';

import ThankYou from '../components/ThankYou';

import NavigationLayout from '../components/layouts/NavigationLayout';
import MyAccount from '../components/MyAccount';

import StatusComponent from '../components/admin/StatusComponent';
import ClassesComponent from '../components/admin/ClassesComponent';
import StudentsComponent from '../components/admin/StudentsComponent';
import TeachersComponent from '../components/admin/TeachersComponent';
import UserManagement from '../components/admin/UserManagement';
import ReportingPeriods from '../components/admin/ReportingPeriods';
import ImportData from '../components/admin/ImportData';
import ExportData from '../components/admin/ExportData';
import SettingsComponent from '../components/admin/SettingsComponent';
import MiddleManagers from '../components/admin/MiddleManagers';
import MiddleManagerClassSelector from '../components/admin/MiddleManagerClassSelector.vue';


import StudentClasses from '../components/students/StudentClasses';
import StudentDashboard from '../components/students/StudentDashboard';
import StudentMotionChart from '../components/students/StudentMotionChart';
import StudentEfforts from '../components/students/StudentEfforts';
import StudentsGradeSummary from '../components/students/StudentsGradeSummary';

import TeacherClasses from '../components/teachers/TeacherClasses';
import TeacherMotionChart from '../components/teachers/TeacherMotionChart';
import TeachersGradeSummary from '../components/teachers/TeachersGradeSummary';
import TeacherEfforts from '../components/teachers/teacherEffortGrading/TeacherEfforts';

import MiddleManagerTeachers from '../components/middleManagers/MiddleManagerTeachers';
import ClassesMiddleManager from '../components/middleManagers/ClassesMiddleManager';

import AuthCallback from '../components/AuthCallback';
import {initialize} from '@/utils/effort-tracking-api';
import {getLoginSettings} from '@/api/publicApi';
import {store} from '@/store';

const routes = [
  {
    path: '/',
    name: 'NavigationLayout',
    component: NavigationLayout,
    beforeEnter: async(to) => {
      let currentDate = new Date();
      if(store.state.tokenDate
        && currentDate > store.state.tokenDate
        && to.name !== 'Login') {
        return '/users/login';
      }
    },
    children: [
      {
        path: '/home',
        name: 'HomeComponent',
        component: HomeComponent
      },
      {
        path: '/student/:studentId/student-efforts',
        name: 'StudentEfforts',
        component: StudentEfforts,
        meta: {requiresResourceProperty: true}
      },
      {
        path: '/student/:studentId/grade-summary',
        name: 'StudentsGradeSummary',
        component: StudentsGradeSummary,
        meta: {requiresResourceProperty: true}
      },
      {
        path: '/teacher/:teacherId/teacher-efforts',
        name: 'TeacherEfforts',
        component: TeacherEfforts,
        meta: {requiresResourceProperty: true}
      },
      {
        path: '/teacher/:teacherId/grade-summary',
        name: 'TeachersGradeSummary',
        component: TeachersGradeSummary
      },
      {
        path: '/students',
        name: 'StudentsComponent',
        component: StudentsComponent
      },
      {
        path: '/teachers',
        name: 'TeachersComponent',
        component: TeachersComponent
      },
      {
        path: '/status',
        name: 'StatusComponent',
        component: StatusComponent
      },
      {
        path: '/account',
        name: 'MyAccount',
        component: MyAccount
      },
      {
        path: '/settings',
        name: 'SettingsComponent',
        component: SettingsComponent
      },

      {
        path: '/classes',
        name: 'ClassesComponent',
        component: ClassesComponent
      },
      {
        path: '/datamanagement/export',
        name: 'Export Data',
        component: ExportData
      },
      {
        path: '/datamanagement/import',
        name: 'Import Data',
        component: ImportData
      },
      {
        path: '/datamanagement/usermanagement',
        name: 'User Management',
        component: UserManagement
      },
      {
        path: '/thankYou',
        name: 'ThankYou',
        component: ThankYou
      },
      {
        path: '/reporting-periods',
        name: 'ReportingPeriods',
        component: ReportingPeriods
      },
      {
        path: '/studentclasses/:classId/:className/:classCode',
        name: 'StudentClasses',
        component: StudentClasses
      },
      {
        path: '/teacherclasses/:classId/:className/:classCode',
        name: 'TeacherClasses',
        component: TeacherClasses
      },

      {
        path: '/student/:studentId/dashboard',
        name: 'StudentDashboard',
        component: StudentDashboard,
        meta: {requiresResourceProperty: true}
      },
      {
        path: '/student/:studentId/motionchart',
        name: 'StudentMotionChart',
        component: StudentMotionChart,
        meta: {requiresResourceProperty: true}
      },
      {
        path: '/motionchart',
        name: 'TeacherMotionChart',
        component: TeacherMotionChart,
      },
      {
        path: '/middle-managers',
        name: 'MiddleManagers',
        component: MiddleManagers
      },
      {
        path: '/middle-manager-classes/:managerId',
        name: 'MiddleManagerClassSelector',
        component: MiddleManagerClassSelector
      },
      {
        path: '/middle-manager-teachers',
        name: 'MiddleManagerTeachers',
        component: MiddleManagerTeachers

      },
      {
        path: '/classes-middle-manager',
        name: 'ClassesMiddleManager',
        component: ClassesMiddleManager
      }
    ]
  },
  {
    path: '/users/login',
    name: 'Login',
    component: LoginComponent,
    beforeEnter: (to, from, next) => {
      initialize()
        .then(() => {
          getLoginSettings()
            .then(settings => {
              localStorage.setItem('background-image', '');
              localStorage.setItem('additional-logo', '');
              localStorage.setItem('welcome-text', '');
              if(settings !== null && settings.length > 0) {
                const backgroundImage = settings.filter(ss => ss.key === 'background-image-URL');
                if(backgroundImage.length > 0) {
                  localStorage.setItem('background-image', backgroundImage[0].value);
                }

                const additionalLogo = settings.filter(ss => ss.key === 'additional-logo');
                if(additionalLogo.length > 0) {
                  localStorage.setItem('additional-logo', additionalLogo[0].value);
                }

                const welcomeText = settings.filter(ss => ss.key === 'welcome-text');
                if(welcomeText.length > 0) {
                  localStorage.setItem('welcome-text', welcomeText[0].value);
                }

              }
              next();
            })
            .catch(() => {
              next();
            });
        })
        .catch(ex => console.log(ex));
    }
  },
  {
    path: '/SSO',
    name: 'AuthCallback',
    component: AuthCallback,
    beforeEnter: async(to, from, next) => {
      next();
    },
  },
  {
    path: '/users/passwordrecovery',
    name: 'PasswordRecovery',
    component: PasswordRecovery,
    beforeEnter: (to, from, next) => {
      initialize()
        .then(() => {
          getLoginSettings()
            .then(settings => {
              localStorage.setItem('background-image', '');
              localStorage.setItem('additional-logo', '');
              localStorage.setItem('welcome-text', '');
             
              if(settings.length > 0) {
                const backgroundImage = settings.filter(ss => ss.key === 'background-image-URL');
                if(backgroundImage.length > 0) {
                  localStorage.setItem('background-image', backgroundImage[0].value);
                }

                const additionalLogo = settings.filter(ss => ss.key === 'additional-logo');
                if(additionalLogo.length > 0) {
                  localStorage.setItem('additional-logo', additionalLogo[0].value);
                }

                const welcomeText = settings.filter(ss => ss.key === 'welcome-text');
                if(welcomeText.length > 0) {
                  localStorage.setItem('welcome-text', welcomeText[0].value);
                }

              }
              next();
            })
            .catch(() => {
              next();
            });
        })
    }
  },

]

const router = createRouter({
                              history: createWebHashHistory(),
                              routes
                            })

export default router
