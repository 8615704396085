<template>
  <v-app>
    <v-main
      :style="{ background: 'url(' + image + ') top center / cover' }"
      class="d-flex align-center justify-center"
    >
      <v-card
        class="mx-auto flex-1-1-100"
        max-width="600"
      >
        <v-img
          src="/etracking-logo-long-large.gif"
          height="100px"
        />
        <v-img
          v-if="additionalImage"
          :src="additionalImage"
          height="100px"
        />
        <v-card-text style="text-align: center">
          <br>
          <div class="headline pb-3">
            {{ welcomeMessage }}
          </div>
          <div class="grey--text">
            Login below to access effort grades
          </div>
          <br>
          <v-alert
            v-model="loginModel.showLoginErrorAlert"
            type="error"
            variant="tonal"
            closable
          >
            {{ loginModel.errorMessage }}
          </v-alert>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="loginModel.email"
              prepend-inner-icon="mdi-email-outline"
              :rules="globalStore.emailRules"
              label="Email address"
              required
              :disabled="loading"
              :variant="globalStore.textFieldVariant"
              @keyup.enter="hitEnter()"
            />
            <v-text-field
              v-model="loginModel.password"
              :rules="[v => !!v || 'Password is required']"
              :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
              :type="visible ? 'text' : 'password'"
              label="Password"
              required
              :disabled="loading"
              :variant="globalStore.textFieldVariant"
              prepend-inner-icon="mdi-lock-outline"
              @keyup.enter="hitEnter()"
              @click:append-inner="visible = !visible"
            />
          </v-form>
          <v-btn
            block
            color="success"
            size="large"
            :loading="loading"
            variant="flat"
            @click="login"
          >
            Login
          </v-btn>
          <br>
          <v-btn
            color="success"
            variant="text"
            size="small"
            @click.stop="showResetPasswordDialog()"
          >
            Forgot Password
          </v-btn>
        </v-card-text>
        <v-card-text class="text-center">
          <v-btn
            class="text-green text-decoration-none"
            rel="noopener noreferrer"
            variant="text"
            color="success"
            @click="ssoLogin()"
          >
            StudentNet Login (SSO)
            <v-icon icon="mdi-chevron-right" />
          </v-btn>
        </v-card-text>
      </v-card>

      <!-- forgotPasswordDialog -->
      <v-dialog
        v-model="forgotPasswordModel.showDialog"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Forgot password
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="forgotPasswordModel.email"
              label="Email"
              required
              :variant="globalStore.textFieldVariant"
              :rules="globalStore.emailRules"
            />
            <v-alert
              v-model="forgotPasswordModel.showSuccessAlert"
              variant="tonal"
              type="success"
              closable
            >
              {{ forgotPasswordModel.alertMessage }}
            </v-alert>

            <v-alert
              v-model="forgotPasswordModel.showErrorAlert"
              variant="tonal"
              type="error"
              closable
            >
              {{ forgotPasswordModel.alertMessage }}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="error"
              variant="text"
              @click.stop="forgotPasswordModel.showDialog=false;forgotPasswordModel.email=''"
            >
              Close
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="!forgotPasswordModel.showSuccessAlert"
              variant="elevated"
              color="success"
              @click="resetPassword()"
            >
              Send reset instructions
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>

import {initialize} from '@/utils/effort-tracking-api';
import userManager from '../utils/authService';
import {globalStore} from '@/main';
import {UserType} from '@/utils/settings';
import {sendPasswordResetEmail} from '@/api/accountsApi';
import {userLogin} from '@/api/authApi';
import {getBackgroundImage, getLogoImage} from '@/api/publicApi'
import {getOrderedPeriods} from '@/api/periodApi'

export default {
  data: () => ({
    loading: false,
    valid: true,
    loginModel: {
      email: '',
      password: '',
      errorMessage: '',
      showLoginErrorAlert: false,
    },
    forgotPasswordModel: {
      showDialog: false,
      showErrorAlert: false,
      showSuccessAlert: false,
      alertMessage: '',
      email: '',
    },
    welcomeMessage: '',
    image: '',
    additionalImage: '',
    visible: false
  }),
  computed: {
    globalStore() {
      return globalStore;
    }
  },
  created() {
    localStorage.removeItem('period-selected');
    this.welcomeMessage = 'Welcome to EffortTracking';
    this.additionalImage = localStorage.getItem('additional-image');
    this.image = localStorage.getItem('background-image');
    if (this.image.length > 0) {
      this.image = `/public${this.image}`
    }
    else {
      getBackgroundImage()
      .then(result => {
        this.image = result === null ? '' : `/public${result}`;
      })
      .catch(
          ex => console.log(ex)
      );
    }
    if (this.image.additionalImage > 0) {
      this.additionalImage = `/public${this.additionalImage}`
    }
    else {

      getLogoImage()
      .then(result => {
        this.additionalImage = result === null ? '' : `/public${result}`;
      })
      .catch(
          ex => console.log(ex)
      );
    }
    const welcomeText = localStorage.getItem('welcome-text');
    if (welcomeText != null && welcomeText.length > 0) {
      this.welcomeMessage = welcomeText;
    }

    initialize()
    .then(() => {
      this.$store.state.classStudents = [];
      this.$store.state.isLoggedIn = false;
      this.$store.state.periods = [];
      this.$store.state.teacherClasses = [];
      this.$store.state.token = '';
      this.$store.state.userdata = null;
      this.$store.state.usertype = '';
      this.clear();
    })
    .catch(
        ex => console.log(ex)
    );
  },
  methods: {
    async ssoLogin() {
      try {
        await userManager.signinRedirect();
      } catch (error) {
        console.error('Error during login:', error);
      }
    },
    login() {
      this.loading = true;
      this.loginModel.showLoginErrorAlert = false;
      userLogin({
        user: this.loginModel.email,
        password: this.loginModel.password
      })
      .then(result => {
        if (result.status === 200) {
          const data = result.data.data;
          const usertype = data.userType;
          let destination = '';
          this.$store.commit('setUserData', data);

            getOrderedPeriods(this.$store.state.usertype, this.$store.state.userdata.id)
            .then(periods => {
              localStorage.setItem('period-selected', '' + periods[0].value);
              localStorage.setItem('periods', JSON.stringify(periods));
              switch (usertype) {
                case UserType.Teacher:
                case UserType.MiddleManager:
                  destination = `#/teacher/` + data.id + '/teacher-efforts';
                  break;
                case UserType.Student:
                  destination = `#/student/` + data.id + '/student-efforts';
                  break;
                case UserType.Admin:
                case UserType.SuperAdmin:
                  destination = `#/home`;
                  break;
              }

              this.$store.state.isLoggedIn = true;
              window.location.href = destination;
            });
          }
        else
          if (result.status === 500) {
            this.loginModel.showLoginErrorAlert = true;
            this.loginModel.errorMessage = `Error logging in, please try again ${result.response.status}`;
            this.loginModel.password = '';
          }
          else {
            this.loginModel.showLoginErrorAlert = true;
            this.loginModel.errorMessage = result.response.data.message;
            this.loginModel.password = '';
          }
      })
      .catch(ex => {
        console.log('ex', ex);
        this.loginModel.showLoginErrorAlert = true;
        this.loginModel.errorMessage = `Error logging in, please try again`;
        this.loginModel.password = '';
      });
      this.loading = false;
    },
    usertype() {
      return this.user;
    },
    userdata() {
      return this.$store.state.userdata;
    },
    clear() {
      this.$refs.form.reset();
    },
    showResetPasswordDialog() {
      this.loginModel.showLoginErrorAlert = false;
      this.forgotPasswordModel.alertMessage = '';
      this.forgotPasswordModel.showErrorAlert = false;
      this.forgotPasswordModel.showSuccessAlert = false;
      this.forgotPasswordModel.showDialog = true;
    },
    resetPassword() {
      const jsEmail = {
        email: this.forgotPasswordModel.email,
        host: window.location.protocol + '//' + window.location.hostname
      };
      sendPasswordResetEmail(JSON.stringify(jsEmail))
      .then(result => {
        if (result.status === 200) {
          this.forgotPasswordModel.showSuccessAlert = true;
          this.forgotPasswordModel.alertMessage = 'If your email is valid you will receive ' +
              'a message with instructions to reset your password.';
        }
        else {
          this.forgotPasswordModel.showErrorAlert = true;
          this.forgotPasswordModel.alertMessage = `Something went wrong, ${result.message}`;
        }
      })
      .catch(() => {
        this.forgotPasswordModel.showErrorAlert = true;
        this.forgotPasswordModel.alertMessage = 'Something went wrong, please try again.';
      });
    },
    hitEnter() {
      if (this.valid) {
        this.login();
      }
    }
  }
};
</script>
