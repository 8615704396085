// import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist'
// Vue.use(Vuex);

const vuexPersist = new VuexPersist({
    key: 'ef-session',
    storage: localStorage
});

export const store = new Vuex.Store({
    plugins: [vuexPersist.plugin],
    state: {
        baseUrl: '',
        usertype: '',
        userdata: null,
        token: '',
        tokenDate: new Date(1970,1,1),
        periods: [],
        teacherClasses: [],
        classStudents: [],
        test: 'hello',
        settingsHasChanges: false
    },
    mutations: {
        setUserData(state, data) {
            state.token = data.auth_token
            state.usertype = data.userType
            state.userdata = data.userData
            state.isLoggedIn = true
            state.expiresIn = data.expires_in
            state.tokenDate = new Date().setSeconds(data.expires_in)
        },
        setSsoUserData(state, user) {
            let userData =
            {
                "firstName": user.profile.given_name,
                "lastName": user.profile.family_name,
                "email": user.profile.email,
                "id": ""
            };

            state.token = user.id_token
            state.usertype = "admin"
            state.userdata = userData
            state.isLoggedIn = true


        },
        removeUserData(state) {
            state.periods = [];
            state.token = ''
            state.usertype = ''
            state.userdata = null
        },
        hasChanged(state) {
            state.settingsHasChanges =true;
        }
    }
});
