import {
  BASE_URL,
  addQuery,
  deleteData,
  putData,
  getDataFromUrl,
  addQueryToUrl,
  postData
} from '@/utils/effort-tracking-api';

import {Routes} from '@/utils/settings';

export function getMissingEffortsCountData(model) {
  const url = `${BASE_URL}${Routes.TeachersRoute}/teacher/efforts/missing/count`;
  return postData(url, model);
}

export function getEffortsData(model) {
  const url = `${BASE_URL}${Routes.TeachersRoute}/efforts`;
  return postData(url, model);
}

export function saveEffortsData(model) {
  const url = `${BASE_URL}${Routes.TeachersRoute}/efforts`;
  return putData(url, model);
}

export function getTeacher(teacherId) {
  const url = `${BASE_URL}${Routes.TeachersRoute}/teacher/${teacherId}`;
  return getDataFromUrl(url);
}

export function searchTeachersData(serverFilters) {
  const url = `${BASE_URL}${Routes.TeachersRoute}/search`;
  return postData(url, serverFilters);
}

export function periodHasEffortsData(periodId, teacherId) {
  let url = `${BASE_URL}${Routes.TeachersRoute}/teacher/efforts/count`;
  return postData(url, {
    periodId: Number(periodId),
    teacherId: Number(teacherId)
  });
}

export function countTeachersInPeriod(periodId) {
  let url = `${BASE_URL}${Routes.TeachersRoute}/period/${periodId}/count`;
  return getDataFromUrl(url);
}

export function getTeachersByPeriod(periodId, serverFilters) {
  let url = `${BASE_URL}${Routes.TeachersRoute}/period/${periodId}`;
  url = addQueryToUrl(url, serverFilters);
  return getDataFromUrl(url);
}

export function getTeachersInClassByPeriod(periodId, classId, serverFilters) {
  let url = `${BASE_URL}${Routes.TeachersRoute}/class/${classId}/period/${periodId}`;
  url = addQueryToUrl(url, serverFilters);
  return getDataFromUrl(url);
}

export function getTeacherByRole(role, excludeRole = false, serverFilters) {
  let url = `${BASE_URL}${Routes.TeachersRoute}/role/${role}`;
  if(serverFilters !== undefined) {
    url = addQueryToUrl(url, serverFilters);
  }
  if(excludeRole) {
    url = addQuery(excludeRole, url, 'exclude', excludeRole);
  }
  return getDataFromUrl(url);
}

export function getTeacherEffortsByPeriod(periodId, teacherId, serverFilters) {
  let url = `${BASE_URL}${Routes.TeachersRoute}/efforts/period/${periodId}/teacher/${teacherId}`;

  url = addQueryToUrl(url, serverFilters);
  return getDataFromUrl(url);
}

export function addTeacherToClassData(model) {
  const url = `${BASE_URL}${Routes.TeachersRoute}/class/add`;
  return postData(url, model);
}

export function removeTeacherFromClass(periodID, classId, teacherId) {
  const url = `${BASE_URL}${Routes.TeachersRoute}/${teacherId}/class/${classId}/period/${periodID}`;
  return deleteData(url);
}

export function getCompleteClasses(periodId, teacherId) {
  const url = `${BASE_URL}${Routes.TeachersRoute}/period/${periodId}/teacher/${teacherId}/class/complete`;
  return getDataFromUrl(url);
}

export function getTeacherEfforts(periodId, teacherId, classId) {
  const url = `${BASE_URL}${Routes.TeachersRoute}/${teacherId}/class/${classId}/period/${periodId}`;
  return getDataFromUrl(url);
}

export function getClassesForMiddleManagerData(model) {
  const url = `${BASE_URL}${Routes.TeachersRoute}/classes/complete/middle-manager`;
  return postData(url, model);
}

export function changePrimaryTeacherData(model){
  const url = `${BASE_URL}${Routes.TeachersRoute}/class/primary-teacher`;
  return postData(url, model);
}
