<template style="height: 100%">
  <v-container class="w100 mw-100 ma-0 pa-0">
    <v-card
      style="height: 100%"
      class="pa-0 ma-0 mw-100 w100"
    >
      <v-toolbar>
        <v-toolbar-title>Bubble Chart: {{ studentName }}</v-toolbar-title>
        <v-autocomplete
          v-model="selectedStudent"
          :items="autoCompleteItems"
          item-value="studentId"
          item-title="studentName"
          variant="underlined"
        >
          <!--        >-->
          <!--          @open="onOpen"-->
          <!--          @close="onClose"-->
          <!--          <template #list-footer>-->
          <!--            <li-->
          <!--              v-show="hasNextPage"-->
          <!--              ref="load"-->
          <!--              class="loader"-->
          <!--            >-->
          <!--              Loading more options...-->
          <!--            </li>-->
          <!--          </template>-->
        </v-autocomplete>
      </v-toolbar>
      <v-alert
        v-model="noDataNotification"
        icon="warning"
        variant="tonal"
      >
        There is no data to display
      </v-alert>
      <v-sheet
        id="teacherMotionChart"
        style="width: 95%;"
        :height="getHeight()"
      />
    </v-card>
  </v-container>
</template>

<script>

import {initialize} from '@/utils/effort-tracking-api';
import {getGradingTabSettings} from '@/api/settingsApi';
import { getTeacherMotionChartForStudent, getTeacherMotionChart} from '@/api/motionChartApi'
import * as echarts from '../../../node_modules/echarts/dist/echarts.min';


export default {
  name: 'TeacherMotionchart',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.meta.requiresResourceProperty && vm.$store.state.usertype === 'teacher') {
        if (parseInt(vm.$store.state.userdata.id) !== parseInt(vm.$route.params.teacherId)) {
          vm.$route.params.teacherId = vm.$store.state.userdata.id;
          window.location.href = '#/';
        }
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    const myChart = echarts.getInstanceByDom(document.getElementById('teacherMotionChart'));
    myChart.dispose();
    next();
  },
  data() {
    return {
      periods: [],
      reportingPeriodId: localStorage.getItem('period-selected'),
      noDataNotification: false,
      studentName: '',
      students: [],
      ChartData: null,
      selectedStudent: null,
      selectedIndex: null,
      observer: null,
      limit: 10,
      search: '',
      currentTimeLine: 0,
      allStudentsOptions: null,
      selectedFromChart: false,
      comesFromTimeLine: false,
      autocompleteSearch: null,
      autoCompleteItems: []
    };
  },
  computed: {
    filtered() {
      if (this.students.length !== 0) {
        return this.students.filter((student) => student.studentName.includes(this.search));
      }
      else {
        return this.students;
      }
    },
    paginated() {
      return this.filtered.slice(0, this.limit);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
  },
  watch: {
    selectedStudent(val) {

      const myChart = echarts.getInstanceByDom(document.getElementById('teacherMotionChart'));

      const previousSelectedIndex = this.selectedIndex;

      this.selectedIndex = this.ChartData.periods[this.currentTimeLine]
        .series.findIndex(item => item[2] === val);

      if (this.selectedIndex !== -1 && !this.selectedFromChart) {
        this.comesFromTimeLine = false;

        myChart.dispatchAction({
          type: 'select',
          seriesIndex: 0,
          dataIndex: this.selectedIndex,
        });

        this.selectedFromChart = false;
      }
      else {
        if (previousSelectedIndex !== -1 && !this.selectedFromChart) {
          myChart.dispatchAction({
            type: 'unselect',
            seriesIndex: 0,
            dataIndex: previousSelectedIndex,
          });
        }
        this.selectedFromChart = false;
      }
    },
    autocompleteSearch(val) {
      val && val !== this.selectedStudent && this.querySelections(val);
    }
  },
  mounted() {
    console.log('on teacher motion chart mounted...');
    this.periods = this.$store.state.periods;
    initialize()
      .then(() => {
        // Initialize the echarts instance based on the prepared dom
        const myChart = echarts.init(document.getElementById('teacherMotionChart'));
        myChart.showLoading();
        getTeacherMotionChart()
          .then(data => {
            this.autoCompleteItems = data.students;
            this.ChartData = data;
            if (data.periods.length > 0) {
              getGradingTabSettings()
                .then(tabs => {
                  const diligence = tabs.filter(tb => tb.name === 'DILIGENCE')[0];
                  const diligenceLabel = (diligence.customName !== '')
                    ? diligence.customName
                    : diligence.name;

                  const engagement = tabs.filter(tb => tb.name === 'ENGAGEMENT')[0];
                  const engagementLabel = (engagement.customName !== '')
                    ? engagement.customName
                    : engagement.name;

                  const behaviour = tabs.filter(tb => tb.name === 'BEHAVIOUR')[0];
                  const behaviourLabel = (behaviour.customName !== '')
                    ? behaviour.customName
                    : behaviour.name;

                  // Specify the configuration items and data for the chart
                  const categoryTimeLine = [];
                  const studentSeries = [];

                  this.students = data.students;

                  data.periods
                    .forEach(
                      function (series) {
                        categoryTimeLine.push(series.reportingName);

                        const legends = [];
                        series.series.forEach(function (studentSeries) {
                          legends.push(studentSeries[3]);
                        });
                        const studentSerie = {
                          legend: {
                            right: 10,
                            data: legends
                          },
                          series: [
                            {
                              name: 'Students',
                              type: 'scatter',
                              data: series.series,
                              symbolSize: 10,
                              color: 'lightgrey',
                              tooltip: {
                                show: true, formatter: function (data) {
                                  return data.data[3] + ':<br /><br />' +
                                    '<b>Teacher rated effort: </b>' + data.data[0] + '<br />' +
                                    '<b>Student rated effort: </b>' + data.data[4] + '<br />' +
                                    '<b>' + diligenceLabel + ': </b>' + data.data[5] + '<br />' +
                                    '<b>' + engagementLabel + ': </b>' + data.data[7] + '<br />' +
                                    '<b>' + behaviourLabel + ': </b>' + data.data[6];
                                }
                              },
                              selectedMode: 'single',
                              select: {
                                itemStyle: {
                                  borderColor: 'rgba(0, 115, 255, 1)',
                                  color: 'rgba(0, 115, 255, 1)'
                                }
                              }
                            }
                          ],
                          animationDuration: 500,
                          animationEasing: 'linear',
                          animationDurationUpdate: 1000,
                          animationEasingUpdate: 'linear'
                        };

                        studentSeries.push(studentSerie);
                      }
                    );
                  const option = {
                    baseOption: {
                      legend: {},
                      timeline: {
                        axisType: 'category',
                        autoPlay: false,
                        loop: false,
                        data: categoryTimeLine,
                        checkpointStyle: {
                          animation: true,
                          animationDuration: 1500,
                          animationEasing: 'linear'
                        }
                      },
                      tooltip: {
                        trigger: 'item'
                      },
                      xAxis: {
                        name: 'Effort Score',
                        type: 'value',
                        min: 30,
                        max: 100,
                      },
                      yAxis: {
                        name: 'Academic Score',
                        type: 'value',
                        min: 30,
                        max: 100,
                      },
                      series: [
                        {
                          name: 'Students',
                          type: 'scatter',
                          data: [],
                          symbolSize: 10,
                          color: 'lightgrey',
                          selectedMode: 'single',
                          select: {
                            itemStyle: {
                              borderColor: 'rgba(0, 115, 255, 1)',
                              color: 'rgba(0, 115, 255, 1)'
                            }
                          }
                        }
                        , {
                          name: 'Student Effort',
                          type: 'line',
                          data: [],
                          lineStyle: { cap: 'butt', type: 'dashed' },
                          markPoint: { data: [], symbol: 'pin' }
                        }
                        , {
                          name: 'Student',
                          type: 'effectScatter',
                          data: [],
                          tooltip: { show: false },
                          symbolSize: 20,
                          symbol: 'circle'
                        }
                      ],
                      animationDuration: 1000,
                      animationEasing: 'linear',
                      animationDurationUpdate: 1500,
                      animationEasingUpdate: 'linear'
                    },

                    options: studentSeries
                  };

                  this.allStudentsOptions = studentSeries;

                  myChart.setOption(option);

                  myChart.hideLoading();

                  //To access inside of methods with different scope
                  const vm = this;

                  myChart.on('selectchanged', function (serie) {
                    if (serie.fromAction === 'select' && !vm.comesFromTimeLine) {
                      vm.comesFromTimeLine = false;

                      if (serie.fromActionPayload.seriesIndex === 0) {
                        vm.selectedFromChart = true;

                        if (serie.selected.length > 0) {
                          vm.selectedStudent
                            = vm.ChartData.periods[vm.currentTimeLine].series[serie.selected[0].dataIndex][2];
                        }
                        else {
                          vm.selectedStudent
                            = vm.ChartData.periods[vm.currentTimeLine].series[serie.fromActionPayload.dataIndex][2];
                        }

                        getTeacherMotionChartForStudent(vm.selectedStudent)
                          .then(data => {

                            const lineSeries = [];
                            const newSeries = Array.from(vm.allStudentsOptions);

                            data.forEach(function (serie) {
                              // Fill Series for selected student
                              let tmpSeries = [];
                              lineSeries.push([serie.effortScore, serie.academicScore]);
                              tmpSeries = tmpSeries.concat(lineSeries);

                              const studentMarker = { coord: [serie.effortScore, serie.academicScore] };

                              // Specify the configuration items and data for the chart
                              const MarkerTooltip = {
                                formatter: serie.studentName + ':<br /><br />' +
                                  '<b>Teacher rated effort: </b>' + serie.effortScore + '<br />' +
                                  '<b>Student rated effort: </b>' + serie.studentEffortScore + '<br />' +
                                  '<b>' + diligenceLabel + ': </b>' + serie.teacherDiligenceScore + '<br />' +
                                  '<b>' + engagementLabel + ': </b>' + serie.teacherEngagementScore + '<br />' +
                                  '<b>' + behaviourLabel + ': </b>' + serie.teacherBehaviourScore
                              };

                              newSeries[data.indexOf(serie)].series = newSeries[data.indexOf(serie)].series.slice(0, 1);
                              newSeries[data.indexOf(serie)].series = newSeries[data.indexOf(serie)].series.concat({
                                name: 'Student Effort',
                                type: 'line',
                                data: tmpSeries,
                                lineStyle: {
                                  cap: 'butt',
                                  type: 'dashed'
                                },
                                markPoint: {
                                  data: [studentMarker],
                                  symbol: 'pin',
                                  tooltip: MarkerTooltip
                                },
                                tooltip: { show: false }
                              }
                                , {
                                  name: 'Student',
                                  type: 'effectScatter',
                                  data: tmpSeries,
                                  tooltip: { show: false },
                                  symbolSize: 20,
                                  symbol: 'circle'
                                });
                            }
                            );

                            const option = { options: newSeries };
                            myChart.setOption(option);


                          })
                          .catch(ex => console.log(ex));
                      }
                      else {
                        if (vm.selectedIndex != null) {
                          vm.comesFromTimeLine = true;

                          myChart.dispatchAction({
                            type: 'select',
                            seriesIndex: 0,
                            dataIndex: vm.selectedIndex,
                          });
                        }
                        else if (serie.selected.length > 0) {
                          myChart.dispatchAction({
                            type: 'unselect',
                            seriesIndex: 0,
                            dataIndex: serie.selected[0].dataIndex
                          });
                        }
                      }
                    }
                    else if (serie.fromAction === 'unselect') {
                      vm.selectedIndex = null;
                      vm.selectedStudent = null;
                    }

                    vm.comesFromTimeLine = false;
                  });

                  myChart.on('timelinechanged', function (value) {
                    vm.currentTimeLine = value.currentIndex;
                  });

                  this.observer = new IntersectionObserver(this.infiniteScroll);

                })
                .catch(ex => console.log(ex));
            }
            else {
              this.noDataNotification = true;
              myChart.hideLoading();
            }
          })
          .catch(ex => console.log(ex));
      });
  },
  beforeMount() {
    window.addEventListener('resize', function () {
      const myChart = echarts.getInstanceByDom(document.getElementById('teacherMotionChart'));
      myChart.resize();
    });
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    getHeight() {
      return window.innerHeight - 200;
    },
    querySelections(v) {
      this.loading = true;
      setTimeout(() => {
        this.autoCompleteItems = this.students.filter(e => {
          return (e.studentName || '')
            .toLowerCase()
            .indexOf((v || '').toLowerCase()) > -1;
        });

        this.loading = false;
      }, 500);
    }
  }
};
</script>
<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
