<template>
  <v-list
    :model="selectedClassId"
    :value="selectedClassId"
  >
    <v-list-subheader inset>
      Classes
    </v-list-subheader>
    <v-list-item
      v-for="classItem in classes"
      :key="classItem.Id"

      :class="{ 'border-thin':true,'complete': classItem.isComplete, 'incomplete': !classItem.isComplete }"
      :model="selectedClassId === classItem.id"
      @click="classSelected(classItem.id, classItem)"
    >
      <template #append>
        <v-icon
          v-if="selectedClassId === classItem.id"
          icon="mdi-check"
        />
      </template>
      {{ classItem.name }}
    </v-list-item>
  </v-list>
</template>

<script>
import {toRaw} from 'vue';
import {getClassesForMiddleManagerData, getCompleteClasses} from '@/api/teachersApi';
import {globalStore} from '@/main';

export default {
  name: 'ClassList',
  props: {
    teacherId: {
      type: Number,
      required: true
    }
  },
  emits: ['teacherClassSelected'],
  data() {
    return {
      classes: [],
      selectedClassId: '0',
      middleManagerId: 0,
      loading: false
    };
  },
  watch: {
    teacherId: function() {
      this.update();
    }
  },
  mounted() {
    console.log('on teacher classes list mounted...');

    const managerId = this.$route.params.middleManagerId;
    if(managerId !== undefined) {
      localStorage.setItem('middlemanagerid', managerId);
    }

    this.update();
  },
  methods: {
    classSelected(classId, selectedPeriodClassID) {

      this.selectedClassId = classId;
      console.log('this.selectedClassId', this.selectedClassId);
      console.log('selectedPeriodClassID', selectedPeriodClassID);
      this.$emit('teacherClassSelected', classId, toRaw(selectedPeriodClassID));
    },
    update() {
      let managerId = this.$route.params.middleManagerId;
      if(managerId === undefined) {
        managerId = localStorage.getItem('middlemanagerid');
      }

      // if(managerId !== 'undefined' && managerId !== null && managerId !== this.teacherId) {
      //   const middleManagerId = Number(managerId);
      //   const model = {
      //     middleManagerId: middleManagerId,
      //     teacherId: this.$route.params.teacherId,
      //     periodId: globalStore.selectedPeriod};
      //   getClassesForMiddleManagerData(model)
      //     .then(request => {
      //       const classes = request.data;
      //       this.$store.state.teacherClasses = classes;
      //       this.classes = classes;
      //     });
      // }
      // else {
        getCompleteClasses(globalStore.selectedPeriod, this.$route.params.teacherId)
          .then((classes) => {
            this.$store.state.teacherClasses = classes;
            this.classes = classes;
          });
      //}
    },
    refresh(data, classId) {
      console.log('refresh')
      let _class = this.$store.state.teacherClasses.filter(tc => tc.id === classId)[0];
      const incompleteGrades = this.$store.state.classStudents.filter(cs => cs.incompleteGrades !== 0);
      _class.isComplete = (incompleteGrades.length === 0);
    }
  },
};
</script>

<style scoped>
.complete > .list__tile {
  max-height: 2em !important;
}

.incomplete > .list__tile {
  max-height: 2em !important;
}

.complete {
  background: #DDF2BB;
  max-height: 2em !important;
  overflow: hidden;

}

.incomplete {
  background: #F39797;
  max-height: 2em !important;
  overflow: hidden;
}

.v-list__tile__title {
  height: 2.5em;
}

.v-list__tile__title a {
  max-height: 2.5em;
}

.list > li.complete > a {
  background: plum;
}

.incomplete > .v-list__tile.v-list__tile--link.v-list__tile--active.primary--text > .v-list__tile__content {
  color: black !important;
  font-weight: bolder !important;
}

.complete > .v-list__tile.v-list__tile--link.v-list__tile--active.primary--text > .v-list__tile__content {
  color: black !important;
  font-weight: bolder !important;
}

</style>
