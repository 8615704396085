<template>
  <v-card
    v-if="hasEfforts"
    width="100%"
  >
    <v-alert
      v-model="noEfforts"
      icon="mdi-alert"
      variant="tonal"
      type="error"
    >
      No efforts for the selected period.
    </v-alert>
    <v-toolbar dense>
      <v-toolbar-title>
        {{ teacher.firstName }} {{ teacher.lastName }} - Effort Grades
      </v-toolbar-title>
      <v-spacer />

      <span
        v-if="isTeacher"
        class="mr-4"
      >
        <b>{{ progressMsg }}</b>
      </span>

      <v-progress-circular
        :model-value="progress"
        :size="40"
        :width="7"
        :rotate="360"
        class="mr-4"
        :color="progressColor"
      >
        {{ toComplete }}
      </v-progress-circular>

      <grades-downloader
        :class-id="selectedClassId"
        :is-teacher="true"
        :is-student="false"
      />
    </v-toolbar>
    <v-card-text class="pa-0">
      <v-container class="pa-0 ma-0 mw-100">
        <v-row no-gutters>
          <v-col cols="2">
            <class-list
              ref="teacherClasses"
              :teacher-id="Number($route.params.teacherId)"
              :middle-manager-id="Number($route.params.middleManagerId)"
              :model="selectedClassId"
              :value="selectedClassId"
              @teacher-class-selected="onTeacherClassSelected"
            />
          </v-col>
          <v-col cols="2">
            <student-list
              ref="studentList"
              :class-id="Number(selectedClassId)"
              :teacher-id="Number($route.params.teacherId)"
              :model="selectedStudentId"
              :value="selectedStudentId"
              @abc="onStudentSelected"
            />
          </v-col>
          <v-col cols="8">
            <grading-tabs
              ref="_gradingTabs"
              v-model="efforts"
              :loading="loading"
              :value="efforts"
              :class-id="selectedClassId"
              :is-student="false"
              :settings="settings"
              :readonly="readonly"
              @grade-changed="onGradeChanged"
              @save-failed="saveFailed"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>

import {initialize} from '@/utils/effort-tracking-api';
import {getPeriod} from '@/api/periodApi';
import {getEffortsData, getMissingEffortsCountData, getTeacher, periodHasEffortsData} from '@/api/teachersApi';
import {getGradingButtonSettings} from '@/api/settingsApi';
import ClassList from './ClassList';
import StudentList from './StudentList';
import GradingTabs from '@/components/shared/grading/GradingTabs';
import GradesDownloader from '@/components/shared/grading/GradesDownloader';
import {store} from '@/store';
import {Emit, UserType} from '@/utils/settings';
import {globalStore} from '@/main';

export default {
  name: 'TeacherEfforts',
  components: {
    ClassList, StudentList, GradingTabs, GradesDownloader
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const isFromMiddleManagersTab = from.name === 'MiddleManagerTeachers';
      if (to.meta.requiresResourceProperty && !isFromMiddleManagersTab && vm.$store.state.usertype === 'teacher') {
        if (parseInt(vm.$store.state.userdata.id) !== parseInt(vm.$route.params.teacherId)) {
          vm.$route.params.teacherId = vm.$store.state.userdata.id;
          window.location.href = '#/';
        }
      }
    });
  },
  beforeRouteLeave: (next) => {
    const saving = localStorage.getItem('saving');
    if (saving != null) {
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!');
      if (answer) {
        localStorage.removeItem('saving');
        next();
      }
      else {
        next(false);
      }
    }
    else {
      localStorage.removeItem('saving');

    }
  },
  beforeRouteUpdate(next) {
    console.log('next', next)
    this.load(this.$store.state.userdata.id);
    this.$refs.studentList.reset();
    this.$refs._gradingTabs.reset();
    next();
  },
  emits: [Emit.ShowPeriodNotification],
  data() {
    return {
      efforts: {},
      selectedPeriod: localStorage.getItem('period-selected'),
      selectedClassId: 0,
      selectedStudentId: 0,
      teacher: [],
      progress: 0,
      progressMsg: 'REPORTS TO COMPLETE',
      progressColor: 'green',
      toComplete: 0,
      isTeacher: true,
      hasEfforts: false,
      noEfforts: false,
      readonly: false,
      settings: [],
      teacherId: this.$route.params.teacherId,
      loading: false,
    };
  },
  computed: {
    globalStore() {
      return globalStore
    }
  },
  watch: {
    selectedStudentId: function () {
      console.log('On watch selected student ID: ', this.selectedStudentId);
    },
    selectedClassId: function () {
      console.log('On watch selected class ID: ', this.selectedClassId);


      this.update();

    },
    selectedPeriod: function () {
    },
    hasEfforts() {
      console.log('On watch has efforts: ');
      this.noEfforts = !this.hasEfforts;
    }
  },
  mounted() {
    this.loading = true;

    console.log('On teacher efforts mounted...');

    initialize()
    .then(() => {
      console.log('initialize...')
      this.load(this.teacherId);
      this.updateProgress();
      this.isTeacher = this.$store.state.usertype === UserType.Teacher
          || this.$store.state.usertype === UserType.MiddleManager
          || this.$store.state.usertype === UserType.Admin;
      let userid = this.teacherId;
      let periodId = localStorage.getItem('period-selected');
      getPeriod(globalStore.selectedPeriod)
      .then(result => {
        periodId = result.periodId;
        this.readonly = !result.active || result.complete;
        if (result.visible || this.$store.state.usertype === UserType.Admin) {
          getGradingButtonSettings()
          .then(sett => {
            this.settings = sett;
            periodHasEffortsData(periodId, userid)
            .then(count => {
              this.$emit(Emit.ShowPeriodNotification);
              if (count.data > 0) {
                this.hasEfforts = true;
                this.noEfforts = false;
              }
              else {
                this.hasEfforts = false;
                this.noEfforts = true;
              }
            })
            .catch(ex => {
              console.log(ex);
            });
          })
          .catch(ex => console.log(ex));
        }
      })
      .catch(ex => console.log(ex));
    });

    this.loading = false;
  },
  methods: {
    update() {
      console.log('on teacher efforts update...');
      const userid = store.state.userdata.id;
      const usertype = this.$store.state.usertype;
      const paramsId = this.teacherId;
      if (Number(userid) !== paramsId && usertype !== UserType.Admin && usertype !== UserType.MiddleManager) {
        window.location.href = '#/users/login';
      }

      console.log('Before initialize: ');
      initialize()
      .then(() => {
        const model = {
          periodId: globalStore.selectedPeriod,
          classId: this.selectedClassId,
          teacherId: this.teacherId
        };
        getEffortsData(model)
        .then((efforts) => {
          this.efforts = efforts.data;
        });
      })
      .catch(ex => console.log(ex));
    },
    onGradeChanged: function (data, classId) {
      const student = this.$store.state.classStudents.filter(st => st.id === data.studentId)[0];
      const grades = student.grades;
      for (let i in grades) {
        if (grades[i].effortId === data.effortId) {
          grades[i].grade = data.grade;
          break;
        }
      }

      student.grades = grades;
      this.$refs.studentList.refresh(data, classId);
      this.$refs.teacherClasses.refresh(data, classId);
      this.update();
      this.updateProgress();
      localStorage.removeItem('saving');
    },
    saveFailed() {
      localStorage.setItem('saving', true);
    },
    load(teacherId) {
      this.teacherId = Number(this.teacherId);
      getTeacher(teacherId)
      .then((teacher) => {
        this.teacher = teacher;
      });
    },
    updateProgress() {
      {
        if (this.selectedPeriod < 1) {
          this.selectedPeriod = globalStore.selectedPeriod;
        }
        const model = {
          periodId: Number(this.selectedPeriod),
          teacherId: Number(this.teacherId)
        };
        getMissingEffortsCountData(model)
        .then(result => {
          this.progress = 100 - (result.data.missing * 100 / result.data.all);
          this.toComplete = result.data.missing;
        })
        .catch(ex => console.log(ex));
      }
    },
    onTeacherClassSelected: function (classId) {
      this.loading = true;
      console.log('on teacher class selected from child component...');
      this.selectedClassId = classId;
    },
    onStudentSelected: function (studentId) {
      console.log('on studnet selected from child component...', studentId);
    }
  },
};
</script>
