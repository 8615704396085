<template>
  <div class="counters">
    <v-chip
      class="mr-3"
      color="primary"
      text-color="white"
      size="large"
    >
      <v-avatar class="green darken-4 move-avatar">
        <v-icon
          icon="mdi-account-circle"
          color="white"
          size="large"
        />
      </v-avatar>
      <b>{{ teachers }}</b> &nbsp; Teachers
    </v-chip>
    <v-chip
      color="green"
      text-color="white"
      class="mr-3"
      size="large"
    >
      <v-avatar class="green darken-4 move-avatar">
        <v-icon
          icon="mdi-clipboard-account"
          color="white"
          size="large"
        />
      </v-avatar>
      <b>{{ students }}</b> &nbsp; Students
    </v-chip>

    <v-chip
      color="green"
      text-color="white"
      size="large"
    >
      <v-avatar
        size="large"
        class="move-avatar green darken-4"
      >
        <v-icon
          icon="mdi-account-multiple"
          color="white"
          size="large"
        />
      </v-avatar>
      <b>{{ classes }}</b> &nbsp; Classes
    </v-chip>
  </div>
</template>
<script>

import {initialize} from '@/utils/effort-tracking-api';
import {countClassesInPeriod} from '@/api/classesApi';
import {countTeachersInPeriod} from '@/api/teachersApi';
import {countStudentsInPeriod} from '@/api/studentApi';

export default {
  data() {
    return {
      name: 'counters',
      teachers: 0,
      students: 0,
      classes: 0
    };
  },
  mounted() {
    this.updateCounters();
  },
  methods: {
    updateCounters() {
      console.log('on update counters');

      initialize()
        .then(() => {
          const periodId = localStorage.getItem('period-selected');
          countTeachersInPeriod(periodId)
            .then(tc => this.teachers = tc)
            .catch(ex => console.log(ex));

          countStudentsInPeriod(periodId)
            .then(sc => this.students = sc)
            .catch(ex => console.log(ex));

          countClassesInPeriod(periodId)
            .then(cc => this.classes = cc)
            .catch(ex => console.log(ex));
        });
    }
  }
};
</script>
<style>
.move-avatar {
  margin-left: -10px;
  margin-right: 10px;
}

.green.darken-4 {
  background-color: #1b5e20 !important;
  border-color: #1b5e20 !important;
}

.counters {
  text-align: right;
}
</style>
