import {
    BASE_URL,
    deleteData,
    postData,
    getDataFromUrl,
    putData,
} from '@/utils/effort-tracking-api'

import {Routes} from '@/utils/settings'

export function searchPeriods(serverFilters){
    let url = `${BASE_URL}${Routes.PeriodsRoute}/search`;
    return postData(url, serverFilters);
}

export function getPeriods(){
    let url = `${BASE_URL}${Routes.PeriodsRoute}`;
    return getDataFromUrl(url);
}

export function getOrderedPeriods(role, id){
    let url = `${BASE_URL}${Routes.PeriodsRoute}/default/role/${role}/${id}`;
    return getDataFromUrl(url);
}

export function getActivePeriodsByUser(model){
    let url = `${BASE_URL}${Routes.PeriodsRoute}/user/active`;
    return postData(url, model);
}

export function getVisiblePeriodsByUser(model){
    let url = `${BASE_URL}${Routes.PeriodsRoute}/user/visible`;
    return postData(url, model);
}

export function getPeriod(periodId){
    let url = `${BASE_URL}${Routes.PeriodsRoute}/period/${periodId}`;
    return getDataFromUrl(url);
}

export function addPeriod(model){
    return postData(`${BASE_URL}${Routes.PeriodsRoute}`, model);
}

export function deletePeriod(periodId){
    return deleteData(`${BASE_URL}${Routes.PeriodsRoute}/period/${periodId}`);
}

export function setPeriodActive(model){
    const url = `${BASE_URL}${Routes.PeriodsRoute}/period/set/active`
    return postData(url, model);
}

export function setPeriodVisible(model){
    const url = `${BASE_URL}${Routes.PeriodsRoute}/period/set/visible`
    return postData(url, model);
}

export function setPeriodComplete(model){
    const url= `${BASE_URL}${Routes.PeriodsRoute}/complete`;
    return postData(url, model);
}

export function updatePeriod(model){
    return putData(`${BASE_URL}${Routes.PeriodsRoute}`, model);
}

export function getProgress(){
    return getDataFromUrl(`${BASE_URL}${Routes.PeriodsRoute}/calculation/status`)
}

export function sendPeriodNotifications( periodId, model){
    const url = `${BASE_URL}${Routes.PeriodsRoute}/notification/period/${periodId}`
    return postData(url, model);
}
