import {
  BASE_URL,
  getDataFromUrl
} from '@/utils/effort-tracking-api'
import {Routes} from '@/utils/settings';

export function getTeacherMotionChart(){
  const url =`${BASE_URL}${Routes.ChartRoute}/teacher`;
  return getDataFromUrl(url);
}

export function getTeacherMotionChartForStudent(studentId){
  const url =`${BASE_URL}${Routes.ChartRoute}/teacher/student/${studentId}`;
  return getDataFromUrl(url);
}

export function getStudentMotionChart(studentId){
  const url =`${BASE_URL}${Routes.ChartRoute}/student/${studentId}`;
  return getDataFromUrl(url);
}
