import {
    BASE_URL,
    deleteData,
    postData,
    getDataFromUrl,
    addQueryToUrl,
} from '@/utils/effort-tracking-api'

import {Routes} from '@/utils/settings'

export function countClassesInPeriod(periodId){
    let url = `${BASE_URL}${Routes.ClassesRoute}/period/${periodId}/count`;
    return getDataFromUrl(url);
}

export function getClassesByPeriod( periodId, serverFilters) {
    let url = `${BASE_URL}${Routes.ClassesRoute}/period/${periodId}`;
    url = addQueryToUrl(url, serverFilters);
    return getDataFromUrl(url);
}

export function removeClassFromPeriod(periodId, classId){
    const url = `${BASE_URL}${Routes.ClassesRoute}/class/${classId}/period/${periodId}`;
    return deleteData(url);
}

export function updateClass(classId, periodId, online) {
    const url = `${BASE_URL}${Routes.ClassesRoute}/update`;
    const data = {
        classId,
        periodId,
        online
    }
    return postData(url, JSON.stringify(data))
}

export function getStudentsInClass(periodId, classId, teacherId){
    const url = `${BASE_URL}${Routes.ClassesRoute}/${classId}/teacher/${teacherId}/period/${periodId}/students`
    return getDataFromUrl(url)
}
