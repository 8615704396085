<template>
  <v-btn
    id="help-menu"
    icon="true"
    density="compact"
     
    dark
    variant="plain"
  >
    <v-icon
      size="small"
      icon="mdi-help-circle"
    />
    <v-menu
      activator="parent"
      offset-y
    >
      <v-list>
        <v-list-item @click="openDocument()">
          <v-list-item-title>Help</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn>
</template>

<script>

import { getHelpFile }  from '@/utils/effort-tracking-api'

export default {
    name: 'HelpControls',
    methods: {
        openDocument() {
            getHelpFile(this.$store.state.usertype);
        }
    }
}
</script>
