<template>
  <v-app>
    <v-main
      :style="{ background: 'url(' + image + ') top center / cover' }"
      class="d-flex align-center justify-center"
    >
      <v-card
        v-if="!success"
        class="mx-auto flex-1-1-100"
        max-width="600"
      >
        <v-img
          src="/etracking-logo-long-large.gif"
          height="100px"
        />
        <v-card-text>
          <div class="text-h6">
            Password Recovery Form
          </div>

          <v-text-field
            v-model="resetPasswordModel.email"
            rows="1"
            max-rows="1"
            prepend-inner-icon="mdi-email-outline"
            :rules="globalStore.emailRules"
            :variant="globalStore.textFieldVariant"
            label="Email"
            name="Math.random()"
            autocomplete="do-not-autofill"
            required
          />

          <v-text-field
            v-model="resetPasswordModel.newPassword"
            :rules="globalStore.passwordRules"
            :append-inner-icon="resetPasswordModel.passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="resetPasswordModel.passwordVisible ? 'text' : 'password'"
            label="New Password"
            :variant="globalStore.textFieldVariant"
            prepend-inner-icon="mdi-lock-outline"
            required
            autocomplete="false"
            @click:append-inner="resetPasswordModel.passwordVisible = !resetPasswordModel.passwordVisible"
          />

          <v-textarea
            v-model="resetPasswordModel.repeatPassword"
            rows="1"
            max-rows="1"
            :rules="[v => (v === resetPasswordModel.newPassword) || 'passwords must match']"
            :append-inner-icon="resetPasswordModel.passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="resetPasswordModel.passwordVisible ? 'text' : 'password'"
            label="Repeat Password"
            :variant="globalStore.textFieldVariant"
            prepend-inner-icon="mdi-lock-outline"
            required
            autocomplete="false"
            @click:append-inner="resetPasswordModel.passwordVisible = !resetPasswordModel.passwordVisible"
          />
          <v-alert
            v-model="error"
            variant="tonal"
            type="error"
            closable
          >
            {{ errorMessage }}
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-btn
            variant="elevated"
            color="primary"
            to="login"
          >
            BACK TO LOGIN
          </v-btn>
          <v-spacer />
          <v-btn
            variant="elevated"
            color="error"
            @click="resetPassword()"
          >
            RESET
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        v-if="success"
        class="elevation-12"
      >
        <v-card-title primary-title>
          <div class="headline pb-3">
            Your password has been reset
          </div>
        </v-card-title>
        <v-card-text>
          <p>
            {{ resultMessage }}
          </p>
          <p>
            <router-link to="login">
              Click here to go to the login screen
            </router-link>
          </p>
        </v-card-text>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import {initialize} from '@/utils/effort-tracking-api';
import {getBackgroundImage, getLogoImage} from '@/api/publicApi';
import {resetForgottenPassword} from '@/api/accountsApi';
import {globalStore} from '@/main';

export default {
  data() {
    return {
      image: '',
      additionalImage: '',

      resetPasswordModel:{
        email: '',
        newPassword: '',
        repeatPassword: '',
        passwordVisible: false,
      },
      resultMessage: '',
      success: false,
      error: false,
      errorMessage: '',
      e1: true,
      e2: false,
      token: ''
    };

  },
  computed: {
    globalStore() {
      return globalStore
    }
  },
  created() {
    this.additionalImage = '';
    this.image = '';

    getBackgroundImage()
      .then(result => {
        this.image =  result === null ? '' : `/public${result}`;
      })
      .catch(
        ex => console.log(ex)
      );

    getLogoImage()
      .then(result => {
        this.additionalImage = result === null ? '' : `/public${result}`;
      })
      .catch(
        ex => console.log(ex)
      );
  },
  mounted() {
    console.log(this.$route.query.token);
    this.token = this.$route.query.token;
  },
  methods: {
    resetPassword() {
      this.error = false;
      this.success = false;

      initialize()
        .then(() => {
          let model = {
            'Email': this.resetPasswordModel.email,
            'Token': this.token,
            'NewPassword': this.resetPasswordModel.newPassword,
            'Host': window.location.protocol + '//' + window.location.hostname
          };
          resetForgottenPassword(model)
            .then(result => {
              if(result.status === 200) {
                this.success = true;
                this.resultMessage = result.data;
              }
              else {
                console.log(result);
                this.error = true;
                this.errorMessage = result.response.data.toString();
                if(this.errorMessage.length < 5) {
                  this.errorMessage = 'Something went wrong, please review your email address and try again.';
                }
                console.log(result.response.data);
              }
            })
            .catch(() => {
              this.error = true;
              this.errorMessage = 'Something went wrong, please try again';
            });
        })
        .catch(ex => console.log(ex));
    }
  }
}
</script>
