import {
    BASE_URL,
    postData,
    getDataFromUrl,
    addQueryToUrl,
} from '@/utils/effort-tracking-api'

import {Routes} from '@/utils/settings'

export function getPeriodDetails(periodId){
    let url = `${BASE_URL}${Routes.StatusRoutes}/period/${periodId}/details`;
    return getDataFromUrl(url);
}

export function getPeriodStatus(periodId, serverFilters){
    let url = `${BASE_URL}${Routes.StatusRoutes}/period/${periodId}`;
    url = addQueryToUrl(url, serverFilters)
    return getDataFromUrl(url);
}

export function getFilteredStatus(periodId, serverFilter){
    const url = `${BASE_URL}${Routes.StatusRoutes}/search/period/${periodId}`;
    return postData(url, serverFilter);
}

export function getMissingReport(periodId){
    const url = `${BASE_URL}${Routes.StatusRoutes}/report/missing/period/${periodId}`;
    return getDataFromUrl(url);
}

export function sendReminders(model){
    const url = `${BASE_URL}${Routes.StatusRoutes}/notification`;
    return postData(url, model);
}
