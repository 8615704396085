<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>
        Data Export
      </v-toolbar-title>
      <v-spacer />
      <counters-component />
    </v-toolbar>

    <v-expansion-panels
      v-model="panel"
      variant="accordion"
      class="mt-8"
    >
      <v-expansion-panel
        value="exportData"
      >
        <v-expansion-panel-title :class="panel === 'exportData' ? 'bg-grey-lighten-3' : ''">
          Export Data
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-container class="ma-0 pa-0">
            <v-row no-gutters>
              <v-btn
                id="exportButton"
                color="success"
                @click.stop="exportCsv()"
              >
                Export All Effort Grades
              </v-btn>

              <v-btn
                id="exportStudentsDerivedDataFields"
                class="ml-4"
                color="success"
                @click.stop="exportStudentsDerivedDataFields()"
              >
                Export Summary Data
              </v-btn>
            </v-row>
          </v-container>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
        value="downloadProgress"
      >
        <v-expansion-panel-title :class="showExport || panel === 'downloadProgress' ? 'bg-grey-lighten-3' : ''">
          Data Export Progress
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-progress-linear
            class="mb-4"
            :indeterminate="loadingExport"
            :active="showExport"
          />

          <p>{{ progressReport }}</p>

          <i>The link to your csv file will appear below when ready</i>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
        value="exportResults"
      >
        <v-expansion-panel-title :class="showExport || panel === 'exportResults' ? 'bg-grey-lighten-3' : ''">
          Data Export Results
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <a
            id="generatedFile"
            href="#"
            @click="requestFile()"
          >{{ exportedFile }}</a>

          <v-alert
            v-model="showError"
            variant="tonal"
            type="info"
            closable
            outline
          >
            {{ errorItems[0] }}
          </v-alert>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>

import {
  getAllDataAsCsv,
  createStudentsDashboardFile,
  downloadFile,
  initialize,
  getExportProgress,
  getCsvFile,
  getDashboardDataCount
} from '@/utils/effort-tracking-api';

import CountersComponent from './components/CountersComponent';

export default {
  components: {
    CountersComponent
  },
  data() {
    return {
      exportData: false,
      errors: 0,
      errorItems: [],
      timerExport: '',
      progressRetries: 0,
      maxProgressRetries: 6,
      downloadProgress: false,
      exportResults: false,
      loadingExport: false,
      showExport: false,
      exportedFile: '',
      showFile: false,
      progressReport: '',
      showError: false,
      panel: []
    };
  },

  mounted() {
    initialize()
      .then(() => {
        this.downloadProgress = false;
        this.exportedFile = '';
      });
  },
  beforeUnmount() {
    this.exportedFile = '';
    clearInterval(this.timerExport);
  },
  methods: {
    requestFile() {
      const url = getCsvFile(this.exportedFile);
      downloadFile(url, this.exportedFile);
    },
    exportCsv() {
      this.exportedFile = '';
      this.exportData = false;
      this.downloadProgress = true;
      this.panel = ['downloadProgress'];
      this.loadingExport = true;
      this.showExport = true;
      let periodId = localStorage.getItem('period-selected');

      getAllDataAsCsv(periodId)
        .then(result => {
          if(result) {
            this.loadingExport = false;
            clearInterval(this.timerExport);
            this.timerExport = setInterval(this.exportProgressUpdate, 1000);
          }
        });
    },
    exportStudentsDerivedDataFields() {
      this.exportedFile = '';
      this.exportData = false;
      this.downloadProgress = true;
      this.loadingExport = true;
      this.showExport = true;
      let periodId = localStorage.getItem('period-selected');

      getDashboardDataCount(periodId)
        .then(hasData => {
          if(hasData > 0) {
            createStudentsDashboardFile(periodId)
              .then(result => {
                if(result) {
                  this.loadingExport = false;
                  this.downloadProgress = true;
                  this.exportResults = false;
                  clearInterval(this.timerExport);
                  this.timerExport = setInterval(this.exportProgressUpdate, 1000);
                }
              });
          }
          else {
            this.showError = true;
            this.errorItems[0]
              = 'This reporting period has not been completed. Please complete the period first to calculate all effort scores.';
            this.loadingExport = false;
            this.downloadProgress = false;
            this.exportResults = true;
          }
        });
    },
    exportProgressUpdate() {
      getExportProgress()
        .then(progress => {
          console.log('Exporter progress...');
          console.log(progress);
          this.progressReport = progress.status.status;

          if(progress.status.isDone) {
            console.log('is Done...');
            this.loadingExport = false;
            this.downloadProgress = false;
            this.exportResults = true;
            this.panel = ['exportResults'];

            this.errors = progress.status.errors.length;
            this.errorItems = progress.status.errors;
            if(this.errors > 0) {
              this.showFile = false;
              this.exportedFile = '';
              this.showError = true;
            }
            else {
              this.showFile = true;
              this.exportedFile = progress.exportedFile;
              this.showError = false;
            }

            clearInterval(this.timerExport);
          }

          if(progress.IsRunning) {
            this.loadingExport = false;
            this.downloadProgress = true;
            this.exportResults = false;
          }
        });
    }
  }
};
</script>
