<template>
  <v-container class="pa-0 ma-0 mw-100">
    <v-alert
      v-model="noEfforts"
      icon="warning"
      variant="tonal"
    >
      No efforts for the selected period.
    </v-alert>
    <v-card v-if="hasEfforts ">
      <v-toolbar dense>
        <v-toolbar-title>
          {{ efforts.groupName }}
        </v-toolbar-title>
        <v-spacer />
        <grades-downloader
          :class-id="0"
          :is-student="true"
          :is-teacher="false"
        />
      </v-toolbar>
      <grading-tabs
        v-model="efforts"
        :value="efforts"
        :hide-title="true"
        :is-student="true"
        :settings="settings"
        :readonly="readonly"
        :class-id="0"
        @grade-changed="onGradeChanged"
        @save-failed="saveFailed()"
      />
    </v-card>
  </v-container>
</template>
<script>

import {initialize} from '@/utils/effort-tracking-api';
import {getStudentEfforts} from '@/api/studentApi';
import {getGradingButtonSettings} from '@/api/settingsApi';
import {getPeriod, getPeriods} from '@/api/periodApi';
import {UserType} from '@/utils/settings';
import GradingTabs from '../shared/grading/GradingTabs';
import GradesDownloader from '../shared/grading/GradesDownloader';
import {globalStore} from '@/main';


export default {
  name: 'StudentEfforts',
  components: {
    GradingTabs,
    GradesDownloader
  },
  data() {
    return {
      efforts: {},
      hasEfforts: false,
      noEfforts: false,
      settings: [],
      readonly: false,
      isTeacher: false,
      isStudent: true,
      classId: 0,
      studentId: this.$route.params.studentId,
      periodId: 0
    };
  },
  computed: {
    globalStore() {
      return globalStore
    }
  },
  mounted() {
    initialize()
      .then(() => {
        this.periodId = globalStore.selectedPeriod;
        getGradingButtonSettings()
          .then(
            settings => {
              this.settings = settings;
              getPeriods()
                .then(
                  periods => {
                    if(this.periodId !== 'undefined') {
                      let p = periods.filter(p => p.periodId === Number(this.periodId));
                      if(p[0].visible ||
                          this.$store.state.usertype === UserType.Admin)
                      {
                        this.isTeacher
                          = (this.$store.state.usertype === UserType.Teacher || this.$store.state.usertype === UserType.MiddleManager);
                        getPeriod(globalStore.selectedPeriod)
                          .then(
                            result => {
                              this.readonly = !result.active || result.complete;
                            })
                          .catch(ex => console.log(ex));
                        this.checkUser();
                        this.update();
                      }
                    }
                  });
            });
      });
  },
  methods: {
    checkUser() {
      const userid = this.$route.params.studentId;
      const paramsId = this.$route.params.studentId;
      const usertype = this.$store.state.usertype;
      if(Number(userid) !== paramsId
        && usertype !== UserType.Admin
        && usertype !== UserType.MiddleManager
        && usertype !== UserType.Teacher) {
        window.location.href = '#/users/login';
      }
    },
    update() {
      getStudentEfforts(this.studentId, this.periodId)
        .then(
          (efforts) => {
            this.$emit('show-period-notification');
            if(efforts.effortGroups.length > 0 && efforts.effortGroups[0].effortGroups.length > 0) {
              this.hasEfforts = true;
              this.noEfforts = false;
              this.efforts = efforts;
            }
            else {
              this.hasEfforts = false;
              this.noEfforts = true;
            }
          })
        .catch(ex => console.error('getStudentEfforts', ex));
    },

    onGradeChanged: function() {
      localStorage.removeItem('saving');
      this.checkUser();
      this.update();
    },

    saveFailed() {
      localStorage.setItem('saving', 'true');
    }
  }
};
</script>
