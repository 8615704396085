<template>
  <v-container class="pa-0 ma-0 w-100 mw-100">
    <v-alert
      v-model="alertSuccess"
      variant="tonal"
      type="success"
      closable
    >
      {{ resultMessage }}
    </v-alert>
    <v-alert
      v-model="alertError"
      variant="tonal"
      type="error"
      closable
    >
      {{ resultMessage }}
    </v-alert>
    <v-card>
      <v-toolbar class="elevation1">
        <v-toolbar-title>
          {{ selectedStudentClass }} - Students
        </v-toolbar-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-inner-icon="mdi-magnify"
          label="search"
          variant="solo"
          density="compact"
          class="search"
        />
      </v-toolbar>
    </v-card>
    <k-grid
      ref="grid"
      class="studentClassesGrid gridFullHeight"
      :data-items="gridData.items"
      :sort="sort"
      :sortable="true"
      :skip="serverFilters.skip"
      :take="serverFilters.take"
      :total="gridData.totalItems"
      :pageable="pageable"
      :page-size="globalStore.defaultRowsPerPage"
      :columns="gridData.columns"
      :loader="loader"
      @sortchange="sortChangeHandler"
      @pagechange="pageChangeHandler"
    >
      <template #loader>
        <div class="k-loader-container k-loader-container-md k-loader-top">
          <div class="k-loader-container-overlay k-overlay-dark" />
          <div class="k-loader-container-inner">
            <k-loader
              :size="'large'"
              :type="'pulsing'"
              :theme-color="'success'"
            />
          </div>
        </div>
      </template>

      <template #myTemplate="{props}">
        <td class="d-flex justify-center ">
          <v-btn
            icon="true"
            :dark="!readonly"
            :disabled="readonly"
            class="ma-2 pa-0"
            density="comfortable"
            variant="outlined"
            color="red"
            @click.stop="loadDialog(props.dataItem)"
          >
            <v-tooltip activator="parent">
              Remove Student from Class
            </v-tooltip>
            <v-icon
              small
              icon="mdi-delete"
            />
          </v-btn>
        </td>
      </template>
    </k-grid>

    <v-dialog
      v-model="dialog"
      max-width="40%"
    >
      <v-card>
        <v-card-title>
          Confirmation
        </v-card-title>
        <v-card-text>
          This action will remove the student
          <b> {{ studentName }} </b>
          from the class
          <b> {{ selectedStudentClass }} </b>
          on period <b>{{ periodName }}</b>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            flat
            @click.stop="unloadDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click.stop="deleteSC()"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import {getStudentsInClasses, removeStudentFromClass} from '@/api/studentApi';
import {getPeriod} from '@/api/periodApi';
import {globalStore} from '@/main';
import {Grid as KGrid} from '@progress/kendo-vue-grid';
import {Loader as KLoader} from '@progress/kendo-vue-indicators';
import {debounce} from 'lodash';
import {initialize} from "@/utils/effort-tracking-api";

export default {
  components: {
    KGrid,
    KLoader,
  },
  data() {
    return {

      loading: false,

      search: '',
      // Does not work as a child property.
      sort: [{field: 'lastName', dir: 'asc'}],
      loader: false,
      serverFilters: {
        sortBy: 'lastName',
        filter: '',
        take: globalStore.defaultRowsPerPage,
        skip: 0,
        descending: false
      },
      pagerValues: {
        pagerType: globalStore.pagerTypes['numeric'],
        pagerButtons: globalStore.pagerButtons,
        pagerInfo: globalStore.pagerInfo,
        pagerSizes: globalStore.pagerSizes,
        pagePrevNext: globalStore.pagerSizes,
        pageSize: globalStore.defaultRowsPerPage,
        pageSizeValue: globalStore.defaultRowsPerPage,
      },
      gridData: {
        columns: [
          {field: 'code', title: 'Code'},
          {field: 'lastName', title: 'Last Name'},
          {field: 'firstName', title: 'First Name'},
          {field: '', cell: 'myTemplate', sortable: false},
        ],
        items: [],
        totalItems: 0,
      },

      dialog: false,
      alertSuccess: false,
      alertError: false,
      selectedStudentClass: '',
      delReportingPeriodClassId: 0,
      delStudentId: 0,
      studentName: '',
      resultMessage: '',
      periodName: '',
      classCode: '',
      readonly: false,

    };
  },
  computed: {
    globalStore() {
      return globalStore;
    },
    pageable() {
      return {
        buttonCount: this.pagerValues.pagerButtons,
        info: this.pagerValues.pagerInfo,
        type: this.pagerValues.pagerType,
        pageSizes: this.pagerValues.pagerSizes
                   ? globalStore.allowedRowsPerPage
                   : undefined,
        previousNext: this.pagerValues.pagePrevNext,
        pageSizeValue: this.pagerValues.pageSizeValue,
      };
    },
  },
  watch: {
    search() {
      this.debouncedGetDataFromApi();
    },
  },
  created() {
    this.debouncedGetDataFromApi = debounce(() => {
      this.getDataFromApi();
    }, 500);
  },
  mounted() {
    this.setPeriodName();
    this.selectedStudentClass = this.$route.params.className;
    this.classCode = this.$route.params.classCode;
    console.log('On class students mounted...');
    this.debouncedGetDataFromApi();
    this.disableActions();
  },
  methods: {
    sortChangeHandler: function(e) {
      if(e.sort.length === 0) {
        this.serverFilters.descending = false;
        this.debouncedGetDataFromApi();
        this.sort = e.sort;
        return;
      }
      this.serverFilters.descending = e.sort[0].dir !== 'asc';
      this.serverFilters.sortBy = e.sort[0].field;
      this.debouncedGetDataFromApi();
      this.sort = e.sort;
    },
    pageChangeHandler: function(event) {
      console.log('event', event);
      this.pagerValues.pageSize = event.page.take;
      this.pagerValues.pageSizeValue = event.page.take;
      this.serverFilters.skip = event.page.skip;
      this.serverFilters.take = event.page.take;
      this.debouncedGetDataFromApi();
    },
    getDataFromApi() {
      this.loader = true;
      const classId = this.$route.params.classId;
      this.serverFilters.filter = this.search;
      getStudentsInClasses(globalStore.selectedPeriod, classId, this.serverFilters)
        .then(data => {
          this.gridData.items = data.items;
          this.gridData.totalItems = data.count;
          this.loader = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadDialog(item) {
      this.alertSuccess = false;
      this.alertError = false;
      this.studentName = item.firstName + ' ' + item.lastName;
      this.delStudentId = item.studentId;
      this.delReportingPeriodClassId = item.reportingPeriodClassId;
      this.dialog = true;
    },
    setPeriodName() {
      const periodId = localStorage.getItem('period-selected');
      const period = this.$store.state.periods.filter(p => p.id === periodId);
      this.periodName = ((period.length > 0)
                         ? period[0].text
                         : '');
    },
    unloadDialog() {
      this.dialog = false;
      this.selectedStudentClass = '';
    },
    deleteSC() {
      this.dialog = false;
      removeStudentFromClass(this.delReportingPeriodClassId, this.delStudentId)
        .then(() => {
          this.debouncedGetDataFromApi();
        })
        .catch(ex => {
          console.log(ex);
        });
    },
    disableActions() {
      console.log('disableActions..')
      initialize().then(()=> {
        getPeriod(Number(globalStore.selectedPeriod))
        .then(result => {
          this.readonly = !result.active || result.complete;
        })
        .catch(ex => console.log(ex));
      })
    }
  }
};
</script>
