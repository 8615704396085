import { UserManager } from 'oidc-client';

const config = {
  authority: 'https://cosource-id.cloudworkengine.net', // URL of the OIDC provider
  client_id: 'Elh3W1VTaWa9', // Client ID registered with the OIDC provider
  
  redirect_uri: 'http://localhost:8080/#/SSO', // Redirect URI for your Vue app
 // redirect_uri: 'https://staging.efforttracking.app/#/SSO', // Redirect URI for your Vue app
  response_type: 'token id_token', // Response type (code for authorization code flow)
  scope: 'openid profile email', // Scopes you want to request from the OIDC provider
  post_logout_redirect_uri: 'http://localhost:8080/#/users/login', // Redirect URI after logout,
  signinSilentError: true,
  userinfo_endpoint: 'https://cosource-id.cloudworkengine.net/api/oauth/userinfo',
  metadata: {
    issuer: 'https://cosource-id.cloudworkengine.net',
    jwks_uri: 'https://cosource-id.cloudworkengine.net/api/oauth/jwks',
    token_endpoint: 'https://cosource-id.cloudworkengine.net/api/oauth/token',
    token_endpoint_auth_method: 'client_secret_post',
    authorization_endpoint: 'https://cosource-id.cloudworkengine.net/api/oauth/authorize',
    userinfo_endpoint: 'https://cosource-id.cloudworkengine.net/api/oauth/userinfo',
    end_session_endpoint: 'https://cosource.cloudworkengine.net/saml/logout'
  }
};

const userManager = new UserManager(config);

export default userManager;
