<template>
  <v-container class="pa-0 ma-0 mw-100 w-100">
    <v-alert
      v-model="alertSuccess"
      variant="tonal"
      type="success"
      closable
    >
      {{ resultMessage }}
    </v-alert>

    <v-alert
      v-model="alertError"
      variant="tonal"
      type="error"
      closable
    >
      {{ resultMessage }}
    </v-alert>

    <v-toolbar class="elevation-1">
      <v-toolbar-title>
        {{ middleManagerName }} - Classes
      </v-toolbar-title>
    </v-toolbar>

    <v-card variant="flat">
      <v-card-text>
        <v-row>
          <v-col cols="7">
            <v-radio-group
              v-model="selectedPeriodOptionClasses"
              inline
            >
              <v-radio
                v-for="n in periodOptionsClasses"
                :key="n.id"
                :label="n.text"
                :value="n.id"
                color="grey"
              />
            </v-radio-group>
          </v-col>
          <v-col cols="5">
            <v-radio-group
              v-model="selectedPeriodOptionAssociations"
              inline
            >
              <v-radio
                v-for="n in periodOptionsAssociations"
                :key="n.id"
                :label="n.text"
                :value="n.id"
                color="grey"
              />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7">
            <v-row>
              <v-col cols="10">
                <v-text-field
                  v-model="searchClasses"
                  append-inner-icon="mdi-magnify"
                  label="Search"
                  :variant="globalStore.textFieldVariant"
                />
              </v-col>
              <v-col
                cols="2"
                class="d-flex align-self-center"
              >
                <v-btn
                  color="success"
                  variant="outlined"
                  @click="addAllAssociation()"
                >
                  Add All
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5">
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="searchAssociations"
                  append-inner-icon="mdi-magnify"
                  label="Filter Status"
                  :variant="globalStore.textFieldVariant"
                />
              </v-col>
              <v-col
                cols="4"
                class="d-flex align-self-center"
              >
                <v-btn
                  v-if="showDeleteAllButton"
                  color="red"
                  variant="outlined"
                  @click="removeAllAssociationsDialog = true;"
                >
                  Remove All
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="7">
            <k-grid
              ref="classesMiddleManagerGrid"
              class="classesMiddleManagerGrid gridFullHeightClassSelector"
              :data-items="gridDataClasses.items"
              :skip="serverFiltersClasses.skip"
              :sort="sortClasses"
              :take="serverFiltersClasses.take"
              :sortable="true"
              :total="gridDataClasses.totalItems"
              :pageable="pageableClasses"
              :page-size="pagerValuesClasses.pageSize"
              :columns="gridDataClasses.columns"
              :loader="classesLoader"
              @sortchange="sortChangeHandlerClasses"
              @pagechange="pageChangeHandlerClasses"
            >
              <template #loader>
                <div class="k-loader-container k-loader-container-md k-loader-top">
                  <div class="k-loader-container-overlay k-overlay-dark" />
                  <div class="k-loader-container-inner">
                    <k-loader
                      :size="'large'"
                      :type="'pulsing'"
                      :theme-color="'success'"
                    />
                  </div>
                </div>
              </template>
              <template #myTemplate="{props}">
                <td class="k-table-td ">
                  <v-btn
                    icon="true"
                    style="min-width: 36px;"
                    class="ma-0 pa-0"
                    density="comfortable"
                    color="green"
                    variant="outlined"
                    :disabled="readonly"
                    @click.stop="addAssociation(props.dataItem)"
                  >
                    <v-icon
                      small
                      icon="mdi-playlist-plus"
                    />
                    <v-tooltip activator="parent">
                      Add
                    </v-tooltip>
                  </v-btn>
                </td>
              </template>
            </k-grid>
          </v-col>
          <v-col cols="5">
            <k-grid
              ref="associationsMiddleManagerGrid"
              class="gridFullHeightClassSelector"
              :data-items="gridDataAssociations.items"
              :skip="serverFiltersAssociations.skip"
              :sort="sortAssociations"
              :take="serverFiltersAssociations.take"
              :sortable="true"
              :total="gridDataAssociations.totalItems"
              :pageable="pageableAssociation"
              :page-size="pagerValuesClasses.pageSize"
              :columns="gridDataAssociations.columns"
              :loader="associationsLoader"
              @sortchange="sortChangeHandlerAssociations"
              @pagechange="pageChangeHandlerAssociations"
            >
              <template #loader>
                <div class="k-loader-container k-loader-container-md k-loader-top">
                  <div class="k-loader-container-overlay k-overlay-dark" />
                  <div class="k-loader-container-inner">
                    <k-loader
                      :size="'large'"
                      :type="'pulsing'"
                      :theme-color="'success'"
                    />
                  </div>
                </div>
              </template>
              <template #myTemplate="{props}">
                <td class="d-flex justify-center ">
                  <v-btn
                    icon="true"
                    class="ma-2 pa-0"
                    density="comfortable"
                    color="red"
                    variant="outlined"
                    @click.stop="removeAssociation(props.dataItem)"
                  >
                    <v-tooltip activator="parent">
                      Remove
                    </v-tooltip>
                    <v-icon
                      size="small"
                      icon="mdi-delete"
                    />
                  </v-btn>
                </td>
              </template>
            </k-grid>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-dialog
      v-model="removeAllAssociationsDialog"
      max-width="35%"
    >
      <v-card>
        <v-card-title>
          Confirmation
        </v-card-title>
        <v-card-text>
          This action will <span class="dialogWarningColor">remove</span> all the info related to the class
          <b> {{ selectedClass }} </b>
          from the period <b>{{ periodName }}</b>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            variant="text"
            @click.stop="removeAllAssociationsDialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            variant="elevated"
            @click.stop="removeAllAssociations()"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {initialize} from '@/utils/effort-tracking-api';
import {
  removeAssociation,
  removeAllAssociations,
  addAssociations,
  getClassesByAssociation,
  getClassesByAssociationAndPeriod,
  getMiddleManagerClasses,
  getMiddleManagerClassesByPeriod,
  getMiddleManager,
} from '@/api/middleManagerApi';
import {LocalStorageKey} from '@/utils/settings';
import {globalStore} from '@/main';
import {debounce} from 'lodash';
import {Loader as KLoader} from '@progress/kendo-vue-indicators';
import {Grid as KGrid} from '@progress/kendo-vue-grid';

export default {
  name: 'MiddleManagerClassSelector',
  components: {
    KLoader,
    KGrid,
  },
  data() {
    return {
      removeAllAssociationsDialog: false,
      periodName: '',
      selectedClass: '',
      selectedClassId: 0,

      alertSuccess: false,
      alertError: false,
      resultMessage: '',

      readonly: false,

      middleManagerId: this.$route.params.managerId,
      showDeleteAllButton: true,
      middleManagerName: '',

      selectedPeriodOptionClasses: 2,
      periodOptionsClasses: [],
      searchClasses: '',
      // Does not work as a child property.
      sortClasses: [{field: 'classCode', dir: 'asc'}],
      classesLoader: false,
      serverFiltersClasses: {
        sortBy: 'classCode',
        filter: '',
        take: globalStore.defaultRowsPerPage,
        skip: 0,
        descending: false
      },
      pagerValuesClasses: {
        pagerType: globalStore.pagerTypes['numeric'],
        pagerButtons: globalStore.pagerButtons,
        pagerInfo: globalStore.pagerInfo,
        pagerSizes: globalStore.pagerSizes,
        pagePrevNext: globalStore.pagerSizes,
        pageSize: globalStore.defaultRowsPerPage,
        pageSizeValue: globalStore.defaultRowsPerPage,
      },
      gridDataClasses: {
        columns: [
          {field: 'classCode', title: 'Code'},
          {field: 'courseName', title: 'Course'},
          {field: 'className', title: 'Class'},
          {field: 'teacherName', title: 'Teacher'},
          {field: '', cell: 'myTemplate', sortable: false},
        ],
        items: [],
        totalItems: 0,
      },

      selectedPeriodOptionAssociations: 2,
      periodOptionsAssociations: [],
      searchAssociations: '',
      sortAssociations: [{field: 'classCode', dir: 'asc'}],
      associationsLoader: false,
      serverFiltersAssociations: {
        sortBy: 'classCode',
        filter: '',
        take: globalStore.defaultRowsPerPage,
        skip: 0,
        descending: false
      },
      pagerValuesAssociation: {
        pagerType: globalStore.pagerTypes['numeric'],
        pagerButtons: globalStore.pagerButtons,
        pagerInfo: globalStore.pagerInfo,
        pagerSizes: globalStore.pagerSizes,
        pagePrevNext: globalStore.pagerSizes,
        pageSize: globalStore.defaultRowsPerPage,
        pageSizeValue: globalStore.defaultRowsPerPage,
      },
      gridDataAssociations: {
        columns: [
          {field: 'classCode', title: 'Code'},
          {field: 'className', title: 'Name'},
          {field: 'teacherName', title: 'Teacher'},
          {field: '', cell: 'myTemplate', sortable: false},
        ],
        items: [],
        totalItems: 0,
      },
    };
  },
  computed: {
    globalStore() {
      return globalStore;
    },
    pageableClasses() {
      return {
        buttonCount: this.pagerValuesClasses.pagerButtons,
        info: this.pagerValuesClasses.pagerInfo,
        type: this.pagerValuesClasses.pagerType,
        pageSizes: this.pagerValuesClasses.pagerSizes
                   ? globalStore.allowedRowsPerPage
                   : undefined,
        previousNext: this.pagerValuesClasses.pagePrevNext,
        pageSizeValue: this.pagerValuesClasses.pageSizeValue,
      };
    },
    pageableAssociation() {
      return {
        buttonCount: this.pagerValuesAssociation.pagerButtons,
        info: this.pagerValuesAssociation.pagerInfo,
        type: this.pagerValuesAssociation.pagerType,
        pageSizes: this.pagerValuesAssociation.pagerSizes
                   ? globalStore.allowedRowsPerPage
                   : undefined,
        previousNext: this.pagerValuesAssociation.pagePrevNext,
        pageSizeValue: this.pagerValuesAssociation.pageSizeValue,
      };
    },
  },
  watch: {
    searchClasses: {
      handler() {
        this.debouncedGetDataFromApi();
      },
    },
    searchAssociations: {
      handler() {
        this.debouncedGetAssociationsFromApi();
      }
    },
    selectedPeriodOptionClasses: {
      handler() {
        this.debouncedGetDataFromApi();
      }
    },
    selectedPeriodOptionAssociations: {
      handler() {
        this.debouncedGetAssociationsFromApi();
      }
    }
  },
  created() {
    this.debouncedGetDataFromApi = debounce(
      () => {
        this.getDataFromApi();
      },
      500
    );

    this.debouncedGetAssociationsFromApi = debounce(
      () => {
        this.getAssociations();
      }
      , 500);
  },
  mounted() {
    console.log('on middle manager classes mounted');

    this.periodOptionsClasses.push(
      {id: 1, text: 'All Reporting Periods'},
      {id: 2, text: 'Current Reporting Period'}
    );
    this.periodOptionsAssociations.push(
      {id: 1, text: 'All Reporting Periods'},
      {id: 2, text: 'Current Reporting Period'}
    );

    this.middleManagerId = this.$route.params.managerId;
    if(this.middleManagerId !== undefined) {
      localStorage.setItem(LocalStorageKey.MiddleManagerid, this.middleManagerId);
    }
    else {
      this.middleManagerId = localStorage.getItem(LocalStorageKey.MiddleManagerid);
    }

    this.debouncedGetDataFromApi();
    this.debouncedGetAssociationsFromApi();

  },
  methods: {
    sortChangeHandlerClasses: function(e) {
      if(e.sort.length === 0) {
        this.serverFiltersClasses.descending = false;

        this.getDataFromApi();
        this.sortClasses = e.sort;
        return;
      }
      this.serverFiltersClasses.descending = e.sort[0].dir !== 'asc';
      this.serverFiltersClasses.sortBy = e.sort[0].field;
      this.getDataFromApi();
      this.sortClasses = e.sort;
    },
    sortChangeHandlerAssociations: function(e) {
      if(e.sort.length === 0) {
        this.serverFiltersAssociations.descending = false;

        this.getAssociations();
        this.sortAssociations = e.sort;
        return;
      }
      this.serverFiltersAssociations.descending = e.sort[0].dir !== 'asc';
      this.serverFiltersAssociations.sortBy = e.sort[0].field;
      this.getAssociations();
      this.sortAssociations = e.sort;
    },
    pageChangeHandlerClasses: function(event) {
      console.log('pageChangeHandlerClasses: ', event);
      this.pagerValuesClasses.pageSize = event.page.take;
      this.pagerValuesClasses.pageSizeValue = event.page.take;
      this.serverFiltersClasses.skip = event.page.skip;
      this.serverFiltersClasses.take = event.page.take;
      this.getDataFromApi();
    },
    pageChangeHandlerAssociations: function(event) {
      console.log('pageChangeHandlerAssociations: ', event);
      this.pagerValuesAssociation.pageSize = event.page.take;
      this.pagerValuesAssociation.pageSizeValue = event.page.take;
      this.pagerValuesAssociation.skip = event.page.skip;
      this.pagerValuesAssociation.take = event.page.take;
      this.getAssociations();
    },
    getDataFromApi() {
      if(this.middleManagerId === undefined) {
        this.middleManagerId = localStorage.getItem('middlemanagerid');
      }
      initialize()
        .then(
          () => {
            this.classesLoader = true;
            getMiddleManager(this.middleManagerId)
              .then(result => {
                this.middleManagerName = result.name;
              });
            this.serverFiltersClasses.filter = this.searchClasses;
            if(this.selectedPeriodOptionClasses === 1) {

              // Get classes from all reporting periods
              getMiddleManagerClasses(this.serverFiltersClasses)
                .then(
                  data => {
                    this.gridDataClasses.items = data.items;
                    this.gridDataClasses.totalItems = data.count;

                    if(data.count > 0) {
                      this.allResults = data.items[0].allResults;
                    }
                    this.classesLoader = false;
                  });
            }
            else {
              // Get classes of current reporting period
              getMiddleManagerClassesByPeriod(globalStore.selectedPeriod, this.serverFiltersClasses)
                .then(
                  data => {
                    this.gridDataClasses.items = data.items;
                    this.gridDataClasses.totalItems = data.count;

                    if(data.count > 0) {
                      this.allResults = data.items[0].allResults;
                    }
                    this.classesLoader = false;
                  });
            }
          });
    },
    getAssociations() {
      this.serverFiltersAssociations.filter = this.searchAssociations;
      this.associationsLoader = true;
      initialize()
        .then(
          () => {
            if(this.selectedPeriodOptionAssociations === 1) {
              getClassesByAssociation(this.middleManagerId, this.serverFiltersAssociations)
                .then(
                  associations => {
                    this.gridDataAssociations.items = associations.items;
                    this.gridDataAssociations.totalItems = associations.count;
                    this.showDeleteAllButton = associations.count > 0;
                    this.associationsLoader = false;
                  });
            }
            else {
              getClassesByAssociationAndPeriod(globalStore.selectedPeriod, this.middleManagerId, this.serverFiltersAssociations)
                .then(
                  associations => {
                    this.gridDataAssociations.items = associations.items;
                    this.gridDataAssociations.totalItems = associations.count;
                    this.showDeleteAllButton = associations.count > 0;
                    this.associationsLoader = false;
                  });
            }
          });
    },
    addAssociation(_class) {
      initialize()
        .then(
          () => {
            addAssociations(this.middleManagerId, globalStore.selectedPeriod, [_class.classId], false)
              .then(
                () => {
                  this.debouncedGetAssociationsFromApi();
                });
          });
    },
    addAllAssociation() {
      initialize()
        .then(() => {
          addAssociations(this.middleManagerId, globalStore.selectedPeriod, [], true)
            .then(() => {
              this.debouncedGetAssociationsFromApi();
            });
        });
    },
    removeAssociation(_class) {
      removeAssociation(this.middleManagerId, _class.classId,)
        .then(() => {
          this.debouncedGetAssociationsFromApi();
        });
    },
    removeAllAssociations() {
      this.removeAllAssociationsDialog = false;
      if(this.middleManagerId === undefined) {
        this.middleManagerId = localStorage.getItem('middlemanagerid');
      }
      this.loading = true;
      initialize()
        .then(
          () => {
            removeAllAssociations(this.middleManagerId)
              .then(() => {
                this.debouncedGetAssociationsFromApi();
                this.loading = false;
              });
          });
    },
  }
};
</script>

<style>
.classesMiddleManagerGrid .k-grid-header col:nth-of-type(1),
.classesMiddleManagerGrid .k-grid-table col:nth-of-type(1) {
  width: 20%;
}

.classesMiddleManagerGrid .k-grid-header col:nth-of-type(2),
.classesMiddleManagerGrid .k-grid-table col:nth-of-type(2) {
  width: 20%;
}


.classesMiddleManagerGrid .k-grid-header col:nth-of-type(3),
.classesMiddleManagerGrid .k-grid-table col:nth-of-type(3) {
  width: 20%;
}


.classesMiddleManagerGrid .k-grid-header col:nth-of-type(4),
.classesMiddleManagerGrid .k-grid-table col:nth-of-type(4) {
  width: 20%;
}

.classesMiddleManagerGrid .k-grid-header col:nth-of-type(5),
.classesMiddleManagerGrid .k-grid-table col:nth-of-type(5) {
  width: 10%;
}

</style>
