<template>
  <v-card>
    <v-toolbar class="elevation1">
      <v-toolbar-title>Site settings</v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="isNotInPasswordsTab"
        color="primary"
        centered
        variant="elevated"
        @click.stop="saveActiveCardSettings()"
      >
        SAVE
      </v-btn>
    </v-toolbar>
    <v-alert
      v-model="success"
      variant="tonal"
      type="success"
      closable
    >
      {{ tabName }} saved successfully
    </v-alert>

    <v-alert
      v-model="error"
      variant="tonal"
      type="error"
      closable
    >
      There was an error with this action. Please try again.
    </v-alert>

    <v-alert
      v-model="pendingChanges"
      variant="tonal"
      type="warning"
      closable
    >
      There are pending changes. Remember to save them before leaving so you don't lose them.
    </v-alert>

    <v-tabs
      v-model="active"
      class="tab-bar"
    >
      <v-tab
        variant="text"
        hide-slider="hide-slider"
        class="fix-tab-bg"
        value="tab-1"
        @click="success=false; isNotInPasswordsTab=true"
      >
        Login Page
        <v-icon
          v-if="pendingLoginTabChanges"
          color="error"
          class="ml-8"
          icon="mdi-information-outline"
        />
      </v-tab>

      <v-tab
        variant="text"
        hide-slider="hide-slider"
        class="fix-tab-bg"
        value="tab-2"
        @click="success=false; isNotInPasswordsTab=true"
      >
        Effort Rubric
        <v-icon
          v-if="effortRubricChanges"
          color="error"
          class="ml-8"
          icon="mdi-information-outline"
        />
      </v-tab>

      <v-tab
        variant="text"
        hide-slider="hide-slider"
        class="fix-tab-bg"
        value="tab-3"
        @click="success=false; isNotInPasswordsTab=true"
      >
        Effort Criteria
        <v-icon
          v-if="effortCriteriaChanges"
          class="ml-8"
          color="error"
          icon="mdi-information-outline"
        />
      </v-tab>

      <v-tab
        variant="text"
        hide-slider="hide-slider"
        class="fix-tab-bg"
        value="tab-4"
        @click="success=false; isNotInPasswordsTab=true"
      >
        Middle Manager Actions
      </v-tab>

      <v-tab
        variant="text"
        hide-slider="hide-slider"
        class="fix-tab-bg"
        value="tab-5"
        @click="success=false; isNotInPasswordsTab=false"
      >
        Users Passwords
        <v-icon
          color="blue"
          :class="{'showTick': middleManagerChanges, 'hideTick': !middleManagerChanges}"
        >
          info_outline
        </v-icon>
      </v-tab>
    </v-tabs>

    <v-window v-model="active">
      <v-window-item value="tab-1">
        <v-card>
          <v-card-text>
            <v-layout>
              <v-text-field
                v-model="welcomeMessage"
                label="Welcome Message"
                :variant="globalStore.textFieldVariant"
                @change="showPendingChangesAlert(1)"
              />
            </v-layout>
          </v-card-text>

          <v-card-text>Background Image</v-card-text>
          <v-container>
            <div class="dropbox">
              <input
                type="file"
                style="opacity: 0!important"
                multiple
                :name="uploadFieldNameBack"
                accept="image/*"
                class="input-file"
                @change="backgroundImageChange($event.target.name, $event.target.files);
                         fileCount = $event.target.files.length"
              >
              <p v-if="isBgInitial">
                Drag your file here to begin or click to browse
              </p>
              <p v-if="isBgSaving">
                Uploading files...
              </p>
              <p v-if="isBgSaved">
                New background image saved.
              </p>
            </div>
            <div
              v-if="backgroundImage"
              class="preview"
            >
              <img
                alt=""
                :src="backgroundImage"
                style="max-height: 200px; vertical-align: bottom; margin: 4px"
              >
              <v-btn
                color="default"
                @click.stop="deleteBackgroundImg()"
              >
                remove
              </v-btn>
            </div>
          </v-container>

          <v-card-text>
            Additional Logo
          </v-card-text>
          <v-container>
            <div class="dropbox">
              <input
                type="file"
                style="opacity: 0 !important"
                multiple
                :name="uploadFieldNameLogo"
                accept="image/*"
                class="input-file"
                @change="logoImageChanged($event.target.name, $event.target.files);
                         fileCount = $event.target.files.length"
              >
              <p v-if="isLogoInitial">
                Drag your file here to begin or click to browse
              </p>
              <p v-if="isLogoSaving">
                Uploading files...
              </p>
              <p v-if="isLogoSaved">
                New additional logo image saved.
              </p>
            </div>
            <div v-if="additionalLogo">
              <img
                alt=""
                :src="additionalLogo"
                style="max-height: 200px; vertical-align: bottom; margin: 4px"
              >
              <v-btn
                v-if="addLogoControl"
                color="default"
                @click.stop="removeAdditionalLogo()"
              >
                remove
              </v-btn>
            </div>
          </v-container>
        </v-card>
      </v-window-item>

      <v-window-item value="tab-2">
        <v-card flat>
          <v-card-text>
            <v-switch
              v-model="switchTeacherName"
              label="Show teacher's name on Student Efforts screen"
              color="primary"
              @change="showOrHide()"
            />
          </v-card-text>

          <v-card-text
            v-for="card in cards"
            :key="card.title"
          >
            <v-row class="ml-2">
              <h3>{{ card.title }}</h3>
            </v-row>
            <v-row>
              <v-col
                v-for="setting in card.settings"
                :key="setting.id"
              >
                <v-text-field
                  v-model="setting.button.text"
                  label="Text"
                  @change="saveSetting(setting.button.key, setting.button.text); showPendingChangesAlert(2)"
                />
                <v-textarea
                  v-model="setting.description.text"
                  height="10em"
                  label="Description"
                  @change="saveSetting(setting.description.key, setting.description.text); showPendingChangesAlert(2); "
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-window-item>

      <v-window-item value="tab-3">
        <v-card flat>
          <v-card-text>
            <h3>Criteria 1</h3>
            <v-text-field
              v-model="diligenceLabel"
              counter="15"
              maxlength="15"
              :variant="globalStore.textFieldVariant"
              @change="showPendingChangesAlert(3)"
            />
            <v-layout>
              <v-text-field
                v-model="diligenceSTD"
                label="Students Description"
                :variant="globalStore.textFieldVariant"
                @change="showPendingChangesAlert(3)"
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="diligenceTTD"
                :variant="globalStore.textFieldVariant"
                label="Teachers Description"
                @change="showPendingChangesAlert(3)"
              />
            </v-layout>
          </v-card-text>

          <v-card-text>
            <h3>Criteria 2</h3>
            <v-text-field
              v-model="engagementLabel"
              counter="15"
              :variant="globalStore.textFieldVariant"
              maxlength="15"
              @change="showPendingChangesAlert(3)"
            />
            <v-layout>
              <v-text-field
                v-model="engagementSTD"
                label="Students Description"
                :variant="globalStore.textFieldVariant"
                @change="showPendingChangesAlert(3)"
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="engagementTTD"
                label="Teachers Description"
                :variant="globalStore.textFieldVariant"
                @change="showPendingChangesAlert(3)"
              />
            </v-layout>
          </v-card-text>
          <v-card-text>
            <h3>Criteria 3</h3>
            <v-text-field
              v-model="behaviourLabel"
              counter="15"
              :variant="globalStore.textFieldVariant"
              maxlength="15"
              @change="showPendingChangesAlert(3)"
            />
            <v-layout>
              <v-text-field
                v-model="behaviourSTD"
                :variant="globalStore.textFieldVariant"
                label="Students Description"
                @change="showPendingChangesAlert(3)"
              />
            </v-layout>
            <v-layout>
              <v-text-field
                v-model="behaviourTTD"
                :variant="globalStore.textFieldVariant"
                label="Teachers Description"
                @change="showPendingChangesAlert(3)"
              />
            </v-layout>
          </v-card-text>
        </v-card>
      </v-window-item>

      <v-window-item value="tab-4">
        <v-card flat>
          <v-card-text>
            <v-switch
              v-model="deleteClass"
              label="Delete Class"
              color="primary"
              @change="changeSettingDeleteClass()"
            />

            <v-switch
              v-model="hideClass"
              label="Show or Hide Class"
              color="primary"
              @change="changeSettingHideClass()"
            />

            <v-switch
              v-model="inspectStudents"
              label="Manage Class Students"
              color="primary"
              @change="changeSettingInspectStudents()"
            />

            <v-switch
              v-model="manageTeachers"
              label="Manage Class Teachers"
              color="primary"
              @change="changeSettingManageTeachers()"
            />
          </v-card-text>
        </v-card>
      </v-window-item>

      <v-window-item value="tab-5">
        <v-container>
          <v-row>
            <v-col cols="lg-6 md-12">
              <v-card variant="flat">
                <v-card-text>
                  <v-container class="pa-0">
                    <v-row class="d-flex justify-center align-center">
                      <v-col cols="11">
                        <v-text-field
                          v-model="studentsDefaultPassword"
                          :rules="globalStore.passwordRules"
                          :append-inner-icon="studentsDefaultPasswordMask ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="studentsDefaultPasswordMask ? 'password' : 'text'"
                          label="Student Default Password"
                          hint="At least 6 characters"
                          :variant="globalStore.textFieldVariant"
                          required
                          @click="studentsDefaultPasswordMask = !studentsDefaultPasswordMask"
                          @change="showPendingChangesAlert(4)"
                        />
                      </v-col>
                      <v-col
                        cols="1"
                        class="pa-0"
                      >
                        <v-btn
                          icon="true"
                          class="align-self-center"
                          density="compact"
                          variant="plain"
                          @click="generatePassword(UserType.Student)"
                        >
                          <v-tooltip
                            activator="parent"
                            location="top"
                          >
                            Suggest secure password
                          </v-tooltip>
                          <v-icon
                            dark
                            size="small"
                            icon="mdi-lastpass"
                            class="align-self-center ml-4"
                          />
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-progress-linear
                    indeterminate
                    :active="loadingStudents"
                  />
                </v-card-text>
                <v-card-actions class="ma-0 pa-0 justify-center">
                  <v-btn
                    color="primary"
                    variant="elevated"
                    @click.stop="loadDefaultConfirmationDialog(UserType.Student)"
                  >
                    Save
                  </v-btn>
                  <v-btn
                    color="error"
                    variant="elevated"
                    @click.stop="loadResetConfirmationDialog(UserType.Student)"
                  >
                    Reset All Student Passwords
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col
              cols="lg-6 md-12"
              class="elevation-0"
            >
              <v-card variant="flat">
                <v-card-text>
                  <v-container class="pa-0">
                    <v-row class="d-flex justify-center align-center">
                      <v-col cols="11">
                        <v-text-field
                          v-model="teachersDefaultPassword"
                          :rules="globalStore.passwordRules"
                          :append-icon="teachersDefaultPasswordMask ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="teachersDefaultPasswordMask ? 'password' : 'text'"
                          label="Teacher Default Password"
                          hint="At least 6 characters"
                          :variant="globalStore.textFieldVariant"
                          @click="(teachersDefaultPasswordMask = !teachersDefaultPasswordMask)"
                          @change="showPendingChangesAlert(4)"
                        />
                      </v-col>

                      <v-col
                        cols="1"
                        class="pa-0"
                      >
                        <v-btn
                          icon="true"
                          class="align-self-center"
                          density="compact"
                          variant="plain"
                          @click="generatePassword(UserType.Teacher)"
                        >
                          <v-tooltip
                            activator="parent"
                            location="top"
                          >
                            Suggest secure password
                          </v-tooltip>
                          <v-icon
                            dark
                            size="small"
                            icon="mdi-lastpass"
                            class="align-self-center ml-4"
                          />
                        </v-btn>
                      </v-col>
                      <v-progress-linear
                        indeterminate
                        :active="loadingTeachers"
                      />
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="ma-0 pa-0 justify-center">
                  <v-btn
                    color="primary"
                    variant="elevated"
                    @click.stop="loadDefaultConfirmationDialog(UserType.Teacher)"
                  >
                    Save
                  </v-btn>
                  <v-btn
                    color="error"
                    variant="elevated"
                    @click.stop="loadResetConfirmationDialog(UserType.Teacher)"
                  >
                    Reset All Teacher Passwords
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
    </v-window>

    <v-dialog
      v-model="leaveUnsavedChangesDialog"
      persistent
      max-width="340"
    >
      <v-card>
        <v-card-title class="text-h5">
          Leave without saving?
        </v-card-title>
        <v-card-text>
          There are pending changes to save. If you leave without saving them, they will be lost.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            variant="text"
            @click="okPendingChanges(true)"
          >
            OK
          </v-btn>
          <v-btn
            color="error"
            variant="elevated"
            @click="okPendingChanges(false)"
          >
            Go Back to Save Changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rememberToSave"
      persistent
      max-width="340"
    >
      <v-card>
        <v-card-title class="text-h5">
          There are Pending Changes
        </v-card-title>
        <v-card-text>If you change the period without saving them, they will be lost.</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            variant="elevated"
            @click="okPendingChanges2()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="resetPasswordDialog"
      max-width="35%"
    >
      <v-card>
        <v-card-title>
          Confirmation
        </v-card-title>
        <v-card-text>
          This action will &nbsp; <span class="dialogWarningColor"> RESET </span> &nbsp; all
          {{ resetOption }} passwords to Default.
        </v-card-text>
        <v-card-text>
          <v-layout
            row
            wrap
          >
            <i>Note: Please be patient, it can take a while.</i>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            flat
            @click.stop="unloadResetConfirmationDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            variant="elevated"
            @click.stop="resetPasswords('')"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="changePasswordDialog"
      max-width="35%"
    >
      <v-card>
        <v-card-title>
          Confirmation
        </v-card-title>
        <v-card-text>
          <v-layout
            row
            wrap
          >
            This action will &nbsp; <span style="color: #b71c1c !important;"><b> CHANGE </b></span> &nbsp; the
            {{ defaultOption }} default password.
          </v-layout>
          <v-layout
            row
            wrap
          >
            <v-switch
              v-model="execResetDefaultPassword"
              color="primary"
              label="Change the default password of those who have not changed it yet (Please be patient, it can take a while)"
            />
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            flat
            @click.stop="unloadDefaultConfirmationDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            variant="elevated"
            @click.stop="saveDefaultPasswords(defaultOption)"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>

import {
  initialize,
} from '@/utils/effort-tracking-api';

import {
  getGradingButtonSettings,
  updateSetting,
  getSettingByKey,
  removeImage,
  bulkUpdateSetting,
  saveImage,
  getSettingsContainingKey,
} from '@/api/settingsApi';
import {updateEffortTabs, getEffortTabs} from '@/api/effortsApi';
import {globalStore} from '@/main';
import {generateRandomPassword} from '@/utils/passwordService';
import {store} from '@/store';
import {Defaults, Setting, UserType} from '@/utils/settings';
import {resetAllPasswordsForUserType} from '@/api/accountsApi';

export default {
  name: 'SettingsComponent',
  data() {
    return {
      switchTeacherName: false,
      selectedPeriodId: localStorage.getItem('period-selected'),
      uploadFieldNameBack: 'BackgroundImage',
      uploadFieldNameLogo: 'AdditionalLogo',
      welcomeMessage: '',
      isBgInitial: true,
      isBgSaving: false,
      isBgSaved: false,
      isLogoInitial: true,
      isLogoSaving: false,
      isLogoSaved: false,
      active: null,
      backgroundImage: '',
      additionalLogo: '',
      bgImageId: 0,
      addLogoId: 0,
      addLogoControl: false,
      success: false,
      tabName: '',
      error: false,
      pendingChanges: false,
      pendingLoginTabChanges: false,
      effortRubricChanges: false,
      effortCriteriaChanges: false,
      middleManagerChanges: false,
      isNotInPasswordsTab: true,
      diligenceLabel: '',
      diligenceSTD: '',
      diligenceTTD: '',
      engagementLabel: '',
      engagementSTD: '',
      engagementTTD: '',
      behaviourLabel: '',
      behaviourSTD: '',
      behaviourTTD: '',
      diligenceButtons: [],
      diligenceDescriptions: [],
      engagementButtons: [],
      engagementDescriptions: [],
      behaviourButtons: [],
      behaviourDescriptions: [],
      savedSettings: [],
      defaultCards: [],
      defaultButtons: [],
      widgetId: '',
      btnsavewidgetId: true,
      cards: [
        {
          title: 'Diligence', flex: 12,
          buttons: [
            {id: '0', text: '1 - UNSATISFACTORY', key: 'd-grade-button-text-1'},
            {id: '1', text: '2 - FAIR', key: 'd-grade-button-text-2'},
            {id: '2', text: '3 - GOOD', key: 'd-grade-button-text-3'},
            {id: '4', text: '4 - VERY GOOD', key: 'd-grade-button-text-4'},
            {id: '5', text: '5 - OUTSTANDING', key: 'd-grade-button-text-5'}
          ],
          descriptions: [
            {id: '6', text: '', key: 'd-grade-button-description-1'},
            {id: '7', text: '', key: 'd-grade-button-description-2'},
            {id: '8', text: '', key: 'd-grade-button-description-3'},
            {id: '9', text: '', key: 'd-grade-button-description-4'},
            {id: '10', text: '', key: 'd-grade-button-description-5'}
          ]
        },
        {
          title: 'Engagement', flex: 12,
          buttons: [
            {id: '11', text: '1 - UNSATISFACTORY', key: 'e-grade-button-text-1'},
            {id: '12', text: '2 - FAIR', key: 'e-grade-button-text-2'},
            {id: '13', text: '3 - GOOD', key: 'e-grade-button-text-3'},
            {id: '14', text: '4 - VERY GOOD', key: 'e-grade-button-text-4'},
            {id: '15', text: '5 - OUTSTANDING', key: 'e-grade-button-text-5'}
          ],
          descriptions: [
            {id: '16', text: '', key: 'e-grade-button-description-1'},
            {id: '17', text: '', key: 'e-grade-button-description-2'},
            {id: '18', text: '', key: 'e-grade-button-description-3'},
            {id: '19', text: '', key: 'e-grade-button-description-4'},
            {id: '20', text: '', key: 'e-grade-button-description-5'}
          ]
        },
        {
          title: 'Behaviour', flex: 12,
          buttons: [
            {id: '21', text: '1 - UNSATISFACTORY', key: 'b-grade-button-text-1'},
            {id: '22', text: '2 - FAIR', key: 'b-grade-button-text-2'},
            {id: '23', text: '3 - GOOD', key: 'b-grade-button-text-3'},
            {id: '24', text: '4 - VERY GOOD', key: 'b-grade-button-text-4'},
            {id: '25', text: '5 - OUTSTANDING', key: 'b-grade-button-text-5'}
          ],
          descriptions: [
            {id: '26', text: '', key: 'b-grade-button-description-1'},
            {id: '27', text: '', key: 'b-grade-button-description-2'},
            {id: '28', text: '', key: 'b-grade-button-description-3'},
            {id: '29', text: '', key: 'b-grade-button-description-4'},
            {id: '30', text: '', key: 'b-grade-button-description-5'}
          ]
        }
      ],
      deleteClass: false,
      hideClass: false,
      inspectStudents: false,
      manageTeachers: false,
      middleManagerSettings: [],

      toRedirect: 'null',
      rememberToSave: false,
      cachePeriods: [],
      cacheToken: '',
      cacheUsertype: '',
      cacheUserdata: null,
      resetOption: '',
      leaveUnsavedChangesDialog: false,
      resetPasswordDialog: false,
      changePasswordDialog: false,
      defaultOption: '',
      studentsDefaultPassword: '',
      teachersDefaultPassword: '',
      loadingTeachers: false,
      loadingStudents: false,
      studentsDefaultPasswordMask: true,
      teachersDefaultPasswordMask: true,
      firstWelcomeMsg: '',
      execResetDefaultPassword: false
    };
  },
  computed: {
    UserType() {
      return UserType;
    },
    globalStore() {
      return globalStore;
    }
  },
  watch: {
    store: {
      handler() {
        if(store.state.settingsHasChanges) {
          this.leaveUnsavedChangesDialog = true;
        }
      },
    },
    additionalLogo(val) {
      this.addLogoControl = (val !== '');
    },
    bgImageId(val) {
      this.bgImageId = val;
    },
    widgetId() {
      const errors = document.getElementsByClassName('v-messages error--text');
      this.btnsavewidgetId = errors.length > 0;
    },
    pendingChanges() {
      this.rememberToSave = false;
    },
    diligenceLabel() {
      this.cards[0].title = this.diligenceLabel;
    },
    engagementLabel() {
      this.cards[1].title = this.engagementLabel;
    },
    behaviourLabel() {
      this.cards[2].title = this.behaviourLabel;
    }
  },
  mounted() {
    console.log('on Settings mounted...');
    initialize()
      .then(() => {
        this.defaultCards = this.cards;

        getSettingByKey(Setting.WelcomeText)
          .then(setting => {
            this.welcomeMessage = (setting.value !== undefined)
                                  ? setting.value
                                  : '';
            this.firstWelcomeMsg = this.welcomeMessage;
          })
          .catch(ex => console.log(ex));

        getEffortTabs()
          .then(tabs => {
            const diligence = tabs.filter(tb => tb.name === 'DILIGENCE')[0];
            this.diligenceLabel = (diligence.customName !== '')
                                  ? diligence.customName
                                  : diligence.name;
            this.diligenceSTD = diligence.studentDescription;
            this.diligenceTTD = diligence.teacherDescription;

            const engagement = tabs.filter(tb => tb.name === 'ENGAGEMENT')[0];
            this.engagementLabel = (engagement.customName !== '')
                                   ? engagement.customName
                                   : engagement.name;
            this.engagementSTD = engagement.studentDescription;
            this.engagementTTD = engagement.teacherDescription;

            const behaviour = tabs.filter(tb => tb.name === 'BEHAVIOUR')[0];
            this.behaviourLabel = (behaviour.customName !== '')
                                  ? behaviour.customName
                                  : behaviour.name;
            this.behaviourSTD = behaviour.studentDescription;
            this.behaviourTTD = behaviour.teacherDescription;

          })
          .catch(ex => console.log(ex));

        getSettingByKey(Setting.WidgetId)
          .then(setting => {
            this.widgetId = setting.value !== undefined
                            ? setting.value
                            : '';
          })
          .catch(ex => console.log(ex));

        getSettingsContainingKey(Setting.MiddleManager)
          .then(settings => {
            if(settings.length > 0) {
              this.middleManagerSettings = settings;
              const deleteClass = settings.filter(st => st.key === "middle-manager-delete-class");
              console.log('deleteClass', deleteClass)
              if(deleteClass.length > 0) {
                this.deleteClass = deleteClass[0].value === 'true';
              }

              const hideClass = settings.filter(st => st.key === "middle-manager-hide-class");
              if(hideClass.length > 0) {
                this.hideClass = hideClass[0].value === 'true';
              }

              const inspectStudents = settings.filter(st => st.key === "middle-manager-inspect-students");
              console.log('inspectStudents', inspectStudents)
              if(inspectStudents.length > 0) {
                this.inspectStudents = inspectStudents[0].value === 'true';
              }

              const manageTeachers = settings.filter(st => st.key === "middle-manager-manage-teachers");
              if(manageTeachers.length > 0) {
                this.manageTeachers = manageTeachers[0].value === 'true';
              }
            }
          })
          .catch(ex => console.log(ex));

        getSettingByKey(Setting.StudentsDefaultPassword)
          .then(setting => {
            this.studentsDefaultPassword = setting.value !== undefined
                                           ? setting.value
                                           : Defaults.StudentsDefaultPassword;
          })
          .catch(ex => console.log(ex));

        getSettingByKey(Setting.TeachersDefaultPassword)
          .then(setting => {
            this.teachersDefaultPassword = setting.value !== undefined
                                           ? setting.value
                                           : Defaults.TeachersDefaultPassword;
          })
          .catch(ex => console.log(ex));

        getSettingByKey(Setting.AdditionalLogo)
          .then(result => {
            this.additionalLogo = result.value;
          })
          .catch(ex => console.log(ex));

        getSettingByKey(Setting.BackgroundImageUrl)
          .then(result => {
            this.backgroundImage = result.value;
          });

        getSettingByKey(Setting.ShowTeacherNameInEffortGrades)
          .then(result => {
            this.switchTeacherName = JSON.parse(result.value.toLowerCase());
            this.getEffortCriteriaSettings();
          })
          .catch(ex => console.log(ex));

      });
  },
  beforeMount() {

    //get State Information
    this.$store.state.periods.forEach(p => {
      this.cachePeriods.push(p);
    });
    this.cacheToken = this.$store.state.token;
    this.cacheUsertype = this.$store.state.usertype;
    this.cacheUserdata = this.$store.state.userdata;
  },
  methods: {
    backgroundImageChange(name, files) {
      this.isBgInitial = false;
      this.isBgSaving = true;
      let formData = new FormData();
      formData.append('settingsKey', Setting.BackgroundImageUrl);
      formData.append('settingsValue', files[0]);
      saveImage(Setting.BackgroundImageUrl, formData)
        .then(() => {
          this.isBgSaving = false;
          this.isBgSaved = true;
          getSettingByKey(Setting.BackgroundImageUrl)
            .then(result => {
              console.log(result);
              this.backgroundImage = result.backgroundImage;
              setTimeout(() => {
                this.isBgSaved = false;
                this.isBgInitial = true;
              }, 5000);
            })
            .catch(ex => console.log(ex));
        })
        .catch(ex => console.log(ex));
    },
    logoImageChanged(name, files) {
      this.isLogoInitial = false;
      this.isBgSaving = true;
      let formData = new FormData();
      formData.append('settingsKey', Setting.AdditionalLogo);
      formData.append('settingsValue', files[0]);
      saveImage(Setting.AdditionalLogo, formData)
        .then(() => {
          this.isLogoSaving = false;
          this.isLogoSaved = true;

          getSettingByKey(Setting.AdditionalLogo)
            .then(result => {
              this.additionalLogo = result.value;
              setTimeout(() => {
                this.isLogoSaved = false;
                this.isLogoInitial = true;
              }, 5000);
            })
            .catch(ex => console.log(ex));
        })
        .catch(ex => console.log(ex));
    },
    saveActiveCardSettings() {
      switch(this.active) {
        case 'tab-1':
          this.saveWelcomeMessage();
          break;
        case 'tab-2':
          this.saveAllSettings();
          break;
        case 'tab-3':
          this.saveTabsSettings();
          break;
      }
    },
    showPendingChangesAlert(option) {
      switch(option) {
        case 1:
          if(this.firstWelcomeMsg !== this.welcomeMessage) {
            this.pendingLoginTabChanges = true;
          }
          break;
        case 2:
          this.effortRubricChanges = true;
          break;
        case 3:
          this.effortCriteriaChanges = true;
          break;
        case 4:
          this.middleManagerChanges = true;
          break;
      }

      if(this.pendingLoginTabChanges
        || this.effortRubricChanges
        || this.effortCriteriaChanges
        || this.middleManagerChanges) {
        store.commit('hasChanged');
        this.pendingChanges = true;
      }
    },
    hidePendingChangesAlert() {
      if(!this.pendingLoginTabChanges &&
          !this.effortRubricChanges &&
          !this.effortCriteriaChanges &&
          !this.middleManagerChanges) {
        this.pendingChanges = false;
      }
    },
    saveWelcomeMessage() {
      updateSetting({
                      key: Setting.WelcomeText,
                      value: this.welcomeMessage
                    })
        .then(result => {
          this.tabName = 'Login page settings';
          this.success = true;

          this.pendingLoginTabChanges = false;
          this.hidePendingChangesAlert();
          console.log(result);
        })
        .catch(ex => {
          console.log(ex);
          this.error = true;
        });
    },
    saveSetting(key, model) {
      this.defaultCards.forEach(element => {
        element.buttons.forEach(eb => {
          this.defaultButtons.push(eb);
        });
      });
      const defaultSetting = this.defaultButtons.filter(df => df.key === key);
      if(model === '' && key.indexOf('text') !== -1) {
        model = defaultSetting[0].text;
      }

      this.savedSettings.push(key + ':' + model);
    },
    saveAllSettings() {
      const rows = this.generateRows(this.savedSettings);
      const g_jsonSettings = JSON.stringify(rows);
      const g_settings = JSON.stringify({Settings: g_jsonSettings});
      console.log('g_settings', g_settings);
      bulkUpdateSetting(g_settings)
        .then(result => {
          if(result.status === 200) {
            this.tabName = 'Effort Rubric settings';
            this.success = true;
            this.effortRubricChanges = false;
            this.hidePendingChangesAlert();
          }
          else {
            this.error = true;
          }
        })
        .catch(ex => {
          console.log(ex);
          this.error = true;
        });

    },
    saveDefaultSettings() {
      const innerSettings = [];
      this.success = false;
      this.hidePendingChangesAlert();
      this.error = false;
      this.cards.forEach(card => {
        card.buttons.forEach(btn => {
          innerSettings.push(btn.key + ':' + btn.text);
        });
        card.descriptions.forEach(desc => {
          innerSettings.push(desc.key + ':' + desc.text);
        });
      });

      const rows = this.generateRows(innerSettings);
      const g_jsonSettings = JSON.stringify(rows);
      const g_settings = JSON.stringify({
                                          Settings: g_jsonSettings
                                        });

      bulkUpdateSetting(g_settings)
        .then(() => {
          this.getEffortCriteriaSettings();
        })
        .catch(() => {
          this.error = true;
        });
    },
    generateRows(columnsResult) {
      const jsonData = {};
      columnsResult.forEach(function(column) {
        const data = column.split(':');
        jsonData[data[0]] = data[1];
      });
      return jsonData;
    },
    saveTabsSettings() {
      this.success = false;
      this.error = false;
      const settings = {
        'diligence-label': this.diligenceLabel,
        'diligence-student': this.diligenceSTD,
        'diligence-teacher': this.diligenceTTD,
        'engagement-label': this.engagementLabel,
        'engagement-student': this.engagementSTD,
        'engagement-teacher': this.engagementTTD,
        'behaviour-label': this.behaviourLabel,
        'behaviour-student': this.behaviourSTD,
        'behaviour-teacher': this.behaviourTTD
      };

      const jsonSettings = JSON.stringify(settings);
      const _settings = JSON.stringify({
                                         Settings: jsonSettings
                                       });

      updateEffortTabs(_settings)
        .then(result => {
          if(result.status === 200) {
            this.tabName = 'Effort Critera settings';
            this.success = true;
            this.effortCriteriaChanges = false;
            this.hidePendingChangesAlert();
          }
          else {
            this.error = true;
          }
        })
        .catch(() => {
          this.error = true;
        });
    },
    unloadDefaultConfirmationDialog() {
      this.changePasswordDialog = false;
      this.defaultOption = '';
      this.execResetDefaultPassword = false;
    },
    loadDefaultConfirmationDialog(option) {
      this.changePasswordDialog = true;
      this.defaultOption = option;
    },
    saveDefaultPasswords(option) {
      this.success = false;
      this.error = false;
      const changeDefaultPasswords = this.execResetDefaultPassword;
      let prevDefaultPassword = '';
      let settings = {};
      settings = option === UserType.Student
                 ? {'students-default-password': this.studentsDefaultPassword}
                 : {'teachers-default-password': this.teachersDefaultPassword};


      const jsonSettings = JSON.stringify(settings);
      const _settings = JSON.stringify({Settings: jsonSettings});

      if(changeDefaultPasswords) {
        if(option === UserType.Student) {
          getSettingByKey(Setting.StudentsDefaultPassword)
            .then(setting => {
              prevDefaultPassword = setting.value !== undefined
                                    ? setting.value
                                    : Defaults.StudentsDefaultPassword;
            })
            .catch(ex => console.log(ex));
        }

        if(option === UserType.Teacher) {
          getSettingByKey(Setting.TeachersDefaultPassword)
            .then(setting => {
              prevDefaultPassword = setting.value !== undefined
                                    ? setting.value
                                    : Defaults.TeachersDefaultPassword;
            })
            .catch(ex => console.log(ex));
        }
      }

      bulkUpdateSetting(_settings)
        .then(() => {
          this.success = true;
          this.middleManagerChanges = false;
          this.hidePendingChangesAlert();
          this.unloadDefaultConfirmationDialog();

          if(changeDefaultPasswords) {
            this.resetOption = option;
            this.resetPasswords(prevDefaultPassword);
          }

        })
        .catch(() => {
          this.error = true;
          this.unloadDefaultConfirmationDialog();
        });
    },
    showOrHide() {
      updateSetting({
                      key: Setting.ShowTeacherNameInEffortGrades,
                      value: String(this.switchTeacherName)
                    })
        .then(() => {
        })
        .catch(ex => console.log(ex));
    },
    deleteBackgroundImg() {
      removeImage(Setting.BackgroundImageUrl)
        .then(() => {
          this.backgroundImage = '';
          this.bgImageId = 0;
        })
        .catch(ex => console.log(ex));
    },
    removeAdditionalLogo() {
      removeImage(Setting.AdditionalLogo)
        .then(() => {
          this.additionalLogo = '';
          localStorage.setItem('additional-logo', '');
          this.addLogoId = 0;
        })
        .catch(ex => console.log(ex));
    },
    getEffortCriteriaSettings() {
      getGradingButtonSettings()
        .then(settingsCards => {
          if(settingsCards.diligenceTexts.length === 0) {
            this.saveDefaultSettings();
          }
          else {
            this.cards = [];
          }

          // Card Diligence
          if(settingsCards.diligenceTexts.length > 0) {
            this.diligenceButtons = [];
            this.diligenceDescriptions = [];
            settingsCards.diligenceTexts.forEach(element => {
              const button = {
                id: element.id,
                text: element.value,
                key: element.key
              };
              this.diligenceButtons.push(button);
            });

            settingsCards.diligenceDescriptions.forEach(element => {
              const description = {
                id: element.id,
                text: element.value,
                key: element.key
              };
              this.diligenceDescriptions.push(description);
            });

            const dSettings = [];
            this.diligenceButtons.forEach((element, index) => {
              dSettings.push({
                               id: 'ds_' + index,
                               button: element,
                               description: this.diligenceDescriptions[index]
                             });
            });

            const diligenceCard = {
              title: this.diligenceLabel, flex: 12,
              settings: dSettings
            };

            this.cards.push(diligenceCard);
          }

          // Card Engagement
          if(settingsCards.engagementTexts.length > 0) {
            this.engagementeButtons = [];
            this.engagementDescriptions = [];
            settingsCards.engagementTexts.forEach(element => {
              const button = {
                id: element.id,
                text: element.value,
                key: element.key
              };
              this.engagementeButtons.push(button);
            });

            settingsCards.engagementDescriptions.forEach(element => {
              const description = {
                id: element.id,
                text: element.value,
                key: element.key
              };
              this.engagementDescriptions.push(description);
            });

            const eSettings = [];
            this.engagementeButtons.forEach((element, index) => {
              eSettings.push({
                               id: 'es_' + index,
                               button: element,
                               description: this.engagementDescriptions[index]
                             });
            });

            const engagementCard = {
              title: this.engagementLabel, flex: 12,
              settings: eSettings
            };

            this.cards.push(engagementCard);
          }

          // Card behaviour
          if(settingsCards.behaviourTexts.length > 0) {
            this.behaviourButtons = [];
            this.behaviourDescriptions = [];
            settingsCards.behaviourTexts.forEach(element => {
              const button = {
                id: element.id,
                text: element.value,
                key: element.key
              };
              this.behaviourButtons.push(button);
            });

            settingsCards.behaviourDescriptions.forEach(element => {
              const description = {
                id: element.id,
                text: element.value,
                key: element.key
              };
              this.behaviourDescriptions.push(description);
            });

            const bSettings = [];
            this.behaviourButtons.forEach((element, index) => {
              bSettings.push({
                               id: 'es_' + index,
                               button: element,
                               description: this.behaviourDescriptions[index]
                             });
            });

            const behaviourCard = {
              title: this.behaviourLabel, flex: 12,
              settings: bSettings
            };

            this.cards.push(behaviourCard);
          }
        })
        .catch(ex => console.log(ex));
    },
    changeSettingDeleteClass() {
      updateSetting({
        key: Setting.MiddleManagerDeleteClass,
        value: String(this.deleteClass)})
        .then(() => {
        })
        .then(() => {

        });
    },
    changeSettingHideClass() {
      updateSetting({
        key: Setting.MiddleManagerHideClass,
        value: String(this.hideClass)})
        .then(result => {
          console.log(result);
        });
    },
    changeSettingInspectStudents() {
      updateSetting({
        key: Setting.MiddleManagerInspectStudents,
        value: String(this.inspectStudents)})
        .then(() => {
        });
    },
    changeSettingManageTeachers() {
      updateSetting({
        key: Setting.MiddleManagerManageTeachers,
        value: String(this.manageTeachers)})
        .then(() => {
        });
    },
    okPendingChanges(answer) {
      this.leaveUnsavedChangesDialog = false;

      if(!answer) {
        if(this.$store.state.userdata == null) {
          this.cachePeriods.forEach(p => {
            this.$store.state.periods.push(p);
          });
          this.$store.state.token = this.cacheToken;
          this.$store.state.usertype = this.cacheUsertype;
          this.$store.state.userdata = this.cacheUserdata;
        }
      }
      else {
        this.pendingChanges = false;
        this.$router.push({name: this.toRedirect.name});
      }
    },
    okPendingChanges2() {
      this.rememberToSave = false;
    },
    unloadResetConfirmationDialog() {
      this.resetPasswordDialog = false;
      this.resetOption = '';
    },
    loadResetConfirmationDialog(option) {
      this.resetPasswordDialog = true;
      this.resetOption = option;
    },
    resetPasswords(prevDefaultPassword) {
      this.resetPasswordDialog = false;

      switch(this.resetOption) {
        case UserType.Student:
          this.loadingStudents = true;
          break;
        case UserType.Teacher:
          this.loadingTeachers = true;
          break;
      }

      let model = {
        UserType: this.resetOption,
        PreviousPassword: prevDefaultPassword};

      resetAllPasswordsForUserType(model)
        .then(result => {
          switch(this.resetOption) {
            case UserType.Student:
              this.loadingStudents = false;
              break;
            case UserType.Teacher:
              this.loadingTeachers = false;
              break;
          }

          this.resetOption = '';

          if(result.status === 200) {
            this.success = true;
          }
          else {
            this.error = true;
          }
        });
    },
    generatePassword(option) {
      if(option === 'Student') {
        this.studentsDefaultPassword = generateRandomPassword();
        this.studentsDefaultPasswordMask = false;
      }
      else {
        this.teachersDefaultPassword = generateRandomPassword();
        this.teachersDefaultPasswordMask = false;
      }
    }
  },
};
</script>
<style>
.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: white;
  color: dimgray;
  padding: 10px 10px;
  max-height: 3.5em;
  position: relative;

}

.input-file {
  opacity: 0;
  width: 100%;
  height: 4em;
  position: absolute;
}

.dropbox:hover {
  background: lightblue;
}

.dropbox p {
  text-align: center;
  padding: 0.5em 0;
}

.preview {
  max-height: 20em;
  width: 100% !important;
  vertical-align: middle !important;
}

.v-input.btnGrade.v-text-field {
  height: 4em;
}

.flex.xs3 {
  max-width: 20% !important;
}


</style>
