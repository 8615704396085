<template>
  <div>
    <h2>Loading user information, please wait...</h2>
  </div>
</template>
  
  <script>
  import userManager from '../utils/authService'
  
  export default {

    data() {
      return {
        user: null,
      };
    },

    mounted() {

      userManager.signinRedirectCallback()
         .then(() => {
            // Authentication successful, redirect to desired route
            console.log("routing after callback");
           
            this.getUserInfo();

           
           
            this.$router.push('/');



         })
         .catch((error) => {
           console.log("error on callback");
           console.error('Error during signinRedirectCallback:', error);
      });


      //this.getUserInfo();
    },
    methods: {
      getUserInfo() {
        userManager.getUser()
          .then((user) => {
            //this.user = user;
            console.log('User info after SSO login: ');
            console.log(user);

            // let _tokenUrl = 'https://cosource-id.cloudworkengine.net/api/oauth/token';
            // getSsoDataFromUrl(_tokenUrl).then(result => {
            //   'TOKEN DATA RESULT: ';
            //   console.log(result);
            // });
            

            // this.$store.state.usertype = "admin";
            // this.$store.state.token = user.id_token;

            // let userData =
            // {
            //     "firstName": user.profile.given_name,
            //     "lastName": user.profile.family_name,
            //     "email": user.profile.email,
            //     "id": ""
            //   };

              this.$store.commit('setSsoUserData', user);

            // this.$store.state.userdata.firstName = user.profile.given_name;
            // this.$store.state.userdata.lastName = user.profile.family_name;
            // this.$store.state.userdata.email = user.profile.email;


            console.log('Saved user info: ');
            console.log(this.$store.state);
          })
          .catch((error) => {
            console.error('Error retrieving user information:', error);
          });
      },
    },

  };
  </script>
  