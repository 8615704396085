<template>
  <v-card>
    <v-toolbar class="elevation1">
      <v-toolbar-title>
        {{ teacher }} Grade Summary
      </v-toolbar-title>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-inner-icon="mdi-magnify"
        label="Search"
        density="compact"
        class="search"
        variant="solo"
      />
      <grade-summary-downloader
        :usertype="'teacher'"
        :user-id="teacherId"
      />
    </v-toolbar>
    <v-container
      class="pa-0 ma-0 mw-100 w-100"
    >
      <k-grid
        ref="grid"
        class="teachersEffortGrid gridFullHeight"
        :data-items="gridData.items"
        :sort="sort"
        :sortable="true"
        :skip="serverFilters.skip"
        :take="serverFilters.take"
        :total="gridData.totalItems"
        :pageable="pageable"
        :page-size="globalStore.defaultRowsPerPage"
        :columns="gridData.columns"
        :loader="loader"
        @pagechange="pageChangeHandler"
        @sortchange="sortChangeHandler"
      >
        <template #loader>
          <div class="k-loader-container k-loader-container-md k-loader-top">
            <div class="k-loader-container-overlay k-overlay-dark" />
            <div class="k-loader-container-inner">
              <k-loader
                :size="'large'"
                :type="'pulsing'"
                :theme-color="'success'"
              />
            </div>
          </div>
        </template>
      </k-grid>
    </v-container>
  </v-card>
</template>

<script>

import {getTeacherEffortsByPeriod} from '@/api/teachersApi';
import {getGradingTabSettings} from '@/api/settingsApi';
import GradeSummaryDownloader from '../shared/grading/GradeSummaryDownloader';
import {globalStore} from '@/main';
import {debounce} from 'lodash';
import {Grid as KGrid} from '@progress/kendo-vue-grid';
import {Loader as KLoader} from '@progress/kendo-vue-indicators';


export default {
  name: 'TeachersGradeSummary',
  components: {
    GradeSummaryDownloader,
    KLoader,
    KGrid,
  },
  data() {
    return {
      teacherId: 0,
      teacher: '',
      loading: false,

      diligenceLabel: '',
      engagementLabel: '',
      behaviourLabel: '',

      search: '',
      // Does not work as a child property.
      sort: [{field: 'class', dir: 'asc'}],
      loader: false,
      serverFilters: {
        sortBy: 'class',
        filter: '',
        take: globalStore.defaultRowsPerPage,
        skip: 0,
        descending: false
      },
      pagerValues: {
        pagerType: globalStore.pagerTypes['numeric'],
        pagerButtons: globalStore.pagerButtons,
        pagerInfo: globalStore.pagerInfo,
        pagerSizes: globalStore.pagerSizes,
        pagePrevNext: globalStore.pagerSizes,
        pageSize: globalStore.defaultRowsPerPage,
        pageSizeValue: globalStore.defaultRowsPerPage,
      },
      gridData: {
        columns: [
          {field: 'className', title: 'Class', class: 'student'},
          {field: 'studentName', title: 'Student'},
          {
            title: 'Diligence', children: [
              {
                field: 'diligenceStudent',
                title: 'Student Graded',
                sortable: false,
                class: 'student'
              },
              {
                field: 'diligenceTeacher',
                title: 'Teacher Graded',
                sortable: false,
                class: 'teacher'
              },
            ]
          },
          {
            title: 'Engagement', children: [
              {
                field: 'engagementStudent',
                title: 'Student Graded',
                sortable: false,
                class: 'student'
              },
              {
                field: 'engagementTeacher',
                title: 'Teacher Graded',
                sortable: false,
                class: 'teacher'
              },
            ]
          },
          {
            title: 'Behaviour', children: [
              {
                field: 'behaviourStudent',
                title: 'Student Graded',
                sortable: false,
                class: 'student'
              },
              {
                field: 'behaviourTeacher',
                title: 'Teacher Graded',
                sortable: false,
                class: 'teacher'
              },
            ]
          },
        ],
        items: [],
        totalItems: 0,
      },
    };
  },
  computed: {
    globalStore() {
      return globalStore;
    },
    pageable() {
      return {
        buttonCount: this.pagerValues.pagerButtons,
        info: this.pagerValues.pagerInfo,
        type: this.pagerValues.pagerType,
        pageSizes: this.pagerValues.pagerSizes
                   ? globalStore.allowedRowsPerPage
                   : undefined,
        previousNext: this.pagerValues.pagePrevNext,
        pageSizeValue: this.pagerValues.pageSizeValue,
      };
    },
  },
  watch: {
    search: {
      handler() {
        this.debouncedGetDataFromApi();
      },
    },
  },

  created() {
    this.debouncedGetDataFromApi = debounce(() => {
      this.getDataFromApi();
    }, 500);

    this.teacherId = this.$route.params.teacherId;
    const teacherData = this.$route.params.teacher;

    if(teacherData !== undefined) {
      this.teacher = teacherData.firstName;
      if(teacherData.lastName !== undefined) {
        this.teacher += ' ' + teacherData.lastName;
      }

      this.teacher += ' - ';
    }
    this.debouncedGetDataFromApi();
  },
  mounted() {

    getGradingTabSettings()
      .then(
        tabs => {
          const diligence = tabs.filter(tb => tb.name === 'DILIGENCE')[0];
          this.diligenceLabel = (diligence.customName !== '')
                                ? diligence.customName
                                : diligence.name;

          const engagement = tabs.filter(tb => tb.name === 'ENGAGEMENT')[0];
          this.engagementLabel = (engagement.customName !== '')
                                 ? engagement.customName
                                 : engagement.name;

          const behaviour = tabs.filter(tb => tb.name === 'BEHAVIOUR')[0];

          this.behaviourLabel = (behaviour.customName !== '')
                                ? behaviour.customName
                                : behaviour.name;
        })
      .catch(ex => console.log(ex));
  },
  methods: {
    sortChangeHandler: function(e) {
      if(e.sort.length === 0) {
        this.serverFilters.descending = false;
        this.debouncedGetDataFromApi();
        this.sort = e.sort;
        return;
      }
      this.serverFilters.descending = e.sort[0].dir !== 'asc';
      this.serverFilters.sortBy = e.sort[0].field;
      this.debouncedGetDataFromApi();
      this.sort = e.sort;
    },
    pageChangeHandler: function(event) {
      console.log('event', event);
      this.pagerValues.pageSize = event.page.take;
      this.pagerValues.pageSizeValue = event.page.take;
      this.serverFilters.skip = event.page.skip;
      this.serverFilters.take = event.page.take;
      this.debouncedGetDataFromApi();
    },
    getDataFromApi() {
      this.loader = true;
      this.serverFilters.filter = this.search;
      getTeacherEffortsByPeriod(globalStore.selectedPeriod, this.teacherId, this.serverFilters)
        .then(
          efforts => {
            console.log('efforts', efforts)
            this.gridData.totalItems = efforts.count;
            this.gridData.items = efforts.items;
            this.loader = false;
          })
        .catch(ex => {
          console.log(ex);
          this.loading = false;
        });
    }
  }
};
</script>
<style>
.border-tab {
  border: solid 1px #a9a5a5;
}

.teachersEffortGrid td[data-grid-col-index="3"],
.teachersEffortGrid td[data-grid-col-index="6"],
.teachersEffortGrid td[data-grid-col-index="9"] {
  background-color: rgba(211, 211, 211, 0.5);
  text-align: center;
}

.teachersEffortGrid td[data-grid-col-index="4"],
.teachersEffortGrid td[data-grid-col-index="7"],
.teachersEffortGrid td[data-grid-col-index="10"] {
  background-color: rgba(211, 232, 211, 0.5);
  text-align: center;
}

.teachersEffortGrid .k-grid-header col:nth-of-type(1),
.teachersEffortGrid .k-grid-table col:nth-of-type(1) {
  width: 20%;
}

.teachersEffortGrid .k-grid-header col:nth-of-type(2),
.teachersEffortGrid .k-grid-table col:nth-of-type(2) {
  width: 20%;
}

.teachersEffortGrid th[role="columnheader"][rowspan="1"][colspan="1"]:nth-of-type(1),
.teachersEffortGrid th[role="columnheader"][rowspan="1"][colspan="1"]:nth-of-type(3),
.teachersEffortGrid th[role="columnheader"][rowspan="1"][colspan="1"]:nth-of-type(5) {
  background-color: rgba(211, 211, 211, 0.5);
}

.teachersEffortGrid th[role="columnheader"][rowspan="1"][colspan="1"]:nth-of-type(2),
.teachersEffortGrid th[role="columnheader"][rowspan="1"][colspan="1"]:nth-of-type(4),
.teachersEffortGrid th[role="columnheader"][rowspan="1"][colspan="1"]:nth-of-type(6) {
  background-color: rgba(211, 232, 211, 0.5);
}

.teachersEffortGrid .k-grid-header col:nth-of-type(3),
.teachersEffortGrid .k-grid-table col:nth-of-type(3),
.teachersEffortGrid .k-grid-header col:nth-of-type(4),
.teachersEffortGrid .k-grid-table col:nth-of-type(4),
.teachersEffortGrid .k-grid-header col:nth-of-type(5),
.teachersEffortGrid .k-grid-table col:nth-of-type(5),
.teachersEffortGrid .k-grid-header col:nth-of-type(6),
.teachersEffortGrid .k-grid-table col:nth-of-type(6),
.teachersEffortGrid .k-grid-header col:nth-of-type(7),
.teachersEffortGrid .k-grid-table col:nth-of-type(7),
.teachersEffortGrid .k-grid-header col:nth-of-type(8),
.teachersEffortGrid .k-grid-table col:nth-of-type(8) {
  width: 10%;
}


.teachersEffortGrid .k-column-title {
  text-overflow: revert;
  white-space: break-spaces;
  text-align: center;
}


.studentGrid .k-grid-header col:nth-of-type(5) {
  width: 40%;
}

.studentGrid .k-grid-table col:nth-of-type(5) {
  width: 40%;
}

</style>
