<template>
  <v-card>
    <v-toolbar class="elevation1">
      <v-toolbar-title>Student Dashboard: {{ studentName }}</v-toolbar-title>
      <v-spacer />
      <dashboard-data-downloader
        :student-id="Number(studentId)"
      />
    </v-toolbar>
    <v-alert
      v-model="noCompletedPeriods"
      icon="mdi-alert"
      type="error"
      variant="tonal"
    >
      No completed periods.
    </v-alert>
    <v-alert
      v-model="noDataNotification"
      icon="mdi-alert"
      type="warning"
      variant="tonal"
    >
      The selected student has no data to display
    </v-alert>
    <v-container
      v-if="hasData"
      style="padding-top: 20px"
    >
      <v-row>
        <v-col>
          <v-sheet class="average">
            <div id="effort-container">
              <svg
                id="averageControl"
                height="200"
              />
            </div>
            <h3>Effort</h3>
            <h4>Student Graded Score: {{ studentEffortScore }}</h4>
          </v-sheet>
        </v-col>
        <v-col class="gauge">
          <v-sheet>
            <span
              id="radialDiligence"
              class="radialGauge"
            />
          </v-sheet>
          <v-sheet>
            <span class="teacher-score">Teacher: {{ teacherDiligenceScore }}</span>
          </v-sheet>
          <v-sheet>
            <span class="student-score">Student: {{ studentDiligenceScore }}</span>
          </v-sheet>
        </v-col>
        <v-col>
          <v-sheet>
            <span
              id="radialEngagement"
              class="radialGauge"
            />
          </v-sheet>
          <v-sheet>
            <span class="teacher-score">Teacher: {{ teacherEngagementScore }}</span> <br>
          </v-sheet>
          <v-sheet>
            <span class="student-score">Student: {{ studentEngagementScore }}</span>
          </v-sheet>
        </v-col>
        <v-col>
          <v-sheet>
            <span
              id="radialBehaviour"
              class="radialGauge"
            />
          </v-sheet>
          <v-sheet>
            <span class="teacher-score">Teacher: {{ teacherBehaviourScore }}</span> <br>
          </v-sheet>
          <v-sheet>
            <span class="student-score">Student: {{ studentBehaviourScore }}</span>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <br>
    <br>
    <v-container
      v-if="hasData"
      style="max-width:100%"
    >
      <v-row>
        <v-col class="d-flex justify-center">
          <v-btn-toggle
            v-model="dashboardPeriodId"
            mandatory
          >
            <v-btn
              v-for="rp in completeReportingPeriods"
              :key="rp.id"
              variant="flat"
              :value="rp.id"
              prepend-icon="mdi-history"
            >
              <span>{{ rp.name }} </span>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col class="d-flex justify-end">
          <v-btn
            size="large"
            variant="outlined"
            color="primary"
            @click="digDeep = !digDeep"
          >
            DIG DEEPER
          </v-btn>
        </v-col>
        <v-col>
          <v-btn
            size="large"
            variant="outlined"
            color="primary"
            :to="{ name: 'StudentMotionChart', params: { studentId: studentId }}"
          >
            BUBBLE CHART
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>

  <v-container
    v-if="digDeep"
    class="ma-0 px-0"
    style="max-width: 100%"
  >
    <v-row>
      <v-col>
        <v-table>
          <thead>
            <tr>
              <th
                colspan="2"
                rowspan="2"
                class="border-thin px-2"
              >
                <span class="pa-2">
                  Classes
                </span>
              </th>
              <th class="border-0" />
              <th
                colspan="4"
                class="border-thin px-2"
              >
                Teacher Graded
              </th>
              <th class="border-0" />
              <th
                colspan="4"
                class="border-thin px-2"
              >
                Student Graded
              </th>
              <th class="border-0" />
              <th
                colspan="3"
                class="border-thin px-2"
              >
                Differences
              </th>
            </tr>
            <tr>
              <th class="border-0" />
              <th class="border-thin px-2">
                {{ diligenceLabel }}
              </th>
              <th class="border-thin px-2">
                {{ engagementLabel }}
              </th>
              <th class="border-thin px-2">
                {{ behaviourLabel }}
              </th>
              <th class="border-thin px-2">
                Score
              </th>
              <th class="border-0" />
              <th class="border-thin px-2">
                {{ diligenceLabel }}
              </th>
              <th class="border-thin px-2">
                {{ engagementLabel }}
              </th>
              <th class="border-thin px-2">
                {{ behaviourLabel }}
              </th>
              <th class="border-thin px-2">
                Score
              </th>
              <th class="border-0" />
              <th class="border-thin px-2">
                {{ diligenceLabel }}
              </th>
              <th class="border-thin px-2">
                {{ engagementLabel }}
              </th>
              <th class="border-thin px-2">
                {{ behaviourLabel }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in gradeItems"
              :key="item.classId"
            >
              <td class="scores class-container">
                {{ item.name }}
              </td>
              <td class="scores class-container">
                {{ item.teacher }}
              </td>
              <td class="border-0" />

              <!-- TEACHER GRADED -->
              <td :class="getBgForGrade(item.teacherGradedDiligence)">
                <score-bar :score="item.teacherGradedDiligence" />
                <div v-if="item.teacherGradedDiligence !== 0">
                  {{ item.teacherGradedDiligence }}
                </div>
              </td>

              <td :class="getBgForGrade(item.teacherGradedEngagement)">
                <score-bar :score="item.teacherGradedEngagement" />
                <div v-if="item.teacherGradedEngagement !== 0">
                  {{ item.teacherGradedEngagement }}
                </div>
              </td>

              <td :class="getBgForGrade(item.teacherGradedBehaviour)">
                <score-bar :score="item.teacherGradedBehaviour" />

                <div v-if="item.teacherGradedBehaviour !== 0">
                  {{ item.teacherGradedBehaviour }}
                </div>
              </td>

              <td :class="getBgForScore(item.teacherGradedScore)">
                <div v-if="item.teacherGradedScore > 0">
                  {{ item.teacherGradedScore }}
                </div>
              </td>

              <td class="border-0" />

              <!-- STUDENT GRADED -->
              <td :class="getBgForGrade(item.studentGradedDiligence)">
                <score-bar :score="item.studentGradedDiligence" />
                <div v-if="item.studentGradedDiligence !== 0">
                  {{ item.studentGradedDiligence }}
                </div>
              </td>

              <td :class="getBgForGrade(item.studentGradedEngagement)">
                <score-bar :score="item.studentGradedEngagement" />
                <div v-if="item.studentGradedEngagement !== 0">
                  {{ item.studentGradedEngagement }}
                </div>
              </td>

              <td :class="getBgForScore(item.studentGradedBehaviour)">
                <score-bar :score="item.studentGradedBehaviour" />
                <div v-if="item.studentGradedBehaviour !== 0">
                  {{ item.studentGradedBehaviour }}
                </div>
              </td>

              <td :class="getBgForScore(item.studentGradedScore)">
                <div v-if="item.studentGradedScore > 0">
                  {{ item.studentGradedScore }}
                </div>
              </td>

              <td class="border-0" />

              <!-- DIFFERENCES -->
              <td
                :class="getBgForDiff(item.teacherGradedDiligence, item.studentGradedDiligence, item.differenceDiligence)"
              >
                <div v-if="item.teacherGradedDiligence !== 0 && item.studentGradedDiligence !== 0">
                  {{ item.differenceDiligence }}
                </div>
              </td>

              <td
                :class="getBgForDiff(item.teacherGradedEngagement, item.studentGradedEngagement, item.differenceEngagement)"
              >
                <div v-if="item.teacherGradedEngagement !== 0 && item.studentGradedEngagement !== 0">
                  {{ item.differenceEngagement }}
                </div>
              </td>

              <td
                :class="getBgForDiff(item.teacherGradedBehaviour, item.studentGradedBehaviour, item.differenceBehaviour)"
              >
                <div v-if="item.teacherGradedBehaviour !== 0 && item.studentGradedBehaviour !== 0">
                  {{ item.differenceBehaviour }}
                </div>
              </td>
              <td class="border-0" />
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="border-0" />
              <td class="border-0" />

              <!-- TEACHER TOTALS -->
              <td class="border-0" />
              <td :class="getBgForScore(teacherDiligenceTotal)">
                <div v-if="teacherDiligenceTotal > 0">
                  {{ teacherDiligenceTotal }}
                </div>
              </td>

              <td :class="getBgForScore(teacherEngagementTotal)">
                <div v-if="teacherEngagementTotal > 0">
                  {{ teacherEngagementTotal }}
                </div>
              </td>

              <td :class="getBgForScore(teacherBehaviourTotal)">
                <div v-if="teacherBehaviourTotal > 0">
                  {{ teacherBehaviourTotal }}
                </div>
              </td>
              <td class="border-0" />
              <td class="border-0" />

              <!-- STUDENT TOTALS -->

              <td :class="getBgForScore(studentDiligenceTotal)">
                <div v-if="studentDiligenceTotal > 0">
                  {{ studentDiligenceTotal }}
                </div>
              </td>

              <td :class="getBgForScore(studentEngagementTotal)">
                <div v-if="studentEngagementTotal > 0">
                  {{ studentEngagementTotal }}
                </div>
              </td>

              <td :class="getBgForScore(studentBehaviourTotal)">
                <div v-if="studentBehaviourTotal > 0">
                  {{ studentBehaviourTotal }}
                </div>
              </td>
              <td class="border-0" />
              <td class="border-0" />
            </tr>
          </tfoot>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import DashboardDataDownloader from './components/DashboardDataDownloader';
import ScoreBar from './components/ScoreBarComponent';
import {
  getChartDataByPeriodIdFromApi,
  getClassesForStudentDashboardByPeriodId,
  getDashboardDifferencesByPeriodId,
  getEffortScoreByPeriodIdFromApi,
  getStudentDashboardTotalsByPeriodId,
  getStudentGradedByPeriodIdFromApi,
  getTeacherGradedByPeriodIdFromApi,
  getValuesForGaugesByPeriodId,
  initialize
} from '@/utils/effort-tracking-api';
import {loadLiquidFillGauge, ScatterChart} from '@/utils/dashboardElements';
import {getStudent, getCompletedAndVisiblePeriods} from '@/api/studentApi';
import {getGradingTabSettings} from '@/api/settingsApi';
import RadialProgressChart from 'radial-progress-chart';
import {globalStore} from '@/main';

export default {
  name: 'StudentDashboard',
  components: {
    ScoreBar,
    DashboardDataDownloader
  },
  data() {
    return {
      noCompletedPeriods:false,
      digDeep: false,
      average: '',
      periods:  this.$store.state.periods,
      studentId: this.$route.params.studentId,
      reportingPeriodId: globalStore.selectedPeriod,
      gaugeDiligence: 0,
      gaugeEngagement: 0,
      gaugeBehaviour: 0,
      teacherDiligenceScore: 0,
      teacherEngagementScore: 0,
      teacherBehaviourScore: 0,
      studentDiligenceScore: 0,
      studentEngagementScore: 0,
      studentBehaviourScore: 0,
      classesHeaders: [
        {text: 'Class', value: 'name', sortable: false},
        {text: 'Teacher', value: 'teacher', sortable: false}
      ],
      classesItems: [],
      teacherGradedHeaders: [
        {text: 'Diligence', value: 'diligence', sortable: false},
        {text: 'Engagement', value: 'engagement', sortable: false},
        {text: 'Behaviour', value: 'behaviour', sortable: false},
        {text: 'Score', value: 'score', sortable: false}
      ],
      teacherGradedItems: [],
      studentGradedHeaders: [
        {text: 'Diligence', value: 'diligence', sortable: false},
        {text: 'Engagement', value: 'engagement', sortable: false},
        {text: 'Behaviour', value: 'behaviour', sortable: false},
        {text: 'Score', value: 'score', sortable: false}
      ],
      studentGradedItems: [],
      chartHeaders: [
        {text: 'Class Id', value: 'classId', sortable: false},
        {text: 'Score', value: 'studentScore', sortable: false},
        {text: 'Grading Date', value: 'gradingDate', sortable: false}
      ],
      differencesItems: [],
      differencesHeaders: [
        {text: 'Diligence', value: 'differenceDiligence', sortable: false},
        {text: 'Engagement', value: 'differentEngagement', sortable: false},
        {text: 'Behaviour', value: 'differentBehaviour', sortable: false},
        {text: 'Score', value: 'differenceScore', sortable: false}
      ],
      chartItems: [],
      data: [99, 71, 78, 25, 36, 92],
      line: '',
      gauges: [],
      gradeItems: [],
      bottomNav: '',
      selectedReportingDate: null,
      noDataNotification: false,
      hasData: false,
      effortScore: 0,
      academicScore: 0,
      differenceBehaviourTotal: 0,
      differenceDiligenceTotal: 0,
      differenceEngagementTotal: 0,
      studentBehaviourTotal: 0,
      studentDiligenceTotal: 0,
      studentEngagementTotal: 0,
      teacherBehaviourTotal: 0,
      teacherDiligenceTotal: 0,
      teacherEngagementTotal: 0,
      studentName: '',
      showTables: false,
      studentEffortScore: 0,
      mainEffortControl: null,
      reportingDate: null,
      teacherDonutColor: 'rgb(79, 220, 103)',
      studentDonutColor: 'rgb(42, 205, 203)',
      diligenceLabel: '',
      engagementLabel: '',
      behaviourLabel: '',
      completeReportingPeriods: [],
      dashboardPeriodId: null,
      loading: false
    };
  },
  computed: {
    globalStore() {
      return globalStore
    }
  },
  watch: {
    classesItems() {
      this.populateTable();
    },
    teacherGradedItems() {
      this.populateTable();
    },
    studentGradedItems() {
      this.populateTable();
    },
    differencesItems() {
      this.populateTable();
    },
    dashboardPeriodId(periodId) {
      this.getValuesByPeriodId(periodId);
    }
  },
  mounted() {
    initialize()
      .then(() => {
        getCompletedAndVisiblePeriods(this.studentId)
          .then(result => {
            if (result.length <=0 ){
              this.noCompletedPeriods = true;
              return;
            }
            this.completeReportingPeriods = result;

            if(result.length > 0) {
              if(result.filter(r => r.id === Number(globalStore.selectedPeriod)).length === 1) {
                // Use period selector value if it is in the list of valid periods.
                this.dashboardPeriodId = Number(globalStore.selectedPeriod);
              }
              else{
                this.dashboardPeriodId = result[result.length - 1].id;
              }
            }
            getStudent(this.studentId)
              .then(student => {
                this.studentName = `${student.lastName}, ${student.firstName}`;
                if(result.length === 0) {
                  this.noDataNotification = true;
                }

                getGradingTabSettings()
                  .then(tabs => {
                    const diligence = tabs.filter(tb => tb.name === 'DILIGENCE')[0];
                    this.diligenceLabel = (diligence.customName !== '')
                                          ? diligence.customName
                                          : diligence.name;

                    const engagement = tabs.filter(tb => tb.name === 'ENGAGEMENT')[0];
                    this.engagementLabel = (engagement.customName !== '')
                                           ? engagement.customName
                                           : engagement.name;

                    const behaviour = tabs.filter(tb => tb.name === 'BEHAVIOUR')[0];
                    this.behaviourLabel = (behaviour.customName !== '')
                                          ? behaviour.customName
                                          : behaviour.name;

                  })
                  .catch(ex => console.log(ex));
              })
              .catch(ex => console.log(ex));
          })
          .catch(ex => console.log(ex));
      });
  },
  methods: {
    getBgForDiff(diffTeacher, diffStudent, diffItem) {
      switch(true) {
        case (diffTeacher === 0 || diffStudent === 0):
          return 'score grey';
        case (diffItem < -2 && diffTeacher !== 0 && diffStudent !== 0):
          return 'score difference-more-down';
        case (diffItem === -2 && diffTeacher !== 0 && diffStudent !== 0):
          return 'score difference-two-down';
        case (diffItem === -1 && diffTeacher !== 0 && diffStudent !== 0):
          return 'score difference-one-down';
        case (diffItem === 1 && diffTeacher !== 0 && diffStudent !== 0):
          return 'score difference-one-up';
        case (diffItem === 2 && diffTeacher !== 0 && diffStudent !== 0):
          return 'score difference-two-up';
        case (diffItem > 2 && diffTeacher !== 0 && diffStudent !== 0):
          return 'score difference-more-up';
        default:
          return 'score';
      }
    },
    getBgForScore(score) {
      switch(true) {
        case (score === 0):
          return 'score gray';
        case (score >= 61 && score <= 70):
          return 'score green1';
        case (score >= 71 && score <= 80):
          return 'score green2';
        case (score >= 81 && score <= 90):
          return 'score green3';
        case (score >= 91):
          return 'score green4';
        default :
          return 'score';
      }
    },
    getBgForGrade(grade) {
      switch(grade) {
        case 0:
          return 'score gray';
        case 1:
          return 'score yellow1';
        case 2:
          return 'score white2';
        case 3:
          return 'score green2';
        case 4:
          return 'score green3';
        case 5:
          return 'score green4';
        default:
          return 'score';
      }
    },
    getValuesByPeriodId(periodId) {
      if(periodId != null) {
        this.hasData = true;
        this.getClassesByPeriodId();
        this.getGaugesByPeriodId();
        this.getTeacherGradedByPeriodId();
        this.getStudentGradedByPeriodId();
        this.getDifferencesByPeriodId();
        this.getAverageByPeriodId();
        this.getChartDataByPeriodId();
        this.getTotalsByPeriodId();
      }
      else {
        this.noDataNotification = true;
      }
    },
    getClassesByPeriodId() {
      getClassesForStudentDashboardByPeriodId(this.studentId, this.dashboardPeriodId)
        .then(result => {
          this.classesItems = result;
        })
        .catch(ex => console.log(ex));
    },
    getGaugesByPeriodId() {
      getValuesForGaugesByPeriodId(this.studentId, this.dashboardPeriodId)
        .then(result => {
          this.teacherDiligenceScore = result.teacherDiligence;
          this.teacherEngagementScore = result.teacherEngagement;
          this.teacherBehaviourScore = result.teacherBehaviour;
          this.studentDiligenceScore = result.studentDiligence;
          this.studentEngagementScore = result.studentEngagement;
          this.studentBehaviourScore = result.studentBehaviour;
          this.emptyGauges();
          this.createRadialGauges();
        })
        .catch(ex => console.log(ex));
    },
    getTeacherGradedByPeriodId() {
      getTeacherGradedByPeriodIdFromApi(this.studentId, this.dashboardPeriodId)
        .then(result => {
          this.teacherGradedItems = result;
        })
        .catch(ex => console.log(ex));
    },
    getStudentGradedByPeriodId() {
      getStudentGradedByPeriodIdFromApi(this.studentId, this.dashboardPeriodId)
        .then(result => {
          this.studentGradedItems = result;
        })
        .catch(ex => console.log(ex));
    },
    getDifferencesByPeriodId() {
      getDashboardDifferencesByPeriodId(this.studentId, this.dashboardPeriodId)
        .then(result => {
          this.differencesItems = result;

        })
        .catch(ex => console.log(ex));
    },
    getChartDataByPeriodId() {
      getChartDataByPeriodIdFromApi(this.studentId, this.dashboardPeriodId)
        .then(result => {
          this.effortScore = result.teacherGradedScore;
          this.academicScore = result.academicScore;

          ScatterChart(result.teacherGradedScore, result.academicScore);
        })
        .catch(ex => console.log(ex));
    },
    getAverageByPeriodId() {
      getEffortScoreByPeriodIdFromApi(this.studentId, this.dashboardPeriodId)
        .then(result => {
          this.average = result.effortScore;
          this.studentEffortScore = result.studentEffortScore;
          if(this.mainEffortControl != null) {
            this.mainEffortControl.update(result.effortScore, this.mainEffortControl);
          }
          else {
            this.mainEffortControl = loadLiquidFillGauge('averageControl', this.average);
          }
        })
        .catch(ex => console.log(ex));
    },
    getTotalsByPeriodId() {
      getStudentDashboardTotalsByPeriodId(this.studentId, this.dashboardPeriodId)
        .then(totals => {
          this.differenceBehaviourTotal = totals.differenceBehaviourTotal;
          this.differenceDiligenceTotal = totals.differenceDiligenceTotal;
          this.differenceEngagementTotal = totals.differenceEngagementTotal;
          this.studentBehaviourTotal = totals.studentBehaviourTotal;
          this.studentDiligenceTotal = totals.studentDiligenceTotal;
          this.studentEngagementTotal = totals.studentEngagementTotal;
          this.teacherBehaviourTotal = totals.teacherBehaviourTotal;
          this.teacherDiligenceTotal = totals.teacherDiligenceTotal;
          this.teacherEngagementTotal = totals.teacherEngagementTotal;
        })
        .catch(ex => console.log(ex));
    },
    populateTable() {
      const results = _(this.classesItems)
        .concat(this.teacherGradedItems)
        .concat(this.studentGradedItems)
        .concat(this.differencesItems)
        .groupBy('classId')
        .map(_.spread(_.assign))
        .value();

      this.gradeItems = _.sortBy(results, ['name']);
    },
    emptyGauges() {
      document.getElementById('radialDiligence').innerHTML = '';
      document.getElementById('radialEngagement').innerHTML = '';
      document.getElementById('radialBehaviour').innerHTML = '';
    },
    createRadialGauges() {
      if(this.behaviourLabel === '' || this.engagementLabel === '' || this.diligenceLabel === '') {
        getGradingTabSettings()
          .then(tabs => {
            const diligence = tabs.filter(tb => tb.name === 'DILIGENCE')[0];
            this.diligenceLabel = (diligence.customName !== '')
                                  ? diligence.customName
                                  : diligence.name;

            const engagement = tabs.filter(tb => tb.name === 'ENGAGEMENT')[0];
            this.engagementLabel = (engagement.customName !== '')
                                   ? engagement.customName
                                   : engagement.name;

            const behaviour = tabs.filter(tb => tb.name === 'BEHAVIOUR')[0];
            this.behaviourLabel = (behaviour.customName !== '')
                                  ? behaviour.customName
                                  : behaviour.name;

            this.createRadialGauge(this.diligenceLabel, 'radialDiligence', this.studentDiligenceScore, this.teacherDiligenceScore);
            this.createRadialGauge(this.engagementLabel, 'radialEngagement', this.studentEngagementScore, this.teacherEngagementScore);
            this.createRadialGauge(this.behaviourLabel, 'radialBehaviour', this.studentBehaviourScore, this.teacherBehaviourScore);

          })
          .catch(ex => console.log(ex));
      }
      else {
        this.createRadialGauge(this.diligenceLabel, 'radialDiligence', this.studentDiligenceScore, this.teacherDiligenceScore);
        this.createRadialGauge(this.engagementLabel, 'radialEngagement', this.studentEngagementScore, this.teacherEngagementScore);
        this.createRadialGauge(this.behaviourLabel, 'radialBehaviour', this.studentBehaviourScore, this.teacherBehaviourScore);
      }
    },
    createRadialGauge(label, container, studentScore, teacherScore) {
      new RadialProgressChart(`#${container}`, {
        max: 100,
        diameter: 250,
        delay: 0,
        animation: {
          duration: 4500
        },
        round: true,
        series: [
          {
            labelStart: '>',
            value: studentScore,
            color: [this.studentDonutColor, this.studentDonutColor]
          },
          {
            labelStart: '>',
            value: teacherScore,
            color: [this.teacherDonutColor, this.teacherDonutColor]
          }
        ],
        center: function() {
          return label;
        }
      });
    }
  }
};
</script>

<style>


.average {
  text-align: center;
}

.gauge {
  margin-top: 1em;
}

.red1 {
  background-color: rgba(220, 57, 18, 0.3);
}

.yellow1 {
  background-color: #f4fe9273;
}

.red2 {
  background-color: rgba(220, 57, 18, 0.1);
}

.gray {
  background-color: #E8E8E8;
}

.green1 {
  background-color: rgba(76, 175, 80, 0.2);
}

.green2 {
  background-color: rgba(76, 175, 80, 0.4);
}

.green3 {
  background-color: rgba(76, 175, 80, 0.6);
}

.green4 {
  background-color: rgba(76, 175, 80, 0.8);
}


.white2 {
  background-color: white;
}

.difference-one-up {
  background-color: rgba(152, 133, 189, 0.4);
}

.difference-two-up {
  background-color: rgba(152, 133, 189, 0.6);
}

.difference-more-up {
  background-color: rgba(152, 133, 189, 0.8);
}


.difference-one-down {
  background-color: rgba(108, 144, 172, 0.4);
}

.difference-two-down {
  background-color: rgba(108, 144, 172, 0.6);
}

.difference-more-down {
  background-color: rgba(108, 144, 172, 0.8);
}

.main text {
  font: 9px sans-serif;
}

.axis line, .axis path {
  shape-rendering: crispEdges;
  stroke: black;
  fill: none;
}

.scores {
  border: 1px solid #dcd3d3;
}

.subtitle {
  border: 1px solid #dcd3d3;
  max-width: 5em !important;
  text-align: center;
  padding: 0 !important;
}

.score {
  text-align: center;
  border: 1px solid #dcd3d3;
  max-width: 4em !important;
}

.v-expansion-panel__header {
  text-align: center;
  vertical-align: middle;
  margin-bottom: 1em;
  margin-top: 1em;
}

.radialGauge {
  max-height: 10em;
  max-width: 10em;
  margin-left: 3em;
}

.radialGauge > svg {
  height: 10em;
  width: 10em;
}

.rbc-center-text {
  font-weight: bold;
  font-size: 2em;
}

.teacher-score {
  color: rgb(79, 220, 103);
  font-weight: bold;
  text-align: center;
}

.student-score {
  color: rgb(42, 205, 203);
  font-weight: bold;
  text-align: center;
}

.empty {
  border-top: solid 1px white;
  border-bottom: solid 1px white;
  padding: 1em 1em 1em !important;
  max-width: 4em !important;
}

.class-container {
  max-width: 12em !important;
}


.period-buttons-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.reporting-period {
  text-align: center;
  width: 100% !important;
}
</style>
