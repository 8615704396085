<template>
  <div class="d-flex justify-center mt-8">
    <h1>Welcome to Effort Tracking</h1>
  </div>
</template>

<script>
import {UserType} from '@/utils/settings';

export default {
  name: 'HomeComponent',
  data() {
    return {
      isTeacher: false,
      isStudent: false
    };
  },
  mounted() {
    this.isStudent = (this.$store.state.usertype === UserType.Student);
    this.isTeacher
      = (this.$store.state.usertype === UserType.Teacher || this.$store.state.usertype === UserType.MiddleManager);
    const userId = this.$store.state.userdata.id;

    if(this.isTeacher) {
      window.location.href = `#/teacher/${userId}/teacher-efforts`;
    }

    if(this.isStudent) {
      window.location.href = `#/student/${userId}/student-efforts`;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
