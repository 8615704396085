<template>
  <v-card class="bg-grey-lighten-3">
    <v-card-text class="text-h3 text-center">
      {{ title }}
    </v-card-text>
    <v-card-text>
      <v-divider class="my-3" />
      <div class="title mb-3 text-center">
        {{ message }}
      </div>
    </v-card-text>
    <v-card-text class="d-flex justify-center">
      <v-btn
        large
        color="primary"
        class="mx-0 "
        @click="gotoEfforts()"
      >
        Go to efforts
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>

import router from '@/router';

export default {
  name: 'ThankYouPage',
  data() {
    return {
      title: 'Thank you!',
      message: 'You have completed all your grades successfully'
    };
  },
  methods: {
    gotoEfforts() {
      const usertype = this.$store.state.usertype;
      const userId = this.$store.state.userdata.id;

      const effortsUrl = '/' + 'student' + '/' + userId + '/' + usertype + '-efforts';
      router.push(effortsUrl);
    }
  }
};
</script>
<style>
.content {
  text-align: center;
}
</style>
