<template>
  <v-card>
    <v-alert
      v-model="alert.show"
      variant="tonal"
      closable
      :type="alert.isError ? Defaults.AlertError : Defaults.AlertSuccess"
    >
      {{ alert.message }}
    </v-alert>

    <v-toolbar>
      <v-toolbar-title>
        My Account
      </v-toolbar-title>
    </v-toolbar>
    <v-card-title />
    <v-card-item>
      <v-form
        ref="form"
        @input="clearAlerts()"
      >
        <v-text-field
          v-model="resetPasswordModel.username"
          label="Name"
          disabled
          :variant="globalStore.textFieldVariant"
        />
        <v-text-field
          v-model="resetPasswordModel.email"
          label="Email"
          disabled
          :variant="globalStore.textFieldVariant"
        />
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="resetPasswordModel.currentPassword"
              name="input-10-0"
              label="Current Password"
              :append-inner-icon="resetPasswordModel.currentPasswordVisible ? Defaults.HidePasswordIcon : Defaults.ShowPasswordIcon"
              :type="resetPasswordModel.currentPasswordVisible ? 'text' : 'password'"
              :variant="globalStore.textFieldVariant"
              :rules="[v => !!v || 'Password is required']"
              @click:append-inner="resetPasswordModel.currentPasswordVisible = !resetPasswordModel.currentPasswordVisible "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="resetPasswordModel.newPassword"
              label="New Password"
              name="input-10-1"
              :append-inner-icon="resetPasswordModel.newPasswordVisible ? Defaults.HidePasswordIcon : Defaults.ShowPasswordIcon"
              :type="resetPasswordModel.newPasswordVisible ? Defaults.ShowPassword : Defaults.HidePassword"
              :variant="globalStore.textFieldVariant"
              :rules="globalStore.passwordRules"
              @click:append-inner="resetPasswordModel.newPasswordVisible = !resetPasswordModel.newPasswordVisible"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="resetPasswordModel.confirmPassword"
              label="Confirm Password"
              name="input-10-2"
              :append-inner-icon="resetPasswordModel.confirmPasswordVisible ? Defaults.HidePasswordIcon : Defaults.ShowPasswordIcon"
              :type="resetPasswordModel.confirmPasswordVisible ? Defaults.ShowPassword : Defaults.HidePassword"
              :rules="[v => (v === resetPasswordModel.newPassword) || 'passwords must match']"
              :variant="globalStore.textFieldVariant"
              @click:append-inner="resetPasswordModel.confirmPasswordVisible = !resetPasswordModel.confirmPasswordVisible"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-item>

    <v-card-actions>
      <v-btn
        color="primary"
        variant="flat"
        @click="changePassword()"
      >
        Update
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {resetUserPassword} from '@/api/accountsApi';
import userManager from '../utils/authService';
import {globalStore} from '@/main';
import {Defaults, UserType} from '@/utils/settings';

export default {
  data() {
    return {
      resetPasswordModel: {
        username: '',
        email: '',
        currentPassword: '',
        currentPasswordVisible: false,
        newPassword: '',
        newPasswordVisible: false,
        confirmPassword: '',
        confirmPasswordVisible: false,
      },
      alert: {
        show: false,
        isError: false,
        message: '',
      },
      userdata: [],
      userSSO: [],
    };
  },
  computed: {
    Defaults() {
      return Defaults
    },
    globalStore() {
      return globalStore;
    }
  },
  async mounted() {
    this.userdata = this.$store.state.userdata;
    if(this.userdata != null) {
      this.setUserData();
    }
    else {
      await this.getUserSSOInfo();
    }
  },
  methods: {
    setUserData() {
      const title =
        typeof this.$store.state.userdata.title != 'undefined'
        ? this.$store.state.userdata.title
        : '';

      let user = '';
      if(this.$store.state.usertype === UserType.Student) {
        user = this.$store.state.userdata.lastName + ', ' + this.$store.state.userdata.firstName;
      }
      else {
        user = title + ' '
          + this.$store.state.userdata.firstName + ' '
          + this.$store.state.userdata.lastName;
      }

      this.resetPasswordModel.username = user;
      this.resetPasswordModel.email = this.$store.state.userdata.email;
    },
    clearAlerts() {
      this.alert.show = false;
      this.alert.message = '';
    },
    clearPasswordData(){
      this.resetPasswordModel.newPassword = '';
      this.resetPasswordModel.currentPassword = '';
      this.resetPasswordModel.confirmPassword = '';
    },
    async getUserSSOInfo() {
      userManager.getUser()
                 .then((user) => {
                   this.userSSO = user;

                   this.username = user.profile.family_name + ', ' + user.profile.given_name;
                   this.email = user.profile.email;
                 })
                 .catch((error) => {
                   console.error('Error retrieving user information:', error);
                 });
    },
    async changePassword() {
      this.clearAlerts();
      const valid = await this.$refs.form.validate();
      if(!valid) {
        return;
      }
      const model = {
        'User': this.resetPasswordModel.email,
        'CurrentPassword': this.resetPasswordModel.currentPassword,
        'NewPassword': this.resetPasswordModel.newPassword
      };
      resetUserPassword(model)
        .then(result => {
          if(result.status === 200) {
            this.alert.isError = false;
            this.alert.show = true;
            this.alert.message = result.data + ' . You will be redirected to the effort grading interface';
            this.clearPasswordData();
            setTimeout(() => {
              window.location.href = '#/home';
            }, 5000);

          }
          else {
            this.alert.isError = true;
            this.alert.show = true;
            if(result.response.status === 400) {
              this.alert.message = result.response.data;
              if(this.alert.message.length < 5) {
                this.alert.message = 'Something went wrong, please review your current password and try again.';
              }
            }
            else {
              this.alert.message  = `Something went wrong, (${result.response.status})`;
            }
          }
        })
        .catch(() => {
          this.alert.isError = true;
          this.alert.message = 'Something went wrong, please try again';
          this.alert.show = true;
        });
    }
  }
}
</script>
