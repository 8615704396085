import {
  BASE_URL,
  postData,
  getDataFromUrl,
  } from '@/utils/effort-tracking-api'

import {Routes} from '@/utils/settings'


export function getCohorts(){
  return getDataFromUrl(`${BASE_URL}${Routes.DataRoute}/cohorts`);
}

export function getYears(){
  return getDataFromUrl(`${BASE_URL}${Routes.DataRoute}/years`);
}

export function getFilePath(fileName){
  return `${BASE_URL}${Routes.DataRoute}/file/${fileName}`;
}

export function getStudentDashboardFile(studentId){
  const url = `${BASE_URL}${Routes.DataRoute}/dashboard/student/${studentId}`;
  return getDataFromUrl(url);
}

export function sendImportResults(model){
  const url = `${BASE_URL}${Routes.DataRoute}/importer/results/send"`;
  return postData(url, model);
}
