<template style="height: 100%">
  <v-card style="height: 100%">
    <v-toolbar class="elevation1">
      <v-toolbar-title>
        Student Bubble Chart: {{ studentName }}
        <v-btn
          :to="{ name: 'StudentDashboard', params: { studentId: $route.params.studentId }}"
          icon="true"
          density="compact"
          size="large"
          style="border-color: rgb(76, 175, 80)"
          variant="outlined"
        >
          <v-icon
            icon="mdi-view-dashboard"
            color="green"
          />
          <v-tooltip
            activator="parent"
            location="bottom"
          >
            Go Back to Dashboard
          </v-tooltip>
          <template #append>
            <v-icon color="green" />
          </template>
        </v-btn>
      </v-toolbar-title>
    </v-toolbar>
    <v-alert
      v-model="noDataNotification"
      icon="mdi-alert"
      type="warning"
      variant="tonal"
    >
      The selected student has no data to display
    </v-alert>
  </v-card>
  <v-container
    id="studentMotionChart"
    class="pa-0 ma-0"
    style="height:800px"
  />
</template>
<script>

import {initialize} from '@/utils/effort-tracking-api';
import {getStudent} from '@/api/studentApi';
import {getGradingTabSettings} from '@/api/settingsApi';
import {getStudentMotionChart} from '@/api/motionChartApi';
import * as echarts from '../../../node_modules/echarts/dist/echarts.min';
import {getInstanceByDom} from "echarts";


export default {
  name: 'StudentMotionchart',
  data() {
    return {
      periods: [],
      studentId: this.$route.params.studentId,
      reportingPeriodId: localStorage.getItem('period-selected'),
      noDataNotification: false,
      selectedReportingDate: null,
      currentTimeLine: 0,
      reportingDates: [],
      hasData: false,
      reportingDate: null,
      studentName: '',
      myChart: '',
    };
  },
  mounted() {
    this.periods = this.$store.state.periods;
    initialize()
      .then(() => {
        getStudent(this.studentId)
          .then(student => {
            this.studentName = `${student.lastName}, ${student.firstName}`;
            // Initialize the echarts instance based on the prepared dom

            const myChart = echarts.init(document.getElementById('studentMotionChart'));
            myChart.showLoading();
            getStudentMotionChart(this.studentId)
              .then(data => {
                if(data.grades.length > 0) {
                  getGradingTabSettings()
                    .then(tabs => {
                      const diligence = tabs.filter(tb => tb.name === 'DILIGENCE')[0];
                      const diligenceLabel = (diligence.customName !== '')
                                             ? diligence.customName
                                             : diligence.name;

                      const engagement = tabs.filter(tb => tb.name === 'ENGAGEMENT')[0];
                      const engagementLabel = (engagement.customName !== '')
                                              ? engagement.customName
                                              : engagement.name;

                      const behaviour = tabs.filter(tb => tb.name === 'BEHAVIOUR')[0];
                      const behaviourLabel = (behaviour.customName !== '')
                                             ? behaviour.customName
                                             : behaviour.name;

                      // Specify the configuration items and data for the chart
                      const studentName = `${student.firstName} ${student.lastName}`;
                      const categoryTimeLine = [];
                      const lineSeries = [];
                      const studentSeries = [];
                      data.grades.forEach(function(series) {

                        // Fill timeline
                        categoryTimeLine.push(series.reportingName);
                        // Fill Series for selected student
                        let tmpSeries = [];

                        lineSeries.push([series.effortScore, series.academicScore]);
                        tmpSeries = tmpSeries.concat(lineSeries);


                        const studentMarker = {coord: [series.effortScore, series.academicScore]};
                        const MarkerTooltip = {
                          formatter: studentName + ':<br /><br />'
                            + '<b>Teacher rated effort: </b>' + series.effortScore + '<br />'
                            + '<b>Student rated effort: </b>' + series.studentEffortScore + '<br />'
                            + '<b>' + diligenceLabel + ': </b>' + series.teacherDiligenceScore + '<br />'
                            + '<b>' + engagementLabel + ': </b>' + series.teacherEngagementScore + '<br />'
                            + '<b>' + behaviourLabel + ': </b>' + series.teacherBehaviourScore};

                        const charts = {
                          series: [
                            {
                              name: 'Other Students',
                              type: 'scatter',
                              data: series.anonymousSeries,
                              symbolSize: 10,
                              color: 'lightgrey',
                              tooltip: {show: false}},
                            {
                              name: 'Your Effort',
                              type: 'line',
                              data: tmpSeries,
                              lineStyle: {cap: 'butt', type: 'dashed'},
                              markPoint: {data: [studentMarker], symbol: 'pin', tooltip: MarkerTooltip}, tooltip: {show: true}},
                            {
                              name: 'You',
                              type: 'effectScatter',
                              data: tmpSeries,
                              tooltip: {show: false},
                              symbolSize: 20,
                              symbol: 'circle'
                            }],
                          animationDuration: 1000,
                          animationEasing: 'linear',
                          animationDurationUpdate: 1500,
                          animationEasingUpdate: 'linear'};
                        studentSeries.push(charts);
                      });


                      const option = {
                        baseOption: {
                          legend: {},
                          timeline: {
                            axisType: 'category',
                            autoPlay: false,
                            loop: false,
                            data: categoryTimeLine,
                            checkpointStyle: {
                              animation: true,
                              animationDuration: 1500,
                              animationEasing: 'linear'
                            }
                          },
                          tooltip: {
                            trigger: 'item'
                          },
                          xAxis: {
                            name: 'Effort Score',
                            type: 'value',
                            min: 30,
                            max: 100,
                          },
                          yAxis: {
                            name: 'Academic Score',
                            type: 'value',
                            min: 30,
                            max: 100,
                          },
                          series: [
                            {
                              name: 'Other Students',
                              type: 'scatter',
                              data: [],
                              symbolSize: 10,
                              color: 'lightgrey'},
                            {
                              name: 'Your Effort',
                              type: 'line',
                              data: [],
                              lineStyle: {cap: 'butt', type: 'dashed'},
                              markPoint: {data: [], symbol: 'pin'}
                            },
                            {
                              name: 'You',
                              type: 'effectScatter',
                              data: [],
                              tooltip: {show: false},
                              symbolSize: 20,
                              symbol: 'circle'
                            }
                          ],
                          animationDuration: 1000,
                          animationEasing: 'linear',
                          animationDurationUpdate: 1500,
                          animationEasingUpdate: 'linear'
                        },
                        options: studentSeries
                      };

                      myChart.setOption(option);
                      myChart.hideLoading();
                      const vm = this;

                      myChart.on('timelinechanged', function (value) {
                        vm.currentTimeLine = value.currentIndex;

                      });


                    })
                    .catch(ex => console.log(ex));
                }
                else {
                  this.noDataNotification = true;
                  this.myChart.hideLoading();
                }
              })
              .catch(ex => console.log(ex));
          })
          .catch(ex => console.log(ex));
      });
  },
  beforeMount() {
    window.addEventListener('resize', function() {
      const myChart = getInstanceByDom(document.getElementById('studentMotionChart'));
      myChart.resize();
    });
  }
};
</script>
