export {
    generateRandomPassword
}

import {globalStore} from "@/main";


function generateRandomPassword()
{
    const lettersSetArray = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
    const symbolsSetArray = ["=", "+", "-", "^", "?", "!", "%", "&", "*", "$", "#", "^", "@", "|"];
    const passwordArray = [];
    const digitsPositionArray = [];

    // first, fill the password array with letters, uppercase and lowercase
    console.log("globalStore", globalStore)
    console.log("digits", globalStore.generatePasswordSettings.digits)
    console.log("length", globalStore.generatePasswordSettings.length)
    for (let i = 0; i < globalStore.generatePasswordSettings.length; i++) {
        // get an array for all indexes of the password array
        digitsPositionArray.push( i );

        const upperCase = Math.round( Math.random() );
        if ( upperCase === 0 ) {
            passwordArray[i] = lettersSetArray[Math.floor( Math.random() * lettersSetArray.length )].toUpperCase();
        }
        else {
            passwordArray[i] = lettersSetArray[Math.floor( Math.random() * lettersSetArray.length )];
        }
    }

    // Add digits to password
    for (let i = 0; i < globalStore.generatePasswordSettings.digits; i++) {
        const digit = Math.round( Math.random() * 9 );
        const numberIndex = digitsPositionArray[Math.floor( Math.random() * digitsPositionArray.length )];

        passwordArray[numberIndex] = digit;

        /* remove position from digitsPositionArray, so we make sure to the have the exact number of digits in our password
        since without this step, numbers may override other numbers */

        const j = digitsPositionArray.indexOf( numberIndex );
        if ( j !== -1 ) {
            digitsPositionArray.splice( j, 1 );
        }
    }

    // add special characters "symbols"
    for (let i = 0; i < globalStore.generatePasswordSettings.symbols; i++) {
        const symbol = symbolsSetArray[Math.floor( Math.random() * symbolsSetArray.length )];
        const symbolIndex = digitsPositionArray[Math.floor( Math.random() * digitsPositionArray.length )];

        passwordArray[symbolIndex] = symbol;

        /* remove position from digitsPositionArray, so we make sure to the have the exact number of digits in our password
        since without this step, numbers may override other numbers */

        let j = digitsPositionArray.indexOf( symbolIndex );
        if ( j !== -1 ) {
            digitsPositionArray.splice( j, 1 );
        }
    }
    return passwordArray.join( "" )
}
