<template>
  <div class="mr-4">
    <v-btn
      v-if="isStudent && periodComplete"
      :loading="loading4"
      :disabled="loading4"
      variant="elevated"
      @click="loader = 'loading4'; downloadStudentGrades()"
    >
      Download Grades
    </v-btn>
    <v-btn
      v-if="isTeacher"
      :loading="loading4"
      :disabled="loading4"
      variant="elevated"
      @click="loader = 'loading4'; loadDialog()"
    >
      Download Grades
    </v-btn>
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Select your download
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="selected"
            label="Select data to download"
            :items="select"
            item-title="text"
            item-value="value"
            :variant="globalStore.textFieldVariant"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            @click.stop="unloadDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            variant="elevated"
            :disabled="disableAccept"
            @click.stop="downloadTeacherGrades()"
          >
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {createFileWithStudentEfforts} from '@/utils/effort-tracking-api'
import {getCsvFile, downloadFile, createFileWithTeacherEfforts} from '@/utils/effort-tracking-api'
import {globalStore} from "@/main";

export default {
  name: 'GradesDownloader',
  props: {
    classId: {
      type: Number,
      required: true
    },
    isStudent: {
      type: Boolean
    },
    isTeacher: {
      type: Boolean
    }
  },
  data() {
    return {
      loader: null,
      loading4: false,
      dialog: false,
      select: [{text: 'Download all grades', value: 0}, {text: 'Download grades for selected class', value: 1}],
      selected: '',
      disableAccept: true,
      periodComplete: false
    }
  },
  computed: {
    globalStore() {
      return globalStore
    }
  },
  watch: {
    selected() {
      if ( this.selected === 0 ) {
        this.disableAccept = false;
      }
      else {
        this.disableAccept = (this.classId === 0);
      }
    }
  },
  mounted() {
    const periods = this.$store.state.periods;
    const periodId = localStorage.getItem( 'period-selected' );
    const period = periods.filter( pp => pp.id === periodId );
    this.periodComplete = period.length > 0 ? period[0].complete : false;

  },
  methods: {
    unloadDialog() {
      this.dialog = false;
      this.selected = null;
      this.disableAccept = true;
    },
    loadDialog() {
      this.disableAccept = true;
      this.dialog = true;
    },
    downloadStudentGrades() {
      this.dialog = false;
      this.disableAccept = true;
      const l = this.loader
      this[l] = !this[l]
      const periodId = localStorage.getItem( 'period-selected' );

      const studentId = this.$route.params.studentId;
      createFileWithStudentEfforts( studentId, periodId ).then( fileName => {
        console.log( fileName );
        const fileUrl = getCsvFile( fileName );
        downloadFile( fileUrl, fileName );
        this[l] = false;
        this.loader = null;
      } )
      .catch( ex => console.log( ex ) );
    },

    downloadTeacherGrades() {
      this.dialog = false;

      const l = this.loader
      this[l] = !this[l]
      const teacherId = this.$route.params.teacherId;
      const periodId = localStorage.getItem( 'period-selected' );
      let _classId = 0;

      if ( this.selected === 1 ) {
        _classId = this.classId;
      }

      createFileWithTeacherEfforts( teacherId, _classId, periodId ).then( fileName => {
        console.log( fileName );
        const fileUrl = getCsvFile( fileName );
        downloadFile( fileUrl, fileName );
        this[l] = false;
        this.loader = null;
        this.selected = null;
      } )
      .catch( ex => console.log( ex ) );
    }
  }
}
</script>
