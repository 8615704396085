<template>
  <v-card>
    <v-toolbar class="elevation-1">
      <v-toolbar-title>
        Middle Manager Teachers
      </v-toolbar-title>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-inner-icon="mdi-magnify"
        label="Search"
        class="search"
        density="compact"
        variant="solo"
      />
    </v-toolbar>
    <k-grid
      ref="grid"
      class="gridFullHeight"
      :data-items="gridData.items"
      :sort="sort"
      :sortable="true"
      :skip="serverFilters.skip"
      :take="serverFilters.take"
      :total="gridData.totalItems"
      :pageable="pageable"
      :page-size="globalStore.defaultRowsPerPage"
      :columns="gridData.columns"
      :loader="loader"
      @sortchange="sortChangeHandler"
      @pagechange="pageChangeHandler"
    >
      <template #loader>
        <div class="k-loader-container k-loader-container-md k-loader-top">
          <div class="k-loader-container-overlay k-overlay-dark" />
          <div class="k-loader-container-inner">
            <k-loader
              :size="'large'"
              :type="'pulsing'"
              :theme-color="'success'"
            />
          </div>
        </div>
      </template>
      <template #myTemplate="{props}">
        <td class="d-flex justify-center">
          <v-btn
            icon="true"
            variant="text"
            class="mr-4"
            size="sm"
            color="success"
            :to="{
              name: 'TeacherEfforts',
              params: {
                teacherId: props.dataItem.teacherId,
                middleManagerId: middleManagerId }}"
          >
            <v-icon
              icon="mdi-clipboard-check"
              class="mr-4"
            />
            Effort grades
          </v-btn>
          <v-btn
            ref="icon"
            icon="true"
            density="comfortable"
            color="primary"
            class="ma-2 pa-0"
            variant="outlined"
            :to="{ name: 'MyEffortsTeacher', params: { teacherId: props.dataItem.teacherId, teacher: props.dataItem }}"
          >
            <v-tooltip activator="parent">
              Grade Summary
            </v-tooltip>
            <v-icon
              small
              icon="mdi-clipboard-text"
            />
          </v-btn>
        </td>
      </template>
    </k-grid>
  </v-card>
</template>


<script>
import {initialize} from '@/utils/effort-tracking-api';
import {globalStore} from '@/main';
import {debounce} from 'lodash';
import {Grid as KGrid} from '@progress/kendo-vue-grid';
import {Loader as KLoader} from '@progress/kendo-vue-indicators';
import {getManagedTeacherByPeriod} from '@/api/middleManagerApi';

export default {
  name: 'MiddleManagerTeachers',
  components: {
    KLoader,
    KGrid,
  },
  data: () => ({
    middleManagerId: 0,
    search: '',
    // Does not work as a child property.
    sort: [{field: 'email', dir: 'asc'}],
    loader: false,
    serverFilters: {
      sortBy: 'email',
      filter: '',
      take: globalStore.defaultRowsPerPage,
      skip: 0,
      descending: false
    },
    pagerValues: {
      pagerType: globalStore.pagerTypes['numeric'],
      pagerButtons: globalStore.pagerButtons,
      pagerInfo: globalStore.pagerInfo,
      pagerSizes: globalStore.pagerSizes,
      pagePrevNext: globalStore.pagerSizes,
      pageSize: globalStore.defaultRowsPerPage,
      pageSizeValue: globalStore.defaultRowsPerPage,
    },
    gridData: {
      columns: [
        {field: 'title', title: 'Title', width: '100px'},
        {field: 'lastName', title: 'Last Name'},
        {field: 'firstName', title: 'First Name'},
        {field: 'email', title: 'Email'},
        // { field: 'teacherId', title: 'teacherId'  },
        {field: '', title: 'actions', cell: 'myTemplate', sortable: false},
      ],
      items: [],
      totalItems: 0,
    },
  }),
  computed: {
    globalStore() {
      return globalStore;
    },
    pageable() {
      return {
        buttonCount: this.pagerValues.pagerButtons,
        info: this.pagerValues.pagerInfo,
        type: this.pagerValues.pagerType,
        pageSizes: this.pagerValues.pagerSizes
                   ? globalStore.allowedRowsPerPage
                   : undefined,
        previousNext: this.pagerValues.pagePrevNext,
        pageSizeValue: this.pagerValues.pageSizeValue,
      };
    },
  },
  watch: {
    search: {
      handler() {
        this.debouncedGetDataFromApi();
      },
    },
  },
  created() {
    this.debouncedGetDataFromApi = debounce(() => {
      this.getDataFromApi();
    }, 500);
  },
  mounted() {
    console.log('on MiddleManagerTeachers mounted');
    const session = JSON.parse(localStorage.getItem('ef-session'));
    this.middleManagerId = Number(session.userdata.id);
    localStorage.setItem('middlemanagerid', session.userdata.id);
    this.debouncedGetDataFromApi();
  },
  methods: {
    sortChangeHandler: function(e) {
      if(e.sort.length === 0) {
        this.serverFilters.descending = false;
        this.debouncedGetDataFromApi();
        this.sort = e.sort;
        return;
      }
      this.serverFilters.descending = e.sort[0].dir !== 'asc';
      this.serverFilters.sortBy = e.sort[0].field;
      this.debouncedGetDataFromApi();
      this.sort = e.sort;
    },
    pageChangeHandler: function(event) {
      console.log('event', event);
      this.pagerValues.pageSize = event.page.take;
      this.pagerValues.pageSizeValue = event.page.take;
      this.serverFilters.skip = event.page.skip;
      this.serverFilters.take = event.page.take;
      this.debouncedGetDataFromApi();
    },
    getDataFromApi() {
      initialize()
        .then(() => {
          this.loader = true;
          this.serverFilters.filter = this.search;
          getManagedTeacherByPeriod(globalStore.selectedPeriod, this.middleManagerId, this.serverFilters)
            .then(result => {
              this.gridData.items = result.data.items;
              this.gridData.totalItems = result.data.count;
              this.loader = false;
            });
        });
    }
  },
};
</script>
