import {
  BASE_URL,
  getDataFromUrl,
} from '@/utils/effort-tracking-api'

import {Routes} from '@/utils/settings'

export function getWelcomeText(){
  const url =`${BASE_URL}${Routes.PublicRoute}/setting/welcome-message`;
  return getDataFromUrl(url);
}

export function getBackgroundImage(){
  const url =`${BASE_URL}${Routes.PublicRoute}/setting/background-image`;
  return getDataFromUrl(url);
}

export function getLogoImage(){
  const url =`${BASE_URL}${Routes.PublicRoute}/setting/logo-image`;
  return getDataFromUrl(url);
}

export function getLoginSettings(){
  const url =`${BASE_URL}${Routes.PublicRoute}/setting/login`;
  return getDataFromUrl(url);
}
