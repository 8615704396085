<template>
  <div
    style="width:100%;"
    class="effort-grades"
  >
    <v-tabs
      v-model="active"
      class="tab-bar"
    >
      <v-tab
        v-for="effort in value.effortGroups"
        :key="effort.groupId"
        variant="text"
        hide-slider="hide-slider"
        class="fix-tab-bg"
        :value="effort.effortId"
        @click.stop="updateActive(effort.groupName, effort.groupId)"
      >
        {{ effort.groupName }}
        <v-icon
          v-if="effort.isComplete"
          size="large"
          class="ml-4"
          color="primary"
          icon="mdi-check-circle"
        />
      </v-tab>
    </v-tabs>

    <v-window
      v-model="active"
    >
      <v-window-item
        v-for="effort in value.effortGroups"
        :id="'top_' + effort.groupId"
        :key="effort.groupId"
        :value="effort.effortId"
        class="grading-tabs-scroll-area mr-0 pr-0"
      >
        <v-container class="effort-description mw-100">
          <v-row
            :id="'group_' + effort.groupId.toString()"
            class="elevation-1"
          >
            <v-col class="mt-4">
              <h2>{{ effort.groupName }}</h2>

              <div>
                {{ effort.groupDescription }}
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div
          class="grades-pane"
          style="overflow: scroll;"
        >
          <v-card
            v-for="classEffort in effort.effortGroups"
            :id="effort.groupName.toLowerCase() + '_card_' + classEffort.efforts[0].studentId"
            :key="classEffort.reportingPeriodClassId"
          >
            <v-divider />
            <v-card-title>
              <h3
                :class="getStyle(classEffort)"
                :data-id="classEffort.groupId"
              >
                {{ classEffort.groupName }}
              </h3>
            </v-card-title>
            <v-card-text
              v-for="eff in classEffort.efforts"
              :key="eff.teacherEffortId"
            >
              <v-container class="ma-0 pa-0">
                <v-row no-gutters>
                  <grading-buttons
                    ref="buttons"


                    :value="classEffort.efforts"
                    :settings="effort.settings"
                    :readonly="readonly"
                    :active="active"
                    :class-id="classId"
                    @grade-changed="onGradeChanged"
                    @save-failed="saveFailed"
                  />
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </div>
      </v-window-item>
    </v-window>
  
    <v-container
      :class="{ 'pa-2 ma-0':1, 'hasResults': typeof(value.groupName) != 'undefined', 'noResults': typeof(value.groupName) == 'undefined'}"
    >
      <v-row
        class="efforts-nav px-4"
      >
        <v-col cols="3">
          <v-btn
            class="ml-4"
            :disabled="prevDisabled"
            @click="prev"
          >
            prev
          </v-btn>
        </v-col>
        <v-col cols="9">
          <v-sheet class="float-end">
            <v-btn
              class="mr-4"
              @click="finish"
            >
              finish
            </v-btn>
            <v-btn
              :disabled="nextDisabled"
              @click="next"
            >
              next
            </v-btn>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      max-width="35%"
    >
      <v-card>
        <v-card-title>
          Information
        </v-card-title>
        <v-card-text>
          <v-row>
            {{ message }}
          </v-row>
        </v-card-text>
        <v-card-text
          v-for="name in incompletes"
          :key="name"
        >
          <v-row>
            {{ name }}
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click.stop="cleanDialog()"
          >
            Continue Grading
          </v-btn>
          <v-btn
            v-if="!isStudent"
            color="primary"
            flat
            @click.stop="saveAndExit()"
          >
            Save and Exit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GradingButtons from './GradingButtons';
import {getStudentEfforts, getTeacherEfforts} from '@/utils/effort-tracking-api';

export default {
  name: 'GradingTabs',
  components: {
    GradingButtons
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Object,
      required: true
    },
    classId: {
      type: Number,
      required: true
    },
    hideTitle: {
      type: Boolean,
      required: false
    },
    isStudent: {
      type: Boolean,
      required: true
    },
    settings: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      required: true
    }
  },
  emits: ['grade-changed', 'save-failed'],
  data() {
    return {
      active: 0,
      error: false,
      prevDisabled: false,
      nextDisabled: false,
      dialog: false,
      message: 'You still have unassigned grades. Please complete them before finishing your grading',
      incompletes: [],
      studentCard: true,
      activeSettings: [],
      selectedEffort: 0
    };
  },
  watch: {
    active: {
      handler(value) {
        console.log('on active watch handler...', value);
        this.prevDisabled = (value === 0);
        this.nextDisabled = (value === 2);
        let id = 'top_' + this.selectedEffort;
        setTimeout(() => {
          let element = document.getElementById(id);
          if(element != null) {
            element.scrollTo(0, 0);
          }
        }, 100);
      }
    },
    classId: {
      handler() {
        console.log('on classId watch handler...',this.value);
        this.active = 10;
        const id = 'top_' + this.selectedEffort;
        const tabsEnum = {
          DILIGENCE: 2,
          ENGAGEMENT: 3,
          BEHAVIOUR: 1
        };

        Object.getOwnPropertyNames(tabsEnum)
              .forEach((i, v) => {
                if(v === this.selectedEffort) {
                  this.active = v;
                }
              });

        setTimeout(() => {
          let element = document.getElementById(id);
          if(element != null) {
            element.scrollTo(0, 0);
          }
        }, 100);
      }
    }
  },
  mounted() {
    console.log('on grading tabs mounted..');

    const element = document.getElementsByClassName('effort-grades');
    element[0].style.display = 'inline';


    this.prevDisabled = (this.active === 0);
    this.nextDisabled = (this.active === 2);
    this.active = 0;
  },
  methods: {
    onGradeChanged: function(data, classId) {
      this.$emit('grade-changed', data, classId);
    },
    saveFailed() {
      this.$emit('save-failed');
    },
    next() {
      this.updateSelectedEffort('n');
      console.log('on NEXT');
      let id = 'top_' + this.selectedEffort;
      const active = parseInt(this.active);
      this.active = (active < 2
                     ? active + 1
                     : 2);
      setTimeout(() => {
        const element = document.getElementById(id);
        element.scrollTo(0, 0);
      }, 100);
    },
    prev() {
      this.updateSelectedEffort('p');
      const id = 'top_' + this.selectedEffort;
      const active = parseInt(this.active);
      this.active = (active > 0
                     ? active - 1
                     : 0);

      setTimeout(() => {
        const element = document.getElementById(id);
        element.scrollTo(0, 0);
      }, 100);
    },
    finish() {
      if(this.$route.params.studentId !== undefined) {
        getStudentEfforts(this.$route.params.studentId, this.$route.params.periodId)
          .then((effortsRefresh) => {
            if(effortsRefresh.isComplete) {
              window.location.href = '#/thankyou';
            }
            else {
              this.dialog = true;
            }
          })
          .catch(ex => {
            console.error('getStudentEfforts', ex);
          });
      }

      if(this.$route.params.teacherId !== undefined) {
        getTeacherEfforts(this.$route.params.teacherId, this.classId)
          .then((efforts) => {
            if(efforts.isComplete) {
              window.location.href = '#/thankyou';
            }
            else {
              this.dialog = true;
            }
          })
          .catch(ex => {
            console.log('getTeacherEfforts', ex);
          });
      }
    },
    cleanDialog() {
      this.dialog = false;
      this.incompletes = [];
    },
    saveAndExit() {
      this.cleanDialog();
      window.location.href = '#/';
    },
    className(classInfo) {
      let className = '';
      if(typeof (classInfo.groupName) != 'undefined' && classInfo.groupName !== '') {
        className = classInfo.groupName + ' - Effort Tracking';
      }

      return className;
    },
    updateActive(effortName, effortId) {
      console.log('on updateActive...');
      const tabsEnum = {
        DILIGENCE: 2,
        ENGAGEMENT: 3,
        BEHAVIOUR: 1
      };

      Object.getOwnPropertyNames(tabsEnum)
            .forEach((i, v) => {
              if(i === effortName) {
                this.active = v;
              }
            });

      this.selectedEffort = effortId;

    },
    updateSelectedEffort(direction) {
      console.log('on updateSelectedEffort, to direction:');

      const tabsEnum = {
        DILIGENCE: 0,
        ENGAGEMENT: 1,
        BEHAVIOUR: 2
      };

      const efforts = this.value;
      Object.getOwnPropertyNames(tabsEnum)
            .forEach((v, i) => {
              let effort;
              if(this.active === i) {
                if(direction === 'n') {
                  effort = efforts.effortGroups[i + 1];
                  this.selectedEffort = effort.groupId;
                }
                else {
                  effort = efforts.effortGroups[i - 1];
                  this.selectedEffort = effort.groupId;
                }
              }
            });
    },
    reset() {
      props.value.effortGroups = [];
      this.value.groupId = 0;
      this.value.groupName = '';
      this.className(this.value);
      let element = document.getElementsByClassName('effort-grades');
      element[0].style.display = 'none';
    },
    getStyle(classEffort) {
      let unGraded = classEffort.efforts[0].studentId !== null && classEffort.efforts[0].grade === '0';

      return unGraded
             ? 'ungraded'
             : ' ';
    }
  }
};
</script>

<style scoped>

h3 {
  font-size: 16px;
}

h2 {
  font-size: 21px;
}

.hasResults {
  display: inline;
}

.noResults {
  display: none;
}

.class-wrapper {
  width: 100%;
}


.ungraded {
  color: #ec4949;
}
</style>
