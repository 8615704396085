<template>
  <v-container class="pa-0 ma-0 w-100 mw-100">
    <v-alert
      v-model="showAlertSuccess"
      variant="tonal"
      type="success"
      closable
    >
      {{ resultMessage }}
    </v-alert>
    <v-alert
      v-model="showAlertError"
      variant="tonal"
      type="error"
      closable
    >
      {{ resultMessage }}
    </v-alert>

    <v-card>
      <v-toolbar class="elevation-1">
        <v-toolbar-title>
          User Management
        </v-toolbar-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-inner-icon="mdi-magnify"
          placeholder="Search"
          variant="solo"
          density="compact"
          style="margin-top: 20px; margin-right: 10px;"
          clearable
        />
        <v-btn
          variant="elevated"
          @click="showAddUserDialog()"
        >
          Add new user
        </v-btn>
      </v-toolbar>

      <k-grid
        ref="grid"
        class="userGrid gridFullHeight"
        :data-items="gridData.items"
        :sort="sort"
        :sortable="true"
        :skip="serverFilters.skip"
        :take="serverFilters.take"
        :total="gridData.totalItems"
        :pageable="pageable"
        :page-size="globalStore.defaultRowsPerPage"
        :columns="gridData.columns"
        :loader="loader"
        @sortchange="sortChangeHandler"
        @pagechange="pageChangeHandler"
      >
        <template #loader>
          <div class="k-loader-container k-loader-container-md k-loader-top">
            <div class="k-loader-container-overlay k-overlay-dark" />
            <div class="k-loader-container-inner">
              <k-loader
                :size="'large'"
                :type="'pulsing'"
                :theme-color="'success'"
              />
            </div>
          </div>
        </template>
        <template #myTemplate="{props}">
          <td class="d-flex justify-center ">
            <v-btn
              icon="true"
              density="comfortable"
              variant="outlined"
              style="margin: 6px"
              @click.stop="showEditUserDialog(props.dataItem)"
            >
              <v-tooltip activator="parent">
                Change User Information
              </v-tooltip>
              <v-icon
                size="small"
                icon="mdi-pencil"
              />
            </v-btn>

            <v-btn
              v-if="props.dataItem.accountStatus"
              icon="true"
              density="comfortable"
              class="ma-2 pa-0"
              variant="outlined"
              color="red"
              style="margin: 6px"
              @click.stop="showLockAccountDialog(props.dataItem)"
            >
              <v-tooltip activator="parent">
                Deactivate Account
              </v-tooltip>
              <v-icon
                size="small"
                icon="mdi-lock"
              />
            </v-btn>

            <v-btn
              v-if="!props.dataItem.accountStatus"
              icon="true"
              density="comfortable"
              class="ma-2 pa-0"
              variant="outlined"
              color="success"
              style="margin: 6px"
              @click.stop="showUnlockAccountDialog(props.dataItem)"
            >
              <v-tooltip activator="parent">
                Activate Account
              </v-tooltip>
              <v-icon
                size="small"
                icon="mdi-check"
              />
            </v-btn>

            <v-btn
              icon="true"
              density="comfortable"
              class="ma-2 pa-0"
              variant="outlined"
              color="grey"
              @click.stop="showResetPasswordDialog(props.dataItem.email)"
            >
              <v-tooltip activator="parent">
                Reset Password.
              </v-tooltip>
              <v-icon
                size="small"
                icon="mdi-lastpass"
              />
            </v-btn>
          </td>
        </template>

        <template #statusCell="{props}">
          <td class="k-table-td">
            <span
              v-if="props.dataItem.accountStatus"
            >Active</span>
            <span
              v-if="!props.dataItem.accountStatus"
            >Inactive</span>
          </td>
        </template>
      </k-grid>
    </v-card>

    <!-- reset password dialog -->
    <v-dialog
      v-model="resetUserPasswordModel.showDialog"
      max-width="35%"
      persistent
    >
      <v-card>
        <v-card-title>
          Confirmation
        </v-card-title>
        <v-card-text>
          This action will <span class="dialogWarningColor"><b> RESET </b></span> the password
          for the user:
          <b> {{ resetUserPasswordModel.selectedUserEmail }} </b>
        </v-card-text>
        <v-card-text>
          <i>The new password will be the default school password set for each type of user. If you want to set a
            specific password, please type it here:</i>
        </v-card-text>
        <v-card-text>
          <v-form
            ref="resetPasswordForm"
            v-model="resetPasswordValidationState"
          >
            <v-container class="pa-0">
              <v-row no-gutters>
                <v-text-field
                  v-model="txtNewPassword"
                  :rules="globalStore.passwordRules"
                  :append-inner-icon="defaultPasswordMask ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="defaultPasswordMask ? 'password' : 'text'"
                  label="New password*"
                  hint="At least 6 characters"
                  :variant="globalStore.textFieldVariant"
                  @click:append-inner="() => (defaultPasswordMask = !defaultPasswordMask)"
                />

                <v-btn
                  icon="true"
                  elevation="0"
                  class="align-self-center"
                  density="compact"
                  variant="plain"
                  @click="generatePassword()"
                >
                  <v-tooltip
                    activator="parent"
                    location="top"
                  >
                    Suggest secure password
                  </v-tooltip>
                  <v-icon
                    dark
                    size="small"
                    icon="mdi-lastpass"
                    class="align-self-center ml-4"
                  />
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            @click.stop="hideResetPasswordDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            :disabled="!resetPasswordValidationState"
            variant="elevated"
            @click.stop="resetPassword()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit user dialog -->
    <v-dialog
      v-model="editUser.showDialog"
      max-width="35%"
      persistent
    >
      <v-card>
        <v-card-title>
          Change User Information
        </v-card-title>
        <v-card-text v-if="editUser.selectedUser !== null">
          <v-form ref="updateUserForm">
            <v-text-field
              v-model="editUser.selectedUser.firstName"
              label="First Name*"
              :rules="[v => !!v || 'First name is required']"
              required
              :variant="globalStore.textFieldVariant"
            />

            <v-text-field
              v-model="editUser.selectedUser.lastName"
              label="Surname*"
              required
              :rules="[v => !!v || 'Surname is required']"
              :variant="globalStore.textFieldVariant"
            />

            <v-text-field
              v-model="editUser.selectedUser.email"
              label="Email* "
              :rules="globalStore.emailRules"
              required
              :variant="globalStore.textFieldVariant"
            />

            <v-select
              v-model="editUser.selectedUser.role"
              :rules="[v => !!v || 'Role Required']"
              label="User role*"
              :items="roleItems"
              item-text="title"
              item-value="value"
              variant="underlined"
              required
            />

            <v-radio-group
              v-model="editUser.selectedUser.status"
              class="ml-n3"
              inline
            >
              <v-radio
                v-for="n in newUserStatusOptions"
                :key="n.id"
                :label="n.text"
                :value="n.id"
              />
            </v-radio-group>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            variant="text"
            @click.stop="hideEditUserDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            variant="elevated"
            @click.stop="updateUserInformation()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add user dialog -->
    <v-dialog
      v-model="addUser.showDialog"
      max-width="600"
      persistent
    >
      <v-card>
        <v-card-title>
          Add a new user
        </v-card-title>

        <v-card-text>
          <v-form
            ref="addUserForm"
            validate-on="lazy blur"
          >
            <v-radio-group
              v-model="newUser.gender"
              class="ml-n3"
              inline
              :rules="[v => !!v || 'Gender is required']"
            >
              <v-radio
                v-for="n in newUserGenderOptions"
                :key="n.id"
                :label="n.text"
                :value="n.id"
              />
            </v-radio-group>

            <v-text-field
              v-model="newUser.title"
              label="Title"
              :variant="globalStore.textFieldVariant"
            />

            <v-text-field
              v-model="newUser.firstName"
              label="First Name*"
              :rules="[v=> !!v || 'Surname Required']"
              single-line
              required
              :variant="globalStore.textFieldVariant"
            />

            <v-text-field
              v-model="newUser.middleName"
              label="Middle Name"

              :variant="globalStore.textFieldVariant"
            />

            <v-text-field
              v-model="newUser.lastName"
              label="Surname*"
              single-line
              :rules="[v=> !!v || 'Surname Required']"
              required
              :variant="globalStore.textFieldVariant"
            />

            <v-textarea
              v-model="newUser.email"
              rows="1"
              label="Email*"
              :rules="globalStore.emailRules"
              :name="`username_${Math.random()}`"
              autocomplete="null"
              type="text"
              required
              :variant="globalStore.textFieldVariant"
            />

            <v-select
              v-model="newUser.role"
              :items="roleItems"
              :rules="[v => !!v || 'Role Required']"
              label="Select role*"
              inline
              item-text="title"
              item-value="value"
              :variant="globalStore.textFieldVariant"
            />

            <v-select
              v-if="newUser.role === 1"
              v-model="newUser.cohort"
              :items="cohortItems"
              :rules="[v => !!v || 'Cohort Required']"
              label="Select Cohort*"
              inline
              item-text="title"
              item-value="id"
              :variant="globalStore.textFieldVariant"
            />

            <v-select
              v-if="newUser.role === 1"
              v-model="newUser.year"
              :items="yearItems"
              :rules="[v => !!v || 'Year Required']"
              label="Select Year*"
              inline
              item-text="title"
              item-value="id"
              :variant="globalStore.textFieldVariant"
            />

            <v-container class="pa-0">
              <v-row class="d-flex justify-center align-center">
                <v-col cols="11">
                  <v-text-field
                    v-model="newUser.password"
                    single-line
                    :rules="globalStore.passwordRules"
                    :append-inner-icon="defaultPasswordMask ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="defaultPasswordMask ? 'password' : 'text'"
                    label="Password*"
                    hint="At least 6 characters"
                    :variant="globalStore.textFieldVariant"
                    @click:append-inner="(defaultPasswordMask = !defaultPasswordMask)"
                  />
                </v-col>
                <v-col
                  cols="1"
                  class="pa-0"
                >
                  <v-btn
                    icon="true"
                    elevation="0"
                    class="align-self-center"
                    density="compact"
                    variant="plain"
                    @click="generateNewUserPassword()"
                  >
                    <v-tooltip
                      activator="parent"
                      location="top"
                    >
                      Suggest secure password
                    </v-tooltip>
                    <v-icon
                      size="small"
                      icon="mdi-lastpass"
                      class="align-self-center ml-4"
                    />
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <!--            <v-select-->
            <!--              v-model="newUser.status"-->
            <!--              :items="newUserStatusOptions"-->
            <!--              :rules="[v => !!v || 'Status Required']"-->
            <!--              label="Status"-->
            <!--              inline-->
            <!--              item-text="title"-->
            <!--              item-value="title"-->
            <!--              :variant="globalStore.textFieldVariant"-->
            <!--            />-->
            <v-radio-group
              v-model="newUser.status"
              inline
              class="ml-n3"
              :rules="[v => !!v || 'Status Required']"
            >
              <v-radio
                v-for="n in newUserStatusOptions"
                :key="n.title"
                :label="n.title"
                :value="n.title"
              />
            </v-radio-group>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            @click.stop="hideAddUserDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            variant="elevated"
            @click.stop="addNewUser()"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Lock account dialog -->
    <v-dialog
      v-model="lockAccount.showDialog"
      max-width="35%"
      persistent
    >
      <v-card>
        <v-card-title>
          Deactivate Account
        </v-card-title>
        <v-card-text v-if="selectedUser !== null">
          <span><b>Email</b>: {{ selectedUser.email }}</span> <br>
          <span><b>Name</b>: {{ selectedUser.firstName }} {{ selectedUser.lastName }}</span><br>
          <span><b>Role</b>: {{ selectedUser.role }}</span><br>

          <br>
          <p>
            By deactivating the account, this user won't be able to log into the application. <br>
            The data related to this user won't be affected by this action.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            flat
            @click.stop="hideLockAccountDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            variant="elevated"
            @click.stop="lockUserAccount(true)"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Unlock account dialog -->
    <v-dialog
      v-model="unlockAccount.showDialog"
      max-width="35%"
      persistent
    >
      <v-card>
        <v-card-title>
          Activate Account
        </v-card-title>
        <v-card-text v-if="selectedUser !== null">
          <span><b>Email</b>: {{ selectedUser.email }}</span> <br>
          <span><b>Name</b>: {{ selectedUser.firstName }} {{ selectedUser.lastName }}</span><br>
          <span><b>Role</b>: {{ selectedUser.role }}</span><br>

          <br>
          <p>
            By activating this account, you are allowing the user to log into the application.<br>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click.stop="hideUnlockAccountDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="success"
            variant="elevated"
            @click.stop="lockUserAccount(false)"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {globalStore} from '@/main';
import {initialize} from '@/utils/effort-tracking-api';
import {debounce} from 'lodash';
import {generateRandomPassword} from '@/utils/passwordService';
import {Defaults, Setting, UserType} from '@/utils/settings';
import {getSettingByKey} from '@/api/settingsApi';
import {
  lockUser,
  resetPassword,
  createUser,
  getUsersForPeriod,
  updateUser,
  unlockUser
} from '@/api/accountsApi';

import {Grid as KGrid} from '@progress/kendo-vue-grid';
import {Loader as KLoader} from '@progress/kendo-vue-indicators';
import {getCohorts, getYears} from "@/api/dataApi";

export default {
  components: {
    KLoader,
    KGrid,
  },
  emits: {'refresh-content-view': null},
  data() {
    return {
      resetPasswordValidationState: {},
      addUserValidationState: true,

      search: '',
      // Does not work as a child property.
      sort: [{field: 'role', dir: 'asc'}],
      loader: false,
      serverFilters: {
        sortBy: 'role',
        filter: '',
        take: globalStore.defaultRowsPerPage,
        skip: 0,
        descending: false
      },
      pagerValues: {
        pagerType: globalStore.pagerTypes['numeric'],
        pagerButtons: globalStore.pagerButtons,
        pagerInfo: globalStore.pagerInfo,
        pagerSizes: globalStore.pagerSizes,
        pagePrevNext: globalStore.pagerSizes,
        pageSize: globalStore.defaultRowsPerPage,
        pageSizeValue: globalStore.defaultRowsPerPage,
      },
      gridData: {
        columns: [
          {field: 'id', title: 'Id', hidden: true},
          {field: 'role', title: 'Role'},
          {field: 'lastName', title: 'Last Name'},
          {field: 'firstName', title: 'First Name'},
          {field: 'email', title: 'Email'},
          {field: 'accountStatus', title: 'Status', cell: 'statusCell'},
          {field: '', cell: 'myTemplate', sortable: false},
        ],
        items: [],
        totalItems: 0,
      },
      addUser: {
        showDialog: false,
      },
      editUser: {
        showDialog: false,
        selectedUser: {
          id: '',
          firstName: '',
          lastName: '',
          middleName: '',
          gender: '',
          title: '',
          email: '',
          password: '',
          role: '',
          accountStatus: ''
        }
      },
      resetUserPasswordModel: {
        showDialog: false,
        selectedUserEmail: ''
      },
      unlockAccount: {
        showDialog: false,
      },
      lockAccount: {
        showDialog: false,
      },

      showAlertSuccess: false,
      showAlertError: false,
      resultMessage: '',

      defaultPassword: '',
      txtNewPassword: '',
      defaultPasswordMask: true,

      roleItems: [
        {title: 'Student', value: 1},
        {title: 'Teacher', value: 2},
        {title: 'Admin', value: 3},
        {title: 'Middle Manager', value: 4}
      ],
      cohortItems: [],
      yearItems: [],
      selectedUser: {
        id: '',
        firstName: '',
        lastName: '',
        middleName: '',
        gender: '',
        title: '',
        email: '',
        password: '',
        role: '',
        roleValue: '',
        accountStatus: '',
        status: ''
      },
      newUser: {
        firstName: '',
        lastName: '',
        middleName: '',
        gender: '',
        title: '',
        email: '',
        password: '',
        role: '',
        status: 'Active',
        cohort: '',
        year: '',
      },
      newUserStatusOptions: [
        {id: 1, title: 'Active'},
        {id: 0, title: 'Inactive'}
      ],
      newUserGenderOptions: [
        {id: 'M', text: 'Male'},
        {id: 'F', text: 'Female'}
      ],
    };
  },
  computed: {
    globalStore() {
      return globalStore;
    },
    pageable() {
      return {
        buttonCount: this.pagerValues.pagerButtons,
        info: this.pagerValues.pagerInfo,
        type: this.pagerValues.pagerType,
        pageSizes: this.pagerValues.pagerSizes
            ? globalStore.allowedRowsPerPage
            : undefined,
        previousNext: this.pagerValues.pagePrevNext,
        pageSizeValue: this.pagerValues.pageSizeValue,
      };
    },
  },
  watch: {
    search: {
      handler() {
        this.debouncedGetDataFromApi();
      },
    },
  },
  created() {
    this.debouncedGetDataFromApi = debounce(() => {
      this.getDataFromApi();
    }, 500);
  },
  mounted() {
    this.debouncedGetDataFromApi();
    initialize()
    .then(() => {
      getSettingByKey(Setting.StudentsDefaultPassword)
      .then(setting => {
        this.txtNewPassword = (setting.value !== undefined)
            ? setting.value
            : Defaults.StudentsDefaultPassword;
        this.defaultPassword = this.txtNewPassword;
        this.newUser.password = this.txtNewPassword;
      })
      .catch(ex => console.log(ex));
      getCohorts()
      .then(result => {
        result.forEach(i => this.cohortItems.push({id: i.id, title: i.name}));
      })
      .catch(ex => console.log(ex))
      getYears().then(results => {
        results.forEach(i => this.yearItems.push({id: i.id, title: i.name}));
      }).catch(ex => console.log(ex))
    });
  },
  methods: {
    sortChangeHandler: function (e) {
      if (e.sort.length === 0) {
        this.serverFilters.descending = false;
        this.debouncedGetDataFromApi();
        this.sort = e.sort;
        return;
      }
      this.serverFilters.descending = e.sort[0].dir !== 'asc';
      this.serverFilters.sortBy = e.sort[0].field;
      this.debouncedGetDataFromApi();
      this.sort = e.sort;
    },
    pageChangeHandler: function (event) {
      console.log('event', event);
      this.pagerValues.pageSize = event.page.take;
      this.pagerValues.pageSizeValue = event.page.take;
      this.serverFilters.skip = event.page.skip;
      this.serverFilters.take = event.page.take;
      this.debouncedGetDataFromApi();
    },
    getDataFromApi() {
      this.loader = true;
      this.serverFilters.filter = this.search;
      getUsersForPeriod(globalStore.selectedPeriod, this.serverFilters)
      .then(result => {
        let data = result.data.data;
        this.gridData.items = data.items;
        this.gridData.totalItems = data.count;
        this.loader = false;
      })
      .catch(ex => {
        this.resultMessage = ex.message;
        this.showAlertError = true;
      });
    },
    hideResetPasswordDialog() {
      this.resetUserPasswordModel.showDialog = false;
      this.resetUserPasswordModel.selectedUserEmail = '';
    },
    showResetPasswordDialog(studentEmail) {
      this.showAlertSuccess = false;
      this.showAlertError = false;
      this.resetUserPasswordModel.showDialog = true;
      this.resetUserPasswordModel.selectedUserEmail = studentEmail;
    },
    showEditUserDialog(userData) {
      this.showAlertSuccess = false;
      this.showAlertError = false;
      this.editUser.showDialog = true;
      this.editUser.selectedUser = userData;
      this.editUser.selectedUser.status = this.editUser.selectedUser.accountStatus ? 1 : 0;
    },
    hideEditUserDialog() {
      this.editUser.showDialog = false;
      this.selectedUser = null;
    },
    showLockAccountDialog(userData) {
      this.showAlertSuccess = false;
      this.showAlertError = false;
      this.lockAccount.showDialog = true;
      this.selectedUser = userData;
    },
    hideLockAccountDialog() {
      this.lockAccount.showDialog = false;
      this.selectedUser = null;
    },
    showUnlockAccountDialog(userData) {
      this.showAlertSuccess = false;
      this.showAlertError = false;
      this.unlockAccount.showDialog = true;
      this.selectedUser = userData;
    },
    hideUnlockAccountDialog() {
      this.unlockAccount.showDialog = false;
      this.selectedUser = null;
    },
    showAddUserDialog() {
      this.showAlertSuccess = false;
      this.showAlertError = false;
      this.addUser.showDialog = true;
    },
    hideAddUserDialog() {
      this.showAlertError = false;
      this.showAlertSuccess = false;
      this.resultMessage = ''
      this.addUser.showDialog = false;
      this.selectedUser = null;
      this.newUser = {
        firstName: '',
        lastName: '',
        middleName: '',
        gender: '',
        title: '',
        email: '',
        password: '',
        role: '',
        status: '',
        cohort: '',
        year: '',
      }
    },
    generatePassword() {
      this.txtNewPassword = generateRandomPassword();
      this.studentsDefaultPasswordMask = false;
    },
    generateNewUserPassword() {
      this.newUser.password = generateRandomPassword();
      this.studentsDefaultPasswordMask = false;
    },
    lockUserAccount(locked) {
      this.loader = true;
      if (locked) {
        lockUser(this.selectedUser)
        .then(() => {
          this.hideLockAccountDialog();
          this.debouncedGetDataFromApi();
        });
      }
      else {
        unlockUser(this.selectedUser)
        .then(() => {
          this.hideUnlockAccountDialog();
          this.debouncedGetDataFromApi();
        });
      }
      this.loader = false;
    },
    async addNewUser() {
      const {valid} = await this.$refs.addUserForm.validate();
      if (!valid) {
        return;
      }
      this.loader = true;
      let role = '';
      switch (this.newUser.role) {
        case 1:
          role = 'student';
          break;
        case 2:
          role = 'teacher';
          break;
        case 3:
          role = 'admin';
          break;
        case 4:
          role = 'middlemanager';
          break;
      }

      let user = {
        role: role,
        firstName: this.newUser.firstName,
        middleName: this.newUser.middleName,
        lastName: this.newUser.lastName,
        title: this.newUser.title,
        email: this.newUser.email,
        gender: this.newUser.gender,
        password: this.newUser.password,
        accountStatus: this.newUser.status === "Active",
        setDefaultPassword: false,
        cohortId: Number(this.newUser.cohort),
        yearId: Number(this.newUser.year)
      }

      createUser(user)
      .then(() => {
        this.hideAddUserDialog();
        this.loader = false;
        this.getDataFromApi();

        this.showAlertSuccess = true;
        this.resultMessage = `User ${user.email} with role ${user.role}`;
      })
      .catch(() => {
        this.loader = false;
        this.addUser.showDialog= false;
        this.resultMessage = `Unable to create user: ${user.email}`;
        this.showAlertError = true;
      });
    },
    async resetPassword() {
      const {valid} = await this.$refs.resetPasswordForm.validate();
      if (!valid) {
        return;
      }
      if (this.txtNewPassword !== '') {
        const model = {
          email: this.resetUserPasswordModel.selectedUserEmail,
          newPassword: this.txtNewPassword
        };
        resetPassword(model)
        .then(result => {
          this.dialog = false;
          this.selectedTeacherEmail = '';
          this.resultMessage = result.data;
          this.alertSuccess = true;
          this.txtNewPassword = this.defaultPassword;
          this.studentsDefaultPasswordMask = true;

        })
        .catch(() => {
          console.log('catch')
          this.resultMessage = 'Error resetting password';
          this.showAlertError = true;
          this.dialog = false;
          this.txtNewPassword = this.defaultPassword;
          this.studentsDefaultPasswordMask = true;
        });
      }
    },
    async updateUserInformation() {

      const {valid} = await this.$refs.updateUserForm.validate();
      if (!valid) {
        return;
      }

      let model = {
        userId: this.editUser.selectedUser.id,
        role: this.editUser.selectedUser.role,
        firstName: this.editUser.selectedUser.firstName,
        lastName: this.editUser.selectedUser.lastName,
        email: this.editUser.selectedUser.email,
        active: true
      }
      updateUser(model)
      .then(() => {
        this.editUser.showDialog = false;
        this.editUser.selectedUser = '';
      })
      .catch(ex => {
        this.resultMessage = ex.message;
        this.showAlertError = true;
      });
    },
  }
};
</script>

<style>
.userGrid .k-grid-header col:nth-of-type(1),
.userGrid .k-grid-table col:nth-of-type(1) {
  width: 15%;
}

.userGrid .k-grid-header col:nth-of-type(2),
.userGrid .k-grid-table col:nth-of-type(2) {
  width: 15%;
}

.middleManagerGrid .k-grid-header col:nth-of-type(3),
.middleManagerGrid .k-grid-table col:nth-of-type(3) {
  width: 15%;
}


.userGrid .k-grid-header col:nth-of-type(4),
.userGrid .k-grid-table col:nth-of-type(4) {
  width: 25%;
}

.userGrid .k-grid-header col:nth-of-type(5),
.userGrid .k-grid-table col:nth-of-type(5) {
  width: 15%;
}

.userGrid .k-grid-header col:nth-of-type(6),
.userGrid .k-grid-table col:nth-of-type(6) {
  width: 15%;
}

</style>
