<template>
  <v-container class="pa-0 ma-0 w-100 mw-100">
    <v-alert
      v-model="alertSuccess"
      icon="mdi-alert"
      closable
      variant="tonal"
      type="success"
    >
      {{ resultMessage }}
    </v-alert>

    <v-alert
      v-model="alertError"
      icon="mdi-alert"
      variant="tonal"
      closable
      type="error"
    >
      {{ resultMessage }}
    </v-alert>

    <v-card>
      <v-toolbar>
        <v-toolbar-title>
          Teachers
        </v-toolbar-title>

        <v-text-field
          v-model="search"
          append-inner-icon="mdi-magnify"
          placeholder="Search"
          variant="solo"
          density="compact"
          class="search"
        />
      </v-toolbar>
    </v-card>

    <k-grid
      ref="grid"
      class="teachersGrid gridFullHeight"
      :data-items="gridData.items"
      :sort="sort"
      :sortable="true"
      :skip="serverFilters.skip"
      :take="serverFilters.take"
      :total="gridData.totalItems"
      :pageable="pageable"
      :page-size="globalStore.defaultRowsPerPage"
      :columns="gridData.columns"
      :loader="loader"
      @sortchange="sortChangeHandler"
      @pagechange="pageChangeHandler"
    >
      <template #loader>
        <div class="k-loader-container k-loader-container-md k-loader-top">
          <div class="k-loader-container-overlay k-overlay-dark" />
          <div class="k-loader-container-inner">
            <k-loader
              :size="'large'"
              :type="'pulsing'"
              :theme-color="'success'"
            />
          </div>
        </div>
      </template>

      <template #myTemplate="{props}">
        <td class="d-flex justify-center ">
          <v-btn
            variant="outlined"
            icon="true"
            class="ma-2 pa-0 hidden-md-and-up"
            density="comfortable"
            color="success"
            :to="{
              name: 'TeacherEfforts',
              params: {
                teacherId: props.dataItem.teacherId
              }}"
          >
            <v-tooltip activator="parent">
              Effort grades
            </v-tooltip>
            <v-icon
              small
              icon="mdi-clipboard-check"
            />
          </v-btn>
          <v-btn
            icon="true"
            variant="text"
            class="mr-4 hidden-sm-and-down"
            size="sm"
            density="compact"
            color="success"
            :to="{
              name: 'TeacherEfforts',
              params: {
                teacherId: props.dataItem.teacherId }}"
          >
            <v-icon
              class="mr-4"
              icon="mdi-clipboard-check"
            />
            Effort grades
          </v-btn>

          <v-btn
            icon="true"
            density="comfortable"
            class="ma-2 pa-0"
            variant="outlined"
            color="success"
            :to="{ name: 'TeachersGradeSummary',
                   params: {
                     teacherId: props.dataItem.teacherId,
                     teacher: props.dataItem }}"
          >
            <v-tooltip activator="parent">
              Grade Summary
            </v-tooltip>
            <v-icon
              small
              icon="mdi-clipboard-text"
            />
          </v-btn>

          <v-btn
            icon="true"
            density="comfortable"
            class="ma-2 pa-0"
            color="red"
            variant="outlined"
            :value="props.dataItem.teacherId"
            @click.stop="loadResetPasswordDialog(props.dataItem.email)"
          >
            <v-icon
              small
              icon="mdi-lastpass"
            />
            <v-tooltip activator="parent">
              Reset Password
            </v-tooltip>
          </v-btn>
        </td>
      </template>
    </k-grid>

    <!-- reset password -->
    <v-dialog
      v-model="resetPasswordDialog"
      max-width="50%"
    >
      <v-card>
        <v-card-title>
          Confirmation
        </v-card-title>
        <v-card-text>
          This action will <span class="dialogWarningColor"><b> RESET </b></span> the Teacher
          password.<br> User:
          <b> {{ selectedTeacherEmail }} </b>
        </v-card-text>
        <v-card-text>
          <i>The new password will be the default school password for teachers. If you want to set a specific password,
            please type it here:</i>
        </v-card-text>
        <v-card-text>
          <v-container class="pa-0">
            <v-row no-gutters>
              <v-text-field
                v-model="txtNewPassword"
                :rules="globalStore.passwordRules"
                :append-inner-icon="teachersDefaultPasswordMask ? 'mdi-eye-off' : 'mdi-eye'"
                :type="teachersDefaultPasswordMask ? 'password' : 'text'"
                label="New password"
                :variant="globalStore.textFieldVariant"
                hint="At least 6 characters"
                @click:append-inner="teachersDefaultPasswordMask = !teachersDefaultPasswordMask"
              />

              <v-btn
                icon="true"
                elevation="0"
                class="align-self-center"
                density="compact"
                variant="plain"
                @click="generatePassword()"
              >
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  Suggest secure password
                </v-tooltip>
                <v-icon
                  small
                  icon="mdi-lastpass"
                  class="align-self-center ml-4"
                />
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            flat
            @click.stop="unloadResetPasswordDialog()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            variant="elevated"
            @click.stop="resetPassword()"
          >
            Reset password
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {initialize} from '@/utils/effort-tracking-api';
import {getTeachersByPeriod} from '@/api/teachersApi';
import {resetPassword} from '@/api/accountsApi';
import {getSettingByKey} from '@/api/settingsApi';
import {Setting, Defaults, UserType} from '@/utils/settings';
import {generateRandomPassword} from '@/utils/passwordService';
import {debounce} from 'lodash';
import {globalStore} from '@/main';
import {Grid as KGrid} from '@progress/kendo-vue-grid';
import {Loader as KLoader} from '@progress/kendo-vue-indicators';


export default {
  components: {
    KLoader,
    KGrid,
  },
  data: () => ({
    alertSuccess: false,
    alertError: false,
    resetPasswordDialog: false,
    addTeacherDialog: false,
    selectedTeacherEmail: '',
    resultMessage: '',
    defaultPassword: '',
    txtNewPassword: '',
    teachersDefaultPasswordMask: true,
    errorMessages: '',

    search: '',
    // Does not work as a child property.
    sort: [{field: 'lastName', dir: 'asc'}],
    loader: false,
    serverFilters: {
      sortBy: 'lastName',
      filter: '',
      take: globalStore.defaultRowsPerPage,
      skip: 0,
      descending: false
    },
    pagerValues: {
      pagerType: globalStore.pagerTypes['numeric'],
      pagerButtons: globalStore.pagerButtons,
      pagerInfo: globalStore.pagerInfo,
      pagerSizes: globalStore.pagerSizes,
      pagePrevNext: globalStore.pagerSizes,
      pageSize: globalStore.defaultRowsPerPage,
      pageSizeValue: globalStore.defaultRowsPerPage,
    },
    gridData: {
      columns: [

        {field: 'lastName', title: 'Last Name'},
        {field: 'firstName', title: 'First Name'},
        {field: 'email', title: 'Email'},
        // { field: 'teacherId', title: 'teacherId'  },
        {field: '', cell: 'myTemplate', sortable: false},
      ],
      items: [],
      totalItems: 0,
    },
  }),
  computed: {
    globalStore() {
      return globalStore;
    },
    pageable() {
      return {
        buttonCount: this.pagerValues.pagerButtons,
        info: this.pagerValues.pagerInfo,
        type: this.pagerValues.pagerType,
        pageSizes: this.pagerValues.pagerSizes
                   ? globalStore.allowedRowsPerPage
                   : undefined,
        previousNext: this.pagerValues.pagePrevNext,
        pageSizeValue: this.pagerValues.pageSizeValue,
      };
    },
  },
  watch: {
    search: {
      handler() {
        this.debouncedGetDataFromApi();
      },
    }
  },
  created() {
    this.debouncedGetDataFromApi = debounce(() => {
      this.getDataFromApi();
    }, 500);
  },
  mounted() {
    console.log('On teachers mounted...');
    localStorage.removeItem('middlemanagerid');

    this.debouncedGetDataFromApi();
    initialize()
      .then(() => {
        getSettingByKey(Setting.TeachersDefaultPassword)
          .then(
            setting => {
              this.txtNewPassword = (setting.value !== undefined)
                                    ? setting.value
                                    : Defaults.TeachersDefaultPassword;
              this.defaultPassword = this.txtNewPassword;
            })
          .catch(
            ex => console.log('getSettingByKey', ex.response.data));
      });

  },
  methods: {
    sortChangeHandler: function(e) {
      if(e.sort.length === 0) {
        this.serverFilters.descending = false;
        this.debouncedGetDataFromApi();
        this.sort = e.sort;
        return;
      }
      this.serverFilters.descending = e.sort[0].dir !== 'asc';
      this.serverFilters.sortBy = e.sort[0].field;
      this.debouncedGetDataFromApi();
      this.sort = e.sort;
    },
    pageChangeHandler: function(event) {
      console.log('event', event);
      this.pagerValues.pageSize = event.page.take;
      this.pagerValues.pageSizeValue = event.page.take;
      this.serverFilters.skip = event.page.skip;
      this.serverFilters.take = event.page.take;
      this.debouncedGetDataFromApi();
    },
    getDataFromApi() {
      this.loader = true;
      this.serverFilters.filter = this.search;
      getTeachersByPeriod(globalStore.selectedPeriod, this.serverFilters)
        .then(
          data => {
            this.gridData.items = data.items;
            this.gridData.totalItems = data.count;
            this.loader = false;
          });
    },
    unloadResetPasswordDialog() {
      this.resetPasswordDialog = false;
      this.selectedTeacherEmail = '';
    },
    loadResetPasswordDialog(teacherEmail) {
      this.alertSuccess = false;
      this.alertError = false;
      this.resetPasswordDialog = true;
      this.selectedTeacherEmail = teacherEmail;
    },
    resetPassword() {
      if(this.txtNewPassword !== '') {
        resetPassword({
                        email: this.selectedTeacherEmail,
                        newPassword: this.txtNewPassword
                      })
          .then(
            () => {
              this.resetPasswordDialog = false;
              this.resultMessage = `Password reset for ${this.selectedTeacherEmail}`
              this.selectedTeacherEmail = '';

              this.alertSuccess = true;
              this.txtNewPassword = this.defaultPassword;
              this.teachersDefaultPasswordMask = true;
            })
        .catch(() => {
          this.resultMessage = 'Unable to reset password';
          this.alertError = true;
          this.txtNewPassword = this.defaultPassword;
          this.teachersDefaultPasswordMask = true;
        });
      }
      else {
        resetPassword({
                        userType: UserType.Teacher,
                        email: this.selectedTeacherEmail,
                        toDefault: true
                      })
          .then(result => {
            this.resetPasswordDialog = false;
            this.selectedTeacherEmail = '';
            if(result.status === 200) {
              this.resultMessage = result.data;
              this.alertSuccess = true;
            }
            else {
              this.resultMessage = result.response.data;
              this.alertError = true;
            }
          });
      }
    },
    generatePassword() {
      this.txtNewPassword = generateRandomPassword();
      this.teachersDefaultPasswordMask = false;
    }
  },
};

</script>


<style>
.teachersGrid .k-grid-header col:nth-of-type(1) {
  width: 25%;
}

.teachersGrid .k-grid-table col:nth-of-type(1) {
  width: 25%;
}

.teachersGrid .k-grid-header col:nth-of-type(2) {
  width: 15%;
}

.teachersGrid .k-grid-table col:nth-of-type(2) {
  width: 15%;
}

.teachersGrid .k-grid-header col:nth-of-type(3) {
  width: 15%;
}

.teachersGrid .k-grid-table col:nth-of-type(3) {
  width: 15%;
}

.teachersGrid .k-grid-header col:nth-of-type(4) {
  width: 25%;
}

.teachersGrid .k-grid-table col:nth-of-type(4) {
  width: 25%;
}


</style>
