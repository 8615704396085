<template>
  <v-app>
    <v-app-bar color="primary">
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="drawer = !drawer"
      />
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <period-selector
        ref="periodSelectorRef"
        @period-selected="onPeriodSelected()"
      />
      <account-controls />
      <help-controls />
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
    >
      <v-list lines="one">
        <v-list-item
          v-for="(item, i) in menus"
          :key="i"
          :value="item"
          :to="item.link"
          color="primary"
          @click="refreshContentView()"
        >
          <v-divider
            v-if="item.isDivider"
            :thickness="1"
            class="border-opacity-100"
          />
          <template #prepend>
            <v-icon
              :icon="item.icon"
            />
          </template>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="(item, i) in helps"
          :key="i"
          :value="item"
          @click="openDocument()"
        >
          <template #prepend>
            <v-icon :icon="item.icon" />
          </template>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="(item, i) in externals"
          :key="i"
          :value="item"
          @click="openExternalLink(item.link)"
        >
          <template #prepend>
            <v-icon :icon="item.icon" />
          </template>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <social-component />
      </v-list>
    </v-navigation-drawer>

    <v-main style="margin: 0.2em">
      <v-alert
        v-model="showInactive"
        icon="mdi-alert"
        variant="tonal"
        type="error"
      >
        The selected period is not active or it has been completed. All the data is in read only mode.
      </v-alert>

      <v-alert
        v-model="showInvisibles"
        icon="mdi-alert"
        variant="tonal"
        type="error"
      >
        There are no visible periods.
      </v-alert>
      <v-container
        fluid
        class="pa-0"
      >
        <router-view v-slot="{ Component }">
          <transition>
            <component
              :is="Component"
              :key="contentview"
              ref="contentview"
              @actives-changed="activesChanged()"
              @period-added="periodAdded()"
              @selected-deleted="selectedDeleted()"
              @show-period-notification="reShowNotification()"
              @refresh-content-view="refreshContentView()"
            />
          </transition>
        </router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import PeriodSelector from '../appBar/PeriodSelector';
import AccountControls from '../appBar/AccountControls';
import HelpControls from '../appBar/HelpControls';
import SocialComponent from '../appBar/SocialComponent';
import {getMenuItems} from '@/utils/menubuilder';
import {initialize, getHelpFile} from '@/utils/effort-tracking-api';
import {getExternalLinks} from '@/utils/menubuilder';
import {getPeriod, getPeriods} from '@/api/periodApi';
import {getHelpLinks} from '@/utils/menubuilder';
import router from '@/router';
import {UserType} from '@/utils/settings';
import {globalStore} from '@/main';

export default {
  name: 'NavigationLayout',
  components: {
    PeriodSelector,
    AccountControls,
    HelpControls,
    SocialComponent
  },
  data() {
    return {
      periodId: 0,
      clipped: true,
      drawer: true,
      fixed: true,
      miniVariant: false,
      right: false,
      title: 'Effort Tracking',
      showInactive: false,
      showInvisibles: false,
      periods: [],
      isTeacher: false,
      isStudent: false,
      completedPeriods: 0,
      periodsViewed: 0,
      contentview: 0,
      menus: this.menuItems(),
      helps: this.helpItems(),
      externals: this.externalItems()
    };
  },
  computed: {
    globalStore() {
      return globalStore
    }
  },
  watch: {
    $route: {
      handler() {
        this.showInactive = false;
      },
    },
  },
  methods: {
    refreshContentView() {
      console.log('On refresh content view...');
      // Alter the content view key to force the content update

      this.contentview++;
    },
    onPeriodSelected() {
      // Alter the content view key to force the content update
      this.contentview++;
    },
    reShowNotification() {
      initialize()
        .then(
          () => {
            getPeriod(globalStore.selectedPeriod)
              .then(
                result => {
                  this.showInactive = !result.active || result.complete;
                })
              .catch(ex => console.log(ex));
          })
        .catch(ex => console.log(ex));
    },
    menuItems() {
      // Check user is logged in
      if(this.$store.state.userdata === null) {
        router.push('/users/login');
        return;
      }
      const userId = this.$store.state.userdata.id;
      // get User state
      const usertype = this.$store.state.usertype;


      initialize()
        .then(() => {
          getPeriods()
            .then(
              result => {
                this.completedPeriods = result.filter(p => p.complete).length;
              });
        });
      if(this.$store.state.userdata != null) {

        const periods = this.$store.state.periods;
        const periodId = localStorage.getItem('period-selected');
        const period = periods.filter(pp => pp.periodId === periodId);
        var periodComplete = period.length > 0
                             ? period[0].complete
                             : false;
      }
      if(usertype === UserType.Student && this.completedPeriods > 0) {
        return getMenuItems(usertype, userId, periodComplete, true);
      }
      else {
        return getMenuItems(usertype, userId, periodComplete, false);
      }
    },
    externalItems() {
      return getExternalLinks();
    },
    helpItems() {
      return getHelpLinks();
    },
    activesChanged() {
      this.showInactive = false;
      getPeriods()
        .then(periods => {
          this.$store.state.periods = [];
          const activePeriods = [];

          periods.forEach(period => {
            const p = {
              periodName: period.periodName,
              periodId: period.periodId,
              startDate: new Date(period.startDate),
              endDate: new Date(period.endDate),
              active: period.active,
              complete: period.complete
            };

            this.$store.state.periods.push(p);
            if(p.active) {
              activePeriods.push(period.value);
            }
          });
          if((this.$route.name === 'TeacherEfforts' || this.$route.name === 'StudentEfforts')
            && this.$store.state.usertype !== UserType.Admin
            && periods.filter(p => p.visible).length === 0) {
            this.showInactive = false;
            this.showInvisibles = true;
          }
          this.$refs.periodSelectorRef.update();
        });
    },
    periodAdded() {
      this.$refs.periodSelectorRef.update();
    },
    selectedDeleted() {
      const periods = this.$store.state.periods;
      const lsPeriod = localStorage.getItem('period-selected');
      const indexes = periods.map(ii => ii.id);
      if(indexes.indexOf(lsPeriod) === -1) {
        this.$refs.periodSelectorRef.update();
        localStorage.setItem('period-selected', '-1');
      }
    },
    openExternalLink(link) {
      window.open(link, '_blank');
    },
    openDocument() {
      getHelpFile(this.$store.state.usertype);
    }
  }
};
</script>
