<template>
  <v-card>
    <v-toolbar class="elevation1">
      <v-toolbar-title>
        {{ student }} Grade Summary
      </v-toolbar-title>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-inner-icon="mdi-magnify"
        placeholder="Search"
        variant="solo"
        density="compact"
        class="search"
      />
      <grade-summary-downloader
        :usertype="'student'"
        :user-id="studentId"
      />
    </v-toolbar>
    <k-grid
      ref="grid"
      class="teachersEffortGrid gridFullHeight"
      :data-items="gridData.items"
      :sort="sort"
      :sortable="true"
      :skip="serverFilters.skip"
      :take="serverFilters.take"
      :total="gridData.totalItems"
      :pageable="pageable"
      :page-size="globalStore.defaultRowsPerPage"
      :columns="gridData.columns"
      :loader="loader"
      @pagechange="pageChangeHandler"
      @sortchange="sortChangeHandler"
    >
      <template #loader>
        <div class="k-loader-container k-loader-container-md k-loader-top">
          <div class="k-loader-container-overlay k-overlay-dark" />
          <div class="k-loader-container-inner">
            <k-loader
              :size="'large'"
              :type="'pulsing'"
              :theme-color="'success'"
            />
          </div>
        </div>
      </template>
    </k-grid>
  </v-card>
</template>

<script>

import {getMyEffortsStudent} from '@/utils/effort-tracking-api'
import {getGradingTabSettings} from '@/api/settingsApi';
import {debounce} from "lodash";
import GradeSummaryDownloader from '../shared/grading/GradeSummaryDownloader'
import {globalStore} from '@/main'
import {Grid as KGrid} from '@progress/kendo-vue-grid';
import {Loader as KLoader} from '@progress/kendo-vue-indicators';


export default {
  name: 'StudentsGradeSummary',
  components: {
    GradeSummaryDownloader,
    KLoader,
    KGrid,
  },
  data() {
    return {
      studentId: 0,
      student: '',

      search: '',
      // Does not work as a child property.
      sort: [{field: 'class', dir: 'asc'}],
      loader: false,
      serverFilters: {
        sortBy: 'class',
        filter: '',
        take: globalStore.defaultRowsPerPage,
        skip: 0,
        descending: false
      },
      pagerValues: {
        pagerType: globalStore.pagerTypes['numeric'],
        pagerButtons: globalStore.pagerButtons,
        pagerInfo: globalStore.pagerInfo,
        pagerSizes: globalStore.pagerSizes,
        pagePrevNext: globalStore.pagerSizes,
        pageSize: globalStore.defaultRowsPerPage,
        pageSizeValue: globalStore.defaultRowsPerPage,
      },
      gridData: {
        columns: [
          {field: 'className', title: 'Class', class: "student"},
          {field: 'teacher', title: 'Teacher'},
          {
            title: 'Diligence', children: [
              {
                field: 'diligenceStudent',
                title: 'Student Graded',
                sortable: false,
                class: 'student'
              },
              {
                field: 'diligenceTeacher',
                title: 'Teacher Graded',
                sortable: false,
                class: 'teacher'
              },
            ]
          },
          {
            title: 'Engagement', children: [
              {
                field: 'engagementStudent',
                title: 'Student Graded',
                sortable: false,
                class: 'student'
              },
              {
                field: 'engagementTeacher',
                title: 'Teacher Graded',
                sortable: false,
                class: 'teacher'
              },
            ]
          },
          {
            title: 'Behaviour', children: [
              {
                field: 'behaviourStudent',
                title: 'Student Graded',
                sortable: false,
                class: 'student'
              },
              {
                field: 'behaviourTeacher',
                title: 'Teacher Graded',
                sortable: false,
                class: 'teacher'
              },
            ]
          },
        ],
        items: [],
        totalItems: 0,
      },

      diligenceLabel: '',
      engagementLabel: '',
      behaviourLabel: ''
    }
  },
  computed: {
    globalStore() {
      return globalStore
    },
    pageable() {
      return {
        buttonCount: this.pagerValues.pagerButtons,
        info: this.pagerValues.pagerInfo,
        type: this.pagerValues.pagerType,
        pageSizes: this.pagerValues.pagerSizes ? globalStore.allowedRowsPerPage : undefined,
        previousNext: this.pagerValues.pagePrevNext,
        pageSizeValue: this.pagerValues.pageSizeValue,
      };
    },
  },
  watch: {
    search: {
      handler() {
        this.debouncedGetDataFromApi()
      },
    },
  },
  mounted() {
    this.debouncedGetDataFromApi = debounce(() => {
      this.getDataFromApi();
    }, 500);
    this.studentId = this.$route.params.studentId;
    const studentData = this.$route.params.student;
    if ( studentData !== undefined )
      this.student = studentData.lastName + ', ' + studentData.firstName + ' - ';

    this.debouncedGetDataFromApi();
    getGradingTabSettings().then( tabs => {
      const diligence = tabs.filter( tb => tb.name === 'DILIGENCE' )[0];
      this.diligenceLabel = (diligence.customName !== '') ? diligence.customName : diligence.name;

      const engagement = tabs.filter( tb => tb.name === "ENGAGEMENT" )[0];
      this.engagementLabel = (engagement.customName !== '') ? engagement.customName : engagement.name;

      const behaviour = tabs.filter( tb => tb.name === "BEHAVIOUR" )[0];
      this.behaviourLabel = (behaviour.customName !== '') ? behaviour.customName : behaviour.name;

    } ).catch( ex => console.log( ex ) );
  },
  methods: {
    sortChangeHandler: function (e) {
      if ( e.sort.length === 0 ) {
        this.serverFilters.descending = false;
        this.debouncedGetDataFromApi();
        this.sort = e.sort;
        return;
      }
      this.serverFilters.descending = e.sort[0].dir !== 'asc';
      this.serverFilters.sortBy = e.sort[0].field
      this.debouncedGetDataFromApi();
      this.sort = e.sort;
    },
    pageChangeHandler: function (event) {
      console.log( 'event', event );
      this.pagerValues.pageSize = event.page.take;
      this.pagerValues.pageSizeValue = event.page.take;
      this.serverFilters.skip = event.page.skip;
      this.serverFilters.take = event.page.take;
      this.debouncedGetDataFromApi();
    },
    getDataFromApi() {
      this.loader = true;
      getMyEffortsStudent( this.studentId, this.serverFilters, this.search )
      .then(
          result => {
            this.student = result.studentName + ' - ';
            result.efforts.forEach( element => {
            this.gridData.items.push( element[0] );
        } );
        this.loader = false;
      } ).catch( ex => {
        console.log( ex );
        this.loading = false;
      } );
    },
  }
}
</script>
<style>
.border-tab {
  border: solid 1px #a9a5a5;
}

.student {
  background-color: lightgray;
}

.teacher {
  background-color: #cbe8cb;
}

.myefforts {
  overflow-x: auto;
}
</style>
