<!-- check unresolved disabled -->
<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>
        Status
      </v-toolbar-title>
      <v-chip
        class="mr-3"
        color="green"
        text-color="white"
        size="large"
      >
        <v-avatar class="green darken-4 move-avatar">
          <b style="color:white">
            {{ incomplete.teacherCount }}
          </b>
        </v-avatar>

        <v-btn
          class="ml-2"
          rounded="lg"
          size="x-small"
          variant="flat"
          :disabled="isDisabled(UserType.Teacher)"
          @click="loadTeachersDialog()"
        >
          Send Teachers Reminder
        </v-btn>
      </v-chip>
      <v-chip
        class="mr-3"
        color="green"
        text-color="white"
        size="large"
      >
        <v-avatar class="green darken-4 move-avatar">
          <b style="color:white">
            {{ incomplete.studentCount }}
          </b>
        </v-avatar>
        <v-btn
          class="ml-2"
          rounded="lg"
          size="x-small"
          variant="flat"
          :disabled="isDisabled(UserType.Student)"
          @click="loadStudentsDialog()"
        >
          Send Students Reminder
        </v-btn>
      </v-chip>
      <v-btn
        :disabled="isDisabled() || selected.length === 0"
        variant="elevated"
        class="ml-3"
        @click="loadReminderDialog()"
      >
        Send Reminder
      </v-btn>
      <v-btn
        variant="elevated"
        class="ml-3"
        :disabled="incomplete.total === 0"
        @click="downloadMissing()"
      >
        Download Missing
      </v-btn>
    </v-toolbar>

    <!-- Selected Reminders Dialog -->
    <v-dialog
      v-model="reminder.showDialog"
      max-width="65%"
    >
      <v-card>
        <v-card-title>
          Reminder message for selected items
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="reminder.subject"
            label="Subject"
            required
          />
        </v-card-text>
        <v-card-text>
          <v-textarea
            v-model="reminder.message"
            height="5em"
            name="input-7-1"
            label="Message"
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            @click.stop="reminder.showDialog=false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            variant="elevated"
            @click="sendReminders()"
          >
            Send Reminders
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- teachers Reminder Dialog-->
    <v-dialog
      v-model="reminder.showTeacherDialog"
      max-width="50%"
    >
      <v-card>
        <v-card-title>
          Reminder message for teachers
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="reminder.subject"
            label="Subject"
            required
          />
        </v-card-text>
        <v-card-text>
          <v-textarea
            v-model="reminder.message"
            height="5em"
            name="input-7-1"
            label="Message"
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            @click.stop="reminder.showTeacherDialog=false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            variant="elevated"
            @click="sendReminders(UserType.Teacher)"
          >
            Send Reminder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- student Reminder Dialog-->
    <v-dialog
      v-model="reminder.showStudentDialog"
      max-width="50%"
    >
      <v-card>
        <v-card-title>
          Reminder message for students
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="reminder.subject"
            label="Subject"
            required
          />
        </v-card-text>
        <v-card-text>
          <v-textarea
            v-model="reminder.message"
            height="5em"
            name="input-7-1"
            label="Message"
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            variant="text"
            @click.stop="reminder.showStudentDialog=false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            variant="elevated"
            @click="sendReminders(UserType.Student)"
          >
            Send Reminder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container
      class="pa-0 ma-0 mw-100 w-100"
      style="position: relative"
    >
      <k-grid
        ref="grid"
        class="statusGrid gridFullHeight"
        :data-items="gridData.items"
        :sort="sort"
        :sortable="sortable"
        :skip="serverFilters.skip"
        :take="serverFilters.take"
        :total="gridData.totalItems"
        :pageable="pageable"
        :filter="filter"
        :filterable="true"
        :page-size="globalStore.defaultRowsPerPage"
        :columns="gridData.columns"
        :loader="loader"
        @filterchange="filterChangeHandler"
        @sortchange="sortChangeHandler"
        @pagechange="pageChangeHandler"
      >
        <template #filterSlotName="{ props }">
          <div>
            <k-text-box
              v-model="filters.name.filter"
              :show-clear-button="true"
              @change="
                (ev) => {
                  props.onChange({
                    operator: 'contains',
                    field: props.field,
                    value: ev.value,
                    syntheticEvent: ev
                  });
                }
              "
            />
          </div>
        </template>

        <template #filterSlotComplete="{ props }">
          <div>
            <k-drop-down-list
              v-model="filters.complete.defaultValue"
              :fill-mode="'flat'"
              :data-items="filters.complete.options"
              :text-field="'text'"
              :value-field="'value'"
              @change="
                (ev) => {
                  props.onChange({
                    operator: 'contains',
                    field: props.field,
                    value: ev.value.value,
                    syntheticEvent: ev
                  });
                }
              "
            />
          </div>
        </template>
        <template #filterSlotCohort="{ props }">
          <div>
            <k-drop-down-list
              v-model="filters.cohort.defaultValue"
              :fill-mode="'flat'"
              :data-items="filters.cohort.options"
              :text-field="'text'"
              :value-field="'value'"
              @change="
                (ev) => {
                  props.onChange({
                    operator: 'contains',
                    field: props.field,
                    value: ev.value.value,
                    syntheticEvent: ev
                  });
                }
              "
            />
          </div>
        </template>
        <template #filterSlotYear="{ props }">
          <div>
            <k-drop-down-list
              v-model="filters.year.defaultValue"
              :fill-mode="'flat'"
              :data-items="filters.year.options"
              :text-field="'text'"
              :value-field="'value'"
              @change="
                (ev) => {
                  props.onChange({
                    operator: 'contains',
                    field: props.field,
                    value: ev.value.value,
                    syntheticEvent: ev
                  });
                }
              "
            />
          </div>
        </template>
        <template #filterSlotRole="{ props }">
          <div>
            <k-drop-down-list
              v-model="filters.role.defaultValue"
              :fill-mode="'flat'"
              :data-items="filters.role.options"
              :text-field="'text'"
              :value-field="'value'"
              @change="
                (ev) => {
                  props.onChange({
                    operator: 'contains',
                    field: props.field,
                    value: ev.value.value,
                    syntheticEvent: ev
                  });
                }
              "
            />
          </div>
        </template>
        <template #loader>
          <div class="k-loader-container k-loader-container-md k-loader-top">
            <div class="k-loader-container-overlay k-overlay-dark" />
            <div class="k-loader-container-inner">
              <k-loader
                :size="'large'"
                :type="'pulsing'"
                :theme-color="'success'"
              />
            </div>
          </div>
        </template>
        <template #completed="{ props }">
          <td>
            <v-checkbox
              v-model="props.dataItem.isComplete"
              disabled
            />
          </td>
        </template>
        <template #sendReminder="{ props }">
          <td>
            <v-checkbox
              v-model="selected"
              :value="props.dataItem.email"
              :disabled="reminder.disabled || props.dataItem.isComplete"
            />
          </td>
        </template>
        <template #myTemplate="{props}">
          <td
            class="k-table-td"
          >
            <v-btn

              variant="text"
              class="ma-2 pa-0"
              density="compact"
              color="success"
              :to="makeLink(props.dataItem)"
            >
              Effort grades
              <v-icon
                class="mr-4"
                icon="mdi-clipboard-check"
              />
              <v-tooltip activator="parent">
                Effort grades
              </v-tooltip>
            </v-btn>
          </td>
        </template>
      </k-grid>
    </v-container>
  </v-card>
</template>

<script>

import {downloadFile, initialize,} from '@/utils/effort-tracking-api';
import {getPeriod} from '@/api/periodApi';
import {
  getPeriodDetails,
  getPeriodStatus,
  getFilteredStatus,
  getMissingReport,
  sendReminders
} from '@/api/statusApi';
import {globalStore} from '@/main';
import {debounce} from 'lodash';
import {UserType} from '@/utils/settings';
import {Loader as KLoader} from '@progress/kendo-vue-indicators';
import {Grid as KGrid} from '@progress/kendo-vue-grid';
import {DropDownList as KDropDownList} from '@progress/kendo-vue-dropdowns';
import {TextBox as KTextBox} from '@progress/kendo-vue-inputs';
import {getFilePath} from '@/api/dataApi';



export default {
  components: {
    KTextBox,
    KDropDownList,
    KGrid,
    KLoader,
  },
  data() {
    return {
      filter: {
        logic: 'and',
        filters: [],
      },
      filters: {
        role: {
          defaultValue: {text: 'All', value: 'all'},
          options: [
            {text: 'All', value: 'all'},
            {text: 'Student', value: UserType.Student},
            {text: 'Teacher', value: UserType.Teacher}
          ],
        },
        name: {
          filter: '',
        },
        complete: {
          defaultValue: {text: 'All', value: 'all'},
          options: [
            {text: 'All', value: 'all'},
            {text: 'Complete', value: 'true'},
            {text: 'Incomplete', value: 'false'}
          ],
        },
        year: {
          defaultValue: {text: 'All', value: 'all'},
          options: [{text: 'All', value: 'all'}]
        },
        cohort: {
          defaultValue: {text: 'All', value: 'all'},
          options: [{text: 'All', value: 'all'}]
        }
      },

      search: '',
      // Does not work as a child property.
      sort: [
          {
            dir: 'desc',
            field: 'role'
          },
          {
            dir: 'desc',
            field: 'isComplete'
          },
          {
            dir: 'asc',
            field: 'name'
          }
      ],
      loader: false,
      serverFilters: {
        sortBy: '',
        filter: '',
        take: globalStore.defaultRowsPerPage,
        skip: 0,
        descending: false
      },
      pagerValues: {
        pagerType: globalStore.pagerTypes['numeric'],
        pagerButtons: globalStore.pagerButtons,
        pagerInfo: globalStore.pagerInfo,
        pagerSizes: globalStore.pagerSizes,
        pagePrevNext: globalStore.pagerSizes,
        pageSize: globalStore.defaultRowsPerPage,
        pageSizeValue: globalStore.defaultRowsPerPage,
      },
      gridData: {
        columns: [
          {field: 'isComplete', title: 'Completed', cell: 'completed', filterCell: 'filterSlotComplete'},
          {field: 'name', title: 'Name', filterCell: 'filterSlotName'},
          {field: 'role', title: 'Role', filterCell: 'filterSlotRole'},
          {field: 'year', title: 'Year', filterCell: 'filterSlotYear'},
          {field: 'cohort', title: 'Cohort', filterCell: 'filterSlotCohort'},
          {
            field: 'sendReminder',
            title: 'Send Reminder',
            cell: 'sendReminder',
            sortable: false,
            filterable: false
          },
          {field: '', cell: 'myTemplate', sortable: false, filterable: false},
        ],
        items: [],
        totalItems: 0,
      },
      incomplete: {
        studentCount: 0,
        teacherCount: 0,
        total: 0
      },
      selected: [],
      reminder: {
        disabled: false,
        showDialog: false,
        showTeacherDialog: false,
        showStudentDialog: false,
        subject: 'Effort Grading Reminder',
        message: this.makeMessage(),
      }
    };
  },
  computed: {
    UserType() {
      return UserType;
    },
    globalStore() {
      return globalStore;
    },
    pageable() {
      return {
        buttonCount: this.pagerValues.pagerButtons,
        info: this.pagerValues.pagerInfo,
        type: this.pagerValues.pagerType,
        pageSizes: this.pagerValues.pagerSizes
                   ? globalStore.allowedRowsPerPage
                   : undefined,
        previousNext: this.pagerValues.pagePrevNext,
        pageSizeValue: this.pagerValues.pageSizeValue,
      };
    },
    sortable() {
      return {
        mode: 'multiple'
      };
    },
  },
  watch: {
    search: {
      handler() {
        console.log('Search changed...');
        this.debouncedGetDataFromApi();
      },
    },
  },
  created() {
    initialize()
      .then(() => {
        getPeriodDetails(globalStore.selectedPeriod)
          .then(result => {
            if(result.years !== '') {
              result.years.forEach(year => {
                this.filters.year.options.push({text: year.name, value: year.name});
              });

              result.cohorts.forEach(
                cohort => {
                  this.filters.cohort.options.push({text: cohort.name, value: cohort.name});
                });
            }
          })
          .catch(ex => console.log(ex));
      });

    this.debouncedGetDataFromApi = debounce(() => {
      this.getDataFromApi();
    }, 500);
  },
  mounted() {
    console.log('on status page mounted');
    this.serverFilters.sortBy = this.sort.map(s=> `${s.field} ${s.dir}`);
    this.getPeriodStatus();
    this.debouncedGetDataFromApi();

  },
  methods: {
    isDisabled: function(userType) {
      if(userType === UserType.Student && this.incomplete.studentCount === 0 || this.reminder.disabled) {
        return true;
      }
      else if(userType === UserType.Teacher && this.incomplete.teacherCount === 0 || this.reminder.disabled) {
        return true;
      }
      else {
        return this.reminder.disabled;
      }
    },
    filterChangeHandler: function(ev) {
      this.loader = true;
       if (typeof(this.serverFilters.sortBy) !== 'string' ){
        this.serverFilters.sortBy = this.serverFilters.sortBy.join();
       }
      this.serverFilters.filter = '{';

      ev.filter.filters.forEach(f => {
        if(this.serverFilters.filter.length > 1) {
          this.serverFilters.filter = this.serverFilters.filter + ',';
        }
        this.serverFilters.filter = `${this.serverFilters.filter}"${f.field}":"${f.value}"`;
      });
      this.serverFilters.filter += '}';
      if(this.serverFilters.filter.length > 3) {
        getFilteredStatus(globalStore.selectedPeriod, this.serverFilters)
          .then(data => {
            this.gridData.items = data.data.results.items;
            this.filter = ev.filter;
            this.loader = false;
          });
      }
    },
    sortChangeHandler: function(e) {
      if(e.sort.length === 0) {
        this.serverFilters.descending = false;
        this.debouncedGetDataFromApi();
        this.sort = e.sort;

        return;
      }
      console.log('e.sort', e.sort);
      this.sort = e.sort;
      this.serverFilters.descending = e.sort[0].dir !== 'asc';
      this.serverFilters.sortBy = e.sort.map(s=> `${s.field} ${s.dir}`);
      this.debouncedGetDataFromApi();

    },
    pageChangeHandler: function(event) {
      console.log('event', event);
      this.pagerValues.pageSize = event.page.take;
      this.pagerValues.pageSizeValue = event.page.take;
      this.serverFilters.skip = event.page.skip;
      this.serverFilters.take = event.page.take;
      this.debouncedGetDataFromApi();
    },
    getDataFromApi: function() {
      this.loader = true;
      getPeriodStatus(globalStore.selectedPeriod, this.serverFilters)
        .then(data => {
          this.incomplete.total = data.totalMissing;
          this.incomplete.studentCount = data.missingStudents;
          this.incomplete.teacherCount = data.missingTeachers;

          this.gridData.items = data.results.items;
          this.gridData.totalItems = data.results.count;

          this.loader = false;
        });
    },
    makeMessage() {
      return '\n This is a reminder that you have not completed your effort grading. '
        + '\n Please log in with your email address and password and complete your grades as soon as possible';
    },
    getPeriodStatus() {
      initialize()
        .then(() => {
          getPeriod(globalStore.selectedPeriod)
            .then(
              result => {
                this.reminder.disabled = !result.active || result.complete;
              })
            .catch(ex => console.log(ex));
          this.reminder.disabled = false;
        });
    },
    loadTeachersDialog() {
      this.reminder.showTeacherDialog = true;
    },
    loadStudentsDialog() {
      this.reminder.showStudentDialog = true;
    },
    loadReminderDialog() {
      this.reminder.showDialog = true;
    },
    sendReminders(role) {
      this.reminder.showDialog = false;
      this.reminder.showTeacherDialog = false;
      this.reminder.showStudentDialog = false;
      if(this.selected.length > 0) {
        role = UserType.Admin;
      }
      if(this.incomplete.total < 0) {
        sendReminders(role, {
          Emails: this.selected,
          Subject: this.reminderSubject,
          Message: this.reminderMessage
        })
          .then(() => {
            this.reminderSubject = 'Effort Grading Reminder';
            this.reminderMessage = this.makeMessage();
            this.selected = [];
          });
      }
    },
    makeLink(item) {
      if(item.role === UserType.Teacher) {
        return {name: 'TeacherEfforts', params: {teacherId: item.id}};
      }
      if(item.role === UserType.Student) {
        return {name: 'StudentEfforts', params: {studentId: item.id}};
      }
    },
    downloadMissing() {
      const periodId = localStorage.getItem('period-selected');
      this.loading = true;
      getMissingReport(periodId)
        .then(fileName => {
          this.loading = false;
          const fileUrl = getFilePath(fileName);
          downloadFile(fileUrl, fileName);
        })
        .catch(ex => console.log(ex));
    }
  }
};
</script>
<style>
.statusGrid .v-input__details {
  display: none !important;
}

.statusGrid .k-picker-solid,
.statusGrid .k-input-solid {
  background-color: unset !important;
}

.statusGrid .k-picker-solid:focus-within,
.statusGrid .k-input-solid:focus-within,
.statusGrid .k-focus {
  border-top: 0;
  border-radius: 0;
  border-color: #66bb6a !important;
}

.statusGrid .mdi-checkbox-marked::before {
  color: #66bb6a !important;
}

.statusGrid .k-table-th {
  border-inline-start-width: 0 !important;
}

.statusGrid tr.k-table-row.k-filter-row > td {
  border-left-width: 0 !important;
}

.k-selected {
  color: #66bb6a !important;
}
</style>
