<template>
  <img
    v-if="score === 1"
    alt="1"
    class="scoreBar"
    src="/baseline_signal_cellular_0_bar_black_18dp.png"
  >
  <img
    v-if="score === 2"
    alt="2"
    class="scoreBar"
    src="/baseline_signal_cellular_1_bar_black_18dp.png"
  >
  <img
    v-if="score === 3"
    alt="3"
    class="scoreBar"
    src="/baseline_signal_cellular_2_bar_black_18dp.png"
  >
  <img
    v-if="score === 4"
    alt="4"
    class="scoreBar"
    src="/baseline_signal_cellular_3_bar_black_18dp.png"
  >
  <img
    v-if="score === 5"
    alt="5"
    class="scoreBar"
    src="/baseline_signal_cellular_4_bar_black_18dp.png"
  >
</template>

<script>
  export default {
    name: 'ScoreBar',
    props: {
      score:{
        type: Number,
        required: true,
      }
    }
  }
</script>

<style scoped>
.scoreBar {
  vertical-align: middle;
  height: 1.5em;
  width: 1.5em;
}
</style>
