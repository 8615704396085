import {
    BASE_URL,
    deleteData,
    postData,
    getDataFromUrl,
    postMultiPartFormData,
} from '@/utils/effort-tracking-api';

import {Routes} from '@/utils/settings'

export function removeImage(setting){
    const url = `${BASE_URL}${Routes.SettingsRoute}/image/${setting}`;
    return deleteData(url);
}

export function saveImage(setting, data ) {
    const url = `${BASE_URL}${Routes.SettingsRoute}/image/${setting}`;
    return postMultiPartFormData(url, data);
}

export function bulkUpdateSetting(model){
    const url = `${BASE_URL}${Routes.SettingsRoute}/bulk`;
    return postData(url, model);
}

export function updateSetting(model){
    const url = `${BASE_URL}${Routes.SettingsRoute}`;
    return postData(url, model);
}

export function getSettingByKey(key){
    const url = `${BASE_URL}${Routes.SettingsRoute}/key/${key}`;
    return getDataFromUrl(url);
}

export function getSettingsContainingKey(key) {
    const url = `${BASE_URL}${Routes.SettingsRoute}/contains/${key}`;
    return getDataFromUrl(url);
}

export function getGradingButtonSettings(){
    const url = `${BASE_URL}${Routes.SettingsRoute}/GradingButton`;
    return getDataFromUrl(url);
}

export function getGradingTabSettings(){
    const url = `${BASE_URL}${Routes.SettingsRoute}/grading/tabs`;
    return getDataFromUrl(url);
}

export function getSettingsForMiddleManagers(){
    return getSettingsContainingKey('MiddleManager');
}
